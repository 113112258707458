// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_replay{
  padding: 15px 15px;
  width: max-content;
  font-weight: bold;
  border-radius: 10px;
  background-color: #2B64F5;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.disabled_button {
  background-color: rgba(43, 100, 245, 0.4);
}

.button_replay_box {
  display: flex;
  flex-direction: row-reverse;
}

.spinner-button {
  width: 32px;
  height: 32px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-left: 10px;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 

  .queue_detail{
    font-size: large;
  }`, "",{"version":3,"sources":["webpack://./src/pages/queues/queues.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,aAAa;EACb,2BAA2B;AAC7B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,gCAAgC;EAChC,kBAAkB;EAClB,qBAAqB;EACrB,sBAAsB;EACtB,sCAAsC;EACtC,iBAAiB;EACjB;;EAEA;EACA;MACI,uBAAuB;EAC3B;EACA;MACI,yBAAyB;EAC7B;EACA;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":[".button_replay{\n  padding: 15px 15px;\n  width: max-content;\n  font-weight: bold;\n  border-radius: 10px;\n  background-color: #2B64F5;\n  color: white;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.disabled_button {\n  background-color: rgba(43, 100, 245, 0.4);\n}\n\n.button_replay_box {\n  display: flex;\n  flex-direction: row-reverse;\n}\n\n.spinner-button {\n  width: 32px;\n  height: 32px;\n  border: 5px solid #FFF;\n  border-bottom-color: transparent;\n  border-radius: 50%;\n  display: inline-block;\n  box-sizing: border-box;\n  animation: rotation 1s linear infinite;\n  margin-left: 10px;\n  }\n\n  @keyframes rotation {\n  0% {\n      transform: rotate(0deg);\n  }\n  100% {\n      transform: rotate(360deg);\n  }\n  } \n\n  .queue_detail{\n    font-size: large;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
