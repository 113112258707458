// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search_user_box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.add_button{
  border-radius: 0.5rem;
  background-color: #4CAF50;
  background-size: 150%;
  color: #FFF;
  font-weight: 700;
  line-height: 2.5;
  width: 10%;

}

.add_button:disabled {
  background-color: rgb(76, 175, 80, 0.5);
}

.delist_button{
  border-radius: 0.5rem;
  background-color: #d31e1e;
  background-size: 150%;
  color: #FFF;
  font-weight: 700;
  line-height: 2.5;
  width: 80px;

}`, "",{"version":3,"sources":["webpack://./src/pages/users/creators/creatorsByCategory/creatorsByCategory.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,qBAAqB;EACrB,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,UAAU;;AAEZ;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,qBAAqB;EACrB,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,WAAW;;AAEb","sourcesContent":[".search_user_box {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.add_button{\n  border-radius: 0.5rem;\n  background-color: #4CAF50;\n  background-size: 150%;\n  color: #FFF;\n  font-weight: 700;\n  line-height: 2.5;\n  width: 10%;\n\n}\n\n.add_button:disabled {\n  background-color: rgb(76, 175, 80, 0.5);\n}\n\n.delist_button{\n  border-radius: 0.5rem;\n  background-color: #d31e1e;\n  background-size: 150%;\n  color: #FFF;\n  font-weight: 700;\n  line-height: 2.5;\n  width: 80px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
