import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";


export const fetchCountries = async (user, search) => {

  const params = `?search=${search}`

  try {
    const response = await axios.get(`${API_BASE_URL}/countries/list${params}`,
      buildHeaders(user));
    return response.data;
  } catch (error) {
    console.error(error);
  }
}