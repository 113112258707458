import { useState, useEffect } from 'react'
import './searchFilter.css'

const SearchFilter = ({ search, setSearch, searchPlaceHolder }) => {

  const [searchTerm, setSearchTerm] = useState(search)

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchTerm)
    }, 300)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  return (
    <input type="text" defaultValue={search} onChange={(e) => { setSearchTerm(e.target.value) }} placeholder={searchPlaceHolder} />
  )
}

export default SearchFilter