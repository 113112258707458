import './timeframeFilter.css'
import { useTranslation } from 'react-i18next'


const TimeframeFilter = ({
    timeframeFilter, setTimeframeFilter, timeframeFilterConfig
}) => {

    const { t } = useTranslation();
    const config = timeframeFilterConfig || ['day', 'month', 'year']

    return (
        <div className='timeframeFilterComponent'>
            {config.includes('hour') &&
                <div>
                    <input
                        type="radio"
                        name="timeframe"
                        id="hour"
                        checked={timeframeFilter === "hour"}
                        onChange={() => {
                            setTimeframeFilter("hour")
                        }}
                    />
                    <label htmlFor="hour">{t('filters.timeframe.hour')}</label>
                </div>}
            {config.includes('day') &&
                <div>
                    <input
                        type="radio"
                        name="timeframe"
                        id="day"
                        checked={timeframeFilter === "day"}
                        onChange={() => {
                            setTimeframeFilter("day")
                        }}
                    />
                    <label htmlFor="day">{t('filters.timeframe.day')}</label>
                </div>}
            {config.includes('week') &&
                <div>
                    <input
                        type="radio"
                        name="timeframe"
                        id="week"
                        checked={timeframeFilter === "week"}
                        onChange={() => {
                            setTimeframeFilter("week")
                        }}
                    />
                    <label htmlFor="week">{t('filters.timeframe.week')}</label>
                </div>}
            {config.includes('month') &&
                <div>
                    <input
                        type="radio"
                        name="timeframe"
                        id="month"
                        checked={timeframeFilter === "month"}
                        onChange={() => {
                            setTimeframeFilter("month")
                        }}
                    />
                    <label htmlFor="month">{t('filters.timeframe.month')}</label>
                </div>
            }
            {config.includes('year') &&
                <div>
                    <input
                        type="radio"
                        name="timeframe"
                        id="year"
                        checked={timeframeFilter === "year"}
                        onChange={() => {
                            setTimeframeFilter("year")
                        }}
                    />
                    <label htmlFor="year">{t('filters.timeframe.year')}</label>
                </div>
            }
        </div>
    )
}

export default TimeframeFilter