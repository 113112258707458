import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchFeatures = async (user, page, limit, startDate, endDate) => {
  try {
    const params = `?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`
    const { data } = await axios.get(`${API_BASE_URL}/trades/features${params}`,
      buildHeaders(user)
    );
    return data;
  } catch (error) {
    throw error
  }
}