import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchAllSpDrafts = async (user, page, limit) => {
  try {
    const params = `?page=${page}&limit=${limit}`
    const { data } = await axios.get(`${API_BASE_URL}/structured-products/drafts/list${params}`,
      buildHeaders(user)
    );
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchSpDraft = async (user, id) => {

  try {
    const { data } = await axios.get(`${API_BASE_URL}/structured-products/drafts/${id}/details`,
      buildHeaders(user)
    )
    return data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const saveSpDraft = async (user, id, product) => {
  try {
    const { data } = await axios.put(`${API_BASE_URL}/structured-products/drafts/${id || ''}`, product,
      buildHeaders(user)
    )
    return data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const uploadSpDraftPicture = async ({ user, id, type, picture }) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/structured-products/drafts/${id}/picture`, { type, picture },
      buildHeaders(user)
    )
    return data
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const convertDraftToSp = async (user, id) => {

  try {
    const { data } = await axios.get(`${API_BASE_URL}/structured-products/drafts/${id}/convert`,
      buildHeaders(user)
    )
    return data
  } catch (err) {
    console.error(err)
    throw err
  }
}