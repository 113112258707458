import axios from "axios";

export const withdrawFetch = async ({ user, type, page, limit, startDate, endDate, status }) => {

    try {
        const options = {
            method: 'POST',
            url: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_VERSION + "/monitoring/deposit-and-withdraw/list",
            headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user },
            data: {
                type,
                page,
                limit,
                startDate,
                endDate,
                pageCardTxs: 0,
                depositsWithdrawsFilter: status
            }
        };
        if (status === 'ALL') delete options.data.depositsWithdrawsFilter

        const transactionsData = await axios.request(options)
        const returnedData = transactionsData.data

        return returnedData
        
    } catch(err) {
        console.log(err)
    };

}