import { useState, useContext } from 'react'
import AuthContext from 'contexte/AuthContext'
import Modal from 'components/modal/Modal'
import './bankTransfer.css'
import Notification from 'components/notifications/Notification'
import { launchError, launchSuccess } from 'components/notifications'
import { useTranslation } from 'react-i18next';


const BankTransfer = ({ title, initiatePayment, verifySmsCode }) => {

  const { user } = useContext(AuthContext)
  const { t } = useTranslation();
  const [amount, setAmount] = useState('')
  const [code, setCode] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await initiatePayment(user, amount)
      setIsModalOpen(true)
    } catch (e) {
      launchError(t('notifications.actifs.bank_transfer.error'))
    }
  }

  const handleSubmitCode = async (e) => {
    e.preventDefault()
    try {
      await verifySmsCode(user, code)
      setIsModalOpen(false)
      launchSuccess(t('notifications.actifs.bank_transfer.success'))
    } catch (e) {
      launchError(t('notifications.actifs.bank_transfer.error'))
    }
  }

  const handleAmount = (e) => {
    const value = e.target.value;
    if (!value || value.match(/^\d{1,}(\.\d{0,4})?$/)) {
      setAmount(value);
    } else {
      launchError(t('notifications.actifs.bank_transfer.not_a_number'))
    }
  };

  return (
    <div>
      <Notification />
      <div className='header_box_bank_transfer_transfert'>
        <h2>{title}</h2>
      </div>
      <div>
        <form onSubmit={handleSubmit} className='form-bank-transfer'>
          <input
            id='bank-transfer-amount'
            className='bank-transfer-input-fields-modal'
            type="text"
            onInput={handleAmount}
            value={amount}/>
          <input type="submit" className='bank-transfer-button-submit' value="Valider" />
        </form>
      </div>

      {
        isModalOpen && (
          <ModalValidation isModalOpen={isModalOpen} onClose={() => setIsModalOpen(false)} handleSubmitCode={handleSubmitCode} setCode={setCode} />
        )
      }

    </div>
  )
}

export default BankTransfer

const ModalValidation = ({ isModalOpen, onClose, handleSubmitCode, setCode }) => {

  return (
    <Modal isOpen={isModalOpen} onClose={onClose} closeButton={false} >
      <div className='modal_header_bank_transfer'>
        <h2 className='modal_title_bank_transfer'>Valider le code</h2>
        <span onClick={onClose} className='bank_transfer_close_x_icone'>x</span>
      </div>
      <p className='mb-10'>Veuillez ajouter un code pour vous authentifier !</p>
      <form onSubmit={handleSubmitCode} className='form-bank-transfer'>
        <input className='bank-transfer-input-fields-modal' type="number" onInput={(e) => setCode(e.target.value)} />
        <input type="submit" className='bank-transfer-button-submit' value="Valider" />
      </form>
    </Modal>
  )
}