import './sidebar.css'
import { sidebarMenu } from 'data/sidebar'
import { useContext, useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import SidebarItem from './SidebarItem'
import AuthContext from 'contexte/AuthContext'

const Sidebar = () => {

    const [currentPath, setCurrentPath] = useState("")
    const [profile, setProfile] = useState({})
    const [openItem, setOpenItem] = useState(null)
    const { user, logout } = useContext(AuthContext)


    useEffect(() => {
        const pathname = window.location.pathname
        setCurrentPath(pathname)
        setProfile(jwt_decode(user))
    }, [])

    const toggleItem = (itemId) => {
        if (openItem === itemId) {
            setOpenItem(null);
        } else {
            setOpenItem(itemId);
        }
    }

    return (
        <div className='sidebar'>
            <a href="/" className='sidebar_logo'>
                <img src="/img/logoAdmin.svg" alt="logo goliaths admin" className='sidebar_logo'/>
            </a>
            <div className='sidebar_menu'>
                {
                    sidebarMenu.map((item, index) => {
                        return (
                            <SidebarItem
                                item={item}
                                key={index}
                                currentPath={currentPath}
                                isOpen={openItem === index}
                                onToggle={() => toggleItem(index)}
                            />
                        )
                    })
                }
            </div>
            <div className='sidebar_profil'>
                <a href="/profile" className='sidebar_profil_link'>
                    <div className={`sidebar_profil_picture`}>
                        <img src="/img/menu/profilepic.png" alt="profile pic" className={`sidebar_profil_img ${currentPath.includes('profile') ? "sidebar_profil_img_active" : ""}`} />
                    </div>
                    <div className='sidebar_profil_infos'>
                        <p className='sidebar_profil_name'>{profile.name}</p>
                        <p className='sidebar_profil_role'>Admin</p>
                    </div>
                </a>
                <div className='sidebar_profil_logout' onClick={logout}>
                    <img src="/img/menu/logoutIcon.svg" alt="logoutIcon" className='sidebar_profil_logout_icon' />
                </div>
            </div>
        </div>
    )
}

export default Sidebar