import { useState, useEffect, useContext } from 'react'
import AuthContext from 'contexte/AuthContext'
import { fetchPortfolio } from 'services/fetchUserById'
import PageLoader from 'components/pageLoader/PageLoader'
import Tableau from 'components/DataComponents/Tableau/Tableau'
import './UserPortfolio.css'
import { useTranslation } from 'react-i18next';
import { fetchPlaylistData } from 'services/fetchPlaylistData'
import Notification from 'components/notifications/Notification'
import { launchError } from 'components/notifications'
import ScrollBar from 'components/Scrollbar/Scrollbar'
import TickerImage from 'components/DataComponents/TickerImage/TickerImage'


const transformeDataToTbody = (data, callFromModal = false) => {
  const sortedData = [...data]

  if (callFromModal) {
    let total = sortedData.shift();
    sortedData.push(total)
  }

  const getComponent = (item, isPlaylist) => {
    if (isPlaylist) {
      return (
        <div>
          <p>{item.playlistName}</p>
        </div>
      )
    } else if (item.ticker === 'TOTAL') {
      return (
        <div className="text-xs font-weight-bold me-3" style={{ color: "#000000" }}>{item.ticker}</div>
      )
    } else {
      return (
        <TickerImage ticker={item.ticker} />
      )
    }
  }

  return sortedData.length && sortedData.map((item, index) => {
    if (!item) return { data: [] }

    const isPlaylist = !item.playlist_id?.startsWith('UNIQUE') && !callFromModal

    return {
      data: [
        <div className='arrayTickerItem'>
          {getComponent(item, isPlaylist)}
        </div>,
        isPlaylist ? '-> voir détail' : item.qty,
        isPlaylist ? '-> voir détail' : item.avg_entry_price,
        isPlaylist ? '-> voir détail' : item.cost_basis,
        isPlaylist ? '-> voir détail' : item.marketValue,
        isPlaylist ? '-> voir détail' : <div className={`portfolio_line_${item.unrealizedPl >= 0 ? 'gain' : 'loss'}`}>{item.unrealizedPl} {item.unrealizedPlpc ? `(${item.unrealizedPlpc}%)` : null}</div>
      ],
    }
  })
}


const UserPortfolio = ({ id }) => {

  const { user } = useContext(AuthContext)
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [portfolio, setPortfolio] = useState([])
  const [page, setPage] = useState(0)
  const [fetchLimit, setFetchLimit] = useState(10)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [playlistData, setPlaylistData] = useState([]);
  const [playlistName, setPlaylistName] = useState(null);

  const tableHeaders = ['Ticker', 'Quantité', "Prix d'entrée moyen", 'Coût de base', 'Valeur actuelle', 'Plus value latente']


  const fetchData = async () => {
    try {
      const data = await fetchPortfolio(user, id, page, fetchLimit)
      setPortfolio(data)
      setLoading(false)
    } catch {
      console.log('error')
    }
  }

  useEffect(() => {
    fetchData()
  }, [page])

  const onClickFunction = (index) => {
    const selectedRow = portfolio[index];
    if (selectedRow.type === 'PLAYLIST') {
      fetchPlaylistDetail(selectedRow.playlist_id)
      setPlaylistName(selectedRow.playlistName)
      setIsModalOpen(true);
    }
  }


  const fetchPlaylistDetail = async (playlist_id) => {
    try {
      setLoading(true)
      const data = await fetchPlaylistData(user, id, playlist_id);
      setPlaylistData(data);
    } catch (error) {
      launchError({ message: 'Error getting playlist detail' });
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      {
        !loading ? (
          <div>
            <Notification />
            <Tableau
              tbody={transformeDataToTbody(portfolio)}
              thead={tableHeaders}
              limit={fetchLimit}
              pagination={true}
              setPage={setPage}
              page={page}
              hadModal
              onClickFunction={onClickFunction}
            />
            <ModalPlaylistDetail
              isModalOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              playlistData={playlistData}
              tableHeaders={tableHeaders}
              playlistName={playlistName}
            />
          </div>
        ) : (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        )
      }
    </div>
  )
}

export default UserPortfolio


const ModalPlaylistDetail = ({ isModalOpen, onClose, playlistData, playlistName, tableHeaders }) => {
  const modalClass = isModalOpen ? 'modal-playlist-overlay open' : 'modal-playlist-overlay';

  const { t } = useTranslation()

  return (
    <div className={modalClass}>
      <div className="modal-playlist-container">
        <button className="close-button" onClick={onClose}>
          X
        </button>
        <h2 className='modal-playlist_title'>{t("pages.user.portfolio.modalPlaylistDetails.title", { playlistName })}</h2>
        <div className="playlist-details">
          <ScrollBar className='modal-playlist-scrollbar'>
            <Tableau
              tbody={transformeDataToTbody(playlistData, true)}
              thead={tableHeaders}
            />
          </ScrollBar>
        </div>
        <div className='modal-playlist-button-div'>
          <button className='close' onClick={onClose}>{t("pages.user.portfolio.modalPlaylistDetails.close")}</button>
        </div>
      </div>
    </div>
  )
}