import { useTranslation } from 'react-i18next';
import { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom';
import './creatorsByCategory.css'
import AuthContext from 'contexte/AuthContext';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import Tableau from 'components/DataComponents/Tableau/Tableau';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import { addCreatorToCategory, removeCreatorFromCategory, fetchCategoryDetails, fetchCreatorsByCategory } from 'services/creatorServices';
import PageLoader from 'components/pageLoader/PageLoader';
import TableUser from 'components/TableUser/TableUser';
import SearchUser from 'components/SearchUser/SearchUser';
import { searchUsers } from 'services/fetchUsers';
import PageTitleWithPicture from 'components/PageTitleWithPicture/PageTitleWithPicture';
import Notification from 'components/notifications/Notification';
import { launchError, launchSuccess } from 'components/notifications';

const CreatorsByCategory = () => {

  const { t } = useTranslation();
  const { user } = useContext(AuthContext)
  const params = useParams();

  const [loading, setLoading] = useState(true)
  const [creators, setCreators] = useState([])
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [newCreator, setNewCreator] = useState({});
  const [searchedCreators, setSearchedCreators] = useState([])
  const [details, setDetails] = useState({})

  const fetchData = async () => {
    try {
      setLoading(true)
      const data = await fetchCreatorsByCategory({ user, page, limit, category_id: params.id })
      setCreators(data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const searchCreators = async (query) => {
    try {
      const data = await searchUsers(user, query)
      setSearchedCreators(data)
    } catch (e) {
      console.log(e)
    } finally {
    }
  }

  const fetchDetails = async () => {
    try {
      setLoading(true)
      const data = await fetchCategoryDetails({ user, category_id: params.id })
      setDetails(data)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const addCreator = async () => {
    try {
      await addCreatorToCategory({ user, categoryId: params.id, creatorId: newCreator.uuid })
      launchSuccess(t('notifications.users.creators.add_to_category.success', { category: details.name }))
      setCreators(prev => [...prev, newCreator])
      setNewCreator({});
      setSearchedCreators([])
    } catch (error) {
      launchError(t('notifications.users.creators.add_to_category.error', { category: details.name }))
    }
  }

  const removeCreator = async (event, uuid) => {
    try {
      event.stopPropagation()
      await removeCreatorFromCategory({ user, categoryId: params.id, creatorId: uuid })
      launchSuccess(t('notifications.users.creators.remove_from_category.success', { category: details.name }))
      setCreators(prev => {
        const index = creators.findIndex((item => item.uuid === uuid));
        const newCreators = [...prev];
        newCreators.splice(index, 1)
        return newCreators
      })
      setNewCreator({});
      setSearchedCreators([])
    } catch (error) {
      launchError(t('notifications.users.creators.remove_from_category.error', { category: details.name }))
    }
  }

  useEffect(() => {
    fetchData()
  }, [page, limit])

  useEffect(() => {
    fetchDetails()
  }, [params.id])

  const transformDataToTbody = (data) => {
    return data?.map((item) => {
      return {
        data: [

          <TableUser
            firstName={item.firstName}
            lastName={item.lastName}
            picture={item.uuid}
            key={item.uuid}
          />,
          item.email,
          <button className='delist_button' onClick={(e) => removeCreator(e, item.uuid)}>Délister</button>
        ],
        utils: {
          id: item.uuid
        }
      }
    })
  }


  const handleFetchLimit = (newLimit) => {
    setLimit(newLimit);
    setPage(0)
  }

  return (
    <DashboardLayout page={t("pages_name.categorie")}>
      <Notification />
      <div>
        {!loading ? (
          <div>
            <div className='externalDeposit_titleComponent'>
              <PageTitleWithPicture
                title={`Créateur de la catégorie : ${details.name}`}
                picture={details.name_en}
                type='category'
              />
              <FilterComponent
                limit
                fetchLimit={limit}
                setFetchLimit={handleFetchLimit}
              />
            </div>

            <div className='search_user_box'>
              <SearchUser
                selectUser={setNewCreator}
                selectedUser={newCreator}
                handleSearch={searchCreators}
                users={searchedCreators}
              />
              <button
                className={'add_button'}
                disabled={!newCreator.uuid}
                onClick={addCreator}
              >Ajouter</button>


            </div>


            <div className="table-container">
              <div className="table-column">
                <Tableau
                  thead={['Créateur', 'Email', 'Action']}
                  tbody={transformDataToTbody(creators)}
                  pagination
                  limit={limit}
                  page={page}
                  setPage={setPage}
                  hadLink={true}
                  linkPath={'/user/'}
                />
              </div>
            </div>
          </div>
        ) : (
          <PageLoader />
        )}
      </div>
    </DashboardLayout>
  )
}

export default CreatorsByCategory