import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchIbans = async (user, page, limit, startDate, endDate) => {

    try {
        const params = `?&startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`
        const { data } = await axios.get(`${API_BASE_URL}/payouts/iban-verifications/pending/list${params}`,
            buildHeaders(user))
        return data
    } catch (err) {
        throw err
    }

}

export const fetchUserIbans = async (user, user_uuid) => {

    try {
        const { data } = await axios.get(`${API_BASE_URL}/payouts/ibans/user/${user_uuid}`, buildHeaders(user))
        return data
    } catch (err) {
        throw err
    }

}

export const approveIban = async (user, bank_account_id) => {
    try {
        await axios.post(`${API_BASE_URL}/payouts/iban/approve`,
            { bank_account_id },
            buildHeaders(user))
    } catch (error) {
        console.error(error)
        throw error;
    }
}

export const rejectIban = async (user, bank_account_id, reason) => {
    try {
        await axios.post(`${API_BASE_URL}/payouts/iban/reject`,
            { bank_account_id, reason },
            buildHeaders(user))
    } catch (error) {
        console.error(error)
        throw error;
    }
}
