import { useState, useContext } from 'react'
import ResponseContext from 'contexte/ToasterContext'
import Sidebar from "../../menu/sidebar/Sidebar"
import './dashboardLayout.css'
import { traductions } from '../../../data/traduction'
import { useTranslation } from 'react-i18next';


const DashboardLayout = ({ children, page }) => {

    const [tradOpen, setTradOpen] = useState(false)
    const { responseToaster, errorToaster, loadingToaster } = useContext(ResponseContext)

    const { t, i18n: translationI18n } = useTranslation();

    const changeLanguage = (languageCode) => {
        translationI18n.changeLanguage(languageCode);
        setTradOpen(false);
    };

    const currentLanguage = translationI18n.language;
    const currentTraduction = traductions.find(trad => trad.code === currentLanguage);


    return (
        <div className="dashboardlayout">
            <Sidebar />
            <div className="dashboardlayout_content">
                <div className="dashboardlayout_header">
                    <div className='dashboardlayout_history'>
                        <div className='dashboardlayout_historyButton'>
                            <div className='dashboardlayout_historyBack' onClick={() => { window.history.back() }}>
                                <img src="/img/menu/openMenu.svg" alt="" />
                            </div>
                            <div className='dashboardlayout_historyNext' onClick={() => { window.history.forward() }}>
                                <img src="/img/menu/openMenu.svg" alt="" />
                            </div>
                        </div>
                        <p className="dashboardlayout_title">{page}</p>
                    </div>
                    <div className="dashboardlayout_traduction" onClick={() => { setTradOpen(!tradOpen) }}>
                        <img src="/img/menu/openMenu.svg" alt="" />
                        <span>{currentTraduction ? currentTraduction.name : "Langue non définie"}</span>
                    </div>

                    <div className={`dashboardlayout_traductionModal ${tradOpen ? "dashboardlayout_traductionModalOpen" : ""}`}>
                        <div className='dashboardlayout_traductionListe'>
                            {
                                traductions.map((lang, i) => {
                                    return (
                                        <p className='dashboardlayout_traductionItem' key={lang.code} onClick={() => { changeLanguage(lang.code) }}>{lang.name}</p>
                                    )
                                })
                            }
                        </div>
                    </div>

                </div>
                <div>
                    {children}
                </div>

                {loadingToaster && (
                    <div className="toaster-component">
                        <div className='toaster-relative'>
                            <div className='toaster loading-toaster'>
                                <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_5951_23699)">
                                        <rect width="38" height="38" rx="5.9375" fill="white" />
                                        <path d="M38.0039 0.078125H0.078125V38.0039H38.0039V0.078125Z" fill="#2B64F5" />
                                        <path d="M18.7426 27.106C16.2262 27.2158 14.212 26.6097 12.6992 25.2881C11.1861 23.966 10.383 22.228 10.2889 20.0729C10.1896 17.7977 10.9774 15.879 12.6524 14.3176C14.3275 12.7559 16.6238 11.9115 19.5417 11.7841C21.8434 11.6836 23.8125 12.0137 25.4484 12.7731C27.0836 13.5334 28.1408 14.6336 28.6197 16.0743L23.0782 17.2213C22.472 15.9339 21.3125 15.3272 19.5995 15.402C18.2745 15.4599 17.2798 15.8753 16.6167 16.6488C15.9531 17.4218 15.65 18.4644 15.7073 19.7758C15.7605 20.9941 16.1608 21.9484 16.9085 22.64C17.6558 23.3313 18.6857 23.6487 19.9974 23.5914C21.1884 23.5394 22.1321 23.2702 22.8284 22.7839C23.5246 22.2978 23.9066 21.7578 23.9747 21.1649L23.9738 21.1448L19.2757 21.3499L19.1451 18.3587L28.6613 17.9432L29.0199 26.1545L25.1051 26.3254L24.8208 23.9643C24.4262 24.7595 23.7363 25.4635 22.7506 26.0763C21.7649 26.6891 20.4289 27.0323 18.7426 27.106Z" fill="#12143A" />
                                        <path d="M17.7582 26.2075C15.2419 26.3174 13.2276 25.7113 11.7148 24.3896C10.2017 23.0676 9.39863 21.3296 9.30454 19.1745C9.2052 16.8993 9.99303 14.9805 11.6681 13.4192C13.3431 11.8575 15.6394 11.0131 18.5573 10.8857C20.8591 10.7852 22.8281 11.1152 24.464 11.8747C26.0992 12.6349 27.1565 13.7352 27.6354 15.1758L22.0938 16.3229C21.4877 15.0355 20.3281 14.4288 18.6151 14.5036C17.2901 14.5614 16.2954 14.9769 15.6323 15.7503C14.9688 16.5234 14.6656 17.566 14.7229 18.8774C14.7761 20.0957 15.1765 21.0499 15.9241 21.7416C16.6714 22.4329 17.7013 22.7503 19.013 22.693C20.204 22.641 21.1478 22.3718 21.844 21.8854C22.5402 21.3994 22.9222 20.8594 22.9903 20.2665L22.9894 20.2464L18.2914 20.4515L18.1608 17.4602L27.677 17.0447L28.0355 25.256L24.1207 25.427L23.8365 23.0659C23.4419 23.861 22.7519 24.565 21.7662 25.1779C20.7805 25.7903 19.4446 26.1339 17.7582 26.2075Z" fill="white" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5951_23699">
                                            <rect width="38" height="38" rx="5.9375" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>

                                <span>Loading...</span>
                            </div>
                        </div>
                    </div>
                )}

                {responseToaster && (
                    <div className="toaster-component">
                        <div className='toaster-relative'>
                            <div className='toaster response-toaster'>
                                <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_5951_23699)">
                                        <rect width="38" height="38" rx="5.9375" fill="white" />
                                        <path d="M38.0039 0.078125H0.078125V38.0039H38.0039V0.078125Z" fill="#2B64F5" />
                                        <path d="M18.7426 27.106C16.2262 27.2158 14.212 26.6097 12.6992 25.2881C11.1861 23.966 10.383 22.228 10.2889 20.0729C10.1896 17.7977 10.9774 15.879 12.6524 14.3176C14.3275 12.7559 16.6238 11.9115 19.5417 11.7841C21.8434 11.6836 23.8125 12.0137 25.4484 12.7731C27.0836 13.5334 28.1408 14.6336 28.6197 16.0743L23.0782 17.2213C22.472 15.9339 21.3125 15.3272 19.5995 15.402C18.2745 15.4599 17.2798 15.8753 16.6167 16.6488C15.9531 17.4218 15.65 18.4644 15.7073 19.7758C15.7605 20.9941 16.1608 21.9484 16.9085 22.64C17.6558 23.3313 18.6857 23.6487 19.9974 23.5914C21.1884 23.5394 22.1321 23.2702 22.8284 22.7839C23.5246 22.2978 23.9066 21.7578 23.9747 21.1649L23.9738 21.1448L19.2757 21.3499L19.1451 18.3587L28.6613 17.9432L29.0199 26.1545L25.1051 26.3254L24.8208 23.9643C24.4262 24.7595 23.7363 25.4635 22.7506 26.0763C21.7649 26.6891 20.4289 27.0323 18.7426 27.106Z" fill="#12143A" />
                                        <path d="M17.7582 26.2075C15.2419 26.3174 13.2276 25.7113 11.7148 24.3896C10.2017 23.0676 9.39863 21.3296 9.30454 19.1745C9.2052 16.8993 9.99303 14.9805 11.6681 13.4192C13.3431 11.8575 15.6394 11.0131 18.5573 10.8857C20.8591 10.7852 22.8281 11.1152 24.464 11.8747C26.0992 12.6349 27.1565 13.7352 27.6354 15.1758L22.0938 16.3229C21.4877 15.0355 20.3281 14.4288 18.6151 14.5036C17.2901 14.5614 16.2954 14.9769 15.6323 15.7503C14.9688 16.5234 14.6656 17.566 14.7229 18.8774C14.7761 20.0957 15.1765 21.0499 15.9241 21.7416C16.6714 22.4329 17.7013 22.7503 19.013 22.693C20.204 22.641 21.1478 22.3718 21.844 21.8854C22.5402 21.3994 22.9222 20.8594 22.9903 20.2665L22.9894 20.2464L18.2914 20.4515L18.1608 17.4602L27.677 17.0447L28.0355 25.256L24.1207 25.427L23.8365 23.0659C23.4419 23.861 22.7519 24.565 21.7662 25.1779C20.7805 25.7903 19.4446 26.1339 17.7582 26.2075Z" fill="white" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5951_23699">
                                            <rect width="38" height="38" rx="5.9375" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>

                                <span>{responseToaster}</span>
                            </div>
                        </div>
                    </div>
                )}

                {errorToaster && (
                    <div className="toaster-component">
                        <div className='toaster-relative'>
                            <div className='toaster error-toaster'>
                                <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_5951_23699)">
                                        <rect width="38" height="38" rx="5.9375" fill="white" />
                                        <path d="M38.0039 0.078125H0.078125V38.0039H38.0039V0.078125Z" fill="#2B64F5" />
                                        <path d="M18.7426 27.106C16.2262 27.2158 14.212 26.6097 12.6992 25.2881C11.1861 23.966 10.383 22.228 10.2889 20.0729C10.1896 17.7977 10.9774 15.879 12.6524 14.3176C14.3275 12.7559 16.6238 11.9115 19.5417 11.7841C21.8434 11.6836 23.8125 12.0137 25.4484 12.7731C27.0836 13.5334 28.1408 14.6336 28.6197 16.0743L23.0782 17.2213C22.472 15.9339 21.3125 15.3272 19.5995 15.402C18.2745 15.4599 17.2798 15.8753 16.6167 16.6488C15.9531 17.4218 15.65 18.4644 15.7073 19.7758C15.7605 20.9941 16.1608 21.9484 16.9085 22.64C17.6558 23.3313 18.6857 23.6487 19.9974 23.5914C21.1884 23.5394 22.1321 23.2702 22.8284 22.7839C23.5246 22.2978 23.9066 21.7578 23.9747 21.1649L23.9738 21.1448L19.2757 21.3499L19.1451 18.3587L28.6613 17.9432L29.0199 26.1545L25.1051 26.3254L24.8208 23.9643C24.4262 24.7595 23.7363 25.4635 22.7506 26.0763C21.7649 26.6891 20.4289 27.0323 18.7426 27.106Z" fill="#12143A" />
                                        <path d="M17.7582 26.2075C15.2419 26.3174 13.2276 25.7113 11.7148 24.3896C10.2017 23.0676 9.39863 21.3296 9.30454 19.1745C9.2052 16.8993 9.99303 14.9805 11.6681 13.4192C13.3431 11.8575 15.6394 11.0131 18.5573 10.8857C20.8591 10.7852 22.8281 11.1152 24.464 11.8747C26.0992 12.6349 27.1565 13.7352 27.6354 15.1758L22.0938 16.3229C21.4877 15.0355 20.3281 14.4288 18.6151 14.5036C17.2901 14.5614 16.2954 14.9769 15.6323 15.7503C14.9688 16.5234 14.6656 17.566 14.7229 18.8774C14.7761 20.0957 15.1765 21.0499 15.9241 21.7416C16.6714 22.4329 17.7013 22.7503 19.013 22.693C20.204 22.641 21.1478 22.3718 21.844 21.8854C22.5402 21.3994 22.9222 20.8594 22.9903 20.2665L22.9894 20.2464L18.2914 20.4515L18.1608 17.4602L27.677 17.0447L28.0355 25.256L24.1207 25.427L23.8365 23.0659C23.4419 23.861 22.7519 24.565 21.7662 25.1779C20.7805 25.7903 19.4446 26.1339 17.7582 26.2075Z" fill="white" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5951_23699">
                                            <rect width="38" height="38" rx="5.9375" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>

                                <span>{errorToaster}</span>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    )
}

export default DashboardLayout