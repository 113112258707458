import DashboardLayout from 'components/layout/dashboard/DashboardLayout';
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import './marginTransactionById.css';
import AuthContext from 'contexte/AuthContext';
import { launchError } from 'components/notifications';
import Notification from 'components/notifications/Notification';
import { formatDate } from 'utils/date';
import Badge from 'components/Badge/Badge';
import colors from 'assets/colors';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import TickerImage from 'components/DataComponents/TickerImage/TickerImage';
import { fetchLeverageTransactionById } from 'services/marginServices';

const MarginTransactionById = () => {

  const [loading, setLoading] = useState(true)
  const [details, setDetails] = useState([])

  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const params = useParams();

  const fetchData = async () => {

    try {
      const tradeDetail = await fetchLeverageTransactionById(user, params.id)
      setDetails(tradeDetail)
    } catch (e) {
      launchError(t('notifications.finances.margin_details.error'))
    } finally {
      setLoading(false)
    }

  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <DashboardLayout page={t("pages_name.margin_details")}>
      <Notification />
      {
        !loading ? (
          <div className='withdrawDeposit_box'>
            <div className='withdrawDepositById_header'>
              <div className='withdrawDepositById_header_name'>
                <h3>Détail de la transaction margin</h3>
              </div>
            </div>
            <div className='withdrawDepositById_items'>
              <div className='withdrawDepositById_item_box'>
                <span className='withdrawDepositById_item_title'>Client</span>
                <div className='userData_profil'>
                  <img src={`${process.env.REACT_APP_CDN_URL}/usr/pic/${details.user_uuid}`} alt="profilepic" className='user_profile_pic' />
                  <div>
                    <a href={`/user/${details.user_uuid}`}><p className='user_profil_name'>{details.firstName} {details.lastName}</p></a>
                  </div>
                </div>
              </div>
              <hr />
              <div className='withdrawDepositById_item_box'>
                <span className='withdrawDepositById_item_title'>Ticker</span>
                <span className='withdrawDepositById_item_content'>{details.ticker}</span>
              </div>
              <hr />
              <div className='withdrawDepositById_item_box'>
                <span className='withdrawDepositById_item_title'>Type</span>
                <span className='withdrawDepositById_item_content'>
                  <Badge color={colors.badge[details.type]} text={details?.type === 'BUY' ? 'ACHAT' : 'VENTE'} />
                </span>
              </div>
              <hr />
              <div className='withdrawDepositById_item_box'>
                <span className='withdrawDepositById_item_title'>Commission</span>
                <span className='withdrawDepositById_item_content'>
                  <p>Plateforme : {details.platform_fees - details.legal_fees}</p>
                  <p>Legal fees : {details.legal_fees}</p>
                  <p>Markup : {details.markup}</p>
                  <p>Total : {details.platform_fees + details.markup}</p>
                </span>
              </div>
              <hr />
              <div className='withdrawDepositById_item_box'>
                <span className='withdrawDepositById_item_title'>Status</span>
                <span className='withdrawDepositById_item_content'>
                  <Badge color={'grey'} text={details.status} />
                </span>
              </div>
            </div>

            <div className='withdrawDepositById_details_wallet'>
              <p className='withdrawDepositById_details_title'>Détails :</p>
              <div className='withdrawDepositById_details_wallet_box'>
                <div className='withdrawDepositById_details_box'>
                  <p className='withdrawDepositById_details_p withdrawDepositById_details_'><span className='margin_transaction_details_item_title'>Montant total (+frais) : </span>
                    {details.markup + details.platform_fees + details.total} €
                  </p>
                  <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='margin_transaction_details_item_title'>Total des frais :</span>
                    {details.markup + details.platform_fees} €
                  </p>
                  <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='margin_transaction_details_item_title'>Quantité :</span>{details?.amount}</p>
                  <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='margin_transaction_details_item_title'>Date de création : </span>
                    {formatDate({ date: details.created_at, month: 'long', time: true })}
                  </p>
                  <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='margin_transaction_details_item_title'>Date effective : </span>
                    {formatDate({ date: details.filled_at, month: 'long', time: true })}
                  </p>
                  <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='margin_transaction_details_item_title'>Plus value : </span>
                    {details.realized_pl} €
                  </p>
                  <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='margin_transaction_details_item_title'>Frais quotidien sur argent emprunté :</span>
                    {details.daily_fees} €
                  </p>
                </div>
                <div className='withdrawDepositById_details_box'>
                  <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='margin_transaction_details_item_title'>Argent investi :</span>
                    {details.invested} €
                  </p>
                  <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='margin_transaction_details_item_title'>Argent emprunté :</span>
                    {details.borrowed_amount} €
                  </p>
                  <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='margin_transaction_details_item_title'>Plus value : </span>
                    {details.realized_pl} €
                  </p>
                  {
                    details?.type === 'SELL' &&
                    <>
                      <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='margin_transaction_details_item_title'>Temps de détention moyen :</span>
                        {details.avg_holding_period} jour(s)
                      </p>
                      <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='margin_transaction_details_item_title'>Gains argent emprunté estimé :</span>
                        {details.borrowed_amount_gains} €
                      </p>
                      <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='margin_transaction_details_item_title'>Gains estimé :</span>
                        {details.gains} €
                      </p>
                    </>
                  }
                </div>
              </div>
            </div>
            <div>

            </div>
            <div>

            </div>
          </div>
        ) : (
          <>
            Loading..
          </>
        )
      }
    </DashboardLayout>
  )
}

export default MarginTransactionById