// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.home_action_main {
    width: 50%;
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.home_action_first {
    display: flex;
    flex-direction: row;
    gap: 12px;
    width: 65%;
}

.home_action_first_box {
    background-color: #F2F3FC;
    border: solid 1px #2B64F5;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    padding: 15px 20px;
    gap: 5px;
    border-radius: 10px;
}

.home_action_first_box p {
    margin: 0;
    font-size: 40px;
    font-weight: 800;
    color: #2B64F5;
}

.home_action_first_box_text {
    display: flex;
    gap: 5px;
    font-size: 11px;
}

.home_action_second {
    width: 35%;
    background-color: #F2F3FC;
    padding: 15px 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: auto;
    justify-content: center;
}

.home_action_second p {
    margin: 0;
    font-size: 14px;
}

.home_action_second input {
    height: 28px;
    border-radius: 4px;
    border: 0px;
    padding: 0 10px;
}

`, "",{"version":3,"sources":["webpack://./src/components/DataComponents/MainActions/mainActions.css"],"names":[],"mappings":";AACA;IACI,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,gBAAgB;IAChB,kBAAkB;IAClB,QAAQ;IACR,mBAAmB;AACvB;;AAEA;IACI,SAAS;IACT,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,QAAQ;IACR,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,eAAe;AACnB","sourcesContent":["\n.home_action_main {\n    width: 50%;\n    display: flex;\n    flex-direction: row;\n    gap: 12px;\n}\n\n.home_action_first {\n    display: flex;\n    flex-direction: row;\n    gap: 12px;\n    width: 65%;\n}\n\n.home_action_first_box {\n    background-color: #F2F3FC;\n    border: solid 1px #2B64F5;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: end;\n    padding: 15px 20px;\n    gap: 5px;\n    border-radius: 10px;\n}\n\n.home_action_first_box p {\n    margin: 0;\n    font-size: 40px;\n    font-weight: 800;\n    color: #2B64F5;\n}\n\n.home_action_first_box_text {\n    display: flex;\n    gap: 5px;\n    font-size: 11px;\n}\n\n.home_action_second {\n    width: 35%;\n    background-color: #F2F3FC;\n    padding: 15px 20px;\n    border-radius: 10px;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    height: auto;\n    justify-content: center;\n}\n\n.home_action_second p {\n    margin: 0;\n    font-size: 14px;\n}\n\n.home_action_second input {\n    height: 28px;\n    border-radius: 4px;\n    border: 0px;\n    padding: 0 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
