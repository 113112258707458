import './depositWithdrawFilter.css'
import { useTranslation } from 'react-i18next'


const DepositWithdrawFilter = ({
    isDeposit, setIsDeposit,
    isWithdraw, setIsWithdraw,
    status, setStatus
}) => {

    const { t } = useTranslation();
    return (
        <div className='depositWithdrawFilterComponent'>
            <div>
                <input
                    type="checkbox"
                    id="isDeposit"
                    checked={isDeposit}
                    onChange={() => setIsDeposit(prev => !prev)}
                />
                <label htmlFor="isDeposit">{t('filters.deposit_withdraw.deposits')}</label>
            </div>
            <div>
                <input
                    type="checkbox"
                    id="isWihdraw"
                    checked={isWithdraw}
                    onChange={() => setIsWithdraw(prev => !prev)}
                />
                <label htmlFor="isWithdraw">{t('filters.deposit_withdraw.withdraws')}</label>
            </div>
            <div>
                <label htmlFor="selectStatus">{t('filters.deposit_withdraw.status')}</label>
                <select 
                id='selectStatus'
                value={status}
                className='depositWithdrawFilterSelect'
                onChange={(e) => setStatus(e.target.value)}>
                    <option value='ALL' defaultValue>ALL</option>
                    <option value='CANCELED'>CANCELED</option>
                    <option value='REFUSED'>REFUSED</option>
                    <option value='FAILED'>FAILED</option>
                    <option value='PENDING'>PENDING</option>
                    <option value='SUCCESS'>SUCCESS</option>
                    <option value='EXECUTED'>EXECUTED</option>
                    <option value='IN PROGRESS'>REJECTED</option>
                </select>
            </div>
        </div>
    )
}

export default DepositWithdrawFilter