import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchCategories = async (user, search) => {
    try {
        const { data } = await axios.get(`${API_BASE_URL}/categories/list${search ? `?value=${search}` : ''}`, buildHeaders(user))
        return data
    } catch (e) {
        throw e
    }
}

export const fetchCategoryDetail = async (user, categoryId) => {
    try {
        const { data } = await axios.get(`${API_BASE_URL}/categories/details/${categoryId}/info`, buildHeaders(user));
        return data
    } catch (e) {
        throw e
    }
}

export const fetchCategoryTickers = async (user, category_name_en, search, page, limit) => {
    try {
        const params = `?search=${search}&page=${page}&limit=${limit}`
        const { data } = await axios.get(`${API_BASE_URL}/categories/${category_name_en}/tickers${params}`, buildHeaders(user));
        return data
    } catch (e) {
        throw e
    }
}



export const changeCategorieOrder = async (user, id, side, order) => {
    try {
        const { data } = await axios.post(`${API_BASE_URL}/categories/changeorder/${id}`, { side, order }, buildHeaders(user));
        return data
    } catch (e) {
        throw e
    }
}

export const addTickerToCategory = async (user, tickerId, categoryName) => {
    try {
        const { data } = await axios.post(`${API_BASE_URL}/categories/listing/${tickerId}`, { name_en: categoryName },
            buildHeaders(user)
        )
        return data
    } catch (err) {
        throw err
    }
}

export const removeTickerFromCategory = async (user, tickerId, categoryName) => {
    try {
        const { data } = await axios.post(`${API_BASE_URL}/categories/delist/${tickerId}`, { name_en: categoryName },
            buildHeaders(user)
        )
        return data
    } catch (err) {
        throw err
    }
}