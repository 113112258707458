import { useTranslation } from 'react-i18next';
import { useEffect, useState, useContext } from 'react'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import './gifts.css'
import AuthContext from 'contexte/AuthContext';
import { fetchGift } from 'services/fetchGift';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import { formatDate, RELEASE_DATE } from 'utils/date';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';

const Gifts = () => {

  const { t } = useTranslation();
  const { user } = useContext(AuthContext);


  const [loading, setLoading] = useState(true);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedDate, setSelectedDate] = useState({
    date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
    date2: { startDate: null, endDate: null },
  });
  const [cadeaux, setCadeaux] = useState([]);
  const [search, setSearch] = useState("")

  const [signup, setSignup] = useState(true);
  const [playlist, setPlaylist] = useState(true);
  const [referral, setReferral] = useState(true);
  const [invoice, setInvoice] = useState(true);

  const fetchData = async () => {
    try {

      const fetchGiftData = await fetchGift(user, page, fetchLimit, signup, playlist, referral, invoice, selectedDate.date1.startDate, selectedDate.date1.endDate, search)
      setCadeaux(fetchGiftData)
      setLoading(false)

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    console.log('==========Called')
    if (selectedDate.date1.startDate !== null && selectedDate.date1.endDate !== null) {
      fetchData()
    }
  }, [fetchLimit, page, signup, playlist, referral, invoice, selectedDate, search])

  function transformDataToTBody(data) {

    return data.map(item => {

      return {
        data: [
          item.type,
          item.status,
          item.qty || "" + item.amount + ' €',
          item.ticker_value || "N/A",
          item.firstName + ' ' + item.lastName,
          formatDate({ date: item.updated_at, time: true })
        ]

      };
    });
  }
  const resetFilter = () => {
    setFetchLimit(10)
  }

  const handleNextPage = () => {
    setPage(page + 1)
  }



  return (
    <DashboardLayout page={t("pages_name.cadeaux")}>
      <div>
        {!loading ? (
          <div>
            <div className='externalDeposit_titleComponent'>
              <div className='withdraw_filter_component'>

                <FilterComponent
                  limit={true} date={true} gift={true} withSearch={true}
                  search={search} setSearch={setSearch}
                  signup={signup} setSignup={setSignup}
                  playlist={playlist} setPlaylist={setPlaylist}
                  referral={referral} setReferral={setReferral}
                  invoice={invoice} setInvoice={setInvoice}
                  fetchLimit={fetchLimit} setFetchLimit={setFetchLimit} resetFilter={resetFilter}
                  selectedDate={selectedDate} setSelectedDate={setSelectedDate}
                />
              </div>
            </div>
            <Tableau
              thead={["Type", "Status", "QTY/Montant", "Ticker", "Beneficiaire", "Dernière modification"]}
              tbody={transformDataToTBody(cadeaux)}
              handleNextPage={handleNextPage}
              limit={fetchLimit}
            />
          </div>
        ) : (
          <div>Loading..</div>
        )}
      </div>
    </DashboardLayout>
  )
}

export default Gifts