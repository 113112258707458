import { useState } from "react";
import './backgroundImage.css'

function BackgroundImage({ imgSrc, fallbackSrc, style }) {
    const [backgroundImage, setBackgroundImage] = useState(imgSrc);

    const handleError = () => {
        setBackgroundImage(fallbackSrc);
    };

    return (
        <>
            <img src={imgSrc} onError={handleError} style={{ display: 'none' }} alt="" />
            <div
                className='image_background'
                style={{ ...style, backgroundImage: `url(${backgroundImage})` }}
            ></div>
        </>
    );
}

export default BackgroundImage;