// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table_user_div_flex{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.redirect {
  cursor: pointer;
}

.table_user_bg_image{
  width: "30px";
  height: "30px";
  border-radius: "50px"
}`, "",{"version":3,"sources":["webpack://./src/components/TableUser/tableUser.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,cAAc;EACd;AACF","sourcesContent":[".table_user_div_flex{\n  display: flex;\n  flex-direction: row;\n  gap: 5px;\n  align-items: center;\n}\n\n.redirect {\n  cursor: pointer;\n}\n\n.table_user_bg_image{\n  width: \"30px\";\n  height: \"30px\";\n  border-radius: \"50px\"\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
