import { useState, useContext, useEffect } from 'react'
import AuthContext from 'contexte/AuthContext'
import './etfSectorsTab.css'
import { useTranslation } from 'react-i18next';
import Notification from 'components/notifications/Notification'
import { launchError } from 'components/notifications'
import SearchSector from 'components/SearchSector/SearchSector';
import { fetchCategories } from 'services/categoriesServices';
import { getDecimalValue } from 'utils/numbers';


const EtfSectorsTab = ({ etf, editEtf }) => {

  const { user } = useContext(AuthContext)
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [newSectors, setNewSectors] = useState([])
  const [selectedSector] = useState({});
  const [sumWeight, setSumWeight] = useState(0);

  useEffect(() => {
    const sum = etf?.sectors?.reduce((acc, { weight }) => acc + Number(weight), 0);
    setSumWeight(getDecimalValue(sum));
  }, [etf.sectors]);

  const handleSectorWeight = (index, e) => {
    const newEtf = { ...etf };
    const newSectors = [...etf.sectors];
    newSectors[index].weight = Number(e.target.value);
    newEtf.sectors = newSectors;
    editEtf(newEtf);
  }

  const removeSector = (index) => {
    const newEtf = { ...etf };
    const newSectors = [...newEtf.sectors];
    newSectors.splice(index, 1);
    newEtf.sectors = newSectors;
    editEtf(newEtf);
  }

  const handleSearchSectors = async (search) => {
    if (search.length > 0) {
      try {
        setLoading(true)
        const fetchedTickers = await fetchCategories(user, search)
        setNewSectors(fetchedTickers)
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    } else {
      setNewSectors([])
    }
  }

  const handleAddNewSectors = (sector) => {
    if (sector) {
      const newEtf = { ...etf };
      const newSectors = etf.sectors ? [...etf.sectors] : [];
      newSectors.push({
        name: sector.name,
        name_en: sector.name_en,
        image: sector.image,
        weight: 0
      })
      newEtf.sectors = newSectors;
      editEtf(newEtf)
    }
  }

  return (
    <div>

      <div className='etf_sectors_list'>
        <Notification />
        <div className='etf_sectors_search_box'>

          <SearchSector
            className='etf_sectors_search_input'
            setSelectedSector={handleAddNewSectors}
            selectedSector={selectedSector}
            sectors={newSectors}
            handleSearch={handleSearchSectors}
            label={'Ajouter un pays'}
          />
        </div>

        {etf?.sectors.map((sector, index) =>
          <div key={index} className="etf_sector_item_box">
            <div className="etf_sector_item_desc">
              {sector.image && <img className="etf_sector_item_img" src={`https://cdn.goliaths.io/categories/${sector.image}`} alt="" />}
              <span className="">{sector.name}</span>
            </div>
            <input
              onChange={(e) => handleSectorWeight(index, e)}
              value={sector.weight}
              className='etf_sector_item_input'
              type='number'
            />
            <span onClick={() => removeSector(index)} className="pointer etf_sector_item_delete_button">x</span>
          </div >

        )}
      </div>

      {
        sumWeight !== 100 && (
          <div className='etf_edition_alert_repartition_box'>
            <p className='etf_edition_alert_repartition'>La somme des répartitions des secteurs n'est pas de 100 % ({sumWeight} %)</p>
          </div>
        )
      }

    </div>
  )
}

export default EtfSectorsTab