import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";


export const fetchProfitsPerStock = async ({user, page, limit, startDate, endDate, sort}) => {
  try {
      const params = `?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&sort=${sort}`
      const { data } = await axios.get(`${API_BASE_URL}/rapports/profit/action${params}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchProfits = async ({user, page, limit, startDate, endDate}) => {
  try {
      const params = `?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
      const { data } = await axios.get(`${API_BASE_URL}/rapports/profit${params}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}