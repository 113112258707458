import { useState } from 'react'
import i18n from '../../../translation/i18next'
import { useTranslation } from 'react-i18next'

const SidebarItem = ({ item, currentPath, onToggle, isOpen }) => {

    //const { t } = useTranslation();

    if (item.subMenu.length > 0) {
        return (
            <div className={`sidebar_item_col`} onClick={onToggle}>
                <div className='sidebar_item_childtop'>
                    <div className={`sidebar-info ${currentPath.includes(item.linkTo)  ? "sidebar_item_selected" : "sidebar_item_notselected"}`}></div>
                    <img src={item.icon} alt="" className='sidebar_item_icon' />
                    <p className='sidebar_item_name'>{i18n.t(`menu.${item.name}`)}</p>
                    <img src='/img/menu/openMenu.svg' alt='openMenu' className={isOpen ? "iconOpen" : "iconClose"} />
                </div>
                <div className={`sidebar_item_childbottom ${isOpen ? 'sidebar_item_childbottom-transition' : ''}`} >
                    {
                        item.subMenu.map((subItem, i) => {
                            return (
                                <div key={i}>
                                    <a href={subItem.linkTo} className="sidebar_subitem_link">
                                        <div className={`sidebar-subitem_info ${currentPath === subItem.linkTo  ? "sidebar_subitem_selected" : "sidebar_subitem_notselected"}`}></div>
                                        {i18n.t(`menu.subitem.${subItem.name}`)}
                                    </a>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    } else {
        return (

            <a href={item.linkTo} className="sidebar_item_link">
                <div className={`sidebar_item`}>
                    <div className={`sidebar-info ${currentPath === item.linkTo ? "sidebar_item_selected" : "sidebar_item_notselected"}`}></div>
                    <img src={item.icon} alt="" className='sidebar_item_icon' />
                    <p className='sidebar_item_name'>{i18n.t(`menu.${item.name}`)}</p>
                </div>
            </a>
        )
    }


}

export default SidebarItem