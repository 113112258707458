import DashboardLayout from 'components/layout/dashboard/DashboardLayout';
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import './depositById.css';
import AuthContext from 'contexte/AuthContext';
import { fetchDepositWithdrawDetail } from 'services/fetchWithdrawDetail';
import { launchError } from 'components/notifications';
import Notification from 'components/notifications/Notification';
import { formatDate } from 'utils/date';
import Badge from 'components/Badge/Badge';
import PageTitleWithUserPicture from 'components/PageTitleWithPicture/PageTitleWithPicture';

const DepositById = () => {

  const [loading, setLoading] = useState(true)
  const [dataset, setDataset] = useState([])

  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const params = useParams();

  const fetchData = async () => {

    try {

      const depositDetail = await fetchDepositWithdrawDetail({
        type: 'deposit',
        user: user,
        id: params.id,
      })
      setDataset(depositDetail)

    } catch (e) {
      launchError(t('notifications.payouts.trades.deposit.error'))
    } finally {
      setLoading(false)
    }

  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <DashboardLayout page={t("pages_name.deposit_details")}>
      <Notification />
      {
        !loading ? (
          <div className='withdrawDeposit_box'>
            <PageTitleWithUserPicture
              title={'Détails du dépôt'}
              firstName={dataset.goliathsDetails?.clientInfo?.firstName}
              lastName={dataset.goliathsDetails?.clientInfo?.lastName}
              picture={dataset.goliathsDetails?.clientInfo?.pic}
              type='user'
            />
            <div className='withdrawDepositById_items'>
              <div className='withdrawDepositById_item_box'>
                <span className='withdrawDepositById_item_title'>Dernière mise à jour</span>
                <span className='withdrawDepositById_item_content'>{formatDate({ date: dataset.goliathsDetails.updated_at, month: 'long', time: true })}</span>
              </div>
              <hr />
              <div className='withdrawDepositById_item_box'>
                <span className='withdrawDepositById_item_title'>Methode de paiement</span>
                <span className='withdrawDepositById_item_content'>{dataset.goliathsDetails.method}</span>
              </div>
              <hr />
              <div className='withdrawDepositById_item_box'>
                <span className='withdrawDepositById_item_title'>Evaluation de risque</span>
                <span className='withdrawDepositById_item_content'>
                  <span><Badge color={'grey'} text={`${dataset.stripeDetails.riskScore}`} /> - {dataset.stripeDetails.riskLevel}</span>
                </span>
              </div>
              <hr />
              <div className='withdrawDepositById_item_box'>
                <span className='withdrawDepositById_item_title'>Données overtimes (Dépôts)</span>
                <span className='withdrawDepositById_item_content'>Overtime 24H: {dataset.goliathsDetails.overtime24h} €</span>
                <span className='withdrawDepositById_item_content'>Total Overtime: {dataset.goliathsDetails.totalOvertime} €</span>
              </div>
            </div>
            <div>

              <div className='withdrawDepositById_details'>
                <div className='withdrawDepositById_details_wallet'>
                  <p className='withdrawDepositById_details_title'>Détails :</p>
                  <div className='withdrawDepositById_details_wallet_box'>
                    <div className='withdrawDepositById_details_box'>
                      <p className='withdrawDepositById_details_p withdrawDepositById_details_'><span className='userData_item_title'>ID de paiement : </span><a
                        href={`https://dashboard.stripe.com/payments/${dataset.stripeDetails.id}`} target="_blank">{dataset.stripeDetails.id}</a></p>
                      <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='userData_item_title'>ID de methode :</span>{dataset.stripeDetails.paymentMethod} €</p>
                      <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='userData_item_title'>Fingerprint :</span>{dataset.goliathsDetails.amount} €</p>
                      <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='userData_item_title'></span></p>
                      <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='userData_item_title'>Carte :</span>{`${dataset.stripeDetails.number} - ${dataset.stripeDetails.expires}`}</p>
                      <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='userData_item_title'>Marque :</span>{dataset.stripeDetails.type}</p>
                      <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='userData_item_title'>Network</span>{dataset.stripeDetails.network}</p>
                    </div>
                    <div className='withdrawDepositById_details_box'>
                      <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='userData_item_title'>Status :</span>{dataset.goliathsDetails.status}</p>
                      <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='userData_item_title'>Montant :</span>{dataset.goliathsDetails.amount}</p>
                      <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='userData_item_title'>Frais Stripe :</span>{dataset.goliathsDetails.fees}</p>
                      <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='userData_item_title'>Frais Gains/Pertes :</span>{dataset.goliathsDetails.fees_paid_by_goliaths}</p>
                      <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='userData_item_title'>Monnaie :</span>{dataset.stripeDetails.currency}</p>
                      <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='userData_item_title'>Pays de carte :</span>{dataset.stripeDetails.country}</p>
                      <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='userData_item_title'>Nom de facturation :</span>{dataset.stripeDetails.billingName}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>

              </div>
              <div>

              </div>
            </div>
          </div>
        ) : (
          <>
            Loading..
          </>
        )
      }
    </DashboardLayout>
  )
}

export default DepositById