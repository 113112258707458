import React from 'react'
import DashboardLayout from '../../components/layout/dashboard/DashboardLayout'
import './finances.css'
import { useTranslation } from 'react-i18next';

const Finances = () => {

    const { t } = useTranslation()
    return (
        <DashboardLayout page={t("pages_name.finances")}>
            <div>
                Finances
            </div>
        </DashboardLayout>
    )
}

export default Finances