import React, { useState, useEffect, useContext } from 'react'
import AuthContext from "contexte/AuthContext"
import './usersReports.css'
import { useTranslation } from 'react-i18next';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import { fetchDepositsReport, fetchReportPaul, fetchReportUserCreatedOnTimeframe, fetchReportWalletClient } from 'services/fetchUserReports';
import Notification from 'components/notifications/Notification';
import DownloaderLink from 'components/DownloaderLink/DownloaderLink';
import { getDateFilenameFormatted } from 'utils/date';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import { Link } from "react-router-dom";

const UsersReports = () => {

  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1)
  const [selectedDate, setSelectedDate] = useState({
    date1: { startDate: new Date(lastMonth), endDate: new Date() },
    date2: { startDate: null, endDate: null },
  });

  const resetFilter = () => {
    setSelectedDate({
      date1: { startDate: new Date(lastMonth), endDate: new Date() },
      date2: { startDate: null, endDate: null },
    })
  }


  return (
    <DashboardLayout page={t("pages_name.report_users")}>
      <Notification />
      <div className='externalDeposit_titleComponent'>
        <h2>{t("pages.report_users.title")}</h2>
        <FilterComponent
          resetFilter={resetFilter}
          date={true}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </div>
      <div>
        <ul>
          <Link to="/reports/users/volume/average">Volume moyen de trades par client</Link>
          <Link to="/reports/users/new">Nouveaux clients</Link>
          <Link to="/reports/users/gifts">Volume et montant des cadeaux</Link>
          <DownloaderLink
            filename={`report_wallets_${getDateFilenameFormatted()}.xlsx`}
            fetchData={fetchReportWalletClient}
            fetchDataParams={{ user }}
            type={'excel'}
            display={'Rapport wallets clients (Excel)'}
          />
          <DownloaderLink
            filename={`report_paul_${getDateFilenameFormatted()}.xlsx`}
            fetchData={fetchReportPaul}
            fetchDataParams={{ user }}
            type={'excel'}
            display={'Rapport Paul (Excel)'}
          />
          <DownloaderLink
            filename={`report_clients_${getDateFilenameFormatted()}.xlsx`}
            fetchData={fetchReportUserCreatedOnTimeframe}
            fetchDataParams={{
              user,
              startDate: selectedDate?.date1?.startDate,
              endDate: selectedDate?.date1?.endDate,
            }}
            type={'excel'}
            display={'Liste des clients (Excel)'}
          />
          <DownloaderLink
            filename={`report_deposits_${getDateFilenameFormatted()}.xlsx`}
            fetchData={fetchDepositsReport}
            fetchDataParams={{
              user,
              startDate: selectedDate?.date1?.startDate,
              endDate: selectedDate?.date1?.endDate,
            }}
            type={'excel'}
            display={'Liste des dépôts (Excel)'}
          />
          <Link to='/reports/users/most-important'>Clients les plus importants</Link>
        </ul>
      </div>
    </DashboardLayout>
  )
}

export default UsersReports
