import React, { useState, useEffect, useContext } from 'react';
import '../KPI.css';
import AuthContext from 'contexte/AuthContext';
import DateSelector from 'components/Filters/DateSelector/DateSelector';
import {
    fetchDataForDates,
    fetchTimeBeforeKycAndFirstTrade,
    fetchPercentageVerifiedUsersAndTraders
} from 'services/fetchKpiData'
import { Box } from '../KpiBox/KpiBox';
import { startOfDay } from 'date-fns';
import { fetchInstantConnections } from 'services/analyticsServices';

const KpiData = () => {
    const { user } = useContext(AuthContext);
    const [kpiData, setKpiData] = useState({
        selectedData: {
            subscriptions: 0,
            unsubscriptions: 0,
            trades: {},
            withdraw: 0,
            deposits: 0,
            users: {},
            turnover: 0,
            card: 0,
            volume: 0,
            investment: 0,
            profit: 0,
            tradingInvestors: 0,
            investors: 0,
            kyc: 0,
            commodities: {},
            europeanStocks: {}
        },
        comparedData: {
            subscriptions: 0,
            unsubscriptions: 0,
            trades: {},
            withdraw: 0,
            deposits: 0,
            users: {},
            turnover: 0,
            card: 0,
            volume: 0,
            investment: 0,
            profit: 0,
            tradingInvestors: 0,
            investors: 0,
            kyc: 0,
            commodities: {},
            europeanStocks: {}
        }
    });
    const [instantConnections, setInstantConnections] = useState(0);
    const [timeKycFirstTrade, setTimeKycFirstTrade] = useState({});
    const [verifiedUsersAndTraders, setVerifiedUsersAndTraders] = useState({});


    const [selectedDate, setSelectedDate] = useState({
        date1: { startDate: startOfDay(new Date()), endDate: new Date() },
        date2: { startDate: null, endDate: null },
    });

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchDataForDates(user, selectedDate.date1, selectedDate.date2);
            setKpiData(data);
        };
        fetchData();
    }, [user, selectedDate]);

    useEffect(() => {
        const fetchData = async () => {
            const [instantConnections, time, users] = await Promise.all([
                fetchInstantConnections(user),
                fetchTimeBeforeKycAndFirstTrade(user),
                fetchPercentageVerifiedUsersAndTraders(user)
            ])
            setInstantConnections(instantConnections);
            setTimeKycFirstTrade(time);
            setVerifiedUsersAndTraders(users)
        };
        fetchData();
    }, [user]);

    return (

        <div>
            <div className='kpi_date'>
                <DateSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate} withComparaison={true} />
            </div>
            <div className='kpi_container'>
                {/* <Box
                    title='Connexions instantannées'
                    data={instantConnections} /> */}
                <Box
                    title='Inscriptions'
                    data={kpiData.selectedData.subscriptions}
                    comparedData={kpiData.comparedData.subscriptions}
                    link='/users'
                />
                <Box
                    title='Désinscriptions'
                    data={kpiData.selectedData.unsubscriptions}
                    comparedData={kpiData.comparedData.unsubscriptions}
                    link='/users/deleted/list'
                />
                <Box
                    title='KYC vérifiés sur la période'
                    data={kpiData.selectedData.kyc}
                    comparedData={kpiData.comparedData.kyc}
                    link='/users'
                />
                <Box
                    title='Dépôts'
                    data={kpiData.selectedData.deposits}
                    comparedData={kpiData.comparedData.deposits}
                    ppComparison={true}
                    link='/finances/trades'
                />
                <Box
                    title='Temps avant le KYC'
                    data={timeKycFirstTrade?.days_before_kyc}
                    unit='jours'
                />
                <Box
                    title='Temps avant le 1er trade'
                    data={timeKycFirstTrade?.days_before_first_trade}
                    unit='jours'
                />
                <Box
                    title='Retraits'
                    data={kpiData.selectedData.withdraw}
                    comparedData={kpiData.comparedData.withdraw}
                    ppComparison={true}
                    link='/finances/trades'
                />
                <Box
                    title='Total des users vérifiés KYC'
                    data={verifiedUsersAndTraders?.verified_users_pp}
                    unit='%'
                />
                <Box
                    title='Users ayants réalise 1er trade'
                    data={verifiedUsersAndTraders?.traders_pp}
                    unit='%'
                />
                <Box
                    title='Volume moyen'
                    data={kpiData.selectedData.volume}
                    unit='€'
                    comparedData={kpiData.comparedData.volume}
                    ppComparison={true}
                    link='/finances/trades'
                />

                <Box
                    title='CA moyen par client'
                    data={kpiData.selectedData.turnover}
                    unit='€'
                    comparedData={kpiData.comparedData.turnover}
                    ppComparison
                    link='/finances/orders'
                />
                <Box
                    title='Nombre de trades uniques'
                    data={`${kpiData.selectedData?.trades?.countInvestsGrouped} (${kpiData.selectedData?.trades?.count})`}
                    link='/finances/orders'
                />
                <Box
                    title='Volume total de trades'
                    data={`Total: ${kpiData.selectedData.trades?.volume}, Achat : ${kpiData.selectedData.trades?.volumeBuy}, Vente: ${kpiData.selectedData.trades?.volumeSell}`}
                    link='/finances/orders'
                />
                <Box
                    title='Gain/perte'
                    data={kpiData.selectedData.profit}
                    unit='€'
                    comparedData={kpiData.comparedData.profit}
                    ppComparison={true}
                />
                <Box
                    title='Investissement moyen'
                    data={kpiData.selectedData.investment}
                    unit='€'
                    comparedData={kpiData.comparedData.investment}
                    ppComparison={true}
                    link='/finances/trades'
                />
                <Box
                    title='Paiement par carte'
                    data={kpiData.selectedData.card}
                    unit='%'
                    comparedData={kpiData.comparedData.card}
                    ppComparison={true}
                />
                <Box
                    title='Investisseurs unique'
                    data={kpiData.selectedData.investors}
                    comparedData={kpiData.comparedData.investors}
                    ppComparison={true}
                />
                <Box
                    title='Investisseurs trading unique'
                    data={kpiData.selectedData.tradingInvestors}
                    comparedData={kpiData.comparedData.tradingInvestors}
                    ppComparison={true}
                />
                <Box
                    title='Volume matières premières'
                    data={`Total: ${kpiData.selectedData.commodities?.volume}, Achat : ${kpiData.selectedData.commodities?.volume_buy}, Vente: ${kpiData.selectedData.commodities?.volume_sell}`}
                    link='/reports/trade/volume-per-commodity-timeframe'
                />
                <Box
                    title='Volume stocks EU'
                    data={`Total: ${kpiData.selectedData.europeanStocks?.volume}, Achat : ${kpiData.selectedData.europeanStocks?.volume_buy}, Vente: ${kpiData.selectedData.europeanStocks?.volume_sell}`}
                />
            </div>
        </div>
    )
}


export default KpiData