import { useState, useEffect, useContext } from 'react'
import AuthContext from 'contexte/AuthContext'
import { fetchLeverageUserPositions } from 'services/fetchUserById'
import PageLoader from 'components/pageLoader/PageLoader'
import Tableau from 'components/DataComponents/Tableau/Tableau'
import './UserLeveragePositions.css'
import { useTranslation } from 'react-i18next';
import Notification from 'components/notifications/Notification'
import { launchError } from 'components/notifications'
import TickerImage from 'components/DataComponents/TickerImage/TickerImage'


const transformeDataToTbody = (data, callFromModal = false) => {
  return data.length && data.map((item, index) => {
    if (!item) return { data: [] }


    return {
      data: [
        <TickerImage ticker={item.ticker}/>,
        item.qty,
        item.borrowed_amount,
        item.invested_liquidity,
        item.cost_basis,
        item.marketValue,
        <div className={`user_leverage_position_cell_${item.unrealizedPl >= 0 ? 'gain' : 'loss'}`}>{item.unrealizedPl} {item.unrealizedPlpc ? `(${item.unrealizedPlpc}%)` : null}</div>
      ],
    }
  })
}


const UserLeveragePositions = ({ id }) => {

  const { user } = useContext(AuthContext)
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [portfolio, setPortfolio] = useState([])
  const [page, setPage] = useState(0)
  const [fetchLimit, setFetchLimit] = useState(10)

  const tableHeaders = ['Ticker', 'Quantité', 'Argent emprunté', 'Argent investis', 'Coût de base', 'Valeur actuelle', 'Plus value latente']


  const fetchData = async () => {
    try {
      const data = await fetchLeverageUserPositions(user, id, page, fetchLimit)
      setPortfolio(data)
      setLoading(false)
    } catch {
      console.log('error')
    }
  }

  useEffect(() => {
    fetchData()
  }, [page])

  return (
    <div>
      {
        !loading ? (
          <div>
            <Notification />
            <Tableau
              tbody={transformeDataToTbody(portfolio)}
              thead={tableHeaders}
              limit={fetchLimit}
              pagination={true}
              setPage={setPage}
              page={page}
            />
          </div>
        ) : (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        )
      }
    </div>
  )
}

export default UserLeveragePositions