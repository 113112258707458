import { useState, useEffect, useContext } from 'react'
import AuthContext from 'contexte/AuthContext'
import { fetchStructuredProducts } from 'services/fetchUserById'
import PageLoader from 'components/pageLoader/PageLoader'
import Tableau from 'components/DataComponents/Tableau/Tableau'
import './userStructuredProducts.css'
import { useTranslation } from 'react-i18next';
import Notification from 'components/notifications/Notification'
import { launchError } from 'components/notifications'


const transformeDataToTbody = (data) => {

  return data.map((item, index) => ({
    data: [
      item.product_id?.name,
      item.amount,
      item.profit,
    ],
  }))
}

const UserStructuredProducts = ({ id }) => {

  const { user } = useContext(AuthContext)
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState([])
  const [page, setPage] = useState(0)
  const [fetchLimit, setFetchLimit] = useState(10)

  const tableHeaders = ['Ticker', 'Quantité', "Prix d'entrée moyen", 'Coût de base', 'Valeur actuelle', 'Plus value latente']


  const fetchData = async () => {
    try {
      setLoading(true)
      const data = await fetchStructuredProducts(user, id, page, fetchLimit)
      setProducts(data)
      setLoading(false)
    } catch {
      launchError({ message: 'Error getting user structured products' });
    }
  }

  useEffect(() => {
    fetchData()
  }, [page])

  return (
    <div>
      {
        !loading ? (
          <div>
            <Notification />
            <Tableau
              tbody={transformeDataToTbody(products)}
              thead={['Produit structuré', 'Montant', 'Profit']}
              limit={fetchLimit}
              pagination={true}
              setPage={setPage}
              page={page}
            />
          </div>
        ) : (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        )
      }
    </div>
  )
}

export default UserStructuredProducts