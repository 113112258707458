import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchSumsubScreeningFolder = async ({ user, type }) => {
  try {
    if (!['manually_accepted', 'rejected', 'daily'].includes(type)) throw new Error(`unknown screening report type: ${type}`)
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/sumsub-screening/${type}/list`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchSumsubScreeningReports = async ({ user, type, folder }) => {
  try {
    if (!['manually_accepted', 'rejected', 'daily'].includes(type)) throw new Error(`unknown screening report type: ${type}`)
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/sumsub-screening/${type}/${folder}/list`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const downloadSumsubScreeningReport = async ({ user, type, folder, fileName }) => {
  try {
    console.log({ user, type, folder, fileName })
    if (!['manually_accepted', 'rejected', 'daily'].includes(type)) throw new Error(`unknown screening report type: ${type}`)
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/sumsub-screening/${type}/${folder}/download?filename=${fileName}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}