import { useState, useEffect, useContext } from "react"
import AuthContext from "contexte/AuthContext"
import { useTranslation } from "react-i18next"
import "./averageVolumePerClient.css"
import DashboardLayout from "components/layout/dashboard/DashboardLayout"
import FilterComponent from "components/Filters/FilterComponent/FilterComponent"
import PageLoader from "components/pageLoader/PageLoader"
import Tableau from "components/DataComponents/Tableau/Tableau"
import { fetchReportAverageVolumePerClient } from "services/fetchUserReports"
import { RELEASE_DATE } from "utils/date"


const AverageVolumePerClient = () => {

  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  const [volumes, setVolumes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
    date2: { startDate: null, endDate: null },
  });
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const fetchData = async () => {
    try {
      const endDate = selectedDate.date1?.endDate;
      const startDate = selectedDate.date1?.startDate;
      const data = await fetchReportAverageVolumePerClient({ user, startDate, endDate, limit, page });
      setVolumes(data);
      setLoading(false);

    } catch (e) {
      console.log(e);
      setError(true);
    }
  }

  useEffect(() => {
    fetchData()
  }, [page, limit, selectedDate.date1?.startDate, selectedDate?.date1?.endDate]
  );

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    setPage(0);
  }

  const transformDataToTbody = (data) => {

    return data.map(item => {

      return {
        data: [
          `${item.firstName} ${item.lastName}`,
          item.volume,
        ],
        utils: {
          id: item.user_uuid
        }
      };
    });
  }

  return (
    <DashboardLayout page={t("pages_name.report_users")}>
      <div>
        {
          !loading ? (
            <div>
              <div className="flexbox">
                <h3>Volume moyen de trade par client</h3>
                <FilterComponent
                  page={true}
                  limit={true}
                  date
                  fetchLimit={limit} setFetchLimit={setLimit}
                  selectedDate={selectedDate}
                  setSelectedDate={handleDateChange}
                />
              </div>
              <div>
                <Tableau
                  thead={['Utilisateur', 'Volume']}
                  tbody={transformDataToTbody(volumes)}
                  limit={limit}
                  page={page}
                  hadLink
                  linkPath={'/user/'}
                  setPage={setPage}
                  pagination={true}
                />
              </div>
            </div>
          ) : (
            <div className='pageLoaderBox'>
              <PageLoader />
            </div>
          )
        }
      </div>
    </DashboardLayout>
  )
}

export default AverageVolumePerClient