import DashboardLayout from 'components/layout/dashboard/DashboardLayout';
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import './cardTradeById.css';
import AuthContext from 'contexte/AuthContext';
import { fetchCardTradeDetails } from 'services/fetchTrades';
import { launchError } from 'components/notifications';
import Notification from 'components/notifications/Notification';
import { formatDate } from 'utils/date';
import Badge from 'components/Badge/Badge';
import colors from 'assets/colors';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import TickerImage from 'components/DataComponents/TickerImage/TickerImage';

const CardTradeById = () => {

  const [loading, setLoading] = useState(true)
  const [dataset, setDataset] = useState([])

  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const params = useParams();

  const fetchData = async () => {

    try {
      const tradeDetail = await fetchCardTradeDetails({
        type: params.type,
        user: user,
        id: params.id,
      })
      setDataset(tradeDetail)

    } catch (e) {
      launchError(t('notifications.payouts.trades.details.error'))
    } finally {
      setLoading(false)
    }

  }

  useEffect(() => {
    fetchData();
  }, [])

  const transformeDataToTbody = (data) => {
    return data.length && data.map((item, index) => {
      return {
        data: [
          <TickerImage ticker={item.ticker} />,
          item.amount,
          item.ticker_value,
          `${item.realized_pl || ''}${item.realized_plpc ? ` - (${item.realized_plpc} %)` : ''}`
        ],
      }
    })
  }

  return (
    <DashboardLayout page={t("pages_name.trade_details")}>
      <Notification />
      {
        !loading ? (
          <div className='withdrawDeposit_box'>
            <div className='withdrawDepositById_header'>
              <div className='withdrawDepositById_header_name'>
                <h3>Détail de la transaction</h3>
              </div>
            </div>
            <div className='withdrawDepositById_items'>
              <div className='withdrawDepositById_item_box'>
                <span className='withdrawDepositById_item_title'>Client</span>
                <div className='userData_profil'>
                  <img src={`${process.env.REACT_APP_CDN_URL}/usr/pic/${dataset.user_uuid}`} alt="profilepic" className='user_profile_pic' />
                  <div>
                    <a href={`/user/${dataset.user_uuid}`}><p className='user_profil_name'>{dataset.firstName} {dataset.lastName}</p></a>
                  </div>
                </div>
              </div>
              <hr />
              <div className='withdrawDepositById_item_box'>
                <span className='withdrawDepositById_item_title'>Ticker</span>
                <span className='withdrawDepositById_item_content'>{params.type === 'playlist' && `${params.type} : `}{dataset.ticker}</span>
              </div>
              <hr />
              <div className='withdrawDepositById_item_box'>
                <span className='withdrawDepositById_item_title'>Type</span>
                <span className='withdrawDepositById_item_content'>
                  <Badge color={colors.badge[dataset.type]} text={dataset?.type === 'BUY' ? 'ACHAT' : 'VENTE'} />
                </span>
              </div>
              <hr />
              <div className='withdrawDepositById_item_box'>
                <span className='withdrawDepositById_item_title'>Commission</span>
                <span className='withdrawDepositById_item_content'>
                  <p>Plateforme : {dataset.platform_fees}</p>
                  <p>Markup : {dataset.fees}</p>
                  <p>Total : {dataset.platform_fees + dataset.fees}</p>
                </span>
              </div>
              <hr />
              <div className='withdrawDepositById_item_box'>
                <span className='withdrawDepositById_item_title'>Status</span>
                <span className='withdrawDepositById_item_content'>
                  <Badge color={'grey'} text={dataset.alpaca_status} />
                </span>
              </div>
            </div>

            <div className='withdrawDepositById_details_wallet'>
              <p className='withdrawDepositById_details_title'>Détails :</p>
              <div className='withdrawDepositById_details_wallet_box'>
                <div className='withdrawDepositById_details_box'>
                  <p className='withdrawDepositById_details_p withdrawDepositById_details_'><span className='card_trade_details_item_title'>Montant total (+frais) : </span>
                    {dataset.cardFees + dataset.fees + dataset.platform_fees + dataset.totalEur}
                  </p>
                  <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='card_trade_details_item_title'>Total des frais (com + stripe) :</span>
                    {dataset.cardFees + dataset.fees + dataset.platform_fees}
                  </p>
                  <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='card_trade_details_item_title'>Quantité :</span>{dataset?.amount} €</p>
                  <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='card_trade_details_item_title'>Date de création : </span>
                    {formatDate({ date: dataset.created_at, month: 'long', time: true })}
                  </p>
                  <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='card_trade_details_item_title'>Date effective : </span>
                    {formatDate({ date: dataset.filled_at, month: 'long', time: true })}
                  </p>
                  <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='card_trade_details_item_title'>Plus value : </span>
                    {dataset.realized_pl}{dataset.realized_plpc && ` - (${dataset.realized_plpc} %)`}
                  </p>
                </div>
                <div className='withdrawDepositById_details_box'>
                  <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='card_trade_details_item_title'>Méthode de paiement :</span>{dataset?.method?.toUpperCase()}</p>
                  <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='card_trade_details_item_title'>Frais de carte (Stripe) :</span>{dataset?.cardFees}</p>
                  <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='card_trade_details_item_title'>Carte :</span>****{dataset?.cardLast4} - {dataset.cardExp}</p>
                  <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='card_trade_details_item_title'>ID de paiement carte :</span>
                    <a href={`https://dashboard.stripe.com/payments/${dataset.payment_id}`} target="_blank">{dataset.payment_id}</a>
                  </p>

                  <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='card_trade_details_item_title'>Evaluation de risque carte :</span>
                    {dataset.riskScore} - {dataset.riskLevel}
                  </p>
                </div>
              </div>
            </div>
            {
              params.type === 'playlist' && (
                <div className='withdrawDepositById_details'>
                  <div className='withdrawDepositById_details_wallet'>
                    <p className='withdrawDepositById_details_title'>Détails par ticker :</p>
                    <div className='withdrawDepositById_details_box'>
                      <Tableau
                        id='ticker_details'
                        thead={['Ticker', 'Quantité', "Valeur ticker à l'achat/vente", 'Plus value']}
                        tbody={transformeDataToTbody(dataset.transactions)}
                      />
                    </div>
                  </div>
                </div>


              )
            }
            <div>

            </div>
            <div>

            </div>
          </div>
        ) : (
          <>
            Loading..
          </>
        )
      }
    </DashboardLayout>
  )
}

export default CardTradeById