import { createContext, useState } from "react";
import jwt_decode from "jwt-decode";

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {

    const [user, setUser] = useState(sessionStorage.getItem('bo-token') || "")
    const [email, setEmail] = useState(sessionStorage.getItem('bo-email') || "");

    const verifyUser = () => {
        try {
            var jwt = jwt_decode(user)
            if (jwt.uuid && jwt.role) {
                return { access: true, role: jwt.role }
            } else {
                sessionStorage.removeItem('bo-token')
                sessionStorage.removeItem('bo-email')
                return { access: false }
            }
        } catch {
            sessionStorage.removeItem('bo-token')
            sessionStorage.removeItem('bo-email')
            return { access: false }
        }
    }

    const logout = () => {
        sessionStorage.removeItem('bo-token')
        sessionStorage.removeItem('bo-email')
        setUser('')
        setEmail('')
    }

    return <AuthContext.Provider value={{ setUser, logout, user, email, verifyUser }}>{children}</AuthContext.Provider>
}

export default AuthContext