import './logic.css'
import React, { useCallback } from 'react';
import ReactFlow, { useNodesState, useEdgesState, addEdge, Background } from 'reactflow';
import 'reactflow/dist/style.css';
import { useEffect, useState } from 'react';
import Modal from 'components/modal/Modal';
import { formData } from 'pages/forms/fakeData';

const Logic = () => {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [selectedNode, setSelectedNode] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const generatedNodes = [];
        const generatedEdges = [];

        formData.questions.forEach((question, index) => {
            generatedNodes.push({
                id: String(index),
                position: { x: 750, y: 100  * index },
                data: { label: question.title }
            });

            question.choices.forEach((choice, choiceIndex) => {
                let targetIndex;
                if (choice.go_to_index !== undefined) {
                    targetIndex = choice.go_to_index;
                } else if (index + 1 < formData.questions.length) {
                    targetIndex = index + 1;
                }

                if (targetIndex !== undefined) {
                    generatedEdges.push({
                        id: `e${index}-${targetIndex}-${choiceIndex}`,
                        source: String(index),
                        target: String(targetIndex)
                    });
                }
            });
        });

        setNodes(generatedNodes);
        setEdges(generatedEdges);
    }, []);

    const onNodeClick = useCallback((event, node) => {
        setIsModalOpen(true);
        setSelectedNode(node);
    }, []);

    const onConnect = useCallback(
        (params) => setEdges((eds) => addEdge(params, eds)),
        [setEdges],
    );

    const onSave = useCallback((sourceNodeId, targetNodeId) => {
        if (targetNodeId) {
            setEdges(eds => addEdge({ source: sourceNodeId, target: targetNodeId, type: 'smoothstep' }, eds));
        }
    }, [setEdges]);


    return (
        <div style={{ width: '100vw', height: '85vh' }}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onNodeClick={onNodeClick}
            >
                <Background color="#ccc" variant="dots" />
            </ReactFlow>
            <ModalQuestions
            node={selectedNode}
            onClose={() => setIsModalOpen(false)}
            isModalOpen={isModalOpen}
            onSave={onSave}
        />
        </div>
    );
}

export default Logic

const ModalQuestions = ({ isModalOpen, onClose, node, onSave }) => {
    const [selectedTarget, setSelectedTarget] = useState("");

    const handleSave = () => {
        onSave(node.id, selectedTarget);
        onClose();
    };

    return (
        <Modal isOpen={isModalOpen} onClose={onClose} closeButton={false}>
            <h2 className='modal_title'>Détail du payout</h2>
            <form>
                <label>
                    Question:
                    <input type="text" value={node?.data.label || ''} readOnly />
                </label>
                <label>
                    Rediriger vers :
                    <select value={selectedTarget} onChange={e => setSelectedTarget(e.target.value)}>
                        <option value="">Sélectionner une question</option>
                        {formData.questions.map((question, index) => (
                            <option key={index} value={index}>
                                {question.title}
                            </option>
                        ))}
                    </select>
                </label>
                <button type="button" onClick={handleSave}>Sauvegarder</button>
                <button type="button" onClick={onClose}>Fermer</button>
            </form>
        </Modal>
    );
};