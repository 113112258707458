import axios from "axios"
import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchAccounts = async (user) => {

    try {
        const stripeReq = axios.get(`${API_BASE_URL}/accounts/stripe`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + user
            }
        })

        const revolutReq = axios.get(`${API_BASE_URL}/accounts/revolut`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + user
            }
        })

        const alpacaReq = axios.get(`${API_BASE_URL}/accounts/alpaca`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + user
            }
        })

        const currencyCloudReq = axios.get(`${API_BASE_URL}/accounts/currencycloud`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + user
            }
        })

        let data = {}

        await axios.all([stripeReq, revolutReq, alpacaReq, currencyCloudReq]).then(axios.spread((...res) => {
            data = {
                stripe: res[0]?.data || [],
                revolut: res[1]?.data || [],
                alpaca: res[2]?.data || [],
                currency_cloud: res[3]?.data || []
            }
        }))

        return data

    } catch (e) {
        console.log(e)
    }
}

export const fetchExanteAvailable = async (user) => {
    try {
        const { data } = await axios.get(`${API_BASE_URL}/accounts/exante/available`, buildHeaders(user))
        return data
    } catch (error) {
        console.error('exante', error)
        return null;
    }
}

