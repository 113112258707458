import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchQueueList = async (user, name) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/queues/list?name=${name}`,
      buildHeaders(user)
    );
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchQueueCount = async (user, name) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/queues/count?name=${name}`,
      buildHeaders(user)
    );
    return data;
  } catch (error) {
    throw error
  }
}

export const replayQueue = async (user, name) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/queues/replay?name=${name}`, {},
      buildHeaders(user)
    );
    return data;
  } catch (error) {
    throw error
  }
}