import axios from "axios";

export const approveRejectPendingPayout = async (user, type, withdraw_id) => {

    try {
        const options = {
            method: 'POST',
            url: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_VERSION + '/payouts/pending/' + type,
            headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user },
            data: {
                withdraw_id
            }
        };

        const changeOrderData = await axios.request(options)
        const returnedData = changeOrderData.data

        return returnedData
    } catch (error) {
        console.log(error)
        throw error
    }
}