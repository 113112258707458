import React from 'react'
import LimitSelector from '../LimitSelector/LimitSelector'
import DateRangeSelector from '../DateSelector/DateSelector'
import './filterComponent.css'
import GiftFilters from '../GiftsFilters/GiftFilters'
import SearchFilter from '../SearchFilter/SearchFilter'
import PostsFilters from '../PostsFilters/PostsFilters'
import UsersFilterSearch from '../UsersFilter/UsersFilter'
import TimeframeFilter from '../TimeframeFilter/TimeframeFilter'
import OrdersFilter from '../OrdersFilter/OrdersFilter'
import DepositWithdrawFilter from '../DepositWithdrawFilter/DepositWithdrawFilter'
import DropdownSelector from '../DropdownSelector/DropdownSelector'
import TooltipSlider from '../RangeSlider/TooltipSlider'

const FilterComponent = ({
    limit,
    posts,
    date,
    simpleDate,
    usersFilter,
    gift,
    withSearch,
    useSearchFilter,
    typeFilter, setTypeFilter,
    search, setSearch, searchPlaceHolder,
    fetchLimit, limitOptions,
    setFetchLimit,
    selectedDate,
    maxDate,
    setSelectedDate,
    signup, setSignup,
    playlist, setPlaylist,
    referral, setReferral,
    invoice, setInvoice,
    useAlpaca, useSumsub,
    useLiquidNetWorth,
    setLiquidNetWorth,
    setAlpacaFilter,
    setSumsubFilter,
    timeframeFilterConfig,
    timeframeFilter,
    setTimeframeFilter,
    ordersFilter,
    hideCancel, hideOnHold,
    isPurchase, setIsPurchase,
    isSale, setIsSale,
    isOnHold, setIsOnHold,
    isCanceled, setIsCanceled,
    depositWithdrawFilter,
    isDeposit, setIsDeposit,
    isWithdraw, setIsWithdraw,
    depositWithdrawStatus, setDepositWithdrawStatus,
    useDropdown, dropdowns,
    useRangeSliderFilter,
    sliderMin, sliderMax, handleRange, sliderValues, setSliderValues

}) => {

    return (
        <div className='filter_component_box'>
            {
                limit && <LimitSelector
                    noReset={true}
                    fetchLimit={fetchLimit}
                    setFetchLimit={setFetchLimit}
                    options={limitOptions}
                />
            }
            {
                date && <DateRangeSelector
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    simpleDate={simpleDate || false}
                    maxDate={maxDate}
                />
            }
            {
                useDropdown && Array.isArray(dropdowns) &&
                dropdowns.map(({ label, value, setter, options }) =>
                    <DropdownSelector
                        key={label}
                        label={label}
                        options={options}
                        setDropdownSelected={setter}
                        dropdownSelected={value}
                    />
                )
            }
            {
                posts && <PostsFilters typeFilter={typeFilter} setTypeFilter={setTypeFilter} />
            }
            {
                usersFilter && <UsersFilterSearch
                    useAlpaca={useAlpaca}
                    useSumsub={useSumsub}
                    useSearchFilter={useSearchFilter}
                    useLiquidNetWorth={useLiquidNetWorth}
                    setLiquidNetWorth={setLiquidNetWorth}
                    typeFilter={typeFilter}
                    setTypeFilter={setTypeFilter}
                    setAlpacaFilter={setAlpacaFilter}
                    setSumsubFilter={setSumsubFilter} />
            }
            {
                gift && <GiftFilters
                    signup={signup} setSignup={setSignup}
                    playlist={playlist} setPlaylist={setPlaylist}
                    referral={referral} setReferral={setReferral}
                    invoice={invoice} setInvoice={setInvoice}
                />
            }
            {
                timeframeFilter && <TimeframeFilter
                    timeframeFilter={timeframeFilter}
                    setTimeframeFilter={setTimeframeFilter}
                    timeframeFilterConfig={timeframeFilterConfig}
                />
            }
            {
                ordersFilter && <OrdersFilter
                    isPurchase={isPurchase}
                    isSale={isSale}
                    isOnHold={isOnHold}
                    isCanceled={isCanceled}
                    setIsPurchase={setIsPurchase}
                    setIsSale={setIsSale}
                    setIsOnHold={setIsOnHold}
                    setIsCanceled={setIsCanceled}
                    hideCancel={hideCancel}
                    hideOnHold={hideOnHold}
                />
            }
            {
                withSearch && <SearchFilter
                    search={search}
                    setSearch={setSearch}
                    searchPlaceHolder={searchPlaceHolder} />
            }
            {
                depositWithdrawFilter && <DepositWithdrawFilter
                    isDeposit={isDeposit}
                    setIsDeposit={setIsDeposit}
                    isWithdraw={isWithdraw}
                    setIsWithdraw={setIsWithdraw}
                    status={depositWithdrawStatus}
                    setStatus={setDepositWithdrawStatus}
                />
            }
            {
                useRangeSliderFilter &&
                <TooltipSlider
                    range
                    min={sliderMin}
                    max={sliderMax}
                    defaultValue={sliderValues}
                    onChangeComplete={setSliderValues}
                />

            }
        </div>
    )
}

export default FilterComponent