import { useEffect, useState } from "react";
import './googleLogin.css'
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import { googleLoginServices } from "services/googleLoginServices";


const GoogleLoginComposant = () => {

    const [user, setUser] = useState(null);

    useEffect(() => {
        
        if (user) {
            googleLoginServices(user)
                .then((res) => {
                    console.log('res', res.data);
                    sessionStorage.setItem('bo-email', user.email);
                    sessionStorage.setItem('bo-token', res?.data?.accessToken);
                    window.location.reload()
                }).catch((error) => console.log(error));
        }

    }, [user])

    return (
        <div className="content">
            <img src="/img/logoWhite.svg" alt="logo Goliaths blanc" />
            <p className="headline">Connectez-vous pour accéder à l'admin de Goliaths</p>
            <GoogleLogin
                onSuccess={credentialResponse => {
                    var jwt = jwt_decode(credentialResponse.credential)
                    setUser(jwt);
                }}
                onError={() => {
                    console.log('Login Failed');
                }}
            />
        </div>


    )
}

export default GoogleLoginComposant