// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.users_header_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    height: 50px;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    margin-bottom: 30px;
}

.users_count_div{
    display: flex;
    align-items: center;
    background-color: #F2F3FC;
    color: #2B64F5;
    padding: 3px 10px;
    border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/users/users.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,iBAAiB;IACjB,YAAY;IACZ,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".users_header_box{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 20px 0px;\n    height: 50px;\n    background-color: #fff;\n    border-bottom: 1px solid #ccc;\n    margin-bottom: 30px;\n}\n\n.users_count_div{\n    display: flex;\n    align-items: center;\n    background-color: #F2F3FC;\n    color: #2B64F5;\n    padding: 3px 10px;\n    border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
