// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portfolio_line_loss {
  color: red;
}

.portfolio_line_gain {
  color: green;
}

.modal-playlist-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(80, 67, 74, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.modal-playlist-container {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  position: relative;
  width: 1000px;
  height: 75%
}

.playlist-details {
  height: 90%;
}

.modal-playlist-overlay.open {
  opacity: 1;
  visibility: visible;
}

.modal-playlist-button-div{
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: start;
  width: 100%;
}

.modal-playlist-button-div .close{
  padding: 7px 20px;
  border-radius: 4px;
  background-color: white;
  border: solid 1px black;
}

.modal-playlist_title{
  margin: 0 0 20px 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/ago/AgoUser/AgoDataArray/AgoUserTransactions/agoUserTransactions.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,iCAAiC;EACjC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,UAAU;EACV,kBAAkB;EAClB,iEAAiE;AACnE;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,2CAA2C;EAC3C,kBAAkB;EAClB,aAAa;EACb;AACF;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,mBAAmB;EACnB,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".portfolio_line_loss {\n  color: red;\n}\n\n.portfolio_line_gain {\n  color: green;\n}\n\n.modal-playlist-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(80, 67, 74, 0.3);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  opacity: 0;\n  visibility: hidden;\n  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;\n}\n\n.modal-playlist-container {\n  background: white;\n  border-radius: 8px;\n  padding: 20px;\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);\n  position: relative;\n  width: 1000px;\n  height: 75%\n}\n\n.playlist-details {\n  height: 90%;\n}\n\n.modal-playlist-overlay.open {\n  opacity: 1;\n  visibility: visible;\n}\n\n.modal-playlist-button-div{\n  display: flex;\n  flex-direction: row;\n  gap: 10px;\n  align-items: center;\n  justify-content: start;\n  width: 100%;\n}\n\n.modal-playlist-button-div .close{\n  padding: 7px 20px;\n  border-radius: 4px;\n  background-color: white;\n  border: solid 1px black;\n}\n\n.modal-playlist_title{\n  margin: 0 0 20px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
