import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchMostImportantClients = async (user, limit, page) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/clients/most-important?page=${page}&limit=${limit}`,
      buildHeaders(user)
    );
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchAvailableComplianceReports = async (user) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/clients/compliance/list`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchComplianceReports = async ({ user, year, quarter, fromDb }) => {
  console.log({ user, year, quarter, fromDb });
  try {
    const params = `?quarter=${quarter}&year=${year}${fromDb ? '&fromDb=true' : ''}`;
    const response = await axios.get(`${API_BASE_URL}/rapports/clients/compliance${params}`, buildHeaders(user))
    console.log('response.headers', response.headers);
    console.log('response.data.length', response.data.length);
    return response.data;
  } catch (error) {
    throw error
  }
}

export const fetchReportPaul = async ({ user }) => {
  console.log('user', { user });
  try {
    const response = await axios.get(`${API_BASE_URL}/rapports/clients/paul`,
      buildHeaders(user)
    )
    console.log('response.headers', response.headers);
    console.log('response.data.length', response.data.length);
    return response.data;
  } catch (error) {
    throw error
  }
}

export const fetchDepositsReport = async ({ user, startDate, endDate }) => {
  console.log({ user, startDate, endDate });
  try {
    const params = `?startDate=${startDate}&endDate=${endDate}`;
    const { data } = await axios.get(`${API_BASE_URL}/rapports/clients/deposits${params}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchReportUserCreatedOnTimeframe = async ({ user, startDate, endDate }) => {
  console.log({ user, startDate, endDate });
  try {
    const params = `?startDate=${startDate}&endDate=${endDate}`;
    const { data } = await axios.get(`${API_BASE_URL}/rapports/clients/list${params}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchReportNewClients = async ({ user, page, limit, startDate, endDate }) => {
  try {
    const params = `?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`;
    const { data } = await axios.get(`${API_BASE_URL}/rapports/clients/new${params}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchReportAverageVolumePerClient = async ({ user, page, limit, startDate, endDate }) => {
  try {
    const params = `?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`;
    const { data } = await axios.get(`${API_BASE_URL}/rapports/clients/volume/average${params}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchGiftsReport = async ({ user, page, limit, startDate, endDate }) => {
  try {
    const params = `?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`;
    const { data } = await axios.get(`${API_BASE_URL}/rapports/gifts${params}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchReportWalletClient = async ({ user }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/clients/wallets`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

