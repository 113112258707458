// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  /* background-color: #007bff;  */
  /* color: #fff;  */
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/Badge/Badge.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,kBAAkB;EAClB,gCAAgC;EAChC,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".badge {\n  display: inline-block;\n  padding: 4px 8px;\n  border-radius: 4px;\n  /* background-color: #007bff;  */\n  /* color: #fff;  */\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
