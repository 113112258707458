import { useState, useEffect, useContext } from 'react'
import AuthContext from 'contexte/AuthContext'
import './NewGift.css'
import Notification from 'components/notifications/Notification'
import { launchError, launchSuccess } from 'components/notifications'
import { useTranslation } from 'react-i18next';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import { searchUsers } from 'services/fetchUsers'
import { fetchTickers } from 'services/tickersServices'
import { assignGift } from 'services/fetchGift'
import SearchTicker from 'components/SearchTicker/SearchTicker'
import SearchUser from 'components/SearchUser/SearchUser'


const NewGift = () => {

    const { user } = useContext(AuthContext)
    const { t } = useTranslation();
    const [amount, setAmount] = useState(0)
    const [users, setUsers] = useState([]);
    const [tickers, setTickers] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [selectedTicker, setSelectedTicker] = useState({});
    const [loading, setLoading] = useState(false);
    const [isMoney, setIsMoney] = useState(true);
    const [type, setType] = useState('');
    const [isValid, setIsValid] = useState(false);

    const clearForm = () => {
        setSelectedTicker({})
        setSelectedUser({})
        setIsMoney(true)
        setType('')
        setAmount(0)
        setUsers([])
        setTickers([])
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault()
            await assignGift(user, selectedUser.uuid, selectedTicker.id, amount, type, isMoney)
            launchSuccess(t('notifications.gifts.new.assign.success'))
            clearForm()
        } catch (e) {
            launchError(t('notifications.gifts.new.assign.error'))
        }
    }

    useEffect(() => {
        const valid = selectedUser.uuid &&
            !isNaN(amount) &&
            amount > 0 &&
            (isMoney || (!isMoney && selectedTicker.id)) &&
            type;
        setIsValid(valid)
    }, [type, amount, isMoney, selectedTicker, selectedUser])

    const handleAmount = (e) => {
        const value = e.target.value;
        if (!value || value.match(/^\d{1,}(\.\d{0,4})?$/)) {
            setAmount(value);
        }
    };

    const handleSearchUsers = async (search) => {
        if (search.length > 0) {
            try {
                setLoading(true)
                const fetchUsers = await searchUsers(user, search)
                setUsers(fetchUsers)
            } catch (e) {
                console.error(e)
            } finally {
                setLoading(false)
            }
        } else {
            setUsers([])
        }
    }

    const handleSearchTickers = async (search) => {
        if (search.length > 0) {
            try {
                setLoading(true)
                const fetchedTickers = await fetchTickers(user, 0, 10, search)
                setTickers(fetchedTickers)
            } catch (e) {
                console.error(e)
            } finally {
                setLoading(false)
            }
        } else {
            setTickers([])
        }
    }


    return (
        <DashboardLayout page={t("pages_name.alpaca")}>
            <div>
                <Notification />
                <div>
                    <div className='newGift_titleComponent'>
                        <h2>Nouveau cadeau</h2>
                    </div>
                    <div>
                        <form onSubmit={handleSubmit}>
                            <div className='new_gift_item_box'>
                                <label htmlFor='user-search'>Utilisateur</label>
                                <SearchUser
                                    id='user-search'
                                    selectUser={setSelectedUser}
                                    selectedUser={selectedUser}
                                    users={users}
                                    handleSearch={handleSearchUsers}
                                />
                            </div>
                            <div className='new_gift_item_box'>
                                <label htmlFor="amount">Montant</label>
                                <input
                                    id="amount"
                                    className='new_gift_amount_input'
                                    type="text"
                                    value={amount}
                                    onInput={handleAmount} />
                            </div>

                            <div className="new_gift_item_box">
                                <label htmlFor="isMoney">Cadeau en action</label>
                                <label className="toggle" id="isMoney">
                                    <input className="toggle-checkbox"
                                        type="checkbox"
                                        checked={!isMoney}
                                        onChange={() => setIsMoney(prevState => !prevState)}
                                    />
                                    <div className="toggle-switch"></div>
                                </label>
                            </div>

                            {!isMoney && <div className='new_gift_item_box'>
                                <label htmlFor="search_ticker">Ticker</label>
                                <SearchTicker
                                    id='search_ticker'
                                    setSelectedTicker={setSelectedTicker}
                                    selectedTicker={selectedTicker}
                                    tickers={tickers}
                                    handleSearch={handleSearchTickers}
                                />
                            </div>}
                            <div className='new_gift_item_box'>
                                <label htmlFor="gift-type-select">Type</label>
                                <select name="gift-type"
                                    className='custom-select'
                                    id="gift-type-select"
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}>
                                    <option value="">--Choisissez un type--</option>
                                    <option value="INVOICE">Facture</option>
                                    <option value="GESTURE">Geste commercial</option>
                                </select>
                            </div>

                            <input type="submit" className='new_gift_button_submit' value="Valider" disabled={!isValid} />

                        </form>
                    </div>
                </div>

            </div>
        </DashboardLayout>
    )
}

export default NewGift