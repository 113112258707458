// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.etf_edition_switcher {
  display: flex;
  flex-direction: row;
  padding: 5px;
  border-radius: 14px;
  background-color: #F2F3FC;
  width: max-content;
  margin: auto;
  margin-bottom: 2rem;
}

.etf_edition_tab {
  padding: 10px 20px;
  border-radius: 10px;
}

.etf_edition_tab {
  cursor: pointer;
}

.etf_edition_tab_select {
  background-color: #2B64F5;
  color: white;
}

.etf_edition_tab_not_select {
  background-color: #F2F3FC;
}`, "",{"version":3,"sources":["webpack://./src/pages/actifs/subpages/EtfEdition/EtfEditionTabs/etfEditionTabs.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".etf_edition_switcher {\n  display: flex;\n  flex-direction: row;\n  padding: 5px;\n  border-radius: 14px;\n  background-color: #F2F3FC;\n  width: max-content;\n  margin: auto;\n  margin-bottom: 2rem;\n}\n\n.etf_edition_tab {\n  padding: 10px 20px;\n  border-radius: 10px;\n}\n\n.etf_edition_tab {\n  cursor: pointer;\n}\n\n.etf_edition_tab_select {\n  background-color: #2B64F5;\n  color: white;\n}\n\n.etf_edition_tab_not_select {\n  background-color: #F2F3FC;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
