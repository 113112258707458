export default {
  badge: {
    APPROVAL_PENDING: '#f39c12',
    PENDING: '#f39c12',
    APPROVED: '#28a745',
    VERIFIED: '#28a745',
    REJECTED: '#e74c3c',
    FAIL: '#e74c3c',
    SELL: '#e74c3c',
    BUY: '#28a745',
    ENABLED: '#28a745',
    ACTIVE: '#28a745',
    SUCCESS_DETAIL: '#7b809a',
    structured_product_status: {
      opening: '#0093ff',
      collecting: '#28a745',
      closed: '#ffbb54'
    }
  }
}