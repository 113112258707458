import { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import './structuredProductById.css'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import AuthContext from 'contexte/AuthContext';
import PageLoader from 'components/pageLoader/PageLoader';
import { launchError, launchSuccess } from 'components/notifications';
import Notification from 'components/notifications/Notification';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import { fetchStructuredProductGraphData, fetchInvestors, fetchRegisteredUsers, fetchStructuredProductDetails, generateSpReport, fetchSpReport } from 'services/structuredProductsServices';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import PageTitleWithPicture from 'components/PageTitleWithPicture/PageTitleWithPicture';
import { formatDate } from 'utils/date';
import { getDecimalValue } from 'utils/numbers';
import Badge from 'components/Badge/Badge';
import colors from 'assets/colors';
import { CartesianGrid, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import DownloaderLink from 'components/DownloaderLink/DownloaderLink';

const StructuredProductById = () => {

  const { t } = useTranslation();
  const { user, email } = useContext(AuthContext);
  const { product_id, status } = useParams()
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10);
  const [details, setDetails] = useState({})
  const [graph, setGraph] = useState([])
  let isSuperAdmin = process.env.REACT_APP_SUPERADMIN_EMAILS.split(',')?.includes(email)


  const fetchUsers = async () => {
    try {
      setLoading(true)
      let data;
      if (status === 'opening') {
        data = await fetchRegisteredUsers({
          user,
          productId: product_id,
          page,
          limit
        })
      } else {
        data = await fetchInvestors({
          user,
          productId: product_id,
          page,
          limit
        })
      }
      setUsers(data);
    } catch (error) {
      launchError(t('notifications.registered_users.fetch'))
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const fetchDetails = async () => {
    try {
      setLoading(true);
      const data = await fetchStructuredProductDetails({
        user,
        productId: product_id
      })
      setDetails(data);
    } catch (error) {
      launchError(t('notifications.registered_users.count'))
    } finally {
      setLoading(false)
    }
  }

  const fetchGraph = async () => {
    try {
      setLoading(true);
      const data = await fetchStructuredProductGraphData({
        user,
        productId: product_id
      })
      if (data.points.length > 0) {
        setGraph(data.points?.map(item => ({ ...item, date: item.date.split('T')[0] })));
      }
    } catch (error) {
      launchError(t('notifications.registered_users.count'))
    } finally {
      setLoading(false)
    }
  }

  const generateReport = async () => {
    try {
      setLoading(true);
      await generateSpReport({
        user,
        productId: product_id
      })
      launchSuccess(t('notifications.structured_products.report.success'))
    } catch (error) {
      launchError(t('notifications.structured_products.report.error'))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchDetails();
    if (status === 'closed') {
      fetchGraph()
    }
  }, [])


  useEffect(() => {
    fetchUsers()
  }, [page, limit])


  const transformDataToBody = (data) => {
    if (status === 'opening') {
      return data.map((item) => ({
        data: [
          item.firstName,
          item.lastName,
        ],
        utils: {
          id: item.id
        }
      })
      )
    }
    return data.map((item) => ({
      data: [
        item.firstName,
        item.lastName,
        item.amount_invested
      ],
      utils: {
        id: item.id
      }
    })
    )
  }

  const handleLimit = (newLimit) => {
    setLimit(newLimit);
    setPage(0)
  }

  return (
    <DashboardLayout page={t("pages_name.structured_product")}>
      <Notification />
      <div>
        {!loading ? (
          <div>
            <div className='structured_product_title_box'>

              <PageTitleWithPicture
                title={`Détails du produict structuré ${details.name}`}
                picture={details.picture}
                type={'structured_product'}
              />
              <Badge text={details.status?.toUpperCase()} color={colors.badge.structured_product_status[details.status]} />
            </div>

            {isSuperAdmin &&
              <div className='structured_product_gen_report_box'>
                <button className='structured_product_gen_report_button' onClick={generateReport}>Générer rapport</button>
              </div>
            }

            <div className='structured_product_layout'>

              <div className='structured_product_details_box'>
                <p className='structured_product_details_box_title'>Détails</p>
                <div className='structured_product_informations'>

                  <div className='structured_product_registerinfo structured_product_box'>
                    <p className='structured_product_p structured_product_item_desc'><span className='structured_product_item_title'>Nom :</span> {details.name}</p>
                    <p className='structured_product_p structured_product_item_desc'><span className='structured_product_item_title'>Provider :</span> {details.provider}</p>
                    <p className='structured_product_p structured_product_item_desc'><span className='structured_product_item_title'>Date de création :</span> {formatDate({ date: details.start_date, month: 'long' })}</p>
                    <p className='structured_product_p structured_product_item_desc'><span className='structured_product_item_title'>Date d'échéance :</span> {formatDate({ date: details.maturity_date, month: 'long' })}</p>
                  </div>
                  <div className='structured_product_box'>
                    <p className='structured_product_p structured_product_item_desc'><span className='structured_product_item_title'>Rendement :</span>{details.yield}</p>
                    <p className='structured_product_p structured_product_item_desc'><span className='structured_product_item_title'>Fréquence de versement :</span>{details.yield_frequency}</p>
                    <p className='structured_product_p structured_product_item_desc'><span className='structured_product_item_title'>Durée du produit :</span>{details.maturity}</p>
                    <p className='structured_product_p structured_product_item_desc'><span className='structured_product_item_title'>Barrière de protection :</span>{details.barrier_capital}</p>
                  </div>
                  <div className='structured_product_box'>
                    <p className='structured_product_p structured_product_item_desc'><span className='structured_product_item_title'>Montant total :</span>{details.total} {details.currency}</p>
                    <p className='structured_product_p structured_product_item_desc'><span className='structured_product_item_title'>Montant investi :</span>{details.total_invested} {details.currency}</p>
                    <p className='structured_product_p structured_product_item_desc'><span className='structured_product_item_title'>Nombre d'investisseurs :</span>{details.investors_count}</p>
                    <p className='structured_product_p structured_product_item_desc'><span className='structured_product_item_title'>Investissemet moyen/user :</span>{getDecimalValue(details.avg_invest_user)} {details.currency}</p>
                  </div>
                </div>
              </div>

              <div className='structured_product_details_box'>
                <p className='structured_product_details_box_title'>Compostion</p>
                <div className='structured_product_informations'>
                  {details.products?.map(product => (
                    <div className='structured_product_box' key={product.ticker}>
                      <p className='structured_product_p structured_product_item_desc'><span className='structured_product_item_title'>Nom :</span>{product.name}</p>
                      <p className="structured_product_p structured_product_item_desc"><span className='structured_product_item_title'>Pourcentage :</span> {product.percentage}</p>
                      <p className="structured_product_p structured_product_item_desc"><span className='structured_product_item_title'>Ticker :</span> {product.ticker}</p>
                      <p className="structured_product_p structured_product_item_desc"><span className='structured_product_item_title'>Description :</span>{product.description}</p>
                    </div>
                  ))}
                </div>
              </div>


              <div className='structured_product_details_box'>
                <p className='structured_product_details_box_title'>Documents</p>
                <div className='structured_product_informations'>
                  <p className='structured_product_p structured_product_item_desc'><span className='structured_product_item_title'></span><a href={`${process.env.REACT_APP_CDN_URL}/structured_products/kid/${product_id}.pdf`}>KID</a></p>
                  <p className='structured_product_p structured_product_item_desc'><span className='structured_product_item_title'></span><a href={`${process.env.REACT_APP_CDN_URL}/structured_products/term_sheets/${product_id}.pdf`}>Term Sheets</a></p>
                  <DownloaderLink
                    fetchData={fetchSpReport}
                    filename={`report_sp_${details?.name?.toLowerCase()?.replace(/ /g, '_')}.xlsx`}
                    fetchDataParams={{ user, productId: details._id }}
                    type={'excel'}
                    display={'Report'}
                  />
                </div>
              </div>
            </div>

            {status === 'closed' && graph.length > 0 &&
              (<ResponsiveContainer width="100%" height={500}>
                <LineChart width={730} height={250} data={graph}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" hide />
                  <YAxis type="number"
                    domain={([dataMin, dataMax]) => [Math.min(dataMin, details.barrier_capital) - 5, dataMax + 10]}
                    hide
                  />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="percentage" stroke="#8884d8" />
                  <ReferenceLine y={0} label="Zone de paiement" stroke="blue" strokeDasharray="10 10" />
                  <ReferenceLine
                    y={details.barrier_capital}
                    label="Barrière de protection"
                    stroke="red"
                    strokeDasharray="15 15"
                  />
                </LineChart>
              </ResponsiveContainer>)
            }

            {isSuperAdmin &&
              <div>
                <div className='externalDeposit_titleComponent'>
                  <FilterComponent
                    limit={true}
                    fetchLimit={limit}
                    setFetchLimit={handleLimit}
                  />
                </div>

                <Tableau
                  thead={status === 'opening' ? ["Prénom", "Nom"] : ["Prénom", "Nom", "Montant investi"]}
                  tbody={transformDataToBody(users)}
                  page={page} setPage={setPage}
                  pagination={true}
                  limit={limit}
                />
              </div>
            }
          </div>
        ) : (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        )
        }
      </div>
    </DashboardLayout >
  )
}

export default StructuredProductById