import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchProblems = async ({user, page, limit, startDate, endDate, alpaca_user_id, reason, provider}) => {

    try {
        const params = `?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&alpaca_user_id=${alpaca_user_id}&reason=${reason}&provider=${provider}`
        const { data } = await axios.get(`${API_BASE_URL}/positions/issues/list${params}`,
            buildHeaders(user)
        )
        return data
    } catch (err) {
        throw err
    }
}

export const updateProblems = async (user) => {

    try {
        const { data } = await axios.get(`${API_BASE_URL}/positions/issues/update`,
            buildHeaders(user)
        )
        return data
    } catch (err) {
        throw err
    }

}
