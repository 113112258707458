// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category_header {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}

.category_title {
  margin: 0px;
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.category_add_ticker_box {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 50%;
  align-items: center;
  height: 20px;
  margin-bottom: 20px;
}

.category_add_ticker_button {
  background-color: #2B64F5;
  padding: 12px 24px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.category_add_ticker_button:disabled {
  background-color: #769af5;
}

.category_filter_component_box {
  display: flex;
  flex-direction: row;
  justify-content: end;
}`, "",{"version":3,"sources":["webpack://./src/pages/actifs/subpages/categories/Category/category.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,UAAU;EACV,mBAAmB;EACnB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,oBAAoB;AACtB","sourcesContent":[".category_header {\n  display: flex;\n  flex-direction: row;\n  justify-content: start;\n  align-items: center;\n  gap: 10px;\n  margin-bottom: 30px;\n}\n\n.category_title {\n  margin: 0px;\n  font-size: 20px;\n  font-weight: 600;\n  color: #000;\n}\n\n.category_add_ticker_box {\n  display: flex;\n  flex-direction: row;\n  gap: 10px;\n  width: 50%;\n  align-items: center;\n  height: 20px;\n  margin-bottom: 20px;\n}\n\n.category_add_ticker_button {\n  background-color: #2B64F5;\n  padding: 12px 24px;\n  border-radius: 5px;\n  border: none;\n  outline: none;\n  cursor: pointer;\n  font-size: 16px;\n  font-weight: 600;\n  color: #fff;\n}\n\n.category_add_ticker_button:disabled {\n  background-color: #769af5;\n}\n\n.category_filter_component_box {\n  display: flex;\n  flex-direction: row;\n  justify-content: end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
