import './DropdownSelector.css'

const DropdownSelector = ({ dropdownSelected, setDropdownSelected, options, label }) => {
    return (
        <div className="dropdownSelector_box">
            {label && <label htmlFor="select_alpaca">{label}</label>}
            <select className="custom-select" value={dropdownSelected} onChange={(e) => { setDropdownSelected(e.target.value) }}>
                {options?.map(({ display, value }) => (
                    <option key={value} value={value}>{display}</option>
                ))}
            </select>
        </div>
    )
}

export default DropdownSelector