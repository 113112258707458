import { useState, useContext, useEffect } from 'react'
import AuthContext from 'contexte/AuthContext'
import Notification from 'components/notifications/Notification'
import { launchError, launchSuccess } from 'components/notifications'
import { useTranslation } from 'react-i18next';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout';
import './newTicker.css'
import { createNewTicker, fetchExchanges, searchNewTickers } from 'services/tickersServices';


const NewTicker = () => {

  const { user } = useContext(AuthContext)
  const { t } = useTranslation();
  const [tickers, setTickers] = useState([]);
  const [selectedTicker, setSelectedTicker] = useState({});
  const [search, setSearch] = useState('')


  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await createNewTicker(user, {
        symbol: selectedTicker.symbol,
        exchange: selectedTicker?.exchangeShortName
      })
      launchSuccess(t('notifications.actifs.new_ticker.success'))
      clearSelection();
    } catch (error) {
      console.log(error)
      launchError(t(`notifications.actifs.new_ticker.${error?.response?.data?.error || 'new_ticker_error'}`))
    }
  }

  const clearSelection = () => {
    setTickers([])
    setSelectedTicker({});
    setSearch('');
  };

  const handleSearchTickers = async (searchTerm) => {
    if (searchTerm.length > 0) {
      try {
        const fetchedTickers = await searchNewTickers(user, searchTerm)
        setTickers(fetchedTickers)
      } catch (e) {
        launchError(t('notifications.actifs.new_ticker.searching'))
      }
    } else {
      setTickers([])
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log(search)
      handleSearchTickers(search)
    }, 300)

    return () => clearTimeout(delayDebounceFn)
  }, [search])


  return (
    <DashboardLayout page={t("pages_name.new_ticker")}>
      <Notification />
      <div className='new_ticker_header_box'>
        <h2>{t('pages.new_ticker.title')}</h2>
      </div>
      <div>
        <form onSubmit={handleSubmit} className='new_ticker_form'>
          <div className='snt_container'>
            {(!selectedTicker?.symbol) ? (
              <input
                type="text"
                placeholder='Rechercher un ticker'
                className='snt_input'
                onChange={(e) => setSearch(e.target.value)}
              />
            ) : (
              <div className={`${selectedTicker?.symbol ? "snt_ticker_selected" : "snt_ticker_not_selected"} relative`}>
                <span onClick={() => setSelectedTicker(selectedTicker)}>
                  <p>{selectedTicker.symbol} | {selectedTicker.name}</p>
                  <p>{selectedTicker?.exchangeShortName} | {selectedTicker?.stockExchange}</p>
                </span>
                <div onClick={() => clearSelection()} className='snt_delete_selected_item'>x</div>
              </div>
            )}
            {(!selectedTicker?.symbol) && tickers.length > 0 && (
              <ul className='snt_tickers_list_box'>
                {tickers.map((ticker, index) => (
                  <li key={index} onClick={() => setSelectedTicker(ticker)} className="snt_ticker_not_selected">
                    <div className='new_ticker_result_search'>
                      <p>{ticker.symbol} | {ticker.name}</p>
                      <p>{ticker?.exchangeShortName} | {ticker?.stockExchange}</p>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <input type="submit" className='new_ticker_button_submit' disabled={!selectedTicker?.symbol} value="Valider" />
        </form>
      </div>
    </DashboardLayout>
  )
}

export default NewTicker