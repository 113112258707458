// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.problems_filter_component{
    display: flex;
    gap: 10px;
    align-items: center;
}

.button_update{
    padding: 5px 15px;
    width: max-content;
    font-weight: bold;
    border-radius: 4px;
    background-color: #2B64F5;
    color: white;
}

.problems_filter_box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/pages/finances/subpages/Problems/problems.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC","sourcesContent":[".problems_filter_component{\n    display: flex;\n    gap: 10px;\n    align-items: center;\n}\n\n.button_update{\n    padding: 5px 15px;\n    width: max-content;\n    font-weight: bold;\n    border-radius: 4px;\n    background-color: #2B64F5;\n    color: white;\n}\n\n.problems_filter_box {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
