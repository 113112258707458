import axios from "axios";


export const changeAlpacaState = async (user, report_id, report_date, is_done) => {
        try {
            const options = {
                method: 'PUT',
                url: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_VERSION + '/accounts/alpaca/net_summary/status',
                headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user },
                data: {
                    report_id,
                    report_date,
                    is_done
                }
            };
    
            const changeAlpaca = await axios.request(options)
            const returnedData = changeAlpaca.data
    
            return returnedData
    
        } catch (err) {
            throw err
        }
}