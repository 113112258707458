import { useState, useEffect, useContext } from 'react'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import { useTranslation } from 'react-i18next';
import './agoDeposits.css'
import AuthContext from 'contexte/AuthContext';
import Modal from 'components/modal/Modal';
import { RELEASE_DATE, formatDate } from 'utils/date';
import { fetchAgoDeposits, linkToAgoClient, fetchAgoDepositUsers } from 'services/agoServices';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import PageLoader from 'components/pageLoader/PageLoader';
import Notification from 'components/notifications/Notification';
import { launchSuccess, launchError } from 'components/notifications'
import { formatIBAN } from 'utils/iban';

const AgoDeposits = () => {

  const { t } = useTranslation()
  const { user } = useContext(AuthContext)

  const [status, setStatus] = useState('ON%20HOLD')
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(true)
  const [fetchLimit, setFetchLimit] = useState(10)
  const [agoDeposit, setAgoDeposits] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalIndex, setModalIndex] = useState(0)
  const [selectedDate, setSelectedDate] = useState({
    date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
    date2: { startDate: null, endDate: null },
  });
  const [selectedUserLinked, setSelectedUserLinked] = useState({})
  const [selectedUser, setSelectedUser] = useState({});
  const [users, setUsers] = useState([])

  const fetchData = async () => {

    try {

      const agoDepositData = await fetchAgoDeposits(user, page, fetchLimit, status, selectedDate.date1.startDate, selectedDate.date1.endDate)

      setAgoDeposits(agoDepositData)
      setLoading(false)

    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (selectedDate.date1.startDate !== null && selectedDate.date1.endDate !== null) {
      fetchData()
    }
  }, [fetchLimit, status, page, selectedDate])

  const resetFilter = () => {
    setFetchLimit(10)
    setSelectedDate({
      date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
      date2: { startDate: null, endDate: null },
    })
  }

  const onClickFunction = (index) => {
    setModalIndex(index)
    setIsModalOpen(true);
  }

  const transformDataToTbody = (data) => {
    if (data.length > 0) {
      return data?.map((item) => {
        return {
          data: [
            item.account_holder_name,
            formatIBAN(item.account_identifier?.iban),
            item.transaction[0].amount + ' ' + item.transaction[0].currency,
            formatDate({ date: item.createdAt, time: true })
          ]
        }
      })
    } else {
      return []
    }
  }

  const closeAndInitModalAndLister = (modalIndex) => {
    setAgoDeposits((prevState) => {
      const newState = [...prevState]
      newState.splice(modalIndex, 1)
      return newState
    });
    setSelectedUser({});
    setSelectedUserLinked({});
    setModalIndex(-1);
    setIsModalOpen(false);
    setUsers([])
  }

  const linkMatchingUser = async () => {
    try {
      const userToLink = selectedUser?.uuid ? selectedUser : selectedUserLinked;
      if (!userToLink?.uuid) {
        return launchError(t('notifications.ago.agoDeposits.link.no_user_selected'))
      }
      await linkToAgoClient(user, agoDeposit[modalIndex]._id, userToLink.uuid)
      closeAndInitModalAndLister(modalIndex);
      launchSuccess(t('notifications.ago.agoDeposits.link.success'))
    } catch (e) {
      launchError(t('notifications.ago.agoDeposits.link.error'))
    }
  }

  const handleSearch = async (search) => {
    if (search.length > 0) {
      try {
        const fetchUsers = await fetchAgoDepositUsers(user, search)
        setUsers(fetchUsers)
        setLoading(false)
      } catch (e) {
        console.error(e)
      }
    } else {
      setUsers([])
    }
  }

  return (
    <DashboardLayout page={t("pages_name.ago_deposit")}>
      <div>
        {!loading ? (
          <div>
            <Notification />
            <div className='agoDeposit_titleComponent'>
              <h2>{t("pages.ago_deposit.title")}</h2>
              <div className='agoDeposit_statusFilters'>
                <div onClick={() => { setStatus('ON%20HOLD') }} className={`${status === "ON%20HOLD" && "agoDeposit_statusSelected"} agoDeposit_statusBox`}>{t("pages.ago_deposit.waiting")}</div>
                <div onClick={() => { setStatus('SUCCESS') }} className={`${status === "SUCCESS" && "agoDeposit_statusSelected"} agoDeposit_statusBox`}>{t("pages.ago_deposit.sucess")}</div>
              </div>
              <FilterComponent
                resetFilter={resetFilter}
                limit={true}
                date={true}
                fetchLimit={fetchLimit}
                setFetchLimit={setFetchLimit}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            </div>


            <div className="table-container">
              <div className="table-column">
                <Tableau
                  thead={[t("pages.ago_deposit.name"), t("pages.ago_deposit.iban"), t("pages.ago_deposit.amount"), t("pages.ago_deposit.date")]}
                  tbody={transformDataToTbody(agoDeposit)}
                  hadModal={true}
                  onClickFunction={onClickFunction}
                  page={page}
                  setPage={setPage}
                  pagination={true}
                  limit={fetchLimit}
                />
              </div>
            </div>

            {
              isModalOpen && status === "ON%20HOLD" && (
                <ModalPending isModalOpen={isModalOpen}
                  linkMatchingUser={linkMatchingUser}
                  setSelectedUserLinked={setSelectedUserLinked}
                  selectedUserLinked={selectedUserLinked}
                  onClose={() => setIsModalOpen(false)}
                  closeButton={false}
                  agoDeposit={agoDeposit}
                  modalIndex={modalIndex}
                  handleSearch={handleSearch}
                  users={users}
                  selectedUser={selectedUser}
                  setSelectedUser={setSelectedUser} />
              ) || isModalOpen && status === "SUCCESS" && (
                <ModalSuccess isModalOpen={isModalOpen} onClose={() => setIsModalOpen(false)} closeButton={false} agoDeposit={agoDeposit} modalIndex={modalIndex} />
              )
            }
          </div>
        ) : (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        )}
      </div>
    </DashboardLayout>
  )
}


export default AgoDeposits

// MODALES

const ModalPending = ({ isModalOpen, linkMatchingUser, setSelectedUserLinked, selectedUserLinked, onClose, agoDeposit, modalIndex, handleSearch, users, setSelectedUser, selectedUser }) => {

  const { t } = useTranslation()

  const handleSelectUserLinked = (user) => {
    setSelectedUserLinked(prevState => prevState.uuid === user.uuid ? {} : user);
    handleSearch('')
    setSelectedUser({})
  }

  return (
    <Modal isOpen={isModalOpen} onClose={onClose} closeButton={false} >
      <h2 className='modal_title'>{t("pages.ago_deposit.pendingModal.title")}</h2>
      <div className="payout-details">
        <p><strong>{t("pages.ago_deposit.pendingModal.name")}:</strong> {agoDeposit[modalIndex].account_holder_name}</p>
        <p><strong>{t("pages.ago_deposit.pendingModal.reference")}:</strong> {agoDeposit[modalIndex].reference}</p>
        <p><strong>{t("pages.ago_deposit.pendingModal.date")}:</strong> {formatDate({ date: agoDeposit[modalIndex].createdAt, time: true })}</p>
        <p><strong>{t("pages.ago_deposit.pendingModal.iban")}:</strong> {formatIBAN(agoDeposit[modalIndex]?.account_identifier?.iban)}</p>
        <p><strong>{t("pages.ago_deposit.pendingModal.amount")}:</strong> {agoDeposit[modalIndex].transaction[0].amount + ' ' + agoDeposit[modalIndex].transaction[0].currency}</p>
        <p><strong>{t("pages.ago_deposit.pendingModal.updated_at")}:</strong> {formatDate({ date: agoDeposit[modalIndex].updatedAt, time: true })}</p>
      </div>
      <hr className='modal_hr_button' />
      <div className='external_deposits_matching_users_box'>
        {agoDeposit[modalIndex].matching_users.length > 0 && (
          <ul>
            {agoDeposit[modalIndex].matching_users.map((item, index) => {
              return (
                <li key={index} onClick={() => handleSelectUserLinked(item)}
                  className={`${selectedUserLinked.uuid === item.uuid ? "linkedUserSelect" : "linkedUserNotSelect"}`}>
                  <div className='item_selectMatchingUser'>
                    <p>{item.firstName + " " + item.lastName}</p>
                    <p>{item.email}</p>
                    <p></p>
                  </div>
                </li>
              )
            })}
          </ul>
        )}
      </div>
      <UserSearchModal setSelectedUser={setSelectedUser} selectedUser={selectedUser} users={users} handleSearch={handleSearch} setSelectedUserLinked={setSelectedUserLinked} selectedUserLinked={selectedUserLinked} />
      <div className='modal-button-div'>
        <button className='close' onClick={onClose}>{t("pages.ago_deposit.pendingModal.close")}</button>
        <button
          className='valider'
          onClick={linkMatchingUser}
          disabled={!selectedUser?.uuid && !selectedUserLinked.uuid}
        >{t("pages.ago_deposit.pendingModal.linked")}</button>

      </div>
    </Modal>
  )
}

const ModalSuccess = ({ isModalOpen, onClose, agoDeposit, modalIndex }) => {

  const { t } = useTranslation()

  return (
    <Modal isOpen={isModalOpen} onClose={onClose} closeButton={false} >
      <h2 className='modal_title'>Détail du payout</h2>
      <div className="payout-details">
        <p><strong>{t("pages.ago_deposit.pendingModal.name")}:</strong> {agoDeposit[modalIndex].account_holder_name}</p>
        <p><strong>{t("pages.ago_deposit.pendingModal.reference")}:</strong> {agoDeposit[modalIndex].reference}</p>
        <p><strong>{t("pages.ago_deposit.pendingModal.date")}:</strong> {formatDate({ date: agoDeposit[modalIndex].createdAt, time: true })}</p>
        <p><strong>{t("pages.ago_deposit.pendingModal.iban")}:</strong> {agoDeposit[modalIndex].account_identifier.iban}</p>
        <p><strong>{t("pages.ago_deposit.pendingModal.amount")}:</strong> {agoDeposit[modalIndex].transaction[0].amount + ' ' + agoDeposit[modalIndex].transaction[0].currency}</p>
        <p><strong>{t("pages.ago_deposit.pendingModal.updated_at")}:</strong> {formatDate({ date: agoDeposit[modalIndex].updatedAt, time: true })}</p>
        <p><strong>{t("pages.ago_deposit.pendingModal.benefinary")}:</strong> {agoDeposit[modalIndex].linked_user.firstName + " " + agoDeposit[modalIndex].linked_user.lastName}</p>
      </div>
      <hr className='modal_hr_button' />
      <div className='modal_input_container'>
        <div className='modal-button-div'>
          <button className='close' onClick={onClose}>{t("pages.ago_deposit.pendingModal.close")}</button>
        </div>
      </div>
    </Modal>
  )
}

const UserSearchModal = ({ setSelectedUser, selectedUser, users, handleSearch, setSelectedUserLinked }) => {


  const updateSearch = (searchText) => {
    handleSearch(searchText);
  };

  const selectUser = (user) => {
    setSelectedUserLinked({});
    setSelectedUser(user);
  };


  const clearSelection = () => {
    setSelectedUserLinked({});
    setSelectedUser({});
    updateSearch('')
  };

  return (
    <div className='modal_input_container'>
      {Object.keys(selectedUser).length === 0 ? (
        <input
          type="text"
          placeholder='Entrer un email'
          className='input_sleectedUser'
          onChange={(e) => updateSearch(e.target.value)}
        >

        </input>
      ) : (
        <div className={`${selectedUser?.uuid ? "linkedUserSelect" : "linkedUserNotSelect"} relative`}>
          <span onClick={() => selectUser(selectedUser)}>
            <p>{selectedUser.firstName} {selectedUser.lastName}</p>
            <p>({selectedUser.email})</p>
          </span>
          <div onClick={() => clearSelection()} className='delete_item_select'>x</div>
        </div>
      )}
      {Object.keys(selectedUser).length === 0 && users.length > 0 && (
        <ul className='users_list_box'>
          {users.map((user, index) => (
            <li key={index} onClick={() => selectUser(user)} className="linkedUserNotSelect">
              <div className='item_selectMatchingUser'>
                <p>{user.firstName + " " + user.lastName}</p>
                <p>{user.email}</p>
                <p></p>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};