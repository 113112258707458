import axios from "axios";
import { ka } from "date-fns/locale";
import { API_BASE_URL, buildHeaders } from "utils/services"; 

export const fetchDataForDates = async (user, selectedDate, comparedDate) => {
  try {
    const [selectedData, comparedData] = await Promise.all([
      fetchKpiDataForDate(user, selectedDate),
      fetchKpiDataForDate(user, comparedDate),
    ]);

    return { selectedData, comparedData };
  } catch (error) {
    console.error("Error fetching KPI data for dates:", error);
    return {
      selectedData: getDefaultKpiData(),
      comparedData: getDefaultKpiData(),
    };
  }
};

const fetchKpiDataForDate = async (user, date) => {
  try {
    // Vérifiez si date.startDate et date.endDate sont null
    if (date.startDate === null || date.endDate === null) {
      // Si l'une des dates est null, retournez des données par défaut
      return getDefaultKpiData();
    }

    const dateParts = `?startDate=${date.startDate}&endDate=${date.endDate}`

    const [subscriptions, trades, withdrawRes, depositRes, userRes, turnoverRes, cardRes,
      volumeRes, investmentRes, profitRes, investors, tradingInvestors, kycRes, commodities,
      europeanStocks
    ] = await Promise.all([
      axios.get(`${API_BASE_URL}/dashboard/subscriptions${dateParts}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/dashboard/trades/details${dateParts}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/dashboard/kpi/withdraw${dateParts}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/dashboard/kpi/deposit${dateParts}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/dashboard/kpi/user${dateParts}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/dashboard/kpi/turnover${dateParts}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/dashboard/kpi/card${dateParts}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/dashboard/kpi/volume${dateParts}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/dashboard/kpi/investment${dateParts}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/dashboard/kpi/profit${dateParts}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/dashboard/kpi/users/active${dateParts}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/dashboard/kpi/users/trading-active${dateParts}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/dashboard/kpi/kyc${dateParts}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/dashboard/kpi/commodities/volume${dateParts}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/dashboard/kpi/stocks-eu/volume${dateParts}`, buildHeaders(user)),
    ]);


    const kpiData = {
      subscriptions: subscriptions.data.subscriptionsCount || 0,
      unsubscriptions: subscriptions.data.unsubscriptionsCount || 0,
      trades: trades?.data || {},
      withdraw: withdrawRes?.data?.withdraw || 0,
      deposits: depositRes?.data?.deposit || 0,
      users: userRes?.data || {},
      turnover: turnoverRes?.data?.avgClientProfits || 0,
      card: cardRes?.data?.tradesPaidByCardPP || 0,
      volume: volumeRes?.data?.avgVolume || 0,
      investment: investmentRes?.data?.avgInvest || 0,
      profit: profitRes?.data?.salesProfitLoss || 0,
      tradingInvestors: tradingInvestors?.data?.activeTradingUsersOnTimeframe || 0,
      investors: investors?.data?.activeUsersOnTimeframe || 0,
      kyc: kycRes?.data?.kyc || 0,
      commodities: commodities?.data || {},
      europeanStocks: europeanStocks?.data || {}
    };

    return kpiData;
  } catch (error) {
    console.error("Error fetching KPI data:", error);
    return getDefaultKpiData();
  }
};


const getDefaultKpiData = () => ({
  withdraw: 0,
  deposits: 0,
  users: {},
  time: {},
  turnover: 0,
  card: 0,
  volume: 0,
  investment: 0,
  profit: 0,
  tradingInvestors: 0,
  investors: 0,
  kyc: 0,
  commodities: {},
  europeanStocks: {}
});

export const fetchTimeBeforeKycAndFirstTrade = async (user) => {
  const { data } = await axios.get(`${API_BASE_URL}/dashboard/kpi/time`, buildHeaders(user))
  return data
}

export const fetchPercentageVerifiedUsersAndTraders = async (user) => {
  const { data } = await axios.get(`${API_BASE_URL}/dashboard/kpi/user`, buildHeaders(user))
  return data
}