import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import './tickers.css'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import Alltickers from './AllTickers/Alltickers';
import TickerMostTrade from './TickerMostTrade/TickerMostTrade';

const Tickers = () => {

  const { t } = useTranslation();

  const [tab, setTab] = useState(1)

  return (
    <DashboardLayout page={t("pages_name.alpaca")}>
      <div>
        <div>
          <div className='transactions_switcher'>
            <div className={`transactions_tab ${tab === 1 ? "transactions_tab_select" : "transactions_tab_not_select"}`} onClick={() => { setTab(1) }}>Tous</div>
            <div className={`transactions_tab ${tab === 2 ? "transactions_tab_select" : "transactions_tab_not_select"}`} onClick={() => { setTab(2) }}>+ Tradés</div>
          </div>
          {
            tab === 1 && (
              <Alltickers />
            ) || tab === 2 && (
              <TickerMostTrade />
            )
          }
        </div>

      </div>
    </DashboardLayout>
  )
}

export default Tickers