// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orders_filter_components{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.insufficient_rights {
    margin: 10rem;
    font-size: 2rem;
    font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/pages/finances/subpages/StructuredProductsTrades/structuredProductsTrades.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".orders_filter_components{\n    display: flex;\n    flex-direction: row;\n    gap: 10px;\n    align-items: center;\n}\n\n.insufficient_rights {\n    margin: 10rem;\n    font-size: 2rem;\n    font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
