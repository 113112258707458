import { useState, useContext, useEffect } from 'react'
import AuthContext from 'contexte/AuthContext'
import './etfCountriesTab.css'
import { useTranslation } from 'react-i18next';
import Notification from 'components/notifications/Notification'
import { launchError } from 'components/notifications'
import { fetchCountries } from 'services/countriesServices';
import SearchCountry from 'components/SearchCountry/SearchCountry';
import { getDecimalValue } from 'utils/numbers';


const EtfCountriesTab = ({ etf, editEtf }) => {

  const { user } = useContext(AuthContext)
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [newCountries, setNewCountries] = useState([])
  const [selectedCountry] = useState({});
  const [sumWeight, setSumWeight] = useState(0);

  useEffect(() => {
    const sum = etf?.countries?.reduce((acc, { weight }) => acc + Number(weight), 0);
    setSumWeight(getDecimalValue(sum));
  }, [etf.countries]);


  const handleCountryWeight = (index, e) => {
    const newEtf = { ...etf };
    const newCountries = [...etf.countries];
    newCountries[index].weight = Number(e.target.value);
    newEtf.countries = newCountries;
    editEtf(newEtf);
  }

  const removeCountry = (index) => {
    const newEtf = { ...etf };
    const newCountries = [...newEtf.countries];
    newCountries.splice(index, 1);
    newEtf.countries = newCountries;
    editEtf(newEtf);
  }

  const handleSearchCountries = async (search) => {
    if (search.length > 0) {
      try {
        setLoading(true)
        const fetchedTickers = await fetchCountries(user, search)
        setNewCountries(fetchedTickers)
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    } else {
      setNewCountries([])
    }
  }


  const handleAddNewCountries = ({ country, code }) => {
    if (country) {
      const newEtf = { ...etf };
      const newCountries = etf.countries ? [...etf.countries] : [];
      newCountries.push({
        name: country,
        icon: code?.toLowerCase(),
        weight: 0
      })
      newEtf.countries = newCountries;
      editEtf(newEtf)
    }
  }

  return (
    <div>

      <div className='etf_countries_list'>
        <Notification />
        <div className='etf_countries_search_box'>

          <SearchCountry
            className='etf_countries_search_input'
            setSelectedCountry={handleAddNewCountries}
            selectedCountry={selectedCountry}
            countries={newCountries}
            handleSearch={handleSearchCountries}
            label={'Ajouter un pays'}
          />
        </div>

        {etf?.countries.map((country, index) =>
          <div key={index} className="etf_country_ticker_box">
            <div className="etf_country_ticker_desc">
              <img className="etf_country_ticker_img" src={`https://cdn.goliaths.io/flags/${country.icon}.png`} alt="" />
              <span className="">{country.name}</span>
            </div>
            <input
              onChange={(e) => handleCountryWeight(index, e)}
              value={country.weight}
              className='etf_country_weigth_input'
              type='number'
            />
            <span onClick={() => removeCountry(index)} className="pointer etf_country_ticker_delete_button">x</span>
          </div >

        )}
      </div>

      {sumWeight !== 100 && (
        <div className='etf_edition_alert_repartition_box'>
          <p className='etf_edition_alert_repartition'>La somme des répartitions des pays n'est pas de 100 % ({sumWeight} %)</p>
        </div>
      )}

    </div>
  )
}

export default EtfCountriesTab