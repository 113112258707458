import { useState, useEffect, useContext } from "react"
import AuthContext from "contexte/AuthContext"
import { useTranslation } from "react-i18next"
import "./cardFees.css"
import DashboardLayout from "components/layout/dashboard/DashboardLayout"
import FilterComponent from "components/Filters/FilterComponent/FilterComponent"
import PageLoader from "components/pageLoader/PageLoader"
import Tableau from "components/DataComponents/Tableau/Tableau"
import { fetchCardFees } from "services/fetchTradeReports"
import { formatDate } from "utils/date"


const CardFees = () => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  const [fees, setFees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)
  const [selectedDate, setSelectedDate] = useState({
    date1: { startDate: oneWeekAgo, endDate: new Date() },
    date2: { startDate: null, endDate: null },
  });

  const fetchData = async () => {

    try {

      const response = await fetchCardFees(user, selectedDate.date1.startDate, selectedDate.date1.endDate);
      setFees(response);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setError(true);
    }
  }

  useEffect(() => {
    if (selectedDate.date1.startDate !== null && selectedDate.date1.endDate !== null) {
      fetchData();
    }
  }, [selectedDate]);

  const transformDataToTbody = (data) => {

    return data.map(item => {

      return {
        data: [
          formatDate({ date: item.day, month: 'long' }),
          item.fees,
        ]
      };
    });
  }

  return (
    <DashboardLayout page={t("pages_name.report_trade")}>
      <div>
        {
          !loading ? (
            <div>
              <div className="flexbox">
                <h3>Frais carte</h3>
                <FilterComponent
                  date={true}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
              </div>
              <div>
                <Tableau
                  thead={['Date', 'Fees']}
                  tbody={transformDataToTbody(fees)}
                />
              </div>
            </div>
          ) : (
            <div className='pageLoaderBox'>
              <PageLoader />
            </div>
          )
        }
      </div>
    </DashboardLayout>
  )
}

export default CardFees