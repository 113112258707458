import React from 'react'
import DashboardLayout from '../../components/layout/dashboard/DashboardLayout';
import './profil.css'

const Profil = () => {

  return (
    <DashboardLayout page={"Profil"}>
      <div>Profil</div>
    </DashboardLayout>
  )
}

export default Profil
