import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchReconciliations = async ({ user, startDate, endDate, page, limit, type }) => {
  try {
    let params = `?page=${page}&limit=${limit}`
    if (startDate && endDate) {
      params += `&startDate=${startDate}&endDate=${endDate}`
    }
    if (type) {
      params += `&type=${type}`
    }
    const { data } = await axios.get(`${API_BASE_URL}/reconciliations/list${params}`,
      buildHeaders(user)
    );
    return data;
  } catch (error) {
    throw error
  }
}