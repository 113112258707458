import { useState, useEffect, useContext } from 'react'
import AuthContext from 'contexte/AuthContext'
import { fetchCashLogs } from 'services/fetchUserById'
import PageLoader from 'components/pageLoader/PageLoader'
import Tableau from 'components/DataComponents/Tableau/Tableau'
import { formatDate } from 'utils/date';
import './UserCashLogs.css'
import { useTranslation } from 'react-i18next'
import TickerImage from 'components/DataComponents/TickerImage/TickerImage'

const UserCashLogs = ({ id }) => {

  const { user } = useContext(AuthContext)
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [transactions, setTransactions] = useState([])
  const [page, setPage] = useState(0)
  const [fetchLimit, setFetchLimit] = useState(10)

  const fetchData = async () => {
    try {
      const getUserCashLogs = await fetchCashLogs(user, id, page, fetchLimit)
      setTransactions(getUserCashLogs)
      setLoading(false)
    } catch {
      console.log('error')
    }
  }

  useEffect(() => {
    fetchData()
  }, [page])

  const transformeDataToTbody = (data) => {
    return data.map((item, index) => {
      return {
        data: [
          formatDate({ date: item.timestamp, time: true }),
          item.action?.trigger,
          item.wallet_before,
          item.wallet_change,
          item.wallet,
          <div className='arrayTickerItem'>
            {
              item.action?._type === 'trade-unique' ? (
                <TickerImage ticker={item.action?.details?.ticker} />
              ) : (null)
            }
            <p style={{ margin: 0 }}>{item.action?._type}</p>
          </div>,

        ]
      }
    })
  }


  const handleNextPage = async () => {
    setPage(page + 1)
  }

  return (
    <div>
      {
        !loading ? (
          <div>
            <Tableau
              tbody={transformeDataToTbody(transactions)}
              thead={['Date', 'Déclencheur', 'Liquidité Initiale', 'Ajustement', 'Liquidité', 'Type']}
              handleNextPage={handleNextPage}
              limit={fetchLimit}
              pagination={true}
              setPage={setPage}
              page={page}
            />
          </div>
        ) : (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        )
      }
    </div>
  )
}

export default UserCashLogs