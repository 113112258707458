import './NewAlpacaTransfert.css'
import Notification from 'components/notifications/Notification'
import BankTransfer from 'components/BankTransfer/BankTransfer'
import { alpacaVerifySmsCode, initiateAlpacaBankTransfer } from 'services/bankTransferServices'


const NewAlpacaTransfert = () => {

    return (
        <div>
            <Notification />
            <BankTransfer
                title={'Nouveau virement vers Alpaca'}
                initiatePayment={initiateAlpacaBankTransfer}
                verifySmsCode={alpacaVerifySmsCode}
            />
        </div>
    )
}

export default NewAlpacaTransfert