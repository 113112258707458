import { useState, useEffect, useContext } from "react"
import AuthContext from "contexte/AuthContext"
import { useTranslation } from "react-i18next"
import "./tradeVolume.css"
import DashboardLayout from "components/layout/dashboard/DashboardLayout"
import FilterComponent from "components/Filters/FilterComponent/FilterComponent"
import PageLoader from "components/pageLoader/PageLoader"
import { fetchTradesVolume } from "services/fetchTrades"
import Tableau from "components/DataComponents/Tableau/Tableau"
import { RELEASE_DATE } from 'utils/date';

const TradeVolume = () => {

    const { user } = useContext(AuthContext);
    const { t } = useTranslation();

    const [tradeVolume, setTradeVolume] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [selectedDate, setSelectedDate] = useState({
        date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
        date2: { startDate: null, endDate: null },
    });
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);

    const fetchData = async () => {

        try {

            const response = await fetchTradesVolume(user, limit, page, selectedDate.date1.startDate, selectedDate.date1.endDate);
            setTradeVolume(response);
            setLoading(false);

        } catch (e) {
            console.log(e);
            setError(true);
        }
    }

    useEffect(() => {
        if (selectedDate.date1.startDate !== null && selectedDate.date1.endDate !== null) {
            fetchData();
        }
    }, [selectedDate, page, limit]);

    const transformDataToTbody = (data) => {

        return data.map(item => {

            return {
                data: [
                    item.filled_day,
                    item.volume_per_day,
                    item.volume_per_user,
                    <span>{item.trades_per_day}({item.unique_trades_per_day})</span>
                ]
            };
        });
    }

    const handleNextPage = () => {
        setPage(page + 1);
    }

    const handlePrevPage = () => {
        setPage(page - 1);
    }


    return (
        <DashboardLayout page={t("pages_name.report_trade")}>
            <div>
                {
                    !loading ? (
                        <div>
                            <div className="flexbox">
                                <h3>Volume de trade</h3>
                                <FilterComponent
                                    date={true} page={true} limit={true}
                                    fetchLimit={limit} setFetchLimit={setLimit}
                                    selectedDate={selectedDate}
                                    setSelectedDate={setSelectedDate}
                                />
                            </div>
                            <div>
                                <Tableau
                                    thead={['Date', 'Volume', 'Par client', 'Nombre']}
                                    tbody={transformDataToTbody(tradeVolume)}
                                    handleNextPage={handleNextPage}
                                    limit={limit}
                                    page={page} setPage={setPage}
                                    pagination={true}
                                    firstLineSummup
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='pageLoaderBox'>
                            <PageLoader />
                        </div>
                    )
                }
            </div>
        </DashboardLayout>
    )
}

export default TradeVolume