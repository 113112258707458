import Modal from "components/modal/Modal"
import './ValidationModal.css'

const ValidationModal = ({ isModalOpen, onClose, handleSubmitCode, setCode }) => {

  return (
      <Modal isOpen={isModalOpen} onClose={onClose} closeButton={false} >
          <div className='validation_modal_header'>
              <h2 className='validation_modal_title'>Valider le code</h2>
              <span onClick={onClose} className='validation_modal_close_x_icone'>x</span>
          </div>
          <p className='mb-10'>Veuillez ajouter un code pour vous authentifier !</p>
          <form onSubmit={handleSubmitCode} className='validation_modal_form'>
              <input className='validation_modal_input_fields' type="number" onInput={(e) => setCode(e.target.value)} />
              <input type="submit" className='validation_modal_button_submit' value="Valider" />
          </form>
      </Modal>
  )
}

export default ValidationModal