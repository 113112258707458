import axios from "axios";
import { convertToUtcISOString } from "utils/date";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchConnectionsByTimeframe = async ({ user, startDate, endDate, timeframe }) => {

    try {
        const params = `?startDate=${convertToUtcISOString(startDate, true)}&endDate=${convertToUtcISOString(endDate, false)}&timeframe=${timeframe}`;
        const { data } = await axios.get(`${API_BASE_URL}/analytics/connections/unique/timeframe${params}`,
            buildHeaders(user)
        )
        return data
    } catch (err) {
        throw err
    }
}

export const fetchInstantConnections = async (user) => {
    const { data } = await axios.get(`${API_BASE_URL}/analytics/connections/unique/instant`, buildHeaders(user))
    return data?.instant_connections
}