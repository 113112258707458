import axios from "axios"
import { API_BASE_URL, buildHeaders } from "utils/services"

export const fetchMainAction = async (user) => {
    
    try {
        const pendingReq = axios.get(`${API_BASE_URL}/payouts/pending/list?page=0&limit=50`, buildHeaders(user))

        const DepositReq = axios.get(`${API_BASE_URL}/deposits/external/list?page=0&status=ON%20HOLD&limit=10`, buildHeaders(user))

        const IbansReq = axios.get(`${API_BASE_URL}/payouts/iban-verifications/pending/list?page=0&limit=50`, buildHeaders(user))

        const agoDeposits = axios.get(`${API_BASE_URL}/ago/deposits/external/list?page=0&status=ON%20HOLD&limit=10`, buildHeaders(user))

        const agoPendingWithdraw = axios.get(`${API_BASE_URL}/ago/payouts/pending/list?compact=1&page=1&limit=1`, buildHeaders(user))


        let response = []

        await axios.all([pendingReq, DepositReq, IbansReq, agoDeposits, agoPendingWithdraw]).then(axios.spread((...res) => {
            // response = {
            //     goliaths: [res[0]?.data, res[1]?.data, res[2]?.data],
            //     ago: [res[3]?.data, res[4]?.data]
            // }
            response = [res[0]?.data, res[1]?.data, res[2]?.data, res[3]?.data, res[4]?.data]
        }))

        return response

    } catch (e) {
        console.log('error main action')
        console.log(e)
        // return [-1, -1, -1, -1, -1]
    }
}