import { useEffect, useState, useContext, useCallback } from 'react'
import AuthContext from 'contexte/AuthContext';
import { useTranslation } from 'react-i18next';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import { RELEASE_DATE } from 'utils/date';
import './withdrawDeposit.css'
import { withdrawFetch } from 'services/withdrawFetch.js';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import PageLoader from 'components/pageLoader/PageLoader';
import TableUser from 'components/TableUser/TableUser';

const WithdrawDeposit = () => {

    const { t } = useTranslation();

    // Récupération du user
    const { user } = useContext(AuthContext);

    // Déclaration des states
    const [fetchLimit, setFetchLimit] = useState(10)
    const [transactions, setTransactions] = useState([])
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(0)
    const [selectedDate, setSelectedDate] = useState({
        date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
        date2: { startDate: null, endDate: null },
    });
    // const [typeOfRequest, setTypeOfRequest] = useState("DEPOSIT_AND_WITHDRAW")
    const [isDeposit, setIsDeposit] = useState(true);
    const [isWithdraw, setIsWithdraw] = useState(true);
    const [status, setStatus] = useState('ALL');

    const getRequestType = useCallback(() => {
        if (isDeposit && isWithdraw) return 'DEPOSIT_AND_WITHDRAW';
        if (isDeposit) return 'DEPOSIT'
        if (isWithdraw) return 'WITHDRAW'
        return 'DEPOSIT_AND_WITHDRAW'
    }, [isDeposit, isWithdraw])

    const getRedirectPath = (item) => {
        let path = null
        if (item) {
            if (item.type === 'WITHDRAW' && item.status === 'SUCCESS') 
                path = `${item?.type?.toLowerCase()}/${item.paymentId}`
            else if (item.type === 'DEPOSIT' && item.status === 'SUCCESS' && item.method === 'CB')
                path = `${item?.type?.toLowerCase()}/${item.paymentId}`
        }
        return path;
    };

    // API call
    const fetchData = async () => {
        try {
            const transactionsData = await withdrawFetch({
                user,
                type: getRequestType(),
                page,
                limit: fetchLimit,
                startDate: selectedDate.date1.startDate,
                endDate: selectedDate.date1.endDate,
                status
            })

            setTransactions(transactionsData)
            setLoading(false)

        } catch (e) {
            console.log(e)
        }

    }

    useEffect(() => {
        if (selectedDate.date1.startDate !== null && selectedDate.date1.endDate !== null) {
            fetchData()
        }
    }, [fetchLimit, page, selectedDate, isDeposit, isWithdraw, status])

    const resetFilter = () => {
        setSelectedDate({
            date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
            date2: { startDate: null, endDate: null },
        })
        setFetchLimit(10)
    }

    const handleNextPage = () => {
        setPage(page + 1)
    }

    function transformDataToTBody(data) {
        return data?.map(item => 
            ({
                data: [
                    item.type,
                    item.amount,
                    item.method,
                    item.status,
                    <TableUser
                        firstName={item.clientInfo?.firstName}
                        lastName={item.clientInfo?.lastName}
                        picture={item.clientInfo?.pic}
                        key={item.clientInfo?.pic}
                    />,
                (item.created_at)
                ], utils: {
                    id: getRedirectPath(item)
                }

            })
        )
    }

    const transformedTBody = transformDataToTBody(transactions);

    return (
        <div>
            {!loading ? (
                <div>
                    <div className='externalDeposit_titleComponent'>
                        <FilterComponent
                            limit={true}
                            date={true}
                            fetchLimit={fetchLimit}
                            setFetchLimit={setFetchLimit}
                            resetFilter={resetFilter}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            depositWithdrawFilter
                            isDeposit={isDeposit}
                            setIsDeposit={setIsDeposit}
                            isWithdraw={isWithdraw}
                            setIsWithdraw={setIsWithdraw}
                            depositWithdrawStatus={status}
                            setDepositWithdrawStatus={setStatus}
                        />
                    </div>
                    <Tableau
                        thead={["Type", "Montant", "Méthode", "Status", "Utilisateur", "Date de création"]}
                        tbody={transformedTBody}
                        handleNextPage={handleNextPage}
                        limit={fetchLimit}
                        hadLink={true}
                        linkPath={'/finances/trades/'}
                        pagination
                        setPage={setPage}
                        page={page}
                    />
                </div>
            ) : (
                <div className='pageLoaderBox'>
                    <PageLoader />
                </div>
            )}
        </div>
    )
}

export default WithdrawDeposit
