import React from 'react'
import './sumsubScreeningReports.css'
import { useTranslation } from 'react-i18next';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import Notification from 'components/notifications/Notification';

const SumsubScreeningReports = () => {

  const { t } = useTranslation();
 

  return (
    <DashboardLayout page={t("pages_name.sumsub_screening")}>
      <Notification />
      <div className='sumsub_screening_titleComponent'>
        <h2>{t("pages.sumsub_screening.title")}</h2>
      </div>
      <div>
        <ul>
          <a href='/reports/sumsub-screening/daily'>{t('pages.report_sumsub.screening_daily_link')}</a>
          <a href='/reports/sumsub-screening/manually-accepted'>{t('pages.report_sumsub.screening_manually_accepted_link')}</a>
          <a href='/reports/sumsub-screening/rejected'>{t('pages.report_sumsub.screening_rejected_link')}</a>
        </ul>
      </div>
    </DashboardLayout>
  )
}

export default SumsubScreeningReports
