import axios from "axios";
import { AGO_API_BASE_URL, API_BASE_URL, buildHeaders } from "utils/services";

export const fetchSpDataForDates = async (user, selectedDate, comparedDate) => {
  try {
    const [selectedData, comparedData] = await Promise.all([
      fetchStructuredProductsKpiDataForDate(user, selectedDate),
      fetchStructuredProductsKpiDataForDate(user, comparedDate),
    ]);

    return { selectedData, comparedData };
  } catch (error) {
    console.error("Error fetching KPI data for dates:", error);
    return {
      selectedData: getDefaultStructuredProductKpiData(),
      comparedData: getDefaultStructuredProductKpiData(),
    };
  }
};

export const fetchKpiDataForDates = async (user, type, selectedDate, comparedDate) => {
  let service;
  let default_datas;
  try {
    switch (type) {
      case 'sp':
        service = fetchStructuredProductsKpiDataForDate;
        default_datas = getDefaultStructuredProductKpiData;
        break;
      case 'margin':
        service = fetchMarginKpiDataForDate;
        default_datas = getDefaultMarginKpiData;
        break;
      case 'ago':
        service = fetchAgoKpiDataForDate;
        default_datas = getDefaultAgoKpiData;
        break;
      default:
        return;
    }
    const [selectedData, comparedData] = await Promise.all([
      service(user, selectedDate),
      service(user, comparedDate),
    ]);

    return { selectedData, comparedData };
  } catch (error) {
    console.error("Error fetching KPI data for dates:", error);
    return {
      selectedData: default_datas(),
      comparedData: default_datas(),
    };
  }
};

const fetchStructuredProductsKpiDataForDate = async (user, date) => {
  try {
    if (date.startDate === null || date.endDate === null) {
      return getDefaultStructuredProductKpiData();
    }

    const params = `?startDate=${date.startDate}&endDate=${date.endDate}`

    const [{ data: collectedAndProfit }, { data: investors }, { data: investorsMultiple }] = await Promise.all([
      axios.get(`${API_BASE_URL}/kpi/structured-products/collected-profit${params}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/kpi/structured-products/investors${params}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/kpi/structured-products/investors-multiple${params}`, buildHeaders(user)),
    ]);


    const kpiData = {
      volume: collectedAndProfit.collected || 0,
      profit: collectedAndProfit.profit || 0,
      investors: investors || 0,
      investors_multiple: investorsMultiple || 0,
    };

    return kpiData;
  } catch (error) {
    console.error("Error fetching KPI data:", error);
    return getDefaultStructuredProductKpiData();
  }
};


const getDefaultStructuredProductKpiData = () => ({
  volume: 0,
  profit: 0,
  investors: 0,
  investors_multiple: 0,
});

const getDefaultMarginKpiData = () => ({
  borrowed_amount_instant: 0,
  borrowed_amount: 0,
  borrowed_amount_fees: 0,
  gains: 0,
  open_positions: 0,
  avg_trade_per_client: 0,
  unique_clients: 0,
  clients_profit_loss: 0,
  volume: 0,
  trades_count: 0
});

const fetchMarginKpiDataForDate = async (user, date) => {
  try {
    if (date.startDate === null || date.endDate === null) {
      return getDefaultMarginKpiData();
    }

    const params = `?startDate=${date.startDate}&endDate=${date.endDate}`

    const [
      { data: open_positions },
      { data: borrowed_amount_instant },
      { data: borrowed_amount },
      { data: borrowed_amount_fees },
      { data: trades },
      { data: clients_profit_loss },
      { data: gains },
      { data: volume },
      { data: trades_count },
    ] = await Promise.all([
      axios.get(`${API_BASE_URL}/leverage/kpi/open-positions/count${params}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/leverage/kpi/borrowed-amount/instant${params}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/leverage/kpi/borrowed-amount${params}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/leverage/kpi/borrowed-amount/fees${params}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/leverage/kpi/avg-trade-count${params}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/leverage/kpi/clients-profit-loss${params}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/leverage/kpi/gains${params}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/leverage/kpi/volume${params}`, buildHeaders(user)),
      axios.get(`${API_BASE_URL}/leverage/kpi/trades/count${params}`, buildHeaders(user)),
    ]);


    const kpiData = {
      open_positions,
      borrowed_amount_instant,
      borrowed_amount,
      borrowed_amount_fees,
      gains,
      avg_trade_per_client: trades.avg_trade,
      unique_clients: trades.unique_clients,
      clients_profit_loss,
      volume,
      trades_count
    };

    return kpiData;
  } catch (error) {
    console.error("Error fetching KPI data:", error);
    return getDefaultStructuredProductKpiData();
  }
};

const getDefaultAgoKpiData = () => ({
  ago_gains: 0,
  ago_avg_trade_per_client: 0,
  ago_unique_clients: 0,
  ago_clients_profit_loss: 0,
  ago_volumes: [],
  ago_trades_count: 0,
  deposits: 0
});

const fetchAgoKpiDataForDate = async (user, date) => {
  try {
    if (date.startDate === null || date.endDate === null) {
      return getDefaultAgoKpiData();
    }

    const params = `?startDate=${date.startDate}&endDate=${date.endDate}`
    const { data } = await axios.get(`${AGO_API_BASE_URL}/agbk/kpis${params}`, buildHeaders(user));

    const kpiData = {
      ago_gains: data?.data?.gains,
      ago_avg_trade_per_client: data?.data?.avg_trade_per_client,
      ago_unique_clients: data?.data?.unique_clients,
      ago_clients_profit_loss: data?.data?.clients_profit_loss,
      ago_volumes: data?.data?.volumes,
      ago_trades_count: data?.data?.trades_count,
      deposits: data?.data?.deposits
    };

    return kpiData;
  } catch (error) {
    console.error("Error fetching AGO KPI data:", error);
    return getDefaultAgoKpiData();
  }
};