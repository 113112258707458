import { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import AuthContext from "contexte/AuthContext"
import { useTranslation } from 'react-i18next';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import Notification from 'components/notifications/Notification';
import { launchError } from 'components/notifications';
import DownloaderLink from 'components/DownloaderLink/DownloaderLink';
import './screeningReportList.css'
import { downloadSumsubScreeningReport, fetchSumsubScreeningReports } from 'services/sumsubOverview';

const SumsubScreeningReportList = () => {

  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);

  const location = useLocation();
  const reversedPaths = location.pathname.split('/').reverse();
  const type = reversedPaths[1].replace('-', '_')
  const folder = reversedPaths[0]

  useEffect(() => {

    const fetchReportList = async () => {
      try {
        const data = await fetchSumsubScreeningReports({ user, type, folder })
        setFiles(data);
      } catch (error) {
        launchError(t('notifications.reports.compliance.list.error'))
      }
    }

    fetchReportList();
  }, [])

  return (
    <DashboardLayout page={t("pages_name.report_sumsub_screening")}>
      <Notification />
      <div className='sumsub_screening_files_titleComponent'>
        <h2>{t("pages.report_sumsub_screening.title", { folder })}</h2>
      </div>
      <div>
        <ul>
          {files.map(file => (
            <DownloaderLink
              filename={`${file}.pdf`}
              fetchData={downloadSumsubScreeningReport}
              fetchDataParams={{ user, type, folder, fileName: file }}
              type={'excel'}
              display={file}
              key={file}
            />
          ))}
        </ul>
      </div>
    </DashboardLayout>
  )
}

export default SumsubScreeningReportList;