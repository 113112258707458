// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.questions-container {
  display: flex;
  height: 85vh;
}

.questions-sidebar {
  width: 250px;
  background-color: #F2F3FC;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sidebar-item {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: transparent;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 8px;
}

.sidebar-item:hover,
.sidebar-item.active {
  background-color: white;
}

.question-content {
  flex-grow: 1;
  padding: 80px 20px 20px 20px;
  overflow-y: auto;
}

.question-content h2 {
  margin-top: 0;
}

.question-content ul {
  list-style: none;
  padding: 0;
}

.question-content li {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #F2F3FC;
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/forms/components/Questions/questions.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,gBAAgB;EAChB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,aAAa;EACb,YAAY;EACZ,6BAA6B;EAC7B,gBAAgB;EAChB,eAAe;EACf,iCAAiC;EACjC,kBAAkB;AACpB;;AAEA;;EAEE,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,4BAA4B;EAC5B,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,yBAAyB;EACzB,kBAAkB;AACpB","sourcesContent":[".questions-container {\n  display: flex;\n  height: 85vh;\n}\n\n.questions-sidebar {\n  width: 250px;\n  background-color: #F2F3FC;\n  overflow-y: auto;\n  padding: 20px;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.sidebar-item {\n  width: 100%;\n  padding: 10px;\n  border: none;\n  background-color: transparent;\n  text-align: left;\n  cursor: pointer;\n  transition: background-color 0.3s;\n  border-radius: 8px;\n}\n\n.sidebar-item:hover,\n.sidebar-item.active {\n  background-color: white;\n}\n\n.question-content {\n  flex-grow: 1;\n  padding: 80px 20px 20px 20px;\n  overflow-y: auto;\n}\n\n.question-content h2 {\n  margin-top: 0;\n}\n\n.question-content ul {\n  list-style: none;\n  padding: 0;\n}\n\n.question-content li {\n  margin-bottom: 10px;\n  padding: 10px;\n  background-color: #F2F3FC;\n  border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
