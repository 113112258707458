import './pageTitleWithPicture.css'

const PageTitleWithPicture = ({ title, firstName, lastName, picture, type }) => {

  const getPictureUrl = () => {
    switch (type) {
      case 'user':
        return `${process.env.REACT_APP_CDN_URL}/usr/pic/${picture}`
      case 'category':
        return `https://fra1.digitaloceanspaces.com/tsscdn/categories/${picture}.svg`
      case 'structured_product':
        return picture
      default:
        return ''
    }
  }


  return (
    <div className='page_title_user_picture_header'>
      <div className='page_title_user_picture_header_profilPic' style={{ backgroundImage: `url(${getPictureUrl()})` }}></div>
      <div className='page_title_user_picture_header_name'>
        {
          type === 'user' ?

            (<>
              <h3>{title}</h3>
              <span>{firstName} {lastName}</span>
            </>
            ) :
            <h2>{title}</h2>
        }
      </div>
    </div >
  )

}

export default PageTitleWithPicture