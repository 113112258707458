// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payout_item_container {
    width: 100%;
}
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  
  
  tr:nth-child(odd) {
    background-color: #f9f9f9; 
  }
  
  td {
    padding: 20px 10px; 
    text-align: left; 
  }
  
 `, "",{"version":3,"sources":["webpack://./src/pages/finances/subpages/Payouts/components/payoutItem/payoutItem.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,WAAW;IACX,yBAAyB;IACzB,gBAAgB;EAClB;;;;EAIA;IACE,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;EAClB","sourcesContent":[".payout_item_container {\n    width: 100%;\n}\ntable {\n    width: 100%;\n    border-collapse: collapse;\n    margin-top: 20px;\n  }\n  \n  \n  \n  tr:nth-child(odd) {\n    background-color: #f9f9f9; \n  }\n  \n  td {\n    padding: 20px 10px; \n    text-align: left; \n  }\n  \n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
