import { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import './alpaca.css'
import AuthContext from 'contexte/AuthContext';
import LimitSelector from 'components/Filters/LimitSelector/LimitSelector';
import { fetchAlpaca } from 'services/fetchAlpaca';
import Tableau from 'components/DataComponents/Tableau/Tableau';

const Alpaca = () => {

    const { t } = useTranslation();
    const { user } = useContext(AuthContext)

    const [fetchLimit, setFetchLimit] = useState(10)
    const [page, setPage] = useState(0)
    const [alpaca, setAlpaca] = useState([])
    const [loading, setLoading] = useState(true)
    const [ isOpened, setIsOpened ] = useState('')


    const fetchData = async () => {
        const data = await fetchAlpaca(user, page, fetchLimit)
        setLoading(false)
        setAlpaca(data)
    }

    useEffect(() => {
        fetchData()
    }, [fetchLimit, page])

    const resetFilter = () => {
        setFetchLimit(10)
    }

    const transformDataToTbody = (data) => {
        
        if (data?.length > 0) {
            return data.map((item) => {
                return {
                    data: [
                        item?.date || "",
                        item?.balance || "",
                    ]
                }
            })
        } else {
            return { data: [] }
        }
    }

    const openThis = (name) => {
        if(isOpened === name){
            setIsOpened('')
        }else{
            setIsOpened(name)
        }
    }

    return (
        <div>
            {
                !loading ? (

                    <div className='alpaca_container'>
                        {
                            alpaca?.map((item, index) => {

                                return (
                                    <div key={index} className='alpaca_item_box'>
                                        <div className='alpaca_item_header' onClick={() => openThis(item.ledger_name)}>
                                            <h3>{item.ledger_name}</h3>
                                            <span className='alpaca_opened_icon'>+</span>
                                        </div>
                                        {
                                            isOpened === item.ledger_name && (
                                                <div className='alpaca_item_content'>
                                                    <Tableau
                                                        thead={[t("pages.alpaca.date"), t("pages.alpaca.balance")]}
                                                        tbody={transformDataToTbody(item.array)}
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>

                ) : (
                    <div>Loading...</div>
                )
            }
        </div>
    )
}

export default Alpaca