import { useState, useEffect } from 'react'
import './SearchTicker.css'

const SearchTicker = ({ setSelectedTicker, selectedTicker, tickers, handleSearch, label }) => {

  const [search, setSearch] = useState('');

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch(search)
    }, 300)

    return () => clearTimeout(delayDebounceFn)
  }, [search])


  const clearSelection = () => {
    setSelectedTicker({});
    setSearch('');
  };

  const select = (ticker) => {
    setSelectedTicker(ticker);
    setSearch('')
  }

  return (
    <div className='search_ticker_container'>
      {(!selectedTicker?.id) ? (
        <input
          type="text"
          placeholder={label || 'Choisissez un ticker'}
          className='input_selectedUser'
          onChange={(e) => setSearch(e.target.value)}
        />

      ) : (
        <div className={"search_ticker_selected relative"}>
          <span onClick={() => select(selectedTicker)}>
            <div className='search_ticker_item_select_ticker'>
              <img className='search_ticker_picture' src={`https://cdn.goliaths.io/stocks/${selectedTicker.ticker}.png`} />
              {selectedTicker.ticker} | {selectedTicker.name}
            </div>
          </span>
          <div onClick={clearSelection} className='delete_item_select'>x</div>
        </div>
      )}
      {(!selectedTicker?.id) && tickers.length > 0 && search.length > 0 && (
        <ul className='search_ticker_list_box'>
          {tickers.map((ticker, index) => (
            <li key={index} onClick={() => select(ticker)} className="search_ticker_not_selected">
              <div className='search_ticker_item_select_ticker'>
                <img className='search_ticker_picture' src={`https://cdn.goliaths.io/stocks/${ticker.ticker}.png`} />
                {ticker.ticker} | {ticker.name}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchTicker