import { useState, useEffect, useContext } from "react"
import AuthContext from "contexte/AuthContext"
import { useTranslation } from "react-i18next"
import "./volumePerStock.css"
import DashboardLayout from "components/layout/dashboard/DashboardLayout"
import FilterComponent from "components/Filters/FilterComponent/FilterComponent"
import PageLoader from "components/pageLoader/PageLoader"
import Tableau from "components/DataComponents/Tableau/Tableau"
import { fetchReportVolumePerStock } from "services/fetchTradeReports"
import { formatDate, RELEASE_DATE } from "utils/date"


const VolumePerStock = () => {
    const { user } = useContext(AuthContext);
    const { t } = useTranslation();

    const [tradeVolume, setTradeVolume] = useState([]);
    const [timeframe, setTimeframe] = useState('day');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [selectedDate, setSelectedDate] = useState({
        date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
        date2: { startDate: null, endDate: null },
    });
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);

    const fetchData = async () => {

        try {

            const response = await fetchReportVolumePerStock(user, limit, page, selectedDate.date1.startDate, selectedDate.date1.endDate, timeframe);
            setTradeVolume(response);
            setLoading(false);
        } catch (e) {
            console.log(e);
            setError(true);
        }
    }

    useEffect(() => {
        if (selectedDate.date1.startDate !== null && selectedDate.date1.endDate !== null) {
            fetchData();
        }
    }, [selectedDate, page, limit, timeframe]);

    const computeDateAndTicker = (item) => {
        let date;
        let ticker;
        switch (item.pos) {
            case '-1':
                date = 'TOTAL';
                ticker = ''
                break;
            case '0':
                // date = formatDate({ date: item.date, month: 'long' });
                date = formatDate({
                    date: item.date, month: 'long',
                    noMonth: timeframe === 'year',
                    noDay: ['month', 'year'].includes(timeframe)
                });
                ticker = ''
                break;
            default:
                date = ''
                ticker = item.ticker
                break
        }
        return { date, ticker }
    }

    const transformDataToTbody = (data) => {

        return data.map(item => {

            let {date, ticker} = computeDateAndTicker(item);

            return {
                data: [
                    date,
                    ticker,
                    item.volume,
                    <div className='volumePerStockBuy'>{item.BUY}</div>,
                    <div className='volumePerStockSell'>{item.SELL}</div>,
                ]
            };
        });
    }

    return (
        <DashboardLayout page={t("pages_name.report_trade")}>
            <div>
                {
                    !loading ? (
                        <div>
                            <div className="flexbox">
                                <h3>Volume de trade (d/m/y)</h3>
                                <FilterComponent
                                    date={true}
                                    page={true}
                                    limit={true}
                                    setPage={setPage}
                                    fetchLimit={limit}
                                    setFetchLimit={setLimit}
                                    timeframeFilter={timeframe}
                                    setTimeframeFilter={setTimeframe}
                                    selectedDate={selectedDate}
                                    setSelectedDate={setSelectedDate}
                                />
                            </div>
                            <div>
                                <Tableau
                                    thead={['Date', 'Ticker', 'Volume', 'Achat', 'Vente']}
                                    tbody={transformDataToTbody(tradeVolume)}
                                    limit={limit}
                                    page={page}
                                    setPage={setPage}
                                    pagination={true}
                                    firstLineSummup
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='pageLoaderBox'>
                            <PageLoader />
                        </div>
                    )
                }
            </div>
        </DashboardLayout>
    )
}

export default VolumePerStock