import './agoDataArray.css'
import { useTranslation } from 'react-i18next';
import { useState, useCallback } from 'react'
import AgoUserTransactions from './AgoUserTransactions/AgoUserTransactions';

const AgoDataArray = ({ id }) => {

    const { t } = useTranslation();

    const [tab, setTab] = useState(0)

    const getComponent = useCallback((t) => {
        switch (t) {
            case 0:
                return <AgoUserTransactions id={id} />
            default:
                return null;
        }
    }, [id])

    return (
        <div>
            <div>
                <div className='transactions_switcher'>
                    {['Transactions'].map((title, index) =>
                        (<div key={index} className={`transactions_tab ${tab === index ? "transactions_tab_select" : "transactions_tab_not_select"}`} onClick={() => { setTab(index) }}>{title}</div>)
                    )}
                    {/* <div className={`transactions_tab ${tab === 1 ? "transactions_tab_select" : "transactions_tab_not_select"}`} onClick={() => { setTab(1) }}>Transactions</div>
                    <div className={`transactions_tab ${tab === 2 ? "transactions_tab_select" : "transactions_tab_not_select"}`} onClick={() => { setTab(2) }}>Portfolio</div>
                    <div className={`transactions_tab ${tab === 3 ? "transactions_tab_select" : "transactions_tab_not_select"}`} onClick={() => { setTab(3) }}>Cash Logs</div>
                    <div className={`transactions_tab ${tab === 4 ? "transactions_tab_select" : "transactions_tab_not_select"}`} onClick={() => { setTab(4) }}>Cash Logs</div> */}
                </div>
                {
                    getComponent(tab)
                }
            </div>

        </div>)
}

export default AgoDataArray