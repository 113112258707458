import BackgroundImage from 'components/DataComponents/BackgroundImage/BackgroundImage';
import { useNavigate } from 'react-router-dom';
import './tableUser.css';


const TableUser = ({ firstName, lastName, fullName, picture, user_uuid, redirect = false }) => {

  const navigate = useNavigate()

  const defaultImg = "https://imageio.forbes.com/specials-images/imageserve/5babb7f1a7ea4342a948b79a/0x0.jpg?";
  const thisBackgroundUrl = picture === "user deleted"
    ? defaultImg
    : `${process.env.REACT_APP_CDN_URL}/usr/pic/${picture}`;

  const getNameToDisplay = () => {
    if (fullName) return fullName;
    else if (firstName === 'user deleted') return 'User deleted'
    return `${firstName} ${lastName}`
  }

  const goTo = () => {
    if (redirect && user_uuid) {
      navigate(`/user/${user_uuid}`)
    }
  }

  return (
    <div
      className={`table_user_div_flex ${redirect && user_uuid ? 'redirect' : ''}`}
      onClick={() => goTo()}
    >
      <BackgroundImage
        imgSrc={thisBackgroundUrl}
        fallbackSrc={defaultImg}
        className='table_user_bg_image'
        style={{ width: "30px", height: "30px", borderRadius: "50px" }}
      />
      <div className=''>
        {getNameToDisplay()}
      </div>
    </div>
  )
}

export default TableUser