
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        detection : {
            order : ["cookie", "htmlTag", "localStorage"],
            caches: ['cookie']
        },
        fallbackLng: "fr",
        backend: {
            loadPath: '/locales/{{lng}}/translation.json'
        },
        react: { useSuspense: false }
    });

    export default i18n