import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_VERSION

export const fetchExternalDeposit = async (user, page, limit, status, startDate, endDate) => {

    try {
        const getDeposit = await axios.get(API_BASE_URL + '/deposits/external/list?startDate=' + startDate + '&endDate=' + endDate + '&page=' + page + '&limit=' + limit + '&status=' + status, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + user
            }
        })

        return getDeposit.data

    } catch (err) {
        console.log(err)
        return {
            error: err
        }
    }

}

export const linkToClient = async (user, deposit_id, user_uuid) => {

    try {
        const link = await axios.post(API_BASE_URL + '/deposits/external/link', {
            deposit_id,
            user_uuid
        }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + user
            }
        })

        return link.data

    } catch (err) {
        console.log(err)
        throw err
    }
}

export const fetchExternalDepositUser = async (user, query) => {

    try {
        const getDeposit = await axios.get(API_BASE_URL + '/users/search?search=' + query, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + user
            }
        })

        return getDeposit.data

    } catch (err) {
        console.log(err)
        throw err
    }
}