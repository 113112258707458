// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-selector button {
    padding: 5px 15px;
    border-radius: 4px;
    background-color: #2B64F5;
    color: white;
    font-weight: bold;
}

.date-picker-container{
    display: flex;
    align-items: center;
    gap: 5px;
}

.date_compared_text{
    margin: 0;
}

.react-datepicker__month-container{
    background-color: white;
    padding: 10px;
    border-radius: 0 0 4px 4px;
}

.react-datepicker__header {
    background-color: white !important;
}

.react-datepicker__navigation--previous{
    left: 7px !important;
}

.react-datepicker__navigation--next{
    right: 7px !important;
}

.react-datepicker__navigation{
    top: 7px !important;
}

.header_selectDate{
    display: flex;
    gap: 10px;
    justify-content: center;
    max-width: 100%;
    padding: 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Filters/DateSelector/DateSelector.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,uBAAuB;IACvB,aAAa;IACb,0BAA0B;AAC9B;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,uBAAuB;IACvB,eAAe;IACf,eAAe;AACnB","sourcesContent":[".date-selector button {\n    padding: 5px 15px;\n    border-radius: 4px;\n    background-color: #2B64F5;\n    color: white;\n    font-weight: bold;\n}\n\n.date-picker-container{\n    display: flex;\n    align-items: center;\n    gap: 5px;\n}\n\n.date_compared_text{\n    margin: 0;\n}\n\n.react-datepicker__month-container{\n    background-color: white;\n    padding: 10px;\n    border-radius: 0 0 4px 4px;\n}\n\n.react-datepicker__header {\n    background-color: white !important;\n}\n\n.react-datepicker__navigation--previous{\n    left: 7px !important;\n}\n\n.react-datepicker__navigation--next{\n    right: 7px !important;\n}\n\n.react-datepicker__navigation{\n    top: 7px !important;\n}\n\n.header_selectDate{\n    display: flex;\n    gap: 10px;\n    justify-content: center;\n    max-width: 100%;\n    padding: 10px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
