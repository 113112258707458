import React, { useState, useEffect, useContext } from 'react';
import '../KPI.css';
import AuthContext from 'contexte/AuthContext';
import DateSelector from 'components/Filters/DateSelector/DateSelector';
import { Box } from '../KpiBox/KpiBox';
import { startOfDay } from 'date-fns';
import { fetchKpiDataForDates } from 'services/kpiServices';
import { RELEASE_DATE } from 'utils/date';

const KpiStructuredProducts = () => {
  const { user } = useContext(AuthContext);
  const [kpiData, setKpiData] = useState({
    selectedData: {
      collected: 0,
      profit: 0,
      investors: 0,
      investors_multiple: 0,
    },
    comparedData: {
      collected: 0,
      profit: 0,
      investors: 0,
      investors_multiple: 0,
    }
  });
  const [selectedDate, setSelectedDate] = useState({
    date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
    date2: { startDate: null, endDate: null },
  });

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchKpiDataForDates(user, 'sp', selectedDate.date1, selectedDate.date2);
      setKpiData(data);
    };
    fetchData();
  }, [user, selectedDate]);

  return (

    <div>
      <div className='kpi_date'>
        <DateSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate} withComparaison={true} />
      </div>
      <div className='kpi_container'>
        <Box
          title='Volume produits structurés'
          data={kpiData.selectedData.volume}
          unit='€'
          comparedData={kpiData.comparedData.volume}
          ppComparison={true}
        />
        <Box
          title='Profit'
          data={kpiData.selectedData.profit}
          comparedData={kpiData.comparedData.profit}
          unit='€'
        />
        <Box
          title='Investisseurs unique'
          data={kpiData.selectedData.investors}
          comparedData={kpiData.comparedData.investors}
        />
        <Box
          title='Investisseurs multi-produits'
          data={kpiData.selectedData.investors_multiple}
          comparedData={kpiData.comparedData.investors_multiple}
        />
      </div>
    </div>
  )
}


export default KpiStructuredProducts