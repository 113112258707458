import { useState } from 'react'
import "./questions.css"
import { formData } from 'pages/forms/fakeData'

//passer le form en state pour le manipuler et provoquer un re-render

const Questions = () => {
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);

    const handleQuestionClick = index => {
        setSelectedQuestionIndex(index);
    };

    const selectedQuestion = formData.questions[selectedQuestionIndex];

    return (
        <div className="questions-container">
            <div className="questions-sidebar">
                {formData.questions.map((question, index) => (
                    <button
                        key={index}
                        className={`sidebar-item ${index === selectedQuestionIndex ? 'active' : ''}`}
                        onClick={() => handleQuestionClick(index)}
                    >
                        {question.title}
                    </button>
                ))}
            </div>
            <div className="question-content">
                <h2>{selectedQuestion.title}</h2>
                <ul>
                    {selectedQuestion.choices.map((choice, index) => (
                        <li key={index}>{choice.title}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};


export default Questions