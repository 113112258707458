// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transactions_switcher{
    display: flex;
    flex-direction: row;
    padding: 5px;
    border-radius: 14px;
    background-color: #F2F3FC;
    width: max-content;
    margin: auto;
}

.transactions_tab{
    padding: 10px 20px;
    border-radius: 10px;
}

.transactions_tab{
    cursor: pointer;
}

.transactions_tab_select{
    background-color: #2B64F5;
    color: white;
}

.transactions_tab_not_select{
    background-color: #F2F3FC;
}`, "",{"version":3,"sources":["webpack://./src/pages/finances/subpages/Transactions/transactions.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".transactions_switcher{\n    display: flex;\n    flex-direction: row;\n    padding: 5px;\n    border-radius: 14px;\n    background-color: #F2F3FC;\n    width: max-content;\n    margin: auto;\n}\n\n.transactions_tab{\n    padding: 10px 20px;\n    border-radius: 10px;\n}\n\n.transactions_tab{\n    cursor: pointer;\n}\n\n.transactions_tab_select{\n    background-color: #2B64F5;\n    color: white;\n}\n\n.transactions_tab_not_select{\n    background-color: #F2F3FC;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
