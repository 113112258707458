// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arrayTickerItem{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;
}

.arrayTickerItem img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.span_sell{
    background-color: red;
    color: white;
    padding: 3px 10px;
    border-radius: 5px;
}


.span_buy{
    background-color: green;
    color: white;
    padding: 3px 10px;
    border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/users/userById/UserDataArray/UserTransactions/UserTransactions.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;AACtB;;;AAGA;IACI,uBAAuB;IACvB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".arrayTickerItem{\n    display: flex;\n    flex-direction: row;\n    justify-content: start;\n    align-items: center;\n    gap: 10px;\n}\n\n.arrayTickerItem img{\n    width: 30px;\n    height: 30px;\n    border-radius: 50%;\n}\n\n.span_sell{\n    background-color: red;\n    color: white;\n    padding: 3px 10px;\n    border-radius: 5px;\n}\n\n\n.span_buy{\n    background-color: green;\n    color: white;\n    padding: 3px 10px;\n    border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
