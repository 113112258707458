import { useState, useEffect, useContext, useCallback } from 'react'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import { useTranslation } from 'react-i18next';
import './reconciliations.css'
import AuthContext from 'contexte/AuthContext';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import PageLoader from 'components/pageLoader/PageLoader';
import useFilters from 'hooks/useFilters';
import Notification from 'components/notifications/Notification';
import { launchError } from 'components/notifications';
import { fetchReconciliations } from 'services/reconciliationsServices';
import { subDays } from 'date-fns'


const Reconciliations = () => {

  const { t } = useTranslation()
  const { user } = useContext(AuthContext)

  const initialFilters = {
    fetchLimit: 11,
    page: 0,
    type: '',
    selectedDate: {
      date1: { startDate: subDays(new Date(), 1), endDate: subDays(new Date(), 1) },
      date2: { startDate: null, endDate: null },
    }
  };

  const { filters, handleFiltersChange, resetFilters } = useFilters(initialFilters);

  const typesFilter = [
    '',
    'cc_alpaca',
    'cc_truelayer',
    'cc_stripe',
    'cc_goliaths',
    'net_income',
    'truelayer',
    'available_stripe',
    'pending_stripe',
    'wallet_available',
    'fees_paid_by_goliaths',
    'gifts'
  ].map((item) => ({ value: item, display: t(`pages.reconciliations.types.${item}`) }))

  const setFetchLimit = (newLimit) => {
    handleFiltersChange('fetchLimit', newLimit)
    handleFiltersChange('page', 0)
  };
  const setPage = (newPage) => handleFiltersChange('page', newPage);
  const setType = (newType) => handleFiltersChange('type', newType);
  const setSelectedDate = (newDate) => handleFiltersChange('selectedDate', newDate)


  const [loading, setLoading] = useState(false)
  const [reconciliations, setReconciliations] = useState([]);

  useEffect(() => {

    const fetchData = async () => {
      const data = await fetchReconciliations({
        user,
        page: filters.page,
        limit: filters.fetchLimit,
        type: filters.type,
        startDate: filters.selectedDate.date1.startDate,
        endDate: filters.selectedDate.date1.startDate
      });
      setReconciliations(addDateToData(data));
    }
    try {
      setLoading(true);
      fetchData();
    } catch (e) {
      launchError(t('notifications.payouts.reconciliations.fetch.error'));
    } finally {
      setLoading(false);
    }

  }, [/*filters.page, filters.fetchLimit, */filters.type, filters.selectedDate.date1.startDate, filters.selectedDate.date1.startDate])

  const addDateToData = useCallback((data) => {
    let result = [];
    let previousDate = null;
    for (let item of data) {
      if (item.date != previousDate) {
        previousDate = item.date;
        result.push({ date: item.date })
      }
      result.push(item);
    }
    return result
  }, [])

  const transformDatatoTbody = (data) => {
    return data.map(item => {
      return {
        data: [
          item._id ? t(`pages.reconciliations.types.${item.type}`) :
            // item.date,
            <div style={{ color: 'black', fontWeight: '900', fontSize: '1.3rem' }}>{item.date}</div>,
          item._id && item.balance?.map(bal => (
            <p key={bal._id}>{bal.amount} {bal.currency}</p>
          ))
          // : <></>
        ]
      }
    })
  }

  if (loading) return (
    <div className='pageLoaderBox'>
      <PageLoader />
    </div>
  )

  const dropdowns = [{
    label: '',
    value: filters.type,
    setter: setType,
    options: typesFilter
  }]

  return (
    <DashboardLayout page={t("pages_name.reconciliations")}>
      <div>
        <Notification />
        <div>
          <div className='payouts_titleComponent'>
            <h2>{t('pages.reconciliations.title')}</h2>
          </div>
          <FilterComponent
            resetFilter={resetFilters}
            // limit
            fetchLimit={filters.fetchLimit}
            limitOptions={
              Array.from(Array(5), (_, index) => ({ value: (index + 1) * 11, display: (index + 1) * 11 }))
            }
            setFetchLimit={setFetchLimit}
            date
            simpleDate
            selectedDate={filters.selectedDate}
            setSelectedDate={setSelectedDate}
            useDropdown
            dropdowns={dropdowns}
          />
          <div className="table-container">
            <div className="table-column">
              {reconciliations?.length && (
                <Tableau
                  thead={['Type', 'Balance']}
                  tbody={transformDatatoTbody(reconciliations)}
                  page={filters.page} setPage={setPage}
                  // pagination={true}
                  limit={filters.fetchLimit}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default Reconciliations

