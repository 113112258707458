import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchDepositWithdrawDetail = async ({ type, user, id }) => {

    try {
        const { data } = await axios.get(`${API_BASE_URL}/monitoring/${type}/details/${id}`,
            buildHeaders(user)
        )

        return data

    } catch (err) {

        console.log(err)
    }

}