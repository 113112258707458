import { useState, useEffect, useContext, useCallback } from 'react'
import AuthContext from 'contexte/AuthContext'
import './userNotify.css'
import Notification from 'components/notifications/Notification'
import { launchError, launchSuccess } from 'components/notifications'
import { useTranslation } from 'react-i18next';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import { searchUsers } from 'services/fetchUsers'
import { fetchTickers } from 'services/tickersServices'
import { assignGift } from 'services/fetchGift'
import SearchTicker from 'components/SearchTicker/SearchTicker'
import SearchUser from 'components/SearchUser/SearchUser'
import { fetchLastUserPosts } from 'services/postServices'
import { fetchStructuredProducts } from 'services/structuredProductsServices'
import SearchStructuredProducts from 'components/SearchStructuredProducts/SearchStructuredProducts'
import { notifyUsers, testNotifyUser } from 'services/userServices'


const UserNotify = () => {

  const { user } = useContext(AuthContext)
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [tickers, setTickers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedPost, setSelectedPost] = useState({});
  const [selectedTicker, setSelectedTicker] = useState({});
  const [selectedSp, setSelectedSp] = useState({});
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('');
  const [title, setTitle] = useState('');
  const [posts, setPosts] = useState([]);
  const [structuredProducts, setStructuredProducts] = useState([]);
  const [message, setMessage] = useState('');
  const [isValid, setIsValid] = useState(false);

  const clearForm = () => {
    setSelectedTicker({})
    setSelectedUser({})
    setSelectedSp({})
    setSelectedPost({})
    setType('')
    setTitle('')
    setMessage('')
    setUsers([])
    setTickers([])
  }

  const notify = async (isBroadcast = false) => {
    try {
      // e.preventDefault()
      setLoading(true);
      const body = {
        type,
        title,
        message,
        isBroadcast
      }
      if (type === 'post') {
        body['owner_uuid'] = selectedPost.user_uuid
        body['post_uuid'] = selectedPost.getstream_uuid
      } else if (type === 'stock') {
        body['ticker'] = selectedTicker.ticker
      } else if (type === 'structured_product') {
        body['product_id'] = selectedSp._id
      }
      await notifyUsers(user, body)
      launchSuccess(t('notifications.users.notify.success'))
      if (isBroadcast)
        clearForm()
    } catch (e) {
      launchError(t('notifications.users.notify.error'))
    } finally {
      setLoading(false)
    }
  }

  const fetchUserPosts = async () => {
    try {
      const data = await fetchLastUserPosts(user, selectedUser.uuid)
      setPosts(data);
    } catch (error) {
      launchError();
      setPosts([]);
    }
  }

  useEffect(() => {
    if (selectedUser.uuid) {
      fetchUserPosts();
    }
  }, [selectedUser])


  useEffect(() => {
    const valid = title && message && (
      (type === 'post' && selectedUser.uuid && selectedPost) ||
      (type === 'stock' && selectedTicker.id) ||
      (type === 'structured_product' && selectedSp._id) ||
      (type === 'simple')
    )
    setIsValid(valid)
  }, [title, message, type, selectedPost, selectedSp, selectedUser, selectedTicker])



  const handleSearchUsers = async (search) => {
    if (search.length > 0) {
      try {
        setLoading(true)
        const fetchUsers = await searchUsers(user, search)
        setUsers(fetchUsers)
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    } else {
      setUsers([])
    }
  }

  const handleSearchTickers = async (search) => {
    if (search.length > 0) {
      try {
        setLoading(true)
        const fetchedTickers = await fetchTickers(user, 0, 10, search)
        setTickers(fetchedTickers)
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    } else {
      setTickers([])
    }
  }

  const handleSearchSp = async (search) => {
    if (search.length > 0) {
      try {
        setLoading(true)
        const data = await fetchStructuredProducts({ user, page: 0, limit: 10, search })
        setStructuredProducts(data)
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    } else {
      setStructuredProducts([])
    }
  }

  const handlePostWriter = (writer) => {
    setSelectedUser(writer);
    setSelectedPost({})
  }

  const getComponent = (type) => {
    switch (type) {
      case 'post':
        return (
          <div>
            <div className='user_notify_item_box'>
              <label htmlFor='user-search'>Rédacteur</label>
              <SearchUser
                id='user-search'
                selectUser={handlePostWriter}
                selectedUser={selectedUser}
                users={users}
                handleSearch={handleSearchUsers}
              />
            </div>
            {selectedUser?.uuid && <div className='user_notify_item_box'>
              <label htmlFor="user-posts-select">10 derniers posts de {selectedUser?.firstName} {selectedUser?.lastName}</label>
              <select name="user-posts"
                className='user_notify_custom_select'
                id="user-posts-select"
                // value={index}
                onChange={(e) => setSelectedPost(posts[e.target.value])}>
                {posts.map((post, index) => (<option key={index} value={index}>{post.desc}</option>))}
              </select>
            </div>}
          </div>

        )
      case 'stock':
        return (
          <div className='user_notify_item_box'>
            <label htmlFor="search_ticker">Ticker</label>
            <SearchTicker
              id='search_ticker'
              setSelectedTicker={setSelectedTicker}
              selectedTicker={selectedTicker}
              tickers={tickers}
              handleSearch={handleSearchTickers}
            />

          </div>
        )
      case 'structured_product':
        return (
          <div className='user_notify_item_box'>
            <label htmlFor="search_sp">Nom du produit structuré</label>
            <SearchStructuredProducts
              id='search_sp'
              setSelectedSp={setSelectedSp}
              selectedSp={selectedSp}
              structuredProducts={structuredProducts}
              handleSearch={handleSearchSp

              }
            />

          </div>
        )
      default:
        return;
    }
  }

  const handleType = (newType) => {
    setType(newType);
    setSelectedPost({});
    setSelectedSp({})
    setSelectedTicker({})
    setSelectedTicker({})
  }


  return (
    <DashboardLayout page={t("pages_name.alpaca")}>
      <div>
        <Notification />
        <div>
          <div className='newGift_titleComponent'>
            <h2>Notifier les utilisateurs</h2>
          </div>
          <div>
            {/* <form onSubmit={handleSubmit}> */}
            <div className='user_notify_item_box'>
              <label htmlFor="notification-type-select">Type</label>
              <select name="notification-type"
                className='user_notify_custom_select'
                id="notification-type-select"
                value={type}
                onChange={(e) => handleType(e.target.value)}>
                <option value="">--Choisissez un type--</option>
                <option value="simple">Ne rien lier</option>
                <option value="post">Lier un post</option>
                <option value="structured_product">Lier un produit structuré</option>
                <option value="stock">Lier une action</option>
              </select>
            </div>
            <div className='user_notify_item_box'>
              <label htmlFor="title">Titre</label>
              <input
                id="title"
                className='user_notify_input'
                type="text"
                value={title}
                onInput={(e) => setTitle(e.target.value)} />
            </div>
            <div className='user_notify_item_box'>
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                className='user_notify_input'
                type="text"
                value={message}
                onInput={(e) => setMessage(e.target.value)} />
            </div>
            {getComponent(type)}
            <div className='user_notify_submit_box'>
              <button onClick={() => notify()} className='user_notify_button_test' disabled={!isValid} >Tester</button>
              <button onClick={() => notify(true)} className='user_notify_button_submit' disabled={!isValid} >Envoyer à tout le monde</button>
            </div>
            {/* </form> */}
          </div>
        </div>

      </div>
    </DashboardLayout>
  )
}

export default UserNotify