import { useState, useEffect, useContext } from "react"
import AuthContext from "contexte/AuthContext"
import { useTranslation } from "react-i18next"
import "./waitingTradeVolume.css"
import DashboardLayout from "components/layout/dashboard/DashboardLayout"

const WaitingTradeVolume = () => {

    const { user } = useContext(AuthContext);
    const { t } = useTranslation();

    return (
        <DashboardLayout page={t("pages_name.report_trade")}>
            <div>TradeVolume</div>
        </DashboardLayout>
    )
}

export default WaitingTradeVolume