import React from 'react'
import { useNavigate } from 'react-router-dom';
import "./tableau.css"

const Tableau = ({ thead, tbody, pagination, page, setPage, limit, hadLink, hadModal, linkPath, onClickFunction, onHeaderClick, firstLineSummup }) => {

    const navigate = useNavigate()

    const handleOnClick = (id) => {
        if (id && hadLink) {
            navigate(linkPath + id)
        }
    }

    const handleNextPageTableau = () => {
        setPage(page + 1);
    }

    const handlePreviousPageTableau = () => {
        setPage(page - 1);
    }

    return (
        <div className='tableau_container'>
            <table className='tableau_table'>
                <thead className='tableau_head'>
                    <tr className='tableau_head_item'>
                        {thead?.map((th, index) => (
                            <th className='tableau_head_item_th' key={index} onClick={() => onHeaderClick && onHeaderClick(th)}>{th}</th>
                        ))}
                    </tr>
                </thead>
                {
                    tbody.length > 0 && (
                        <tbody className='tableau_body'>
                            {tbody.map((tr, rowIndex) => (
                                <tr key={rowIndex} className='tableau_body_row' onClick={() => { (hadLink && handleOnClick(tr.utils.id)) || (hadModal && onClickFunction(rowIndex)) }} >
                                    {tr.data.map((td, columnIndex) => (
                                        <td key={columnIndex} className={`${hadLink && 'pointer'} tableau_body_item ${firstLineSummup && page === 0 && rowIndex === 0 &&'tableau_first_line_summup'}`}>
                                            {td}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    )
                }
            </table>
            {
                (tbody.length === 0) && (
                    <div className='nodata_img_div'>
                        <img src="/img/nodata.png" alt="no data" width={75} />
                        <p>Aucune donnée à afficher</p>
                    </div>
                )
            }

            {
                pagination && (
                    <div className='tableau_pagination'>
                        {
                            page !== 0 && (
                                <div className='tableau_nextPage'>
                                    <button onClick={handlePreviousPageTableau} className='button_nextPage'>Page Prédécente</button>
                                </div>
                            )
                        }
                        <div className='actualPage'>{page}</div>
                        {
                            tbody.length !== 0 && tbody.length >= limit && (
                                <div className='tableau_nextPage'>
                                    <button onClick={handleNextPageTableau} className='button_nextPage'>Page suivante</button>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div>
    )
}

export default Tableau