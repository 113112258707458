import { useState, useContext, useEffect } from 'react'
import AuthContext from 'contexte/AuthContext'
import './etfComponentsTab.css'
import { useTranslation } from 'react-i18next';
import Notification from 'components/notifications/Notification'
import { launchError } from 'components/notifications'
import SearchTicker from 'components/SearchTicker/SearchTicker';
import { fetchTickers } from 'services/tickersServices';
import { getDecimalValue } from 'utils/numbers';


const EtfComponentsTab = ({ etf, editEtf }) => {

  const { user } = useContext(AuthContext)
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [newTickers, setNewTickers] = useState([])
  const [selectedTicker] = useState({});
  const [sumWeight, setSumWeight] = useState(0);

  useEffect(() => {
    const sum = etf?.components?.reduce((acc, { weight }) => acc + Number(weight), 0);
    setSumWeight(getDecimalValue(sum));
  }, [etf.components]);


  const handleTickerWeight = (index, e) => {
    const newEtf = { ...etf };
    const newComponents = [...etf.components];
    newComponents[index].weight = Number(e.target.value);
    newEtf.components = newComponents;
    editEtf(newEtf);
  }

  const removeTickerFromEtf = (index) => {
    const newEtf = { ...etf };
    const newComponents = [...newEtf.components];
    newComponents.splice(index, 1);
    newEtf.components = newComponents;
    editEtf(newEtf)
  }

  const handleSearchTickers = async (search) => {
    if (search.length > 0) {
      try {
        setLoading(true)
        const fetchedTickers = await fetchTickers(user, 0, 10, search)
        setNewTickers(fetchedTickers)
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    } else {
      setNewTickers([])
    }
  }

  const handleAddNewTickers = (ticker) => {
    if (ticker.ticker) {
      const newEtf = { ...etf };
      const newComponents = etf.components ? [...etf.components] : [];
      newComponents.push({
        ticker: ticker.ticker,
        name: ticker.name,
        weight: 0
      })
      newEtf.components = newComponents;
      editEtf(newEtf)
    }
  }

  return (
    <div>

      <div className='etf_components_list'>
        <Notification />
        <div className='etf_components_search_box'>

          <SearchTicker
            className='etf_components_search_input'
            setSelectedTicker={handleAddNewTickers}
            selectedTicker={selectedTicker}
            tickers={newTickers}
            handleSearch={handleSearchTickers}
            label={'Ajouter un ticker'}
          />
        </div>

        {etf?.components.map((component, index) =>
          <div key={index} className="etf_component_ticker_box">
            <div className="etf_component_ticker_desc">
              <img className="etf_component_ticker_img" src={`https://cdn.goliaths.io/stocks/${component.ticker}.png`} alt="" />
              <span className="">{component.name}</span>
            </div>
            <input
              onChange={(e) => handleTickerWeight(index, e)}
              value={component.weight}
              className='etf_component_weigth_input'
              type = 'number'
            />
            <span onClick={() => removeTickerFromEtf(index)} className="pointer etf_component_ticker_delete_button">x</span>
          </div >

        )}
      </div>

      {sumWeight !== 100 && (
        <div className='etf_edition_alert_repartition_box'>
          <p className='etf_edition_alert_repartition'>La somme des répartitions des composants n'est pas de 100 % ({sumWeight} %)</p>
        </div>
      )}


    </div>
  )
}

export default EtfComponentsTab