export const getInitialFilter = (filterKey) => {
  let result
  const params = new URLSearchParams(window.location.search)

  if (filterKey === 'selectedDate') {
    if (params.get('startDate1') && params.get('endDate1')) {
      result = {
        date1: { startDate: new Date(params.get('startDate1')), endDate: new Date(params.get('endDate1')) },
        date2: { startDate: null, endDate: null }
      }
    }
  } else {
    result = params.get(filterKey)
  }
  // Vérifier si filterkey est une date renvoyer un object pour le calendar
  return result;
}