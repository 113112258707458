import { useEffect, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AuthContext from "contexte/AuthContext"
import { useTranslation } from 'react-i18next';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import Notification from 'components/notifications/Notification';
import { launchError } from 'components/notifications';
import DownloaderLink from 'components/DownloaderLink/DownloaderLink';
import './screeningFolderList.css'
import { fetchSumsubScreeningFolder } from 'services/sumsubOverview';

const SumsubScreeningFolderList = () => {

  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [folders, setFolders] = useState([]);

  const location = useLocation();

  useEffect(() => {
    const fetchReportList = async () => {
      try {
        const data = await fetchSumsubScreeningFolder({ user, type: location.pathname.split('/').reverse()[0].replace('-', '_') })
        setFolders(data);
      } catch (error) {
        launchError(t('notifications.reports.compliance.list.error'))
      }
    }

    fetchReportList();
  }, [])

  return (
    <DashboardLayout page={t("pages_name.folder_sumsub_screening")}>
      <Notification />
      <div className='sumsub_screening_folder_titleComponent'>
        <h2>{t("pages.folder_sumsub_screening.title")}</h2>
      </div>
      <div>
        <ul>
          {folders.map(folder => (
            <Link to={folder} key={folder}>{folder}</Link>
          ))}
        </ul>
      </div>
    </DashboardLayout>
  )
}

export default SumsubScreeningFolderList;