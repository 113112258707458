// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.margin_transaction
s_filter_components{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/finances/subpages/MarginTransactions/marginTransactions.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,mBAAmB;AACvB","sourcesContent":[".margin_transaction\ns_filter_components{\n    display: flex;\n    flex-direction: row;\n    gap: 10px;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
