import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";


export const fetchLeverageTransactions = async (user, page, limit, startDate, endDate, type, user_uuid) => {

  try {
    const params = `?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}&type=${type}${user_uuid ? `&user_uuid=${user_uuid}` : ''}`
    const { data } = await axios.get(`${API_BASE_URL}/leverage/transactions${params}`, buildHeaders(user)
    )

    return data

  } catch (err) {

    console.log(err)
    throw err
  }

}

export const fetchLeverageTransactionById = async (user, id) => {

  try {
    const { data } = await axios.get(`${API_BASE_URL}/leverage/transaction/${id}`, buildHeaders(user))

    return data

  } catch (err) {

    console.log(err)
    throw err
  }

}
