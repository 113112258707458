import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_VERSION;

export const fetchUsers = async (user, page, limit, alpacaFilter, sumsubFilter, startDate, endDate, search, filterType, sort, order) => {

    const searchParam = `${search !== '' ? `&value=${search}` : ''}${filterType !== 'all' ? `&filter=${filterType}` : ''}${sort ? `&field=${sort}&order=${order}` : ''}`

    try {
        const response = await axios.get(API_BASE_URL + "/users/clients?page=" + page + "&limit=" + limit + "&alpacaFilter=" + alpacaFilter + "&sumsubFilter=" + sumsubFilter + '&startDate=' + startDate + '&endDate=' + endDate + searchParam,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${user}`
                }
            });
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const searchUsers = async (user, query) => {

    try {
        const { data } = await axios.get(API_BASE_URL + '/users/search?search=' + query, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + user
            }
        })

        return data

    } catch (err) {
        console.log(err)
        throw err
    }
}