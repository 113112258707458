import axios from "axios";

import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchPlaylistData = async (user, user_uuid, playlist_id) => {

    try {
        const { data } = await axios.get(`${API_BASE_URL}/users/clients/${user_uuid}/playlist/${playlist_id}/positions`,
            buildHeaders(user));
        return data;
    } catch (error) {
        console.error(error);
    }
}