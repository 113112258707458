// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* giftFilters.css */
.ordersFilterComponent{
  display: flex;
}
/* Conteneur principal */
.ordersFilterComponent div {
  display: flex;
  justify-content: space-between; /* Distribue les éléments uniformément le long de l'axe principal */
  align-items: center; /* Centre les éléments le long de l'axe transversal */
  padding: 10px;
}

/* Conteneur pour chaque filtre */
.ordersFilterComponent div div {
  display: flex;
  align-items: center;
}

/* Espacement entre la case à cocher et le libellé */
.ordersFilterComponent input[type="checkbox"] + label {
  margin-left: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Filters/OrdersFilter/ordersFilter.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;EACE,aAAa;AACf;AACA,wBAAwB;AACxB;EACE,aAAa;EACb,8BAA8B,EAAE,mEAAmE;EACnG,mBAAmB,EAAE,qDAAqD;EAC1E,aAAa;AACf;;AAEA,iCAAiC;AACjC;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA,oDAAoD;AACpD;EACE,gBAAgB;AAClB","sourcesContent":["/* giftFilters.css */\n.ordersFilterComponent{\n  display: flex;\n}\n/* Conteneur principal */\n.ordersFilterComponent div {\n  display: flex;\n  justify-content: space-between; /* Distribue les éléments uniformément le long de l'axe principal */\n  align-items: center; /* Centre les éléments le long de l'axe transversal */\n  padding: 10px;\n}\n\n/* Conteneur pour chaque filtre */\n.ordersFilterComponent div div {\n  display: flex;\n  align-items: center;\n}\n\n/* Espacement entre la case à cocher et le libellé */\n.ordersFilterComponent input[type=\"checkbox\"] + label {\n  margin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
