export const formData = {
  "_id": {
    "$oid": "657748d0ec8db9b6680aa173"
  },
  "type": "kyc",
  "language": "fr",
  "questions": [
    {
      "key": "employment_status",
      "title": "Votre emploi",
      "type": "risk_level",
      "choices": [
        {
          "key": "employed",
          "title": "Employé(e)",
          "score": 0.4
        },
        {
          "key": "unemployed",
          "title": "Sans emploi",
          "go_to_index": 2,
          "score": 0.8
        },
        {
          "key": "student",
          "title": "Étudiant(e)",
          "go_to_index": 2,
          "score": 0.6
        },
        {
          "key": "retired",
          "title": "Retraité(e)",
          "go_to_index": 2,
          "score": 0.4
        },
        {
          "key": "freelance",
          "title": "Freelance",
          "score": 0.6
        },
        {
          "key": "business_owner",
          "title": "Chef d'entreprise",
          "score": 0.8
        }
      ]
    },
    {
      "key": "industry",
      "type": "risk_level",
      "title": "Industrie",
      "choices": [
        {
          "key": "accounting_tax_legal_advice",
          "title": "Comptabilité, Fiscalité et Conseils Juridiques",
          "score": 1.6
        },
        {
          "key": "aerospace_defence",
          "title": "Aérospatiale et Défense",
          "score": 1.6
        },
        {
          "key": "antique_fine_art_dealers",
          "title": "Antiquaires et Marchands d'Art",
          "score": 1.6
        },
        {
          "key": "apparel_fashion",
          "title": "Vêtements et Mode",
          "score": 1.6
        },
        {
          "key": "architecture_planning_civil_engineering",
          "title": "Architecture, Urbanisme et Génie Civil",
          "score": 1.6
        },
        {
          "key": "banking_mortgage_credit",
          "title": "Banque, Hypothèque et Crédit",
          "score": 1.6
        },
        {
          "key": "biotechnology_pharmaceuticals",
          "title": "Biotechnologie et Pharmaceutique",
          "score": 1.6
        },
        {
          "key": "capital_markets_hedge_fund_private_equity",
          "title": "Marchés des Capitaux, Fonds Spéculatifs et Capital-Investissement",
          "score": 1.6
        },
        {
          "key": "computer_information_technology",
          "title": "Informatique et Technologie de l'Information",
          "score": 1.6
        },
        {
          "key": "construction",
          "title": "Construction",
          "score": 1.6
        },
        {
          "key": "consumer_goods_services",
          "title": "Biens de consommation et Services",
          "score": 1.6
        },
        {
          "key": "cosmetics_beauty",
          "title": "Cosmétiques et Beauté",
          "score": 1.6
        },
        {
          "key": "education_primary_secondary_academia",
          "title": "Éducation Primaire, Secondaire et Universitaire",
          "score": 1.6
        },
        {
          "key": "electrical_electronic_manufacturing",
          "title": "Fabrication Électrique et Électronique",
          "score": 1.6
        },
        {
          "key": "entertainment_movie_production",
          "title": "Divertissement et Production Cinématographique",
          "score": 1.6
        },
        {
          "key": "environmental_services",
          "title": "Services Environnementaux",
          "score": 1.6
        },
        {
          "key": "farming_ranching_fishing",
          "title": "Agriculture, Élevage et Pêche",
          "score": 1.6
        },
        {
          "key": "financial_services_investment_banking",
          "title": "Services Financiers et Banque d'Investissement",
          "score": 1.6
        },
        {
          "key": "food_beverages_events_services",
          "title": "Alimentation, Boissons et Services Événementiels",
          "score": 1.6
        },
        {
          "key": "forestry",
          "title": "Sylviculture",
          "score": 1.6
        },
        {
          "key": "gambling_casinos",
          "title": "Jeux d'Argent et Casinos",
          "score": 1.6
        },
        {
          "key": "government_public_sector",
          "title": "Gouvernement et Secteur Public",
          "score": 0.4
        },
        {
          "key": "graphic_design_web_design",
          "title": "Design Graphique et Conception Web",
          "score": 1.6
        },
        {
          "key": "healthcare",
          "title": "Soins de Santé",
          "score": 1.6
        },
        {
          "key": "hotels_accommodation",
          "title": "Hôtels et Hébergement",
          "score": 1.6
        },
        {
          "key": "human_resources_staffing",
          "title": "Ressources Humaines et Recrutement",
          "score": 1.6
        },
        {
          "key": "industrial_manufacturing_automation",
          "title": "Fabrication Industrielle et Automatisation",
          "score": 1.6
        },
        {
          "key": "insurance",
          "title": "Assurance",
          "score": 1.6
        },
        {
          "key": "interior_design",
          "title": "Design d'Intérieur",
          "score": 1.6
        },
        {
          "key": "internet_online_services",
          "title": "Internet et Services en Ligne",
          "score": 1.6
        },
        {
          "key": "investment_portfolio_management",
          "title": "Investissement et Gestion de Portefeuille",
          "score": 1.6
        },
        {
          "key": "journalism_media_publishing",
          "title": "Journalisme, Médias et Édition",
          "score": 1.6
        },
        {
          "key": "management_consulting",
          "title": "Conseil en Gestion",
          "score": 1.6
        },
        {
          "key": "manufacturing_of_glass_ceramics_concrete_furniture",
          "title": "Fabrication de Verre, Céramique, Béton, Meubles",
          "score": 1.6
        },
        {
          "key": "marketing_advertising",
          "title": "Marketing et Publicité",
          "score": 1.6
        },
        {
          "key": "media_publishing",
          "title": "Médias et Édition",
          "score": 1.6
        },
        {
          "key": "medical_devices",
          "title": "Dispositifs Médicaux",
          "score": 1.6
        },
        {
          "key": "mining_metals",
          "title": "Exploitation Minière et Métaux",
          "score": 1.6
        },
        {
          "key": "non-profit_organization",
          "title": "Organisation à But Non Lucratif",
          "score": 1.6
        },
        {
          "key": "oil_gas",
          "title": "Pétrole et Gaz",
          "score": 1.6
        },
        {
          "key": "performing_fine_arts",
          "title": "Arts du Spectacle et Beaux-Arts",
          "score": 1.6
        },
        {
          "key": "real_estate",
          "title": "Immobilier",
          "score": 1.6
        },
        {
          "key": "research_development",
          "title": "Recherche et Développement",
          "score": 1.6
        },
        {
          "key": "retail_wholesale",
          "title": "Commerce de Détail et de Gros",
          "score": 1.6
        },
        {
          "key": "scientific_technical_consulting",
          "title": "Conseil Scientifique et Technique",
          "score": 1.6
        },
        {
          "key": "sports_leisure_fitness",
          "title": "Sports, Loisirs et Fitness",
          "score": 1.6
        },
        {
          "key": "telecommunications_internet_providers",
          "title": "Télécommunications et Fournisseurs Internet",
          "score": 1.6
        },
        {
          "key": "tourism",
          "title": "Tourisme",
          "score": 1.6
        },
        {
          "key": "transportation_logistics",
          "title": "Transport et Logistique",
          "score": 1.6
        },
        {
          "key": "utilities_renewable_energy",
          "title": "Services Publics et Énergie Renouvelable",
          "score": 1.6
        },
        {
          "key": "veterinary",
          "title": "Vétérinaire",
          "score": 1.6
        },
        {
          "key": "wine_spirits_industry",
          "title": "Industrie du Vin et des Spiritueux",
          "score": 1.6
        }
      ]
    },
    {
      "key": "income_amount",
      "type": "risk_level",
      "title": "Vos Revenus",
      "choices": [
        {
          "key": "0-20",
          "title": "0 - 20 000 € / an",
          "score": 0.36
        },
        {
          "key": "20-50",
          "title": "20 000 - 50 000 € / an",
          "score": 0.36
        },
        {
          "key": "50-100",
          "title": "50 000 - 100 000 € / an",
          "score": 0.72
        },
        {
          "key": "100",
          "title": "+ 100 000 € / an",
          "score": 1.08
        }
      ]
    },
    {
      "key": "funding_source",
      "type": "risk_level",
      "title": "Source de votre Patrimoine",
      "choices": [
        {
          "key": "employment_income",
          "title": "Salaire",
          "score": 0.4
        },
        {
          "key": "inheritance",
          "title": "Héritage",
          "score": 0.6
        },
        {
          "key": "investments",
          "title": "Investissement",
          "score": 0.4
        },
        {
          "key": "business_income",
          "title": "Dividendes",
          "score": 0.4
        },
        {
          "key": "savings",
          "title": "Épargne",
          "score": 0.6
        }
      ]
    },
    {
      "key": "funding_size",
      "type": "risk_level",
      "title": "Votre patrimoine",
      "choices": [
        {
          "key": "0-200",
          "title": "0 € - 200 000 €",
          "score": 0.3
        },
        {
          "key": "200-500",
          "title": "200 000 € - 500 000 €",
          "score": 0.3
        },
        {
          "key": "500-1000",
          "title": "500 000 € - 1 000 000 €",
          "score": 0.6
        },
        {
          "key": "1000",
          "title": "+ 1 000 000 €",
          "score": 0.9
        }
      ]
    },
    {
      "key": "trading_experience",
      "type": "appropriateness_level",
      "title": "Depuis combien de temps investissez vous sur les marchés financiers ?",
      "choices": [
        {
          "key": "0",
          "title": "Jamais",
          "score": 0
        },
        {
          "key": "1",
          "title": "2 ans ou moins",
          "score": 0.42
        },
        {
          "key": "2",
          "title": "Plus de 2 ans",
          "score": 0.56
        }
      ]
    },
    {
      "key": "investing",
      "type": "appropriateness_level",
      "title": "Quel est le montant investi au cours des deux dernières années ?",
      "choices": [
        {
          "key": "0",
          "title": "0 €",
          "score": 0
        },
        {
          "key": "1",
          "title": "Moins de 10 000 €",
          "score": 0.18
        },
        {
          "key": "2",
          "title": "Plus de 10 000 €",
          "score": 0.27
        }
      ]
    },
    {
      "key": "fractional",
      "type": "appropriateness_level",
      "title": "Avez-vous déjà acheté des fractions d'actions ?",
      "choices": [
        {
          "key": "0",
          "title": "Oui",
          "score": 0.28
        },
        {
          "key": "1",
          "title": "Non",
          "score": 0
        }
      ]
    },
    {
      "key": "professional_experience",
      "type": "appropriateness_level",
      "title": "Avez-vous une expérience ou des qualifications en matière de trading ?",
      "choices": [
        {
          "key": "0",
          "title": "Aucune",
          "score": 0
        },
        {
          "key": "1",
          "title": "J'ai des connaissances acquises par l'éducation ou la formation",
          "score": 0.13
        },
        {
          "key": "2",
          "title": "Une qualification professionnelle pertinente",
          "score": 0.26
        },
        {
          "key": "3",
          "title": "J'ai exercé une fonction impliquant directement la négociation sur les marchés financiers pendant au moins 1 an",
          "score": 0.52
        }
      ]
    },
    {
      "key": "question_1",
      "type": "appropriateness_level",
      "title": "Si vous investissez 100€  dans une action dont le cours est de 75€ , combien d'actions possédez-vous ?",
      "choices": [
        {
          "key": "0",
          "title": "1 action",
          "score": 0
        },
        {
          "key": "1",
          "title": "1 action et 1/3 action",
          "score": 0.9
        },
        {
          "key": "2",
          "title": "100 actions",
          "score": 0
        }
      ]
    },
    {
      "key": "governance_1",
      "title": "Quelle est la perte maximum acceptable pour votre portefeuille ?",
      "choices": [
        {
          "key": "no_loss",
          "title": "Ne peut supporter aucune perte",
          "score": 0
        },
        {
          "key": "less_20_loss",
          "title": "Peut supporter des pertes jusqu'à 20%",
          "score": 0
        },
        {
          "key": "more_20_loss",
          "title": "Peut supporter des pertes supérieures à 20%",
          "score": 0
        }
      ]
    },
    {
      "key": "terms",
      "title": "Terms & policies",
      "subtitle": "Je déclare avoir lu, compris et accepté ce qui suit :",
      "is_document_list": true,
      "choices": [
        {
          "key": "terms_of_use",
          "title": "Terms and Conditions",
          "url": "https://www.goliaths.io/en/terms-of-use",
          "score": 0
        },
        {
          "key": "risk_disclosure",
          "title": "Risk Disclosures",
          "url": "https://assets-global.website-files.com/63e54278e3a069eea4b03f71/6525a8417e77601a483918c2_Risk%20Disclosure.pdf",
          "score": 0
        },
        {
          "key": "execution_policy",
          "title": "Order Execution Policy",
          "url": "https://assets-global.website-files.com/63e54278e3a069eea4b03f71/6525ab721580f88dda738ce5_Order%20Execution%20Policy%20May%202023%20(1).pdf",
          "score": 0
        },
        {
          "key": "client_cat",
          "title": "Client Categorization Policy",
          "url": "https://assets-global.website-files.com/63e54278e3a069eea4b03f71/6525a965f07a73c3d35c8dce_Client%20Info%20(1).pdf",
          "score": 0
        },
        {
          "key": "compensation",
          "title": "Investor Compensation Fund",
          "url": "https://assets-global.website-files.com/63e54278e3a069eea4b03f71/6525a9c0fd4ab36fadf9e1d9_Investor%20Compensation%20Fund%20May%202023.pdf",
          "score": 0
        },
        {
          "key": "conflict_policy",
          "title": "Conflict of Interest Policy",
          "url": "https://assets-global.website-files.com/63e54278e3a069eea4b03f71/6525aa8daf9773d82cad1add_Conflicts%20of%20Interest%20Policy%20May%202023%20(1).pdf",
          "score": 0
        },
        {
          "key": "privacy_policy",
          "title": "Privacy Policy",
          "url": "https://www.goliaths.io/en/privacy-privacy",
          "score": 0
        },
        {
          "key": "alpaca_agreement",
          "title": "Alpaca Customer Agreement",
          "url": "https://files.alpaca.markets/disclosures/library/AcctAppMarginAndCustAgmt.pdf",
          "score": 0
        },
        {
          "key": "alpaca_terms",
          "title": "Alpaca Terms",
          "url": "https://www.goliaths.io/politiques-alpaca",
          "score": 0
        }
      ]
    }
  ],
  "country": "FR",
  "appropriateness_minimum_score": 2.3,
  "risk": {
    "low": 2,
    "medium": 5,
    "high": 100
  }
}