
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_VERSION;

export const fetchGift = async (user, page, limit, signup, playlist, referral, invoice, startDate, endDate, search) => {

  try {
    const filters = [];

    if (playlist) filters.push('PLAYLIST');
    if (signup) filters.push('INVEST');
    if (referral) filters.push('REFERRAL');
    if (invoice) filters.push('INVOICE');

    const filterString = filters.map(filter => `&filters%5B%5D=${filter}`).join('');
    const searchStr = search ? `&search=${search}` : '';
    const url = `${API_BASE_URL}/gifts/list?startDate=${startDate}&endDate=${endDate}${filterString}&page=${page}&limit=${limit}${searchStr}`;

    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${user}`
      }
    });

    return response.data;

  } catch (err) {

    console.log(err)
  }

}


export const assignGift = async (user, userUuid, tickerUuid, amount, type, isMoneyGift) => {

  try {
    await axios.post(API_BASE_URL + '/gifts/assign', {
      userUuid,
      tickerUuid,
      amount,
      type,
      isMoneyGift
    }, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${user}`
      }
    })
  } catch (e) {
    throw e
  }

}
