import PsLayout from "pages/structured/layout/PsLayout"
import "../builder.css"
import { useEffect, useState, useContext } from "react"
import AuthContext from "contexte/AuthContext"
import PageLoader from "components/pageLoader/PageLoader";
import { useNavigate } from "react-router-dom"
import { fetchSpDraft, saveSpDraft } from "services/spDraftServices"


const Dates = () => {

  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [product, setProduct] = useState({});

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(product);
    try {
      const response = await saveSpDraft(user, window.location.pathname.split('/')[2], product);
      navigate(`/structured-products/${window.location.pathname.split('/')[2]}/recap`)

    } catch (err) {
      console.log(err);
      setError(err)
    }
  }

  const fetchActualData = async () => {
    try {
      const response = await fetchSpDraft(user, window.location.pathname.split('/')[2]);
      setProduct({
        ...response,
        status: response.status || 'opening'
      })
      setLoading(false)
    } catch (e) {
      console.log(e);
      setLoading(false)
      setError(e)
    }
  }

  useEffect(() => {
    fetchActualData();
  }, [])

  const handleInputChange = (e) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.value
    })
  }

  const getDate = (value) => value?.split('T')?.[0]

  return (
    <PsLayout>
      {
        loading ? (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        ) : (
          error ? (
            <div>Erreur</div>
          ) : (
            <form onSubmit={handleSubmit} className="psform">
              <div className="psform-input-box">
                <span className="psform-input-label">Date de la collecte :</span>
                <input className="psform-input-text" type="date" name="collecting_date" defaultValue={getDate(product.collecting_date)} onChange={handleInputChange} />
              </div >
              <div className="psform-input-box">
                <span className="psform-input-label">Date de fin de collecte :</span>
                <input className="psform-input-text" type="date" name="end_collection_date" defaultValue={getDate(product.end_collection_date)} onChange={handleInputChange} />
              </div>
              <div className="psform-input-box">
                <span className="psform-input-label">Date d'exécution du produit :</span>
                <input className="psform-input-text" type="date" name="execution_date" defaultValue={getDate(product.execution_date)} onChange={handleInputChange} />
              </div>
              <div className="psform-input-box">
                <span className="psform-input-label">Date du prochain paiement :</span>
                <input className="psform-input-text" type="date" name="next_payment_date" defaultValue={getDate(product.next_payment_date)} onChange={handleInputChange} />
              </div>
              <div className="psform-input-box">
                <span className="psform-input-label">Status :</span>
                <select name="status" id="status" className="psform-input-text" defaultValue={product.status} onChange={handleInputChange}>
                  <option value="opening">opening</option>
                  <option value="close">close</option>
                  <option value="collecting">collecting</option>
                  <option value="funding">funding</option>
                </select>
              </div>
              <div className="psform-input-box">
                <span className="psform-input-label">Documents :</span>
                <input
                  className="psform-input-text"
                  type="file"
                  accept="application/pdf"
                  onChange={handleFileChange}
                />
              </div>
              {
                imagePreview && (
                  <div className="psform-preview-box">
                    <img className="psform-preview-img" src={imagePreview} alt="Aperçu du produit" />
                  </div>
                )
              }
              <div className="psform-submit-box">
                <button type="submit" className="psform-submit">Sauvegarder et continuer</button>
              </div>
            </form >
          )
        )
      }
    </PsLayout >
  )
}

export default Dates