import { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import './structuredProducts.css'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import AuthContext from 'contexte/AuthContext';
import PageLoader from 'components/pageLoader/PageLoader';
import { launchError } from 'components/notifications';
import Notification from 'components/notifications/Notification';
import { formatDate } from 'utils/date';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import { fetchStructuredProducts } from 'services/structuredProductsServices';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import { getDecimalValue } from 'utils/numbers';

const StructuredProducts = () => {

  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10);

  const fetchProducts = async () => {
    try {
      setLoading(true)
      const data = await fetchStructuredProducts({
        user,
        page,
        limit
      })
      setProducts(data);
    } catch (error) {
      launchError(t('notifications.structured_products.fetch'))
      console.log(error)
    } finally {
      setLoading(false)
    }
  }


  useEffect(() => {
    fetchProducts()
  }, [page, limit])


  const transformDataToBody = (data) => {

    return data.map((item) =>
    ({
      data: [
        item.name,
        item.provider,
        item.status,
        item.status === 'collecting' ? `${getDecimalValue(item.invested)}/${item.total}` : item.total,
        `${item.yield} %`
      ],
      utils: {
        id: `${item._id}/${item.status}`
      }
    })
    )
  }

  const handleLimit = (newLimit) => {
    setLimit(newLimit);
    setPage(0);
  }

  return (
    <DashboardLayout page={t('pages_name.structured_products')}>
      <Notification />
      <div>
        {!loading ? (
          <div>
            <div className='externalDeposit_titleComponent'>
              <h2>Liste des produits structurés</h2>
            </div>
            <FilterComponent
              limit={true}
              fetchLimit={limit}
              setFetchLimit={handleLimit}
            />
            <Tableau
              thead={['Nom', 'Emetteur', 'Status', 'Montant', 'Rendement']}
              tbody={transformDataToBody(products)}
              page={page} setPage={setPage}
              pagination={true}
              hadLink
              linkPath={'/finances/structured-product/'}
              limit={limit}
            />
          </div>
        ) : (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        )}
      </div>
    </DashboardLayout>
  )
}

export default StructuredProducts