// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.etf_sectors_list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.etf_sector_item_box{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fill-available;
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  border: solid 1px #2B64F5;
  
}

.etf_sector_item_desc{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
  gap: 10px;
}

.etf_sector_item_img{
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.etf_sector_item_delete_button{
  font-size: 22px;
  font-weight: 700;
  color: #2B64F5;
}

.etf_sector_weigth_input {
  max-width: 30%;
  border: 1px solid #ccc;
  padding: 10px 15px;
  border-radius: 4px;
  width: 20%;
}

.etf_sectors_search_box {
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 50%;
  margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/actifs/subpages/EtfEdition/EtfEditionTabs/EtfSectorsTab/etfSectorsTab.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,6BAA6B;EAC7B,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,yBAAyB;;AAE3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,mBAAmB;EACnB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,UAAU;EACV,mBAAmB;AACrB","sourcesContent":[".etf_sectors_list {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n}\n\n.etf_sector_item_box{\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  width: -webkit-fill-available;\n  background-color: white;\n  padding: 15px;\n  border-radius: 5px;\n  border: solid 1px #2B64F5;\n  \n}\n\n.etf_sector_item_desc{\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  width: 30%;\n  gap: 10px;\n}\n\n.etf_sector_item_img{\n  width: 30px;\n  height: 30px;\n  border-radius: 50%;\n}\n\n.etf_sector_item_delete_button{\n  font-size: 22px;\n  font-weight: 700;\n  color: #2B64F5;\n}\n\n.etf_sector_weigth_input {\n  max-width: 30%;\n  border: 1px solid #ccc;\n  padding: 10px 15px;\n  border-radius: 4px;\n  width: 20%;\n}\n\n.etf_sectors_search_box {\n  display: flex;\n  flex-direction: row;\n  align-self: center;\n  width: 50%;\n  margin-bottom: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
