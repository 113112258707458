import { Area, XAxis, YAxis, ResponsiveContainer, Tooltip, AreaChart } from 'recharts';
import './NewUsersGraph.css'

const NewUsersGraph = ({ graphData }) => {

    return (
        <div className='graph_container'>
            <div className='graph_header'>
                <h3 className='graph_title'>Valeur de trade :</h3>
                <div>
                    Mensuel
                </div>
            </div>
            <ResponsiveContainer width="100%" height={350}>
                <AreaChart width={800} height={350} data={graphData}>
                    <XAxis dataKey="day" />
                    <YAxis dataKey="volume" />
                    <Tooltip />
                    <Area type="monotone" dataKey="volume" stroke="#2B64F5" fill="transparent" strokeWidth={3} />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    )
}

export default NewUsersGraph
