import axios from "axios";

export const fetchTradeAction = async (user, filter, date, page, limit) => {

    //, filter, date, page, limit
    //Sun%20Oct%2008%202023%2002%3A18%3A06%20GMT%2B0200%20(GMT%2B02%3A00)
    //?filter='+ filter + '&date=' + date + '&page=' + page + '&limit=' + limit

    try {
        const getActions = await axios.get(process.env.REACT_APP_BASE_URL + process.env.REACT_APP_VERSION + '/dashboard/trades/actions?filter='+ filter + '&date=' + date + '&page=' + page + '&limit=' + limit, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + user
            }
        })
        
        return getActions.data

    } catch (err) {

        console.log(err)
    }

}

