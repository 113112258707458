// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}

.kpi_box_container {
  background-color: white;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  padding: 15px 20px;
  gap: 5px;
  border-radius: 10px;
  border: solid 1px #2B64F5;
}

.kpi_box_title {
  font-weight: 700;
  margin: 0;
}

.kpi_box_container p {
  margin: 0;
  color: black
}

.kpi_box_container span {
  color: black
}

.kpi_box_datas{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.kpi_box_data {
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/components/DataComponents/KPI/KpiBox/KpiBox.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,gBAAgB;EAChB,kBAAkB;EAClB,QAAQ;EACR,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,SAAS;EACT;AACF;;AAEA;EACE;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["a:link {\n  text-decoration: none;\n}\n\na:visited {\n  text-decoration: none;\n}\n\na:hover {\n  text-decoration: underline;\n}\n\na:active {\n  text-decoration: underline;\n}\n\n.kpi_box_container {\n  background-color: white;\n  max-width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: end;\n  padding: 15px 20px;\n  gap: 5px;\n  border-radius: 10px;\n  border: solid 1px #2B64F5;\n}\n\n.kpi_box_title {\n  font-weight: 700;\n  margin: 0;\n}\n\n.kpi_box_container p {\n  margin: 0;\n  color: black\n}\n\n.kpi_box_container span {\n  color: black\n}\n\n.kpi_box_datas{\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 10px;\n}\n\n.kpi_box_data {\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
