import { useState } from 'react'
import './KPI.css'
import KpiData from './KpiData/KpiData'
import KpiStructuredProducts from './KpiStructuredProducts/KpiStructuredProducts'
import KpiMargin from './KpiMargin/KpiMargin'
import KpiAgo from './KpiAgo/KpiAgo'

const KPI = () => {

    //All other states
    const [isOpen, setIsOpen] = useState(localStorage.getItem("kpi_home_open") === "true")
    const [isSpOpen, setIsSpOpen] = useState(localStorage.getItem("kpi_sp_home_open") === "true")
    const [isMarginOpen, setIsMarginOpen] = useState(localStorage.getItem("kpi_margin_home_open") === "true")
    const [isAgoOpen, setIsAgoOpen] = useState(localStorage.getItem("kpi_ago_home_open") === "true")

    const handleKpiOpen = (kpiType, isChecked) => {
        switch (kpiType) {
            case 'kpi':
                setIsOpen(isChecked)
                break;
            case 'kpi_sp':
                setIsSpOpen(isChecked)
                break;
            case 'kpi_margin':
                setIsMarginOpen(isChecked)
                break;
            case 'kpi_ago':
                setIsAgoOpen(isChecked)
                break;
            default:
                break;
        }
        localStorage.setItem(`${kpiType}_home_open`, isChecked)
    }


    return (
        <>
            <div className='home_kpi_main'>
                <div className='home_kpi_toggle'>
                    <p>Voir les KPIs</p>
                    <label className="toggle">
                        <input className="toggle-checkbox"
                            type="checkbox"
                            checked={isOpen}
                            onChange={(e) => handleKpiOpen('kpi', e.target.checked)}
                        />
                        <div className="toggle-switch"></div>
                    </label>
                </div>

                <div className={`home_kpi_lists ${isOpen ? "home_kpi_lists_transition" : ""}`}>
                    {isOpen && (
                        <div className='home_kpi_item'>
                            <KpiData />
                        </div>
                    )}
                </div>
            </div>
            <div className='home_kpi_main'>
                <div className='home_kpi_toggle'>
                    <p>Voir les KPIs des produits structurés</p>
                    <label className="toggle">
                        <input className="toggle-checkbox"
                            type="checkbox"
                            checked={isSpOpen}
                            onChange={(e) => handleKpiOpen('kpi_sp', e.target.checked)}
                        />
                        <div className="toggle-switch"></div>
                    </label>
                </div>

                <div className={`home_kpi_lists ${isSpOpen ? "home_kpi_lists_transition" : ""}`}>
                    {isSpOpen && (
                        <div className='home_kpi_item'>
                            <KpiStructuredProducts />
                        </div>
                    )}
                </div>
            </div>
            <div className='home_kpi_main'>
                <div className='home_kpi_toggle'>
                    <p>Voir les KPIs Margin</p>
                    <label className="toggle">
                        <input className="toggle-checkbox"
                            type="checkbox"
                            checked={isMarginOpen}
                            onChange={(e) => handleKpiOpen('kpi_margin', e.target.checked)}
                        />
                        <div className="toggle-switch"></div>
                    </label>
                </div>

                <div className={`home_kpi_lists ${isMarginOpen ? "home_kpi_lists_transition" : ""}`}>
                    {isMarginOpen && (
                        <div className='home_kpi_item'>
                            <KpiMargin />
                        </div>
                    )}
                </div>
            </div>
            <div className='home_kpi_main'>
                <div className='home_kpi_toggle'>
                    <p>Voir les KPIs AGO</p>
                    <label className="toggle">
                        <input className="toggle-checkbox"
                            type="checkbox"
                            checked={isAgoOpen}
                            onChange={(e) => handleKpiOpen('kpi_ago', e.target.checked)}
                        />
                        <div className="toggle-switch"></div>
                    </label>
                </div>

                <div className={`home_kpi_lists ${isAgoOpen ? "home_kpi_lists_transition" : ""}`}>
                    {isAgoOpen && (
                        <div className='home_kpi_item'>
                            <KpiAgo />
                        </div>
                    )}
                </div>
            </div>
        </>

    )
}

export default KPI