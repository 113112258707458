import { useState, useEffect, useContext, useCallback } from 'react'
import AuthContext from 'contexte/AuthContext'
import { useTranslation } from 'react-i18next';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import './users.css'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout';
import { fetchUsers } from 'services/fetchUsers';
import PageLoader from 'components/pageLoader/PageLoader';
import { RELEASE_DATE } from 'utils/date';
import Badge from 'components/Badge/Badge';
import Colors from 'assets/colors'

import useFilters from 'hooks/useFilters';
import { getInitialFilter } from 'utils/filters';


const Users = () => {



    const { t } = useTranslation();
    const { user } = useContext(AuthContext)

    const initialFilters = {
        page: parseInt(getInitialFilter('page')) || 0,
        search: getInitialFilter('search') || '',
        alpacaFilter: getInitialFilter('alpacaFilter') || 'ALL',
        sumsubFilter: getInitialFilter('sumsubFilter') || 'ALL',
        fetchLimit: parseInt(getInitialFilter('fetchLimit')) || 10,
        filterType: getInitialFilter('filterType') || 'all',
        selectedDate: getInitialFilter('selectedDate') || {
            date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
            date2: { startDate: null, endDate: null },
        },
        sort: getInitialFilter('sort') || null,
        order: getInitialFilter('order') || 'ASC',
        
        refreshData: false
    }

    const { filters, handleFiltersChange, resetFilters } = useFilters(initialFilters);


    const setPage = (newPage) => handleFiltersChange('page', newPage);
    const setSearch = (newSearch) => handleFiltersChange('search', newSearch);
    const setAlpacaFilter = (newAlpacaFilter) => handleFiltersChange('alpacaFilter', newAlpacaFilter);
    const setSumsubFilter = (newSumsubFilter) => handleFiltersChange('sumsubFilter', newSumsubFilter);
    const setFetchLimit = (newLimit) => handleFiltersChange('fetchLimit', newLimit);
    const setFilterType = (newFilterType) => handleFiltersChange('filterType', newFilterType);
    const setSelectedDate = (newDate) => handleFiltersChange('selectedDate', newDate);
    const setOrder = (newOrder) => handleFiltersChange('order', newOrder);
    const setSort = (newSort) => handleFiltersChange('sort', newSort);


    const [loading, setLoading] = useState(true)
    const [users, setUsers] = useState([])
    const [userCount, setUserCount] = useState(0)

    const fetchData = async () => {
        const data = await fetchUsers(user, filters.page, filters.fetchLimit, filters.alpacaFilter, filters.sumsubFilter, filters.selectedDate.date1.startDate, filters.selectedDate.date1.endDate, filters.search, filters.filterType, filters.sort, filters.order)
        setUsers(data.result)
        setUserCount(data.count)
        setLoading(false)
    }

    useEffect(() => {
        if (filters.selectedDate.date1.startDate !== null && filters.selectedDate.date1.endDate !== null) {
            fetchData()
        }
    }, [filters.page, filters.fetchLimit, filters.alpacaFilter, filters.sumsubFilter, filters.selectedDate, filters.search, filters.filterType, filters.sort, filters.order])

    const transformData = (data) => {
        if (data.length > 0) {
            return data.map((item) => {
                return {
                    data: [
                        item.firstName,
                        item.lastName,
                        <Badge color={Colors.badge[item.sumsub_status]}
                            text={item.sumsub_status} />,
                        <Badge color={Colors.badge[item.alpaca_status]}
                            text={item.alpaca_status} />,
                        item.totalTrades
                    ],
                    utils: {
                        id: item.uuid
                    }
                }
            })
        } else {
            return []
        }
    }

    const onHeaderClick = (title) => {
        let newSort
        switch (title) {
            case 'Trades':
                newSort = 'totalTrades'
                break;
            default:
                newSort = null;
                break;
        }
        if (newSort && newSort === filters.sort) {
            setOrder((prevState) => {
                return (prevState === 'ASC') ? 'DESC' : 'ASC'
            });
        } else if (newSort) {
            setSort(newSort);
            setOrder('ASC')
        }
    }

    return (
        <DashboardLayout page={t("pages_name.users")}>
            <div>
                <div className='users_header_box'>
                    <h1>{t("pages.users.title")}</h1>
                    <div className='users_count_div'>
                        <span>Quantité : {userCount}</span>
                    </div>
                </div>
                {!loading ? (
                    <div>
                        <FilterComponent
                            limit={true} withSearch={true} date={true} alpaca={true} sumsub={true} usersFilter={true}
                            fetchLimit={filters.fetchLimit} setFetchLimit={setFetchLimit}
                            setAlpacaFilter={setAlpacaFilter}
                            setSumsubFilter={setSumsubFilter}
                            selectedDate={filters.selectedDate} setSelectedDate={setSelectedDate}
                            search={filters.search} setSearch={setSearch}
                            typeFilter={filters.filterType} setTypeFilter={setFilterType}
                        />
                        <Tableau
                            thead={["Prénom", "Nom", "Sumsub", "Alpaca", "Trades"]}
                            onHeaderClick={onHeaderClick}
                            tbody={transformData(users)}
                            limit={filters.fetchLimit}
                            pagination={true}
                            page={filters.page}
                            setPage={setPage}
                            hadLink={true}
                            linkPath='/user/'
                        // historyState={page}
                        />
                    </div>
                ) : (
                    <div className='pageLoaderBox'>
                        <PageLoader />
                    </div>
                )}
            </div>
        </DashboardLayout>
    )
}

export default Users