// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image_background {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: "30px";
  height: "30px"; 
  border-radius: "50px"
}`, "",{"version":3,"sources":["webpack://./src/components/DataComponents/BackgroundImage/backgroundImage.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kCAAkC;EAClC,4BAA4B;EAC5B,aAAa;EACb,cAAc;EACd;AACF","sourcesContent":[".image_background {\n  background-size: cover;\n  background-position: center center;\n  background-repeat: no-repeat;\n  width: \"30px\";\n  height: \"30px\"; \n  border-radius: \"50px\"\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
