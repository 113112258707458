import PsLayout from "pages/structured/layout/PsLayout"
import "../builder.css"
import { useEffect, useState, useContext } from "react"
import AuthContext from "contexte/AuthContext"
import PageLoader from "components/pageLoader/PageLoader";
import { useNavigate } from "react-router-dom"
import { fetchSpDraft, saveSpDraft, uploadSpDraftPicture } from "services/spDraftServices"

const PsInfos = (props) => {

  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [product, setProduct] = useState({});
  const [picture, setPicture] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setPicture(reader.result)
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(product);
    try {
      const updatedProduct = await saveSpDraft(user, product._id, product);
      if (picture) {
        await uploadSpDraftPicture({ user, id: updatedProduct._id, type: 'main', picture })
      }
      setProduct(updatedProduct)
      navigate(`/structured-products/${updatedProduct._id}/financement`)

    } catch (err) {
      console.log(err);
      setError(err)
    }
  }

  const fetchActualData = async () => {
    console.log('window.location.pathname', window.location.pathname)
    try {
      if (window.location.pathname.split('/')?.[2] !== 'new') {
        const response = await fetchSpDraft(user, window.location.pathname.split('/')[2]);
        setProduct(response)
      }
      setLoading(false)
    } catch (e) {
      console.log(e);
      setLoading(false)
      setError(e)
    }
  }

  useEffect(() => {
    fetchActualData();
  }, [])

  const handleInputChange = (e) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.value
    })
  }

  return (
    <PsLayout>
      {
        loading ? (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        ) : (
          error ? (
            <div>Erreur</div>
          ) : (
            <form onSubmit={handleSubmit} className="psform">
              <div className="psform-input-box">
                <span className="psform-input-label">ISIN :</span>
                <input className="psform-input-text" type="text" name="isin" defaultValue={product.isin} onChange={handleInputChange} />
              </div >
              <div className="psform-input-box">
                <span className="psform-input-label">Nom du produit :</span>
                <input className="psform-input-text" type="text" name="name" defaultValue={product.name} onChange={handleInputChange} />
              </div>
              <div className="psform-input-box">
                <span className="psform-input-label">Image du produit :</span>
                <input
                  className="psform-input-text"
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </div>
              {
                (imagePreview || product.picture) && (
                  <div className="psform-preview-box">
                    <img className="psform-preview-img" src={imagePreview || product.picture} alt="Aperçu du produit" />
                  </div>
                )
              }
              <div className="psform-submit-box">
                <button type="submit" className="psform-submit">Sauvegarder et continuer</button>
              </div>
            </form >
          )
        )
      }
    </PsLayout >
  )
}

export default PsInfos