import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";


export const fetchReportTradeList = async ({ user }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/trades/list`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchReportVolumePerStockList = async ({ user, startDate, endDate, page, limit }) => {
  try {
    const params = `?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`;
    const { data } = await axios.get(`${API_BASE_URL}/rapports/trades/volume/action/list${params}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchReportVolumePerStock = async (user, limit, page, startDate, endDate, timeframe) => {
  try {
    const params = `?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&sort=${timeframe}`
    const { data } = await axios.get(API_BASE_URL + `/rapports/trades/volume/action${params}`,
      buildHeaders(user));
    return data;
  } catch (error) {
    console.error(error);
  }
}

export const fetchReportVolumeInTrade = async (user, limit, page, startDate, endDate, timeframe) => {
  try {
    const params = `?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&sort=${timeframe}`
    const { data } = await axios.get(API_BASE_URL + `/rapports/trades/money/in-trade${params}`,
      buildHeaders(user));
    return data;
  } catch (error) {
    console.error(error);
  }
}

export const fetchAvailableMoney = async (user, limit, page) => {
  try {
    const params = `?page=${page}&limit=${limit}`
    const { data } = await axios.get(API_BASE_URL + `/rapports/trades/money/deposited${params}`,
      buildHeaders(user));
    return data;
  } catch (error) {
    console.error(error);
  }
}

export const fetchCardFees = async (user, startDate, endDate) => {
  try {
    const params = `?start=${startDate}&end=${endDate}`
    const { data } = await axios.get(API_BASE_URL + `/rapports/fees/cb${params}`,
      buildHeaders(user));
    return data;
  } catch (error) {
    console.error(error);
  }
}
