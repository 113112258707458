import React from 'react'
import './giftFilters.css'

const GiftFilters = ({
    signup, setSignup, 
    playlist, setPlaylist,
    referral, setReferral,
    invoice, setInvoice
}) => {

    return (
        <div className='giftFilterComponent'>
            <div>
                <input
                    type="checkbox"
                    name="signup"
                    id="signup"
                    checked={signup}
                    onChange={() => {
                        if (!signup || playlist || referral || invoice) setSignup(!signup);
                    }}
                />
                <label htmlFor="signup">Signup</label>
            </div>
            <div>
                <input
                    type="checkbox"
                    name="playlist"
                    id="playlist"
                    checked={playlist}
                    onChange={() => {
                        if (!playlist || signup || referral || invoice) setPlaylist(!playlist);
                    }}
                />
                <label htmlFor="playlist">Playlist</label>
            </div>
            <div>
                <input
                    type="checkbox"
                    name="referral"
                    id="referral"
                    checked={referral}
                    onChange={() => {
                        if (!referral || signup || playlist || invoice) setReferral(!referral);
                    }}
                />
                <label htmlFor="referral">Referral</label>
            </div>
            <div>
                <input
                    type="checkbox"
                    name="invoice"
                    id="invoice"
                    checked={invoice}
                    onChange={() => {
                        if (!invoice || signup || playlist || referral) setInvoice(!invoice);
                    }}
                />
                <label htmlFor="invoice">Invoice</label>
            </div>
        </div>
    )
}

export default GiftFilters