import './userFilter.css'

const UsersFilterSearch = ({
    useAlpaca = true, useSumsub = true, useSearchFilter = true, useLiquidNetWorth, setLiquidNetWorth,
    typeFilter, setTypeFilter, setAlpacaFilter, setSumsubFilter
}) => {
    return (
        <div className='usersFilterComponent'>
            {useAlpaca && <div>
                <label htmlFor="select_alpaca">Alpaca</label>
                <select id='select_alpaca'
                    className='custom-select'
                    onChange={(e) => setAlpacaFilter(e.target.value)}
                >
                    <option value='ALL' defaultValue>ALL</option>
                    <option value='NULL'>NULL</option>
                    <option value='INIT'>INIT</option>
                    <option value='SUBMITTED'>SUBMITTED</option>
                    <option value='ACTION_REQUIRED'>ACTION_REQUIRED</option>
                    <option value='EDITED'>EDITED</option>
                    <option value='APPROVAL_PENDING'>APPROVAL_PENDING</option>
                    <option value='APPROVED'>APPROVED</option>
                    <option value='REJECTED'>REJECTED</option>
                    <option value='ACTIVE'>ACTIVE</option>
                    <option value='DISABLED'>DISABLED</option>
                    <option value='ACCOUNT_CLOSED'>ACCOUNT_CLOSED</option>
                </select>
            </div>}
            {useSumsub && <div>
                <label htmlFor="select_sumsub">Sumsub</label>
                <select
                    id='select_sumsub'
                    className='custom-select'
                    onChange={(e) => setSumsubFilter(e.target.value)}>
                    <option value='ALL' defaultValue>ALL</option>
                    <option value='NULL'>NULL</option>
                    <option value='INIT'>INIT</option>
                    <option value='PENDING'>PENDING</option>
                    <option value='FAIL'>FAIL</option>
                    <option value='RETRY'>RETRY</option>
                    <option value='VERIFIED'>VERIFIED</option>
                </select>
            </div>}
            {useLiquidNetWorth && <div>
                <label htmlFor="select_liquid_net_worth">Revenus</label>
                <select
                    id='select_liquid_net_worth'
                    className='custom-select'
                    onChange={(e) => setLiquidNetWorth(e.target.value)}>
                    <option value='ALL' defaultValue>ALL</option>
                    <option value='0-20'>0-20</option>
                    <option value='20-50'>20-50</option>
                    <option value='50-100'>50-100</option>
                    <option value='100'>100+</option>
                </select>
            </div>}
            {useSearchFilter &&
                <>
                    <div>
                        <input
                            type="radio"
                            name="type"
                            id="firstname"
                            checked={typeFilter === "firstName"}
                            onChange={() => {
                                setTypeFilter("firstName")
                            }}
                        />
                        <label htmlFor="firstname">Prénom</label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            name="type"
                            id="name"
                            checked={typeFilter === "lastName"}
                            onChange={() => {
                                setTypeFilter("lastName")
                            }}
                        />
                        <label htmlFor="name">Nom</label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            name="type"
                            id="email"
                            checked={typeFilter === "email"}
                            onChange={() => {
                                setTypeFilter("email")
                            }}
                        />
                        <label htmlFor="email">Email</label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            name="type"
                            id="phone"
                            checked={typeFilter === "phone"}
                            onChange={() => {
                                setTypeFilter("phone")
                            }}
                        />
                        <label htmlFor="phone">Phone</label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            name="type"
                            id="all"
                            checked={typeFilter === "all"}
                            onChange={() => {
                                setTypeFilter("all")
                            }}
                        />
                        <label htmlFor="all">All</label>
                    </div>
                </>
            }

        </div>
    )
}

export default UsersFilterSearch