import { useState, useEffect, useContext } from 'react'
import AuthContext from 'contexte/AuthContext'
import { fetchLeverageUserPositions } from 'services/fetchUserById'
import PageLoader from 'components/pageLoader/PageLoader'
import Tableau from 'components/DataComponents/Tableau/Tableau'
import './agoUserTransactions.css'
import { useTranslation } from 'react-i18next';
import Notification from 'components/notifications/Notification'
import { launchError } from 'components/notifications'
import TickerImage from 'components/DataComponents/TickerImage/TickerImage'
import { fetchAgoTransactions } from 'services/agoServices'
import { formatDate, RELEASE_DATE } from 'utils/date'
import Badge from 'components/Badge/Badge'
import colors from 'assets/colors'


const transformeDataToTbody = (data, callFromModal = false) => {
  return data.length && data.map((item, index) => {
    if (!item) return { data: [] }


    return {
      data: [
        <TickerImage ticker={item.ticker} />,
        item.amount,
        item.price,
        <Badge color={colors.badge[item.side]} text={item?.side === 'BUY' ? 'ACHAT' : 'VENTE'} />,
        formatDate({date: item.filled_at, time: true})
      ],
      utils: { id: item.tp_order_id }
    }
  })
}


const AgoUserTransactions = ({ id }) => {

  const { user } = useContext(AuthContext)
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [portfolio, setPortfolio] = useState([])
  const [page, setPage] = useState(0)
  const [fetchLimit, setFetchLimit] = useState(10)

  const tableHeaders = ['Ticker', 'Quantité', 'Prix', 'Type', 'Date']


  const fetchData = async () => {
    try {
      const data = await fetchAgoTransactions(user, page, fetchLimit, RELEASE_DATE, new Date(), 'ALL', id)
      setPortfolio(data)
      setLoading(false)
    } catch {
      console.log('error')
    }
  }

  useEffect(() => {
    fetchData()
  }, [page])

  return (
    <div>
      {
        !loading ? (
          <div>
            <Notification />
            <Tableau
              tbody={transformeDataToTbody(portfolio)}
              thead={tableHeaders}
              limit={fetchLimit}
              pagination={true}
              setPage={setPage}
              hadLink
              linkPath={'/ago/transaction/'}
              page={page}
            />
          </div>
        ) : (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        )
      }
    </div>
  )
}

export default AgoUserTransactions