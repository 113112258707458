export const downloadFile = ({ data, type, filename }) => {
  let binaryString = atob(data);
  const arrayBuffer = stringToArrayBuffer(binaryString)
  let blob = new Blob([arrayBuffer], { type });
  let link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

};

function stringToArrayBuffer(binaryString) {
  const binaryLength = binaryString.length;
  var buf = new ArrayBuffer(binaryLength);
  var view = new Uint8Array(buf);
  for (let i = 0; i !== binaryLength; ++i) {
    view[i] = binaryString.charCodeAt(i) & 0xFF;
  }
  return buf;
}