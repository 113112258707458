import { useState, useEffect, useContext } from "react"
import { useTranslation } from "react-i18next"
import "./alpacatwo.css"
import AuthContext from "contexte/AuthContext"
import { fetchAlpacaTwo } from "services/fetchAlpaca"
import FilterComponent from "components/Filters/FilterComponent/FilterComponent"

const AlpacaTwo = () => {

    const { t } = useTranslation();
    const { user } = useContext(AuthContext)

    const [loading, setLoading] = useState(true)
    const [alpaca, setAlpaca] = useState("")
    const [selectedDate, setSelectedDate] = useState({
        date1: { startDate: new Date(), endDate: new Date() },
        date2: { startDate: null, endDate: null },
    })
    const [error, setError] = useState(null)

    const fetchData = async (date) => {

        try {

            const getAlpacaValue = await fetchAlpacaTwo(user, date)

            setAlpaca(getAlpacaValue)
            setLoading(false)

        } catch (e) {
            console.log(e)
        }
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    }


    useEffect(() => {

        if (formatDate(selectedDate.date1.startDate) !== formatDate(selectedDate.date1.endDate)) {
            setError("La date de début et de fin doivent être identiques")
        } else {
            setError(null)
        }

        if (selectedDate.date1.startDate !== null && selectedDate.date1.endDate !== null && error === null) {
            const newDate = formatDate(selectedDate.date1.startDate)
            fetchData(newDate)
        }
    }, [selectedDate])


    return (
        <div>

            <div className="flexbox">
                <h3>Rapport Net Payment Final</h3>
                <FilterComponent
                    date={true}
                    setSelectedDate={setSelectedDate}
                    selectedDate={selectedDate}
                />
            </div>

            {
                error === null ? (
                    !loading ? (

                        <div>
                            <iframe width="100%" height="800px" id="embededpdf" src={`data:application/pdf;base64,${alpaca}`}></iframe>
                        </div>

                    ) : (
                        <div>Loading...</div>
                    )
                ) : (
                    <div className="error_component">{error}</div>
                )
            }
        </div>
    )
}

export default AlpacaTwo