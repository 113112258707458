import React from 'react';
import './Badge.css';

const Badge = ({ text, color }) => {
  const badgeStyle = {
    backgroundColor: color || null,
    color: color?.length > 0 ? '#fff' : '#000' 
  };

  return (text?.length > 0 ?
    (<span className="badge" style={badgeStyle}>
      {text}
    </span>) :
    (<></>))
};

export default Badge;