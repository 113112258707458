import DashboardLayout from 'components/layout/dashboard/DashboardLayout';
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
// import './depositById.css';
import AuthContext from 'contexte/AuthContext';
import { fetchDepositWithdrawDetail } from 'services/fetchWithdrawDetail';
import Badge from 'components/Badge/Badge';
import colors from 'assets/colors';
import Notification from 'components/notifications/Notification';
import { launchError } from 'components/notifications';
import { formatDate } from 'utils/date';
import PageTitleWithUserPicture from 'components/PageTitleWithPicture/PageTitleWithPicture';

const WithdrawById = () => {

  const [loading, setLoading] = useState(true)
  const [dataset, setDataset] = useState([])

  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const params = useParams();

  const fetchData = async () => {

    try {

      const withDrawDetail = await fetchDepositWithdrawDetail({
        type: 'withdraw',
        user: user,
        id: params.id,
      })
      setDataset(withDrawDetail)
    } catch (e) {
      launchError(t('notifications.payouts.trades.withdraw.error'))
      console.log(e)
    } finally {
      setLoading(false);
    }

  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <DashboardLayout page={t("pages_name.withdraw_details")}>
      <Notification />
      {
        !loading ? (
          <div className='withdrawDeposit_box'>
            <PageTitleWithUserPicture
              title={'Détails du retrait'}
              firstName={dataset.goliathsDetails?.clientInfo?.firstName}
              lastName={dataset.goliathsDetails?.clientInfo?.lastName}
              picture={dataset.goliathsDetails?.clientInfo?.pic}
              type={'user'}
            />
            <div className='withdrawDepositById_items'>
              <div className='withdrawDepositById_item_box'>
                <span className='withdrawDepositById_item_title'>Dernière mise à jour</span>
                <span className='withdrawDepositById_item_content'>{formatDate({ date: dataset.goliathsDetails.updated_at, month: 'long', time: true })}</span>
              </div>
              <hr />
              <div className='withdrawDepositById_item_box'>
                <span className='withdrawDepositById_item_title'>Methode de paiement</span>
                <span className='withdrawDepositById_item_content'>{dataset.goliathsDetails.method}</span>
              </div>
              <hr />
              <div className='withdrawDepositById_item_box'>
                <span className='withdrawDepositById_item_title'>Evaluation de risque</span>
                <span className='withdrawDepositById_item_content'>
                  <span><Badge color={'grey'} text={`${dataset.trueLayerDetails.riskScore}`} /> - {dataset.trueLayerDetails.riskLevel}</span>
                </span>
              </div>
              <hr />
              <div className='withdrawDepositById_item_box'>
                <span className='withdrawDepositById_item_title'>Données overtimes (Dépôts)</span>
                <span className='withdrawDepositById_item_content'>Overtime 24H: {dataset.goliathsDetails.overtime24h} €</span>
                <span className='withdrawDepositById_item_content'>Total Overtime: {dataset.goliathsDetails.totalOvertime} €</span>
              </div>
            </div>
            <div>

              <div className='withdrawDepositById_details'>
                <div className='withdrawDepositById_details_wallet'>
                  <p className='withdrawDepositById_details_title'>Détails :</p>
                  <div className='withdrawDepositById_details_wallet_box'>
                    <div className='withdrawDepositById_details_box'>
                      <p className='withdrawDepositById_details_p withdrawDepositById_details_'><span className='userData_item_title'>ID du versement : </span><a
                        href={`https://dashboard.stripe.com/payments/$`} target="_blank">{dataset.trueLayerDetails.id}</a></p>
                      <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='userData_item_title'>IBAN récepteur :</span>{dataset.trueLayerDetails.paymentMethod} €</p>
                      <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='userData_item_title'>Nom du compte récepteur :</span>{dataset.trueLayerDetails.invoicerName} €</p>
                      <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='userData_item_title'></span></p>
                    </div>
                    <div className='withdrawDepositById_details_box'>
                      <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='userData_item_title'>Status :</span>
                        <Badge color={colors.badge.SUCCESS_DETAIL} text={dataset.goliathsDetails.status} /></p>
                      <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='userData_item_title'>Montant :</span>{dataset.goliathsDetails.amount}</p>
                      <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='userData_item_title'>Frais Truelayer :</span>{dataset.goliathsDetails.fees}</p>
                      <p className="withdrawDepositById_details_p withdrawDepositById_details_"><span className='userData_item_title'>Monnaie :</span>{dataset.trueLayerDetails.currency}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>

              </div>
              <div>

              </div>
            </div>
          </div>
        ) : (
          <>
            Loading..
          </>
        )
      }
    </DashboardLayout>
  )
}

export default WithdrawById