import React, { createRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

/* ScrollBar */
const ScrollBar = ({ className, onYReachEnd, onScrollUp, children }) => {

  const scrollBarRef = createRef();
  const [wheelPropagation, setWheelPropagation] = useState(true);

  const activeWheelPropagation = () => {
    if (wheelPropagation === false)
      setWheelPropagation(prev => !prev)
  }

  const desactiveWheelPropagation = () => {
    if (wheelPropagation === true)
      setWheelPropagation(prev => !prev)
  }

  return (
    <PerfectScrollbar
      className={className}
      ref={scrollBarRef}
      onYReachEnd={onYReachEnd}
      onScrollUp={onScrollUp}
      options={{ wheelPropagation }}
    >
      {children}
    </PerfectScrollbar>
  );
}

// ScrollBar.propTypes = {
//   children: PropTypes.node.isRequired,
//   className: PropTypes.string,
//   onYReachEnd: PropTypes.func,
//   onScrollUp: PropTypes.func,
// };

// ScrollBar.defaultProps = {
//   onYReachEnd: () => { },
//   onScrollUp: () => { },
// };

export default ScrollBar;
