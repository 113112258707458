import { useState } from 'react'
import DashboardLayout from '../../../../components/layout/dashboard/DashboardLayout'
import { useTranslation } from 'react-i18next';
import './transactions.css'
import CardTransactions from './CardTransactions/CardTransactions';
import WithdrawDeposit from './WithdrawDeposit/WithdrawDeposit';

const Transactions = () => {

    const { t } = useTranslation();

    const [tab, setTab] = useState(1)

    return (
        <DashboardLayout page={t("pages_name.transactions")}>
            <div>
                <div>
                    <div className='transactions_switcher'>
                        <div className={`transactions_tab ${tab === 1 ? "transactions_tab_select" : "transactions_tab_not_select"}`} onClick={() => { setTab(1) }}>Ajout / Retrait</div>
                        <div className={`transactions_tab ${tab === 2 ? "transactions_tab_select" : "transactions_tab_not_select"}`} onClick={() => { setTab(2) }}>Cartes bancaires</div>
                    </div>
                    {
                        tab === 1 ? (
                            <WithdrawDeposit />
                        ) : (
                            <CardTransactions />
                        )
                    }
                </div>

            </div>
        </DashboardLayout>
    )
}

export default Transactions