export const traductions = [
    {
        name: "Français 🇫🇷",
        code: "fr"
    },
    {
        name: "Anglais 🇺🇸",
        code: "en"
    },
    {
        name: "Grec 🇬🇷",
        code: "gr"
    },
    {
        name: "Turc 🇹🇷",
        code: "tr"
    },
    {
        name: "Italien 🇮🇹",
        code: "it"
    },
]