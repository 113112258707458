// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.volumePerStockBuy{
  color: green
}

.volumePerStockSell{
  color: red
}`, "",{"version":3,"sources":["webpack://./src/pages/reports/trades/allComponents/VolumePerStock/volumePerStock.css"],"names":[],"mappings":"AAAA;EACE;AACF;;AAEA;EACE;AACF","sourcesContent":[".volumePerStockBuy{\n  color: green\n}\n\n.volumePerStockSell{\n  color: red\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
