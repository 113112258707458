import Logic from "./components/Logic/Logic";
import Questions from "./components/Questions/Questions";
import { useState } from "react";
import "./forms.css";
import DashboardLayout from '../../components/layout/dashboard/DashboardLayout'
import { useTranslation } from 'react-i18next';

const Forms = () => {

    const [selectedTab, setSelectedTab] = useState(1)
    const { t } = useTranslation()

    //get le forms ici et le passer en props aux composants enfants

    return (
        <DashboardLayout page={t("pages_name.finances")}>
            <div className="forms-container-page">
                <div className="forms-container">
                    <div className='form_transactions_switcher transition_forms'>
                        <div className={`transactions_tab ${selectedTab === 1 ? "transactions_tab_select" : "transactions_tab_not_select transition_forms_tabs"}`} onClick={() => { setSelectedTab(1) }}>Questions</div>
                        <div className={`transactions_tab ${selectedTab === 2 ? "transactions_tab_select" : "transactions_tab_not_select transition_forms_tabs"}`} onClick={() => { setSelectedTab(2) }}>Logic</div>
                    </div>
                    <div className="forms-tab-content">
                        {
                            selectedTab === 1 && (
                                <Questions />
                            ) || selectedTab === 2 && (
                                <Logic />
                            )
                        }
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
}

export default Forms;