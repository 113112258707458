import { useState, useEffect, useContext } from "react"
import AuthContext from "contexte/AuthContext"
import { useTranslation } from "react-i18next"
import "./mostImportantClients.css"
import DashboardLayout from "components/layout/dashboard/DashboardLayout"
import FilterComponent from "components/Filters/FilterComponent/FilterComponent"
import PageLoader from "components/pageLoader/PageLoader"
import Tableau from "components/DataComponents/Tableau/Tableau"
import { fetchMostImportantClients } from "services/fetchUserReports"
import { RELEASE_DATE } from 'utils/date';

const MostImportantClients = () => {

  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
    date2: { startDate: null, endDate: null },
  });
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const fetchData = async () => {
    try {
      const data = await fetchMostImportantClients(user, limit, page);
      setClients(data);
      setLoading(false);

    } catch (e) {
      console.log(e);
      setError(true);
    }
  }

  useEffect(() => {
    fetchData()
  }, [page, limit]
  );

  const transformDataToTbody = (data) => {

    return data.map(item => {

      return {
        data: [
          `${item.firstName} ${item.lastName}`,
          item.wallet,
          item.volume,
        ],
        utils: {
          id: item.user_uuid
        }
      };
    });
  }

  const handleNextPage = () => {
    setPage(page + 1);
  }

  const handlePrevPage = () => {
    setPage(page - 1);
  }


  return (
    <DashboardLayout page={t("pages_name.report_users")}>
      <div>
        {
          !loading ? (
            <div>
              <div className="flexbox">
                <h3>Clients les plus important</h3>
                <FilterComponent
                  page={true}
                  limit={true}
                  fetchLimit={limit} setFetchLimit={setLimit}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
              </div>
              <div>
                <Tableau
                  thead={['Clients', 'Wallet', 'Volume']}
                  tbody={transformDataToTbody(clients)}
                  handleNextPage={handleNextPage}
                  handlePrevPage={handlePrevPage}
                  hadLink={true}
                  linkPath={'/user/'}
                  limit={limit}
                  page={page}
                  setPage={setPage}
                  pagination={true}
                />
              </div>
            </div>
          ) : (
            <div className='pageLoaderBox'>
              <PageLoader />
            </div>
          )
        }
      </div>
    </DashboardLayout>
  )
}

export default MostImportantClients