// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_box_bank_transfer_transfert {
  border-bottom: solid 1px #ccc;
  margin: 30px 0;
}

.bank-transfer-input-fields-modal {
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0px 10px;
}

.bank-transfer-button-submit {
  padding: 7px 20px;
  border-radius: 4px;
  background-color: #007bff;
  border: solid 1px #007bff;
  color: white;
}

.form-bank-transfer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: stretch;
}

.modal_header_bank_transfer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: solid 1px #ccc;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.modal_title_bank_transfer {
  margin: 0;
}

.bank-transfer-close_x_icone{
  cursor: pointer;
}

.bank-transfer-mb-10{
  margin-bottom: 10px;
}

.bank_transfer_close_x_icone{
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/BankTransfer/bankTransfer.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;EACzB,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;EACX,6BAA6B;EAC7B,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".header_box_bank_transfer_transfert {\n  border-bottom: solid 1px #ccc;\n  margin: 30px 0;\n}\n\n.bank-transfer-input-fields-modal {\n  width: 300px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  padding: 0px 10px;\n}\n\n.bank-transfer-button-submit {\n  padding: 7px 20px;\n  border-radius: 4px;\n  background-color: #007bff;\n  border: solid 1px #007bff;\n  color: white;\n}\n\n.form-bank-transfer {\n  display: flex;\n  flex-direction: row;\n  gap: 10px;\n  align-items: stretch;\n}\n\n.modal_header_bank_transfer{\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n  border-bottom: solid 1px #ccc;\n  margin-bottom: 20px;\n  padding-bottom: 20px;\n}\n\n.modal_title_bank_transfer {\n  margin: 0;\n}\n\n.bank-transfer-close_x_icone{\n  cursor: pointer;\n}\n\n.bank-transfer-mb-10{\n  margin-bottom: 10px;\n}\n\n.bank_transfer_close_x_icone{\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
