import { useState, useEffect, useContext } from 'react'
import AuthContext from 'contexte/AuthContext'
import { useTranslation } from 'react-i18next';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import './usersToCall.css'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout';
import PageLoader from 'components/pageLoader/PageLoader';
import { RELEASE_DATE, formatDate } from 'utils/date';
import { fetchUsersToCall, updateUserCallLog } from 'services/userServices';
import TableUser from 'components/TableUser/TableUser';
import Notification from 'components/notifications/Notification';
import { launchError } from 'components/notifications';
import Colors from 'assets/colors';
import Badge from 'components/Badge/Badge';
const path = 'pages.users_to_call.'

const UsersToCall = () => {

  const { t } = useTranslation();
  const { user } = useContext(AuthContext)

  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState([])
  const [userCount, setUserCount] = useState(0)
  const [fetchLimit, setFetchLimit] = useState(10)
  const [page, setPage] = useState(0)
  const [alpacaFilter, setAlpacaFilter] = useState('ALL')
  const [selectedDate, setSelectedDate] = useState({
    date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
    date2: { startDate: null, endDate: null },
  })
  const [search, setSearch] = useState("")
  const [filterType, setFilterType] = useState('all')
  const [ageRange, setAgeRange] = useState([18, 100]);
  const [liquidNetWorth, setLiquidNetWorth] = useState('ALL');
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState('');
  const [note, setNote] = useState('');
  const [called, setCalled] = useState('');
  const [noAnswer, setNoAnswer] = useState('');



  const fetchData = async () => {
    const { count, result } = await fetchUsersToCall({
      user,
      page,
      limit: fetchLimit,
      startDate: selectedDate.date1.startDate,
      endDate: selectedDate.date1.endDate,
      search,
      filterType,
      liquidNetWorth,
      ageRange,
      alpaca: alpacaFilter,
      called,
      noAnswer
    })
    setUsers(result)
    setUserCount(count)
    setLoading(false)
  }

  useEffect(() => {
    if (selectedDate.date1.startDate !== null && selectedDate.date1.endDate !== null) {
      fetchData()
    }
  }, [page, fetchLimit, alpacaFilter, selectedDate, search, filterType, ageRange, liquidNetWorth, called, noAnswer])

  const handleCheckBox = (user_uuid, type, event) => {
    event.stopPropagation();
    try {
      console.log('event', type, event.target.checked)
      updateUserCallLog(user, user_uuid, { [type]: event.target.checked })
      setUsers(prev => {
        const newUsers = [...prev];
        const index = newUsers.findIndex((newUser) => newUser.user_uuid === user_uuid);
        newUsers[index][type] = event.target.checked
        return newUsers
      })
    } catch (error) {
      launchError(t('notifications.users.users_to_call.error'))
    }
  }

  const editNote = () => {
    try {
      setIsModalOpen(false)
      updateUserCallLog(user, selectedUser, { note })
      setUsers(prev => {
        const newUsers = [...prev];
        const index = newUsers.findIndex(newUser => newUser.user_uuid === selectedUser);
        newUsers[index]['note'] = note;
        return newUsers
      })

    } catch (error) {
      launchError(t('notifications.users.users_to_call.error'))
    }
  }

  const transformData = (data) => {
    if (data.length > 0) {
      return data.map((item, index) => {
        return {
          data: [
            <TableUser
              firstName={item.firstName}
              lastName={item.lastName}
              picture={item.picture}
              key={item.picture}
            />,
            <Badge color={Colors.badge[item.alpaca_status]}
              text={item.alpaca_status} />,
            item.liquid_net_worth,
            item.phone,
            formatDate({ date: new Date(item.createdAt) }),
            <input className="checkbox"
              type="checkbox"
              checked={item.called || false}
              onChange={() => { }}
              onClick={(e) => handleCheckBox(item.user_uuid, 'called', e)}
            />,
            <input className="checkbox"
              type="checkbox"
              checked={item.no_answer || false}
              onChange={() => { }}
              onClick={(e) => handleCheckBox(item.user_uuid, 'no_answer', e)}
            />,
            formatDate({ date: new Date(item.registration_date) }),
            <img src="/img/menu/logoutIcon.svg" alt="logoutIcon" onClick={(e) => openNote(e, index)}/>
          ],
          utils: {
            id: item.uuid
          }
        }
      })
    } else {
      return []
    }
  }

  const handleNextPage = () => {
    setPage((prevState) => prevState + 1)
  }

  const handleFetchLimit = (limit) => {
    setFetchLimit(limit);
    setPage(0);
  }

  const openNote = (event, index) => {
    event.stopPropagation();
    const user = users[index];
    console.log(index, user);
    setSelectedUser(user.user_uuid);
    setNote(user.note)
    setIsModalOpen(true);
  }

  const dropdowns = [
    {
      label: 'Appelé',
      value: called,
      setter: setCalled,
      options: [
        { display: t(`${path}dropdown_called.called`), value: '' },
        { display: t(`${path}dropdown_called.true`), value: true },
        { display: t(`${path}dropdown_called.false`), value: false }
      ]
    },
    {
      label: 'Sans réponse',
      value: noAnswer,
      setter: setNoAnswer,
      options: [
        { display: t(`${path}dropdown_no_answer.no_answer`), value: '' },
        { display: t(`${path}dropdown_no_answer.true`), value: true },
        { display: t(`${path}dropdown_no_answer.false`), value: false }
      ]
    }
  ]

  return (
    <DashboardLayout page={t("pages_name.users_to_call")}>
      <Notification />
      <div>
        <div className='users_to_call_header_box'>
          <h1>{t("pages.users_to_call.title")}</h1>
          <div className='users_to_call_count_div'>
            <span>Quantité : {userCount}</span>
          </div>
        </div>
        {!loading ? (
          <div>
            <FilterComponent
              limit={true} withSearch={true} date={true} usersFilter={true}
              useAlpaca={true} useSumsub={false} useLiquidNetWorth={true}
              fetchLimit={fetchLimit} setFetchLimit={handleFetchLimit}
              selectedDate={selectedDate} setSelectedDate={setSelectedDate}
              search={search} setSearch={setSearch}
              setAlpacaFilter={setAlpacaFilter}
              typeFilter={filterType} setTypeFilter={setFilterType}
              useRangeSliderFilter
              sliderMin={18}
              sliderMax={120}
              sliderValues={ageRange}
              setSliderValues={setAgeRange}
              handleRange={setAgeRange}
              setLiquidNetWorth={setLiquidNetWorth}
              useDropdown
              dropdowns={dropdowns}
            />
            <Tableau
              thead={["Utilisateur", "Alpaca", "Revenus", "Téléphone", "Date de demande d'appel", "Contacté", "Sans réponse", "Date d'inscription", "Note"]}
              tbody={transformData(users)}
              limit={fetchLimit}
              pagination={true}
              page={page}
              setPage={setPage}
              handleNextPage={handleNextPage}
              hadLink={true}
              linkPath='/user/'
            />
            <ModalNote
              isModalOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              note={note || ''}
              saveNote={editNote}
              setNote={setNote}
            />
          </div>
        ) : (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        )}
      </div>
    </DashboardLayout>
  )
}

export default UsersToCall


const ModalNote = ({ isModalOpen, onClose, note, setNote, saveNote }) => {


  const modalClass = isModalOpen ? 'users_to_call_modal_overlay open' : 'users_to_call_modal_overlay';

  const { t } = useTranslation()

  return (
    <div className={modalClass}>
      <div className="users_to_call_modal_container">
        <div className="users_to_call_modal_close_button_div">
          <button onClick={onClose}>
            X
          </button>
        </div>
        <h2 className='users_to_call_modal_title'>{t("pages.users_to_call.modal.title")}</h2>
        <div className="users_to_call_modal_details">
          {/* <ScrollBar className='users_to_call_modal_scrollbar'> */}
          <div className="psform-input-box">
            <textarea
              className="psform-input-text-full"
              rows="30"
              value={note}
              onChange={(e) => { setNote(e.target.value) }}
            />
          </div>
          {/* </ScrollBar> */}
        </div>
        <div className='users_to_call_modal_button_div'>
          <button className='close' onClick={onClose}>{t("pages.users_to_call.modal.close")}</button>
          <button className='save' onClick={saveNote}>{t("pages.users_to_call.modal.save")}</button>
        </div>
      </div>
    </div>
  )
}