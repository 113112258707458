import { useState, useContext, useEffect } from 'react'
import DashboardLayout from '../../../../components/layout/dashboard/DashboardLayout'
import { useTranslation } from 'react-i18next';
import AuthContext from 'contexte/AuthContext';
import './orders.css'
import { fetchOrders } from 'services/fetchOrders';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import PageLoader from 'components/pageLoader/PageLoader';
import Badge from 'components/Badge/Badge';
import colors from 'assets/colors';
import { RELEASE_DATE, formatDate } from 'utils/date';

const Orders = () => {

    const { t } = useTranslation();
    const { user } = useContext(AuthContext);

    const [fetchLimit, setFetchLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [orders, setOrders] = useState([]);
    const [pendingCount, setPendingCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState({
        date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
        date2: { startDate: null, endDate: null },
    });
    const [isPurchase, setIsPurchase] = useState(true);
    const [isSale, setIsSale] = useState(true);
    const [isOnHold, setIsOnHold] = useState(false);
    const [isCanceled, setIsCanceled] = useState(false);

    const getType = () => {
        if (isPurchase && isSale) return 'ALL';
        if (isPurchase) return 'BUY'
        return 'SELL'
    }

    const getStatusType = () => {
        if (isOnHold) return 'PENDING'
        if (isCanceled) return 'CANCELED'
        return undefined;
    }

    const fetchData = async () => {
        try {
            const fetchOrdersData = await fetchOrders(user, page, fetchLimit, selectedDate.date1.startDate, selectedDate.date1.endDate, getType(), getStatusType())
            setOrders(fetchOrdersData.tradesList)
            setPendingCount(fetchOrdersData.pendingCount)
            setLoading(false)

        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        if (selectedDate.date1.startDate !== null && selectedDate.date1.endDate !== null) {
            fetchData()
        }
    }, [fetchLimit, page, selectedDate, isSale, isPurchase, isOnHold, isCanceled])

    const formatAlpacaStatus = (alpacaStatus) => {
        switch (alpacaStatus) {
            case 'new':
            case 'INIT':
                return 'En attente'
            case 'filled':
                return 'Effectué'
            case 'canceled':
                return 'Annulé'
            default:
                return 'TO DO'
        }
    }

    const transformDataToTbody = (data) => {
        return data.map((item) => {
            return {
                data: [
                    <div>
                        <p>{item.firstName}</p>
                        <p>{item.lastName}</p>
                    </div>,
                    <div>
                        {item.txType === "UNIQUE" ? (
                            <p>{item.ticker}</p>
                        ) : (
                            <div>
                                <p>{item.txType}</p>
                                <p>{item.ticker}</p>
                            </div>
                        )}
                    </div>,
                    formatAlpacaStatus(item.alpaca_status),
                    item.method,
                    item.amount,
                    item.volume,
                    <Badge
                        text={item.type === 'BUY' ? 'ACHAT' : 'VENTE'}
                        color={colors.badge[item.type]}
                    />,
                    formatDate({date: item.date, time: true})
                ],
                utils: {
                    id: `${item.txType?.toLowerCase()}/${item.uuid}`
                }
            }
        })
    }

    const resetFilter = () => {
        setSelectedDate({
            date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
            date2: { startDate: null, endDate: null },
        })
        setFetchLimit(10)
    }

    const handleFetchLimit = (newLimit) => {
        setFetchLimit(newLimit);
        setPage(0)
    }


    return (
        <DashboardLayout page={t("pages_name.orders")}>
            <div>
                {!loading ? (
                    <div>
                        <div className='externalDeposit_titleComponent'>
                            <h2>Liste des transactions</h2>
                            <div className='orders_filter_components'>
                                <FilterComponent
                                    limit={true}
                                    date={true}
                                    fetchLimit={fetchLimit}
                                    setFetchLimit={handleFetchLimit}
                                    resetFilter={resetFilter}
                                    selectedDate={selectedDate}
                                    setSelectedDate={setSelectedDate}
                                    ordersFilter
                                    isPurchase={isPurchase}
                                    isSale={isSale}
                                    isOnHold={isOnHold}
                                    isCanceled={isCanceled}
                                    setIsPurchase={setIsPurchase}
                                    setIsSale={setIsSale}
                                    setIsOnHold={setIsOnHold}
                                    setIsCanceled={setIsCanceled}

                                />
                            </div>

                        </div>
                        <p>Nombre d'ordres en attente : {pendingCount}</p>
                        <div className="table-container">
                            <div className="table-column">
                                <Tableau
                                    thead={["Utilisateur", "Ticker", "État", "Methode", "Quantité", "Volume", "Type", "Date"]}
                                    tbody={transformDataToTbody(orders)}
                                    hadLink={true}
                                    linkPath={"/finances/trades/"}
                                    limit={fetchLimit}
                                    pagination
                                    page={page}
                                    setPage={setPage}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='pageLoaderBox'>
                        <PageLoader />
                    </div>
                )}
            </div>
        </DashboardLayout>
    )
}

export default Orders