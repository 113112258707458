import axios from "axios";
import { AGO_API_BASE_URL, buildHeaders } from "utils/services";


export const fetchAgoUsers = async (user, page, limit, sumsubFilter, startDate, endDate, search, filterType) => {
  const params = `?page=${page}&limit=${limit}&sumsubFilter=${sumsubFilter}&startDate=${startDate}&endDate=${endDate}`
  const searchParam = `${search !== '' ? `&value=${search}` : ''}${filterType !== 'all' ? `&filter=${filterType}` : ''}`

  try {
    const { data } = await axios.get(`${AGO_API_BASE_URL}/agbk/clients${params}${searchParam}`,
      buildHeaders(user));
    return data?.data;
  } catch (error) {
    console.error(error);
  }
}

export const fetchAgoUserById = async (user, id) => {

  try {
    const { data } = await axios.get(`${AGO_API_BASE_URL}/agbk/clients/${id}`,
      buildHeaders(user));
    return data?.data;
  } catch (error) {
    console.error(error);
  }
}

export const fetchAgoDeposits = async (user, page, limit, status, startDate, endDate) => {

  try {
    const params = `?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}&status=${status}`
    const { data } = await axios.get(`${AGO_API_BASE_URL}/agbk/deposits/external/list${params}`,
      buildHeaders(user))
    return data?.data?.external_deposits

  } catch (err) {
    console.log(err)
    return {
      error: err
    }
  }

}

export const linkToAgoClient = async (user, deposit_id, user_uuid) => {

  try {
    const { data } = await axios.post(`${AGO_API_BASE_URL}/agbk/deposits/external/link`, {
      deposit_id,
      user_uuid
    }, buildHeaders(user))

    return data?.data

  } catch (err) {
    console.log(err)
    throw err
  }
}

export const fetchAgoDepositUsers = async (user, query) => {

  try {
    const params = `?search=${query}`
    const { data } = await axios.get(`${AGO_API_BASE_URL}/agbk/clients/search${params}`, buildHeaders(user))
    return data?.data
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const fetchAgoTransactions = async (user, page, limit, startDate, endDate, type, user_id) => {

  try {
    const params = `?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&type=${type}${user_id ? `&user_uuid=${user_id}` : ''}`
    const { data } = await axios.get(`${process.env.REACT_APP_AGBK_BASE_URL}/agbk/transactions${params}`,
      buildHeaders(user)
    );
    return data?.data;
  } catch (error) {
    throw error
  }
}

export const fetchAgoTransactionDetails = async (user, ago_uuid) => {

  try {
    const { data } = await axios.get(`${process.env.REACT_APP_AGBK_BASE_URL}/agbk/transactions/${ago_uuid}`,
      buildHeaders(user)
    );
    return data?.data;
  } catch (error) {
    throw error
  }
}


export const fetchAgoPendingPayouts = async ({ user, page, limit }) => {

  try {
    const params = `?page=${page}&limit=${limit}&compact=0`
    const { data } = await axios.get(`${AGO_API_BASE_URL}/agbk/payouts/pending/list${params}`,
      buildHeaders(user))
    return data?.data
  } catch (err) {
    console.log(err)
    throw err
  }
}

export const approveAgoPayout = async ({ user, ago_uuid, iban, bank_name, amount, ago_withdraw_id }) => {

  try {
    const { data } = await axios.post(`${AGO_API_BASE_URL}/agbk/payouts/approve`,
      { ago_uuid, iban, bank_name, amount, ago_withdraw_id },
      buildHeaders(user))
    return data?.data
  } catch (err) {
    console.log(err)
    throw err
  }
}