import { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import './problems.css'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import Tableau from 'components/DataComponents/Tableau/Tableau';
import AuthContext from 'contexte/AuthContext';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import PageLoader from 'components/pageLoader/PageLoader';
import { fetchProblems, updateProblems } from 'services/fetchProblems';
import { launchError } from 'components/notifications';
import Notification from 'components/notifications/Notification';
import { formatDate, RELEASE_DATE } from 'utils/date';

const Problems = () => {

  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [reason, setReason] = useState('');
  const [provider, setProvider] = useState('');
  const [problems, setProblems] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedDate, setSelectedDate] = useState({
    date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
    date2: { startDate: null, endDate: null },
  });

  const fetchData = async () => {
    try {
      const fetchProblemsData = await fetchProblems({
        user,
        page,
        limit: fetchLimit,
        startDate: selectedDate.date1.startDate,
        endDate: selectedDate.date1.endDate,
        alpaca_user_id: search,
        reason,
        provider
      })
      setProblems(fetchProblemsData)
    } catch (error) {
      launchError(t('notifications.payouts.problems.fetch.error'))
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const update = async () => {
    try {
      setLoading(true)
      await updateProblems(user);
      setPage(0);
      await fetchData();
    } catch (error) {
      launchError(t('notifications.payouts.problems.update.error'));
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (selectedDate.date1.startDate !== null && selectedDate.date1.endDate !== null) {
      fetchData()
    }
  }, [fetchLimit, page, selectedDate, search, reason, provider])

  const transformDataToTbody = (data) => {
    return data.map((item) => {
      return {
        data: [
          item.user_uuid,
          item.alpaca_id,
          item.ticker,
          <div>
            {item.issues.map((issue, index) => {
              return (
                <span style={{ display: 'block' }} key={index}>{issue}</span>
              )
            })}
          </div>,
          <div>
            {
              item.resolvedIssues.length !== 0 ? (
                item.resolvedIssues.map((resolvedIssue, index) => {
                  return (
                    <span style={{ display: 'block' }} key={index}>{resolvedIssue}</span>
                  )
                })
              ) : (
                <span style={{ display: 'block' }}>-</span>
              )
            }
          </div>,
          item.status,
          <div>
            <span style={{ display: 'block' }}>{item.data.qty}</span>
            <span style={{ display: 'block' }}>{item.data.alpaca_qty || item.data.exante_qty}</span>
          </div>,
          formatDate({ date: item.date, month: 'long', time: true })
        ]
      }
    })
  }

  const handleLimit = (newLimit) => {
    setFetchLimit(newLimit);
    setPage(0)
  }


  const resetFilter = () => {
    setSelectedDate({
      date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
      date2: { startDate: null, endDate: null },
    })
    handleLimit(10)
  }

  const handleReason = (newReason) => {
    setReason(newReason);
    setPage(0);
  }

  const reason_path = 'pages.problems.reasons_dropdown.';
  const provider_path = 'pages.problems.stock_provider_dropdown.';

  const dropdowns = [{
    value: reason,
    setter: setReason,
    options:[
      { display: t(`${reason_path}reason`), value: '' },
      { display: t(`${reason_path}quantity`), value: 'ALPACA_QTY' },
      { display: t(`${reason_path}alpaca_missing`), value: 'ALPACA_MISSING' },
      { display: t(`${reason_path}db_missing`), value: 'DB_MISSING' },
      { display: t(`${reason_path}exante_quantity`), value: 'EXANTE_QTY' },
      { display: t(`${reason_path}exante_missing`), value: 'EXANTE_MISSING' },
    ]
  },
  {
    value: provider,
    setter: setProvider,
    options:[
      { display: t(`${provider_path}provider`), value: '' },
      { display: t(`${provider_path}alpaca`), value: 'alpaca' },
      { display: t(`${provider_path}exante`), value: 'exante' },
    ]
  }]

  return (
    <DashboardLayout page={t("pages_name.problems")}>
      <Notification />
      <div>
        {!loading ? (
          <div>
            <div className='externalDeposit_titleComponent'>
              <h2>Liste des Problèmes</h2>
            </div>
            <div className='problems_filter_box'>
              <FilterComponent
                resetFilter={resetFilter}
                limit={true}
                date={true}
                fetchLimit={fetchLimit}
                setFetchLimit={handleLimit}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                withSearch
                setSearch={setSearch}
                search={search}
                searchPlaceHolder={'Alpaca ID or User ID'}
                useDropdown
                dropdowns={dropdowns}
              />
              <button className="button_update" onClick={update}>Mettre à jour</button>
            </div>
            <div className="table-container">
              <div className="table-column">
                <Tableau
                  thead={["UserID", "Alpaca ID", "Ticker", "Problème", "Résolus", "Status", "Données", "Date"]}
                  tbody={transformDataToTbody(problems)}
                  limit={fetchLimit}
                  pagination
                  page={page}
                  setPage={setPage}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        )}
      </div>
    </DashboardLayout>
  )
}

export default Problems