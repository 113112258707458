import { useContext, useEffect, useState } from 'react'
import { fetchTradeAction } from 'services/fetchTradeActions';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import AuthContext from 'contexte/AuthContext';
import { useTranslation } from 'react-i18next';
import './TickerMostTrade.css'
import PageLoader from 'components/pageLoader/PageLoader';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';

const TickerMostTrade = () => {

    const { user } = useContext(AuthContext)
    const { t } = useTranslation();
    const today = new Date()

    const [graphData, setGraphData] = useState([])
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(0)
    const [loading, setLoading] = useState(true)
    const [periode, setPeriode] = useState("month")

    const handleNextPage = () => {
        setPage(page + 1)
    }

    const loadTradeGraph = async () => {
        const tradeGraphRequest = await fetchTradeAction(user, periode, today)
        setGraphData(tradeGraphRequest)
        setLoading(false)
    }

    useEffect(() => {
        loadTradeGraph()
    }, [periode, page, limit])

    function transformAPIDataToTableRows(graphData) {
        const dataNew = graphData.map((item, index) => {
            return {
                data: [
                    <div className="stock-icon">
                        <img src={`http://cdn.goliaths.io/stocks/${item.ticker}.png`} alt={item.ticker} />
                        <span>{item.ticker}</span>
                    </div>,
                    item.total_count,
                    <div className='stock_detail_trade'>
                        <span className='stock-trades-info-number'>{item.buy}%</span>
                        <div className="stock-gauge">
                            <div className="stock-gauge-fill" style={{ width: `${item.buy}%` }} />
                            <div className="stock-gauge-fill-negative" style={{ width: `${item.sell}%` }} />
                        </div>
                        <span className='stock-trades-info-number'>{item.sell}%</span>
                    </div>
                ]
            }
        });
        console.log(dataNew);
        return dataNew;
    }

    return (
        <div className='most_tickers_component'>
            {
                !loading ? (
                    <div>
                        <div className='most_tickers_header'>
                            <h3>Tickers les plus recherchés</h3>
                            <FilterComponent 
                                limit={true}
                                fetchLimit={limit} setFetchLimit={setLimit}
                                periode={periode} setPeriode={setPeriode}
                            />
                        </div>
                        <Tableau
                            thead={["Ticker", "Nombre", "Achats/Ventes"]}
                            tbody={transformAPIDataToTableRows(graphData)}
                            handleNextPage={handleNextPage}
                            page={page} setPage={setPage}
                            pagination={true}
                        />
                    </div>
                ) : (
                    <div>
                        <PageLoader />
                    </div>
                )
            }
        </div>
    )
}

export default TickerMostTrade