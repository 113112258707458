import React, { useState, useEffect, useContext } from 'react'
import AuthContext from "contexte/AuthContext"
import './complianceReports.css'
import { useTranslation } from 'react-i18next';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import Notification from 'components/notifications/Notification';
import DownloaderLink from 'components/DownloaderLink/DownloaderLink';
import { RELEASE_DATE, getDateFilenameFormatted } from 'utils/date';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import { fetchMonthlyPreventionStatement, fetchDailyTradesCountReport, fetchCountUsersHavingTradedByMonthReport } from 'services/complianceReportsServices';

const getYears = () => {
  const start = Number(process.env.REACT_APP_GOLIATHS_RELEASE_YEAR);
  let years = []
  for (let i = start; i <= new Date().getFullYear(); i++) {
    years.push(i);
  }
  return years;
}

const ComplianceReports = () => {

  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [riskReportSelectedMonth, setRiskReportSelectedMonth] = useState(String(new Date().getMonth() + 1));
  const [countTraderReportSelectedMonth, setCountTraderReportSelectedMonth] = useState(String(new Date().getMonth() + 1));
  const [riskReportSelectedYear, setRiskReportSelectedYear] = useState(new Date().getFullYear());
  const [countTraderReportSelectedYear, setCountTraderReportSelectedYear] = useState(new Date().getFullYear());
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const [selectedDate, setSelectedDate] = useState({
    date1: { startDate: new Date(RELEASE_DATE), endDate: yesterday },
    date2: { startDate: null, endDate: null },
  });

  const handleSelectedTimeframe = (report, type, date) => {
    if (report === 'risk') {
      if (type === 'month') setRiskReportSelectedMonth(date)
      if (type === 'year') setRiskReportSelectedYear(date)
    } else if (report === 'traderCount') {
      if (type === 'month') setCountTraderReportSelectedMonth(date)
      if (type === 'year') setCountTraderReportSelectedYear(date)
    }
  }


  return (
    <DashboardLayout page={t("pages_name.report_compliance")}>
      <Notification />
      <div className='externalDeposit_titleComponent'>
        <h2>{t("pages.report_compliance.title")}</h2>
        <FilterComponent
          date={true}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          maxDate={yesterday}
        />
      </div>
      <div>
        <ul>
          <div><DownloaderLink
            filename={`report_prevention_monthly_${riskReportSelectedYear}_${('0' + riskReportSelectedMonth).slice(-2)}.xlsx`}
            fetchDataParams={{ user, month: riskReportSelectedMonth, year: riskReportSelectedYear }}
            fetchData={fetchMonthlyPreventionStatement}
            type={'excel'}
            display={t('pages.report_compliance.monthly_prevention_link')}
          />
            <span>
              <select
                className="custom-select"
                value={riskReportSelectedMonth}
                onChange={(e) => handleSelectedTimeframe('risk', 'month', e.target.value)}
              >
                {Array(12).fill().map((_, index) =>
                  (<option key={index + 1} value={index + 1}>{('0' + (index + 1)).slice(-2)}</option>)
                )}
              </select>
            </span>
            <span>
              <select
                className="custom-select"
                value={riskReportSelectedYear}
                onChange={(e) => handleSelectedTimeframe('risk', 'year', e.target.value)}
              >
                {getYears().map((year) =>
                  (<option key={year} value={year}>{year}</option>)
                )}
              </select>
            </span>
          </div>
          <DownloaderLink
            filename={`report_trades_count_${getDateFilenameFormatted()}.xlsx`}
            fetchData={fetchDailyTradesCountReport}
            fetchDataParams={{
              user,
              startDate: selectedDate?.date1?.startDate?.toISOString(),
              endDate: selectedDate?.date1?.endDate?.toISOString(),
            }}
            type={'excel'}
            display={t('pages.report_compliance.daily_trades_count_link')}
          />
          <a href='/reports/compliance/best-execution'>{t('pages.report_compliance.best_execution_link')}</a>
          <div>
            <DownloaderLink
              filename={`report_count_user_having_trade_by_day_${countTraderReportSelectedYear}_${('0' + countTraderReportSelectedMonth).slice(-2)}.xlsx`}
              fetchData={fetchCountUsersHavingTradedByMonthReport}
              fetchDataParams={{ user, month: countTraderReportSelectedMonth, year: countTraderReportSelectedYear }}
              type={'excel'}
              display={t('pages.report_compliance.trader_by_day_count_link')}
            />
            <span>
              <select
                className="custom-select"
                value={countTraderReportSelectedMonth}
                onChange={(e) => handleSelectedTimeframe('traderCount', 'month', e.target.value)}
              >
                {Array(12).fill().map((_, index) =>
                  (<option key={index + 1} value={index + 1}>{('0' + (index + 1)).slice(-2)}</option>)
                )}
              </select>
            </span>
            <span>
              <select
                className="custom-select"
                value={countTraderReportSelectedYear}
                onChange={(e) => handleSelectedTimeframe('traderCount', 'year', e.target.value)}
              >
                {getYears().map((year) =>
                  (<option key={year} value={year}>{year}</option>)
                )}
              </select>
            </span>
          </div>
          <a href='/reports/compliance/sumsub'>{t('pages.report_compliance.user_sumsub_link')}</a>
          <a href='/reports/compliance/closed-accounts'>{t('pages.report_compliance.closed_accounts_link')}</a>
          <a href='/reports/compliance/sumsub-manually-accepted'>{t('pages.report_compliance.sumsub_manually_accepted_link')}</a>
          <a href='/reports/compliance/sumsub-rejected'>{t('pages.report_compliance.sumsub_rejected_link')}</a>
          <a href='/reports/compliance/economic-profile'>{t('pages.report_compliance.economic_profile_link')}</a>
          <a href='/reports/compliance/governance'>{t('pages.report_compliance.governance_link')}</a>
          <a href='/reports/compliance/transactions-monitoring'>{t('pages.report_compliance.transactions_monitoring_link')}</a>
          <a href='/reports/compliance/users-cumulative-expenses'>{t('pages.report_compliance.cumulative_expenses_link')}</a>
        </ul>
      </div>
    </DashboardLayout>
  )
}

export default ComplianceReports
