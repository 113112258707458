import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchPosts = async (user, page, limit, startDate, endDate, type, search) => {
  let typevalue = `&type=${type}`
  let searchvalue = `&value=${search}`
  let url = `/posts/list?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}${type !== 'all' ? typevalue : ''}${search !== '' ? searchvalue : ''}`
  try {
    const { data } = await axios.get(`${API_BASE_URL}${url}`, buildHeaders(user))
    return data
  } catch (err) {
    throw err
  }

}

export const fetchPost = async (user, id) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/posts/${id}`, buildHeaders(user))
    return data
  } catch (err) {
    throw err
  }
}

export const archivePost = async (user, id) => {
  try {
    const { data } = await axios.delete(`${API_BASE_URL}/posts/${id}`, buildHeaders(user))
    return data
  } catch (err) {
    throw err
  }
}

export const unpinPost = async (user, id) => {
  try {
    const { data } = await axios.delete(`${API_BASE_URL}/posts/pin/${id}`, buildHeaders(user))
    return data
  } catch (err) {
    throw err
  }
}

export const pinPost = async (user, id) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/posts/pin/${id}`, {}, buildHeaders(user))
    return data
  } catch (err) {
    throw err
  }
}


export const deleteComment = async ({ user, post_uuid, comment_uuid }) => {
  try {
    const { data } = await axios.delete(`${API_BASE_URL}/posts/${post_uuid}/comment/${comment_uuid}`, buildHeaders(user));
    return data
  } catch (err) {
    throw err
  }
}

export const fetchLastUserPosts = async (user, user_uuid) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/posts/user?user_uuid=${user_uuid}`, buildHeaders(user))
    return data
  } catch (err) {
    throw err
  }
}
