import { useTranslation } from 'react-i18next';
import { useEffect, useState, useContext } from 'react'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import './transactionsReporting.css'
import AuthContext from 'contexte/AuthContext';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import { RELEASE_DATE } from 'utils/date';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import { fetchTransactionsReporting } from 'services/transactionsReporting';
import ScrollBar from 'react-perfect-scrollbar';

const TransactionsReporting = () => {

  const { t } = useTranslation();
  const { user } = useContext(AuthContext);


  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedDate, setSelectedDate] = useState({
    date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
    date2: { startDate: null, endDate: null },
  });
  const [trs, setTrs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [details, setDetails] = useState([]);
  const [detailsDate, setDetailsDate] = useState([]);


  const fetchData = async () => {
    try {
      const data = await fetchTransactionsReporting({ user, page, limit, startDate: selectedDate.date1.startDate, endDate: selectedDate.date1.endDate })
      setTrs(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (selectedDate.date1.startDate !== null && selectedDate.date1.endDate !== null) {
      fetchData()
    }
  }, [limit, page, selectedDate])

  function transformDataToTBody(data) {
    return data.map(item => {
      return {
        data: [
          item.createdAt.split('T')[0],
          item.sent,
          item.accepted,
          item.pending,
          item.rejected?.length,
        ]
      };
    });
  }

  const onClickFunction = (index) => {
    const selectedRow = trs[index];
    console.log('selectedRow', selectedRow)
    setDetails(selectedRow.rejected);
    setDetailsDate(selectedRow.createdAt.split('T')[0])
    setIsModalOpen(true);
  }


  return (
    <DashboardLayout page={t("pages_name.transactions_reporting")}>
      <div>
        {!loading ? (
          <div>
            <div className='trs_titleComponent'>
              <div className='trs_filter_component'>

                <FilterComponent
                  limit={true} date={true}
                  fetchLimit={limit} setFetchLimit={setLimit}
                  selectedDate={selectedDate} setSelectedDate={setSelectedDate}
                />
              </div>
            </div>
            <Tableau
              thead={["Date", "Sent", "Accepted", "Pending", "Rejected"]}
              tbody={transformDataToTBody(trs)}
              pagination={true}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              hadModal
              onClickFunction={onClickFunction}

            />
            <ModalTrsRejectedDetails
              isModalOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              details={details}
              date={detailsDate}
            />
          </div>
        ) : (
          <div>Loading..</div>
        )}
      </div>
    </DashboardLayout>
  )
}

export default TransactionsReporting


const ModalTrsRejectedDetails = ({ isModalOpen, onClose, details, date }) => {

  const transformModalDataToTBody = (data) => {
    return data.map(item => {
      return {
        data: [
          item.Instrument,
          item.Error
        ]
      };
    });
  }

  const modalClass = isModalOpen ? 'trs_modal_details_overlay open' : 'trs_modal_details_overlay';

  const { t } = useTranslation()

  return (
    <div className={modalClass}>
      <div className="trs_modal_details_container">
        <button className="close_button" onClick={onClose}>
          X
        </button>
        <h2 className='trs_modal_details_title'>{t("pages.transactions_reporting.modal.title", { date })}</h2>
        <div className="trs_details_details">
          <ScrollBar className='trs_modal_details_scrollbar'>
            <Tableau
              tbody={transformModalDataToTBody(details)}
              thead={['Instrument', 'Error']}
            />
          </ScrollBar>
        </div>
        <div className='trs_modal_details_button_div'>
          <button className='close' onClick={onClose}>{t("pages.transactions_reporting.modal.close")}</button>
        </div>
      </div>
    </div>
  )
}