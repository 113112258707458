import { useState, useEffect, useContext } from 'react'
import AuthContext from "../../contexte/AuthContext";
import { useTranslation } from 'react-i18next';
import './home.css'
import i18n from "translation/i18next";

// import all components
import DashboardLayout from "components/layout/dashboard/DashboardLayout";
import MainActions from 'components/DataComponents/MainActions/MainActions';
import KPI from 'components/DataComponents/KPI/KPI';
import NewUsersGraph from 'components/DataComponents/newUsersGraph/NewUsersGraph';

// import all services
import { fetchMainAction } from 'services/fetchMainAction';
import { fetchTradeGraph } from 'services/fetchTradeGraph';
import ExanteAlert from 'components/DataComponents/ExanteAlert/ExanteAlert';
import { fetchExanteAvailable } from 'services/fetchAccounts';

const Home = () => {

    const { user, email } = useContext(AuthContext)
    const { t } = useTranslation();
    const today = new Date()
    const isSuperAdmin = process.env.REACT_APP_SUPERADMIN_EMAILS.split(',')?.includes(email)
    const [loading, setLoading] = useState(true)

    // Declare all data States
    const [mainActionsData, setMainActionsData] = useState([])
    const [graphData, setGraphData] = useState([])
    const [exanteAvailable, setExanteAvailable] = useState({ tradesAvailable: 0, marginAvailable: 0 })

    // StockMostTrade states
    const [periode, setPeriode] = useState("month")


    const loadMainActions = async () => {
        try {
            const mainActionsRequest = await fetchMainAction(user)
            console.log('mainActionsRequest', mainActionsRequest)
            setMainActionsData(mainActionsRequest)
        } catch (error) {
            setMainActionsData([-1, -1, -1, -1, -1])

        }

    }

    const loadTradeGraph = async () => {
        try {
            const tradeGraphRequest = await fetchTradeGraph(user, periode, today)
            console.log('tradeGraphRequest', tradeGraphRequest)
            setGraphData(tradeGraphRequest)
        } catch (error) {
            setGraphData([])
        }
    }

    const loadExanteAvailable = async () => {
        try {
            const available = await fetchExanteAvailable(user);
            console.log('available', available)
            setExanteAvailable(available)
        } catch (error) {
            setExanteAvailable({ tradesAvailable: null, marginAvailable: null })
        }
    }


    const loadData = async () => {
        try {
            await Promise.all([loadMainActions(), loadTradeGraph(), loadExanteAvailable()]);
        } catch (error) {
            console.error('Error getting data');
            throw error
        } finally {
            setLoading(false)

        }

    }

    useEffect(() => {
        loadData()
    }, [])

    return (
        <DashboardLayout page={t('pages_name.accueil')}>
            <div className='home_layout'>
                {!loading ? (
                    <div className='home_layout_left'>
                        <MainActions mainActionsData={mainActionsData} />
                        <hr />
                        <ExanteAlert available={exanteAvailable} isAdmin={isSuperAdmin} />
                        <KPI />
                        <NewUsersGraph graphData={graphData} />
                    </div>
                ) : (
                    <div>loading...</div>
                )
                }
            </div>
        </DashboardLayout>


    )
}

export default Home