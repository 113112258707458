import PsLayout from "pages/structured/layout/PsLayout"
import "../builder.css"
import { useEffect, useState, useContext } from "react"
import AuthContext from "contexte/AuthContext"
import PageLoader from "components/pageLoader/PageLoader";
import { useNavigate } from "react-router-dom"
import { fetchSpDraft, saveSpDraft, uploadSpDraftPicture } from "services/spDraftServices"

const PsTexts = () => {

  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [product, setProduct] = useState({});
  const [newDescription, setNewDescription] = useState({ title: "", message: "" });
  const [newMoreInfos, setNewMoreInfos] = useState({ title: "", message: "" });
  const [thumbnail, setThumbnail] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setThumbnail(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(product);
    try {
      await saveSpDraft(user, window.location.pathname.split('/')[2], product);
      if (thumbnail) {
        await uploadSpDraftPicture({ user, id: product._id, type: 'thumbnails', picture: thumbnail })
      }
      navigate(`/structured-products/${window.location.pathname.split('/')[2]}/dates`)

    } catch (err) {
      console.log(err);
      setError(err)
    }
  }

  const fetchActualData = async () => {
    try {
      const response = await fetchSpDraft(user, window.location.pathname.split('/')[2]);
      setProduct(response)
      setLoading(false)
    } catch (e) {
      console.log(e);
      setLoading(false)
      setError(e)
    }
  }

  useEffect(() => {
    fetchActualData();
  }, [])

  const handleInputChange = (e) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.value
    })
  }

  const handleAddDescription = () => {
    if (newDescription.title && newDescription.message) {
      setProduct({
        ...product,
        descriptions: [...product.descriptions, newDescription]
      });
      setNewDescription({ title: "", message: "" });
    } else {
      alert("Veuillez remplir le titre et le message de la description.");
    }
  };

  const handleDescriptionChange = (index, field, value) => {
    const updatedDescriptions = [...product.descriptions];
    updatedDescriptions[index] = { ...updatedDescriptions[index], [field]: value };
    setProduct({ ...product, descriptions: updatedDescriptions });
  };

  const handleRemoveDescription = (index) => {
    const updatedDescriptions = product.descriptions.filter((_, i) => i !== index);
    setProduct({ ...product, descriptions: updatedDescriptions });
  };

  const handleAddMoreInfo = () => {
    if (newMoreInfos.title && newMoreInfos.message) {
      setProduct({
        ...product,
        more_info: [...product.more_info, newMoreInfos]
      });
      setNewMoreInfos({ title: "", message: "" });
    } else {
      alert("Veuillez remplir le titre et le message de la description.");
    }
  };

  const handleMoreInfoChange = (index, field, value) => {
    const updatedDescriptions = [...product.more_info];
    updatedDescriptions[index] = { ...updatedDescriptions[index], [field]: value };
    setProduct({ ...product, more_info: updatedDescriptions });
  };

  const handleRemoveMoreInfo = (index) => {
    const updatedDescriptions = product.more_info.filter((_, i) => i !== index);
    setProduct({ ...product, more_info: updatedDescriptions });
  };

  const handleChangeUser = (e) => {
    console.log(e.target.value)
    if (e.target.value === "Clark Hassan") {
      setProduct({
        ...product,
        user: {
          uuid: "0c07284f-32f3-4605-a6af-e666e76eebb4",
          name: "Clark Hassan",
          description: "CEO Goliaths"
        }
      })
    }
  }

  useEffect(() => {
    console.log(product)
  }, [product])


  return (
    <PsLayout>
      {
        loading ? (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        ) : (
          error ? (
            <div>Erreur</div>
          ) : (
            <form onSubmit={handleSubmit} className="psform">
              <div className="description-box-wrapper">
                <span>Description</span>
                {product.descriptions && product.descriptions.map((description, index) => (
                  <div key={index} className="psform-description-box">
                    <div className="psform-input-box">
                      <input
                        className="psform-input-text-full"
                        type="text"
                        value={description.title}
                        onChange={(e) => handleDescriptionChange(index, 'title', e.target.value)}
                      />
                    </div>
                    <div className="psform-input-box">
                      <textarea
                        className="psform-input-text-full"
                        rows="3"
                        value={description.message}
                        onChange={(e) => handleDescriptionChange(index, 'message', e.target.value)}
                      />
                    </div>
                    <div onClick={() => handleRemoveDescription(index)} className="pointer button-add-new">Supprimer</div>
                  </div>
                ))}

                <div className="psform-input-box">
                  <span className="psform-input-label-text-small">Nouvelle description :</span>
                  <input
                    className="psform-input-text-full"
                    type="text"
                    value={newDescription.title}
                    onChange={(e) => setNewDescription({ ...newDescription, title: e.target.value })}
                  />
                </div>
                <div className="psform-input-box">
                  <textarea
                    className="psform-input-text-full"
                    rows="3"
                    value={newDescription.message}
                    onChange={(e) => setNewDescription({ ...newDescription, message: e.target.value })}
                  />
                </div>
                <div onClick={handleAddDescription} className="pointer button-add-new">Ajouter une description</div>
              </div>

              <div className="psform-input-box">
                <span className="psform-input-label">Sous description :</span>
                <input className="psform-input-text-full" type="text" name="sub_description" defaultValue={product.sub_description} onChange={handleInputChange} />
              </div>

              <div className="description-box-wrapper">
                <span>Plus d'infos</span>
                {product.more_info && product.more_info.map((moreinfo, index) => (
                  <div key={index} className="psform-description-box">
                    <div className="psform-input-box">
                      <input
                        className="psform-input-text-full"
                        type="text"
                        value={moreinfo.title}
                        onChange={(e) => handleMoreInfoChange(index, 'title', e.target.value)}
                      />
                    </div>
                    <div className="psform-input-box">
                      <textarea
                        className="psform-input-text-full"
                        rows="3"
                        value={moreinfo.message}
                        onChange={(e) => handleMoreInfoChange(index, 'message', e.target.value)}
                      />
                    </div>
                    <div onClick={() => handleRemoveMoreInfo(index)} className="pointer button-add-new">Supprimer</div>
                  </div>
                ))}

                <div className="psform-input-box">
                  <span className="psform-input-label-text-small">Nouvelle info :</span>
                  <input
                    className="psform-input-text-full"
                    type="text"
                    value={newMoreInfos.title}
                    onChange={(e) => setNewMoreInfos({ ...newMoreInfos, title: e.target.value })}
                  />
                </div>
                <div className="psform-input-box">
                  <textarea
                    className="psform-input-text-full"
                    rows="3"
                    value={newMoreInfos.message}
                    onChange={(e) => setNewMoreInfos({ ...newMoreInfos, message: e.target.value })}
                  />
                </div>
                <div onClick={handleAddMoreInfo} className="pointer button-add-new">Ajouter une info</div>
              </div>

              <div className="psform-input-box-flex">
                <div className="psform-input-box">
                  <span className="psform-input-label">Id de la vidéo :</span>
                  <input className="psform-input-text-full" type="text" name="video_url" defaultValue={product.video_url} onChange={handleInputChange} />
                </div>
                <div className="psform-input-box">
                  <div className="psform-input-box">
                    <span className="psform-input-label">Image de couverture :</span>
                    <input
                      className="psform-input-text"
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </div>
                  {
                    (imagePreview || product.video_thumbnail) && (
                      <div className="psform-preview-box">
                        <img className="psform-preview-img" src={imagePreview || product.video_thumbnail} alt="Aperçu du produit" />
                      </div>
                    )
                  }
                </div>
              </div>

              <div className="psform-input-box">
                <span className="psform-input-label">Utilisateur affiché :</span>
                <select name="user" id="user" className="psform-input-text" defaultValue={product.user?.name} onChange={handleChangeUser}>
                  <option>Choose user</option>
                  <option value="Clark Hassan">Clark Hassan</option>
                </select>
              </div>

              <div className="psform-submit-box">
                <button type="submit" className="psform-submit">Sauvegarder et continuer</button>
              </div>
            </form >
          )
        )
      }
    </PsLayout >
  )
}

export default PsTexts