import { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import './analytics.css'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import AuthContext from 'contexte/AuthContext';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import PageLoader from 'components/pageLoader/PageLoader';
import { launchError } from 'components/notifications';
import Notification from 'components/notifications/Notification';
import { fetchConnectionsByHours, fetchConnectionsByTimeframe } from 'services/analyticsServices';
import BarGraph from 'components/BarGraph/BarGraph';
import { formatDate, RELEASE_DATE } from 'utils/date';
import { startOfDay } from 'date-fns'

const Analytics = () => {

  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [connections, setConnections] = useState([]);
  const [counts, setCounts] = useState([]);
  const [selectedDate, setSelectedDate] = useState({
    date1: { startDate: startOfDay(new Date()), endDate: new Date() },
    date2: { startDate: null, endDate: null },
  });
  const [timeframe, setTimeframe] = useState('day')

  // const fetchConnections = async () => {
  //   try {
  //     setLoading(true)
  //     const connectionsByHour = await fetchConnectionsByHours({
  //       user,
  //       startDate: selectedDate.date1.startDate,
  //       endDate: selectedDate.date1.endDate,
  //     })
  //     console.log('connectionsByHour', connectionsByHour)
  //     setConnections(connectionsByHour)
  //   } catch (error) {
  //     launchError(t('notifications.analytics.connections.fetch'))
  //     console.log(error)
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  const fetchConnections = async () => {
    try {
      setLoading(true)
      const data = await fetchConnectionsByTimeframe({
        user,
        startDate: selectedDate.date1.startDate,
        endDate: selectedDate.date1.endDate,
        timeframe
      })
      setConnections(data)
    } catch (error) {
      launchError(t('notifications.analytics.connections.count'));
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (selectedDate.date1.startDate !== null && selectedDate.date1.endDate !== null) {
      fetchConnections()
      // fetchCount()
    }
  }, [selectedDate, timeframe])

  const resetFilter = () => {
    setSelectedDate({
      date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
      date2: { startDate: null, endDate: null },
    })
  }

  const transformDataToGraph = (data) => {
    return data.map(item => ({ ...item, xAxis: item._id }))
  }

  return (
    <DashboardLayout page={t("pages_name.analytics")}>
      <Notification />
      <div>
        {!loading ? (
          <div>
            <div className='externalDeposit_titleComponent'>
              <h2>Analytics</h2>
            </div>
            <div className='problems_filter_box'>
              <FilterComponent
                resetFilter={resetFilter}
                date
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                timeframeFilterConfig={['hour', 'week', 'day', 'month']}
                timeframeFilter={timeframe}
                setTimeframeFilter={setTimeframe}
              />
            </div>
            <div className='details_per_day_box'>
              {
                counts?.map(({ _id, single_connections, total_connections }) => (
                  <p key={_id}>Connexions totales pour la journée du {_id} : {total_connections}, dont {single_connections} uniques</p>
                ))
                }
            </div>
            <div className='graph_box'>
              <BarGraph
                title={'Connexion unique par timeframe'}
                data={transformDataToGraph(connections)}
                dataKey={'unique_connections'}
                xAxis={'xAxis'}
              />
            </div>

          </div>
        ) : (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        )}
      </div>
    </DashboardLayout>
  )
}

export default Analytics