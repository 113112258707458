import { useState } from 'react'
import './SearchCountry.css'

const SearchCountry = ({ setSelectedCountry, selectedCountry, countries, handleSearch, label }) => {

  const [search, setSearch] = useState('');

  const onChange = (newValue) => {
    setSearch(newValue)
    handleSearch(newValue)
  }

  const clearSelection = () => {
    setSelectedCountry({});
    setSearch('');
  };

  const select = (ticker) => {
    setSelectedCountry(ticker);
    setSearch('')
  }

  return (
    <div className='search_country_container'>
      {(!selectedCountry?.id) ? (
        <input
          type="text"
          placeholder={label || 'Choisissez un pays'}
          className='input_selectedCountry'
          onChange={(e) => onChange(e.target.value)}
        />

      ) : (
        <div className={"search_country_selected search_country_relative"}>
          <span onClick={() => select(selectedCountry)}>
            <div className='search_country_item_select'>
              <img className='search_country_picture' src={`https://cdn.goliaths.io/flags/${selectedCountry.code?.toLowerCase()}.png`} alt=''/>
              {selectedCountry.ticker} | {selectedCountry.country}
            </div>
          </span>
          <div onClick={clearSelection} className='delete_item_select'>x</div>
        </div>
      )}
      {(!selectedCountry?.id) && countries.length > 0 && search.length > 0 && (
        <ul className='search_country_list_box'>
          {countries.map((country, index) => (
            <li key={index} onClick={() => select(country)} className="search_country_not_selected">
              <div className='search_country_item_select'>
                <img className='search_country_picture' src={`https://cdn.goliaths.io/flags/${country.code?.toLowerCase()}.png`} alt=''/>
                {country.code} | {country.country}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchCountry