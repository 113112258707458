import React from 'react'
import { useTranslation } from 'react-i18next';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'

const AccountsReports = () => {

  const { t } = useTranslation();

  return (
    <DashboardLayout page={t("pages_name.report_accounts")}>
      <div>
        <ul>
          <a href='/reports/accounts/users-positions'>{t('pages.report_accounts.users_positions_link')}</a>
          <a href='/reports/accounts/funds-movements'>{t('pages.report_accounts.funds_movements_link')}</a>
          <a href='/reports/accounts/users-wallet'>{t('pages.report_accounts.users_wallet_link')}</a>
        </ul>
      </div>
    </DashboardLayout>
  )
}

export default AccountsReports
