import { useState, useEffect, useContext } from 'react'
import './alpacaTransferts.css'
import AuthContext from 'contexte/AuthContext'
import { useTranslation } from 'react-i18next';
import { fetchAlpacaPayments } from 'services/fetchAlpaca'
import Tableau from 'components/DataComponents/Tableau/Tableau';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import Notification from 'components/notifications/Notification';
import { launchError } from 'components/notifications';
import { formatDate, RELEASE_DATE } from 'utils/date';


const AlpacaTransferts = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext)

  const [loading, setLoading] = useState(false)
  const [payments, setPayments] = useState([])
  const [selectedDate, setSelectedDate] = useState({
    date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
    date2: { startDate: null, endDate: null },
  });
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10)

  const fetchData = async () => {
    try {
      const data = await fetchAlpacaPayments({
        user,
        page,
        limit,
        startDate: selectedDate?.date1.startDate,
        endDate: selectedDate?.date1.endDate
      })
      setPayments(data.payments)
    } catch (error) {
      launchError(t('notifications.actifs.alpaca.payments.error'))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (selectedDate.date1.startDate !== null && selectedDate.date1.endDate !== null) {
      fetchData()
    }
  }, [selectedDate.date1.startDate, selectedDate.date1.endDate, page, limit])

  const resetFilter = () => {
    setSelectedDate({
      date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() }
    })
  }

  const transformData = (data) => {
    return data.map((item) => {
      console.log('item', item)

      return {
        data: [
          formatDate({ date: item.payment_date, month: 'long' }),
          `${item.amount} ${item.currency}`,
          item.status
        ]
      }
    })
  }


  return (
    <div>
      {!loading ? (
        <div>
          <Notification />
          <div className='alpacaThree_titleComponent'>
            <h3>Virements effectués</h3>
            <FilterComponent
              resetFilter={resetFilter}
              limit
              fetchLimit={limit}
              setFetchLimit={setLimit}
              date={true}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          </div>
          <Tableau
            tbody={transformData(payments)}
            thead={['Date', 'Montant', 'Statut']}
            pagination
            page={page}
            limit={limit}
            setPage={setPage}
          />
        </div>
      ) : (
        <div>
          loading...
        </div>
      )}
    </div>
  )
}

export default AlpacaTransferts