import { useState, useEffect, useContext } from 'react'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import { useTranslation } from 'react-i18next';
import './features.css'
import AuthContext from 'contexte/AuthContext';
import { RELEASE_DATE, formatDate } from 'utils/date';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import PageLoader from 'components/pageLoader/PageLoader';
import Notification from 'components/notifications/Notification';
import { launchError } from 'components/notifications'
import TableUser from 'components/TableUser/TableUser';
import { fetchFeatures } from 'services/featuresServices';

const Features = () => {

  const { t } = useTranslation()
  const { user } = useContext(AuthContext)

  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(true)
  const [fetchLimit, setFetchLimit] = useState(10)
  const [features, setFeatures] = useState([])
  const [selectedDate, setSelectedDate] = useState({
    date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
    date2: { startDate: null, endDate: null },
  });

  const fetchData = async () => {
    try {

      const data = await fetchFeatures(user, page, fetchLimit, selectedDate.date1.startDate, selectedDate.date1.endDate)
      setFeatures(data)
    } catch (e) {
      launchError(t('notifications.features.error'))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (selectedDate.date1.startDate !== null && selectedDate.date1.endDate !== null) {
      fetchData()
    }
  }, [fetchLimit, page, selectedDate])


  const transformDataToTbody = (data) => {
    if (data.length > 0) {
      return data?.map((item) => {
        return {
          data: [
            <TableUser
              firstName={item.firstName}
              lastName={item.lastName}
              picture={item.uuid}
              key={item.uuid}
            />,
            t(`pages.features.types.${item.type}`),
            item.totalEur,
            formatDate({ date: item.created_at, time: true })
          ]
        }
      })
    } else {
      return []
    }
  }


  return (
    <DashboardLayout page={t("pages_name.features")}>
      <div>
        {!loading ? (
          <div>
            <Notification />
            <div className='features_titleComponent'>
              <h2>{t("pages.features.title")}</h2>
              <FilterComponent
                limit={true}
                date={true}
                fetchLimit={fetchLimit}
                setFetchLimit={setFetchLimit}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            </div>


            <div className="table-container">
              <div className="table-column">
                <Tableau
                  thead={[t("pages.features.client"), t("pages.features.type"), t("pages.features.amount"), t("pages.features.date")]}
                  tbody={transformDataToTbody(features)}
                  page={page}
                  setPage={setPage}
                  pagination={true}
                  hadLink={true}
                  limit={fetchLimit}
                  linkPath={'/user/'}
                />
              </div>
            </div>


          </div>
        ) : (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        )}
      </div>
    </DashboardLayout>
  )
}


export default Features

