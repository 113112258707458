import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchTransactionsReporting = async ({ user, startDate, endDate, page, limit }) => {
  try {
    const params = `?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`;
    const { data } = await axios.get(`${API_BASE_URL}/rapports/trs${params}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}