import { useState, useContext, useEffect } from 'react'
import DashboardLayout from '../../../components/layout/dashboard/DashboardLayout'
import { useTranslation } from 'react-i18next';
import AuthContext from 'contexte/AuthContext';
import './agoTransactions.css'
import Tableau from 'components/DataComponents/Tableau/Tableau';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import PageLoader from 'components/pageLoader/PageLoader';
import Badge from 'components/Badge/Badge';
import colors from 'assets/colors';
import { RELEASE_DATE, formatDate } from 'utils/date';
import { launchError } from 'components/notifications';
import { fetchAgoTransactions } from 'services/agoServices';
import TickerImage from 'components/DataComponents/TickerImage/TickerImage';

const AgoTransactions = () => {

    const { t } = useTranslation();
    const { user } = useContext(AuthContext);

    const [fetchLimit, setFetchLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState({
        date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
        date2: { startDate: null, endDate: null },
    });
    const [isPurchase, setIsPurchase] = useState(true);
    const [isSale, setIsSale] = useState(true);
    const [isOnHold, setIsOnHold] = useState(false);
    const [isCanceled, setIsCanceled] = useState(false);

    const getType = () => {
        if (isPurchase && isSale) return 'ALL';
        if (isPurchase) return 'BUY'
        return 'SELL'
    }

    const fetchData = async () => {
        try {
            const data = await fetchAgoTransactions(user, page, fetchLimit, selectedDate.date1.startDate, selectedDate.date1.endDate, getType())
            console.log('data', data)
            setTransactions(data)
            setLoading(false)

        } catch (error) {
            launchError(t('notifications.trades.error'))
        }

    }

    useEffect(() => {
        if (selectedDate.date1.startDate !== null && selectedDate.date1.endDate !== null) {
            fetchData()
        }
    }, [fetchLimit, page, selectedDate, isSale, isPurchase, isOnHold, isCanceled])

    const transformDataToTbody = (data) => {
        return data.map((item) => {
            return {
                data: [
                    <div>
                        <p>{item.firstName}</p>
                        <p>{item.lastName}</p>
                    </div>,
                    <TickerImage ticker={item.ticker}/>,
                    item.amount,
                    `${item.volume} €`,
                    <Badge
                        text={item.side === 'BUY' ? 'ACHAT' : 'VENTE'}
                        color={colors.badge[item.side]}
                    />,
                    formatDate({date: item.filled_at, time: true})
                ],
                utils: {
                    id: item.tp_order_id
                }
            }
        })
    }

    const resetFilter = () => {
        setSelectedDate({
            date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
            date2: { startDate: null, endDate: null },
        })
        setFetchLimit(10)
    }

    const handleFetchLimit = (newLimit) => {
        setFetchLimit(newLimit);
        setPage(0)
    }


    return (
        <DashboardLayout page={t("pages_name.ago_trasactions")}>
            <div>
                {!loading ? (
                    <div>
                        <div className='externalDeposit_titleComponent'>
                            <h2>Liste des transactions</h2>
                            <div className='orders_filter_components'>
                                <FilterComponent
                                    limit={true}
                                    date={true}
                                    fetchLimit={fetchLimit}
                                    setFetchLimit={handleFetchLimit}
                                    resetFilter={resetFilter}
                                    selectedDate={selectedDate}
                                    setSelectedDate={setSelectedDate}
                                    ordersFilter
                                    hideCancel
                                    hideOnHold
                                    isPurchase={isPurchase}
                                    isSale={isSale}
                                    isOnHold={isOnHold}
                                    isCanceled={isCanceled}
                                    setIsPurchase={setIsPurchase}
                                    setIsSale={setIsSale}
                                    setIsOnHold={setIsOnHold}
                                    setIsCanceled={setIsCanceled}

                                />
                            </div>

                        </div>
                        <div className="table-container">
                            <div className="table-column">
                                <Tableau
                                    thead={["Utilisateur", "Ticker", "Quantité", "Volume", "Type", "Date"]}
                                    tbody={transformDataToTbody(transactions)}
                                    hadLink={true}
                                    linkPath={"/ago/transaction/"}
                                    limit={fetchLimit}
                                    pagination
                                    page={page}
                                    setPage={setPage}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='pageLoaderBox'>
                        <PageLoader />
                    </div>
                )}
            </div>
        </DashboardLayout>
    )
}

export default AgoTransactions