// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page_title_user_picture_header{
  display: flex;
  justify-content: start;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.page_title_user_picture_header_profilPic{
  width: 80px;
  height: 80px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 200px;
}

.page_title_user_picture_header_name{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page_title_user_picture_header_name h3{
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.page_title_user_picture_header_name span{
  margin: 0;
  font-size: 23px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/PageTitleWithPicture/pageTitleWithPicture.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kCAAkC;EAClC,4BAA4B;EAC5B,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,SAAS;EACT,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,SAAS;EACT,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".page_title_user_picture_header{\n  display: flex;\n  justify-content: start;\n  gap: 20px;\n  align-items: center;\n  margin-bottom: 20px;\n}\n\n.page_title_user_picture_header_profilPic{\n  width: 80px;\n  height: 80px;\n  background-size: cover;\n  background-position: center center;\n  background-repeat: no-repeat;\n  border-radius: 200px;\n}\n\n.page_title_user_picture_header_name{\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.page_title_user_picture_header_name h3{\n  margin: 0;\n  font-size: 18px;\n  font-weight: bold;\n}\n\n.page_title_user_picture_header_name span{\n  margin: 0;\n  font-size: 23px;\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
