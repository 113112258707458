import axios from "axios";
import { convertToUtcISOString } from "utils/date";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchAvailableComplianceReports = async (user) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/clients/compliance/list`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchComplianceReports = async ({ user, year, quarter, fromDb }) => {
  try {
    const params = `?quarter=${quarter}&year=${year}${fromDb ? '&fromDb=true' : ''}`;
    const response = await axios.get(`${API_BASE_URL}/rapports/clients/compliance${params}`, buildHeaders(user))
    return response.data;
  } catch (error) {
    throw error
  }
}


export const fetchMonthlyPreventionStatement = async ({ user, month, year }) => {
  try {
    const params = `?month=${month}&year=${year}`;
    const { data } = await axios.get(`${API_BASE_URL}/rapports/clients/prevention${params}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchDailyTradesCountReport = async ({ user, startDate, endDate }) => {
  try {
    const params = `?startDate=${startDate}&endDate=${endDate}`;
    const { data } = await axios.get(`${API_BASE_URL}/rapports/trades/daily-count${params}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}


export const fetchBestExecutionReport = async ({ user, startDate, endDate }) => {
  try {
    const params = `?startDate=${startDate}&endDate=${endDate}`;
    const { data } = await axios.get(`${API_BASE_URL}/rapports/trades/best-execution${params}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchReportNewClients = async ({ user, page, limit, startDate, endDate }) => {
  try {
    const params = `?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`;
    const { data } = await axios.get(`${API_BASE_URL}/rapports/clients/new${params}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchReportAverageVolumePerClient = async ({ user, page, limit, startDate, endDate }) => {
  try {
    const params = `?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`;
    const { data } = await axios.get(`${API_BASE_URL}/rapports/clients/volume/average${params}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchGiftsReport = async ({ user, page, limit, startDate, endDate }) => {
  try {
    const params = `?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`;
    const { data } = await axios.get(`${API_BASE_URL}/rapports/gifts${params}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchReportWalletClient = async ({ user }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/clients/wallets`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchCountUsersHavingTradedByMonthReport = async ({ user, month, year }) => {
  try {
    const params = `?month=${month}&year=${year}`;
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/trader/month/count${params}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchAvailableSumsubReport = async ({user, type}) => {
  try {
    if (!['sumsub-manually-accepted', 'sumsub-rejected', 'sumsub'].includes(type)) throw new Error(`unknown report type: ${type}`)
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/user/${type}/list`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const downloadSumsubReport = async ({ user, type, fileName }) => {
  try {
    if (!['sumsub-manually-accepted', 'sumsub-rejected', 'sumsub'].includes(type)) throw new Error(`unknown report type: ${type}`)
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/user/${type}/download?filename=${fileName}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchUserPositionsReport = async ({ user, date, fromDb }) => {
  try {
    let params = `?date=${convertToUtcISOString(date, false)}&fromDb=${fromDb}`
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/user/positions${params}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchAvailableUsersPositionsReport = async (user) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/user/positions/list`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const downloadUsersPositionsReport = async ({ user, fileName }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/user/positions/download?filename=${fileName}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchFundsMovementsReport = async ({ user, startDate, endDate }) => {
  try {
    const params = `?startDate=${convertToUtcISOString(startDate, true)}&endDate=${convertToUtcISOString(endDate, false)}&fromDb=false`;
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/funds/movements${params}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchAvailableFundsMovementsReport = async (user) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/funds/movements/list`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const downloadFundsMovementsReport = async ({ user, fileName }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/funds/movements/download?filename=${fileName}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchAvailableClosedAccountsReports = async (user) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/user/closed-accounts/list`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}


export const downloadClosedAccountsReport = async ({ user, fileName }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/user/closed-accounts/download?filename=${fileName}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchEconomicProfileReports = async (user) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/user/economic-profile-monitoring/list`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}


export const downloadEconomicProfileReport = async ({ user, fileName }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/user/economic-profile-monitoring/download?filename=${fileName}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchBestExecutionReports = async (user) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/trades/best-execution/list`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}


export const downloadBestExecutionReport = async ({ user, fileName }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/trades/best-execution/download?filename=${fileName}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchUsersWalletReports = async (user) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/user/wallet/list`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}


export const downloadUsersWalletReport = async ({ user, fileName }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/user/wallet/download?filename=${fileName}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchGovernanceReports = async (user) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/user/governance/list`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}


export const downloadGovernanceReport = async ({ user, fileName }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/user/governance/download?filename=${fileName}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchTransactionsMonitoringReports = async (user) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/user/transactions-monitoring/list`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}


export const downloadTransactionsMonitoringReport = async ({ user, fileName }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/user/transactions-monitoring/download?filename=${fileName}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchCumulativeExpensesReports = async (user) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/user/cumulative-expenses/list`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}


export const downloadCumulativeExpensesReport = async ({ user, fileName }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/user/cumulative-expenses/download?filename=${fileName}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchUsersTradedReports = async (user) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/users-traded/list`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}


export const downloadUsersTradedReport = async ({ user, fileName }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/rapports/compliance/users-traded/download?filename=${fileName}`, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}