import { useEffect, useState, useContext } from 'react'
import AuthContext from 'contexte/AuthContext';
import { useTranslation } from 'react-i18next';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import { formatDate, RELEASE_DATE } from 'utils/date';
import PageLoader from 'components/pageLoader/PageLoader';
import './cardTransactions.css'
import { fetchCardTransactions } from 'services/fetchCardTransactions';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';

const CardTransactions = () => {

    const { t } = useTranslation();

    // Récupération du user
    const { user } = useContext(AuthContext);

    // Déclaration des states
    const [fetchLimit, setFetchLimit] = useState(10)
    const [transactions, setTransactions] = useState([])
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(0)
    const [selectedDate, setSelectedDate] = useState({
        date1: { startDate: new Date(RELEASE_DATE), endDate: new Date() },
        date2: { startDate: null, endDate: null },
    });
    const [typeOfRequest, setTypeOfRequest] = useState("DEPOSIT_AND_WITHDRAW")

    // Fonction pour formater la date
    const formattedDateGMTTwoHours = (dateString) => {
        if (dateString === null) return null;
        const date = new Date(dateString);
        date.setHours(date.getHours() + 2);
        date.setHours(0, 0, 0, 0);
        return date;
    }

    // API call
    const fetchData = async () => {

        try {

            const transactionsData = await fetchCardTransactions({
                user: user,
                type: typeOfRequest,
                page: page,
                limit: fetchLimit,
                startDate: formattedDateGMTTwoHours(selectedDate.date1.startDate),
                endDate: formattedDateGMTTwoHours(selectedDate.date1.endDate)
            })

            setTransactions(transactionsData)
            setLoading(false)

        } catch (e) {
            console.log(e)
        }

    }

    useEffect(() => {
        if (selectedDate.date1.startDate !== null && selectedDate.date1.endDate !== null) {
            fetchData()
        }
    }, [fetchLimit, page, selectedDate, typeOfRequest])

    const resetFilter = () => {
        setFetchLimit(10)
    }

    const handleNextPage = () => {
        setPage(page + 1)
    }

    function transformDataToTBody(data) {

        return data.map(item => {

            return {
                data: [
                    <div>
                        <p>{item.firstName}</p>
                        <p>{item.lastName}</p>
                    </div>,
                    <div>
                        {item.txType === "UNIQUE" ? (
                            <p>{item.ticker}</p>
                        ) : (
                            <div>
                                <p>{item.txType}</p>
                                <p>{item.ticker}</p>
                            </div>
                        )}
                    </div>,
                    item.alpaca_status,
                    item.method,
                    item.amount,
                    item.volume,
                    item.totalExFees,
                    item.type,
                    formatDate({ date: item.date })
                ], utils: {
                    id: `${item.txType?.toLowerCase()}/${item.uuid}`
                }

            };
        });
    }

    const transformedTBody = transformDataToTBody(transactions);

    return (
        <div>
            {!loading ? (
                <div>
                    <div className='externalDeposit_titleComponent'>
                        <div className='withdraw_filter_component'>
                            <FilterComponent
                                resetFilter={resetFilter}
                                limit={true}
                                date={true}
                                fetchLimit={fetchLimit}
                                setFetchLimit={setFetchLimit}
                                selectedDate={selectedDate}
                                setSelectedDate={setSelectedDate}
                            />
                        </div>
                    </div>
                    <Tableau
                        thead={["Utilisateur", "Ticker", "État", "Methode", "Quantité", "Volume", "Montant", "Type", "Date"]}
                        tbody={transformedTBody}
                        handleNextPage={handleNextPage}
                        hadLink={true}
                        linkPath={'/finances/trades/'}
                        limit={fetchLimit}
                    />
                </div>
            ) : (
                <div className='pageLoaderBox'>
                    <PageLoader />
                </div>
            )}
        </div>
    )
}

export default CardTransactions
