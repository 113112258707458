import { DateTime } from 'luxon';

export const RELEASE_DATE = new Date(
  Number(process.env.REACT_APP_GOLIATHS_RELEASE_YEAR),
  Number(process.env.REACT_APP_GOLIATHS_RELEASE_MONTH),
  Number(process.env.REACT_APP_GOLIATHS_RELEASE_DAY)
)

export const MARGIN_RELEASE_DATE = new Date(
  Number(process.env.REACT_APP_MARGIN_RELEASE_YEAR),
  Number(process.env.REACT_APP_MARGIN_RELEASE_MONTH),
  Number(process.env.REACT_APP_MARGIN_RELEASE_DAY)
)

export const RELEASE_DATETIME = new DateTime(process.env.REACT_APP_GOLIATHS_RELEASE_YEAR, process.env.REACT_APP_GOLIATHS_RELEASE_MONTH, process.env.REACT_APP_GOLIATHS_RELEASE_DAY)

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function convertToUtcISOString(date, startOfDay) {
  let datetime = DateTime.fromJSDate(date)
  if (startOfDay) {
    datetime = datetime.startOf('day')
  } else {
    datetime = datetime.endOf('day')
  }
  return datetime.toISO({includeOffset: false})
}

export function formatDate({ date, month = 'numeric', time = false, noDay = false, noMonth = false }) {
  let options = {
    year: 'numeric',
    month,
    day: 'numeric',
  };
  if (noDay) {
    delete options.day
  }
  if (noMonth) {
    delete options.month
  }
  if (time) {
    options = {
      ...options,
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  }
  const toFormat = new Date(date);
  if (!isValidDate(toFormat)) return date
  let dateFormatted = toFormat.toLocaleDateString('fr-FR', options);
  if (noDay) dateFormatted = dateFormatted[0].toUpperCase() + dateFormatted.slice(1)
  return dateFormatted;
}

export function getDateFilenameFormatted(date) {
  const toFormat = date ? new Date(date) : new Date();
  const dateSplit = toFormat.toISOString().split('T')[0];
  return dateSplit.replace(/-/g, '_');
}


export const getCurrentQuarter = () => {
  const date = new Date();
  const currentQuarter = Math.floor(date.getMonth() / 3 + 1)
  return currentQuarter
}