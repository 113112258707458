import { useState } from 'react';
import { launchError } from "components/notifications";
import Notification from "components/notifications/Notification";
import PageLoader from "components/pageLoader/PageLoader";
import { Link } from "react-router-dom";
import { downloadFile } from "utils/helpers";
import './downloaderLink.css'

const DownloaderLink = ({ type, filename, fetchData, fetchDataParams, display, disabled }) => {

  const [isLoading, setIsLoading] = useState(false);

  const getType = () => {
    switch (type) {
      case 'excel':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
      default:
        return ''
    }
  }

  const handleClick = async () => {
    try {
      setIsLoading(true)
      const data = await fetchData(fetchDataParams);
      downloadFile({ data, type: getType(), filename })
    } catch (e) {
      launchError('Erreur download link')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading && <PageLoader />}
      <>
        <Notification />
        <Link
          onClick={() => disabled ? {} : handleClick()}
          disabled={disabled}
        >{display}</Link>
      </>
    </>
  )

}

export default DownloaderLink;