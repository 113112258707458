import { useState, useEffect, useContext } from 'react'
import AuthContext from 'contexte/AuthContext';
import { useTranslation } from 'react-i18next';
import './alltickers.css'
import { editTickerName, fetchTickers } from 'services/tickersServices';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import Notification from 'components/notifications/Notification';
import { launchError, launchSuccess } from 'components/notifications';
import TickerImage from 'components/DataComponents/TickerImage/TickerImage';

const Alltickers = () => {

    const { t } = useTranslation();
    const { user } = useContext(AuthContext)

    const [loading, setLoading] = useState(true)
    const [tickers, setTickers] = useState([])
    const [page, setPage] = useState(0)
    const [fetchLimit, setFetchLimit] = useState(10)
    const [search, setSearch] = useState("")
    const [selected, setSelected] = useState(null);
    const [selectedName, setSelectedName] = useState('')

    const fetchData = async () => {
        try {
            const data = await fetchTickers(user, page, fetchLimit, search)
            setTickers(data)
            setLoading(false)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetchData()
    }, [fetchLimit, page, search])

    const handleSelected = (index) => {
        setSelected(index);
        setSelectedName(tickers[index].name);
    }

    const editName = async (tickerId) => {
        try {
            const data = await editTickerName(user, tickerId, selectedName);
            setTickers((prev) => {
                const newTickers = [...prev];
                const index = newTickers.findIndex(ticker => ticker.id === tickerId);
                newTickers[index].name = data;
                return newTickers;
            })
            setSelected(null);
            setSelectedName('')
            launchSuccess(t('notifications.actifs.edit_ticker_name.success'))
        } catch (e) {
            launchError(t('notifications.actifs.edit_ticker_name.error'))
        }
    }

    const transformDataToTbody = (data) => {
        return data?.map((item, index) => {
            return {
                data: [
                    <TickerImage ticker={item.ticker} bold />,
                    <div className='table_ticker_cell'>
                        {selected === index ?
                            <>
                                <input value={selectedName} onChange={(e) => setSelectedName(e.target?.value)} type='text' />
                                <button onClick={() => editName(item.id)}>Save</button>
                            </>
                            :
                            <>
                                {item.name}
                                <button onClick={() => handleSelected(index)}>Edit</button>
                            </>
                        }
                    </div>
                ]
            }
        })
    }

    return (
        <div>
            <Notification />
            {!loading ? (
                <div>
                    <div className='externalDeposit_titleComponent'>
                        <h2>Liste des Tickers</h2>
                        <FilterComponent
                            withSearch={true}
                            search={search}
                            setSearch={setSearch}
                        />
                    </div>

                    <div className="table-container">
                        <div className="table-column">
                            <Tableau
                                thead={["Tickers", "Name"]}
                                tbody={transformDataToTbody(tickers)}
                                pagination
                                page={page}
                                setPage={setPage}
                                limit={fetchLimit}

                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div>Loading..</div>
            )}
        </div>
    )
}

export default Alltickers