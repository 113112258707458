// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* giftFilters.css */
.usersFilterComponent{
    display: flex;
}
/* Conteneur principal */
.giftFilterComponent div {
    display: flex;
    justify-content: space-between;
    /* Distribue les éléments uniformément le long de l'axe principal */
    align-items: center;
    /* Centre les éléments le long de l'axe transversal */
    padding: 10px;
}

/* Conteneur pour chaque filtre */
.giftFilterComponent div div {
    display: flex;
    align-items: center;
}

/* Espacement entre la case à cocher et le libellé */
.giftFilterComponent input[type="checkbox"] + label {
    margin-left: 5px;
}

.userFilterSelect {
    width: unset;
    padding: unset;
    padding-left: 2px;
    margin-right: 1px;
    border: 1px solid #495057;
}`, "",{"version":3,"sources":["webpack://./src/components/Filters/UsersFilter/userFilter.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,aAAa;AACjB;AACA,wBAAwB;AACxB;IACI,aAAa;IACb,8BAA8B;IAC9B,mEAAmE;IACnE,mBAAmB;IACnB,qDAAqD;IACrD,aAAa;AACjB;;AAEA,iCAAiC;AACjC;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA,oDAAoD;AACpD;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,iBAAiB;IACjB,iBAAiB;IACjB,yBAAyB;AAC7B","sourcesContent":["/* giftFilters.css */\n.usersFilterComponent{\n    display: flex;\n}\n/* Conteneur principal */\n.giftFilterComponent div {\n    display: flex;\n    justify-content: space-between;\n    /* Distribue les éléments uniformément le long de l'axe principal */\n    align-items: center;\n    /* Centre les éléments le long de l'axe transversal */\n    padding: 10px;\n}\n\n/* Conteneur pour chaque filtre */\n.giftFilterComponent div div {\n    display: flex;\n    align-items: center;\n}\n\n/* Espacement entre la case à cocher et le libellé */\n.giftFilterComponent input[type=\"checkbox\"] + label {\n    margin-left: 5px;\n}\n\n.userFilterSelect {\n    width: unset;\n    padding: unset;\n    padding-left: 2px;\n    margin-right: 1px;\n    border: 1px solid #495057;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
