// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.graph_container{
    display: flex;
    flex-direction: column;
    align-items: start;
    background-color: #F2F3FC;
    padding: 20px 20px;
    border-radius: 10px;
}

.graph_title{
    margin: 0;
}

.graph_header{
    display: flex;
    width: 100%;
    padding: 10px 0px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/DataComponents/newUsersGraph/NewUsersGraph.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,aAAa;IACb,WAAW;IACX,iBAAiB;IACjB,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":[".graph_container{\n    display: flex;\n    flex-direction: column;\n    align-items: start;\n    background-color: #F2F3FC;\n    padding: 20px 20px;\n    border-radius: 10px;\n}\n\n.graph_title{\n    margin: 0;\n}\n\n.graph_header{\n    display: flex;\n    width: 100%;\n    padding: 10px 0px;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
