import { useContext } from 'react'
import AuthContext from '../contexte/AuthContext'
import GoogleLoginComposant from '../pages/googleLogin/GoogleLogin';

const ProtectedRoutes = ({ role, children }) => {
    
    const { user, verifyUser } = useContext(AuthContext);
    const verificationResult = verifyUser()

    if(user && verificationResult.access && role.includes(verificationResult.role)){
      return children
    }
    
  return <GoogleLoginComposant />
  
}

export default ProtectedRoutes