import { useState, useEffect, useContext } from 'react'
import './alpacaThree.css'
import AuthContext from 'contexte/AuthContext'
import { useTranslation } from 'react-i18next';
import { fetchAlpacaThree } from 'services/fetchAlpaca'
import { changeAlpacaState } from 'services/changeAlpacaState'
import Tableau from 'components/DataComponents/Tableau/Tableau';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import Notification from 'components/notifications/Notification';
import { launchError, launchSuccess } from 'components/notifications';



const AlpacaThree = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1)
  const { t } = useTranslation();
  const { user } = useContext(AuthContext)

  const [loading, setLoading] = useState(false)
  const [alpaca, setAlpaca] = useState([])
  const [selectedDate, setSelectedDate] = useState({
    date1: { startDate: yesterday },
  });

  const fetchData = async () => {
    const data = await fetchAlpacaThree(user, selectedDate?.date1?.startDate)
    const thisArray = []
    thisArray.push(data)
    setAlpaca(thisArray)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [selectedDate])

  const resetFilter = () => {
    setSelectedDate({
      date1: { startDate: yesterday }
    })
  }

  const transformData = (data) => {
    return data.map((item) => {

      console.log('item', item)

      return {
        data: [
          item.report_date,
          item.t0,
          item.t1,
          item.t2,
          item.report_date && <input type="checkbox" checked={item.is_done} onChange={onChangeCheckbox} />
        ]
      }
    })
  }

  const onChangeCheckbox = async (e) => {
    try {
      await changeAlpacaState(user, alpaca[0]._id, alpaca[0].report_date, !alpaca[0].is_done)
      setAlpaca([{ ...alpaca[0], is_done: !alpaca[0].is_done }])
      launchSuccess(t('notifications.actifs.alpaca.net_summary_update.success'))
    } catch (err) {
      launchError(t('notifications.actifs.alpaca.net_summary_update.error'))
    }
  }


  return (
    <div>
      {!loading ? (
        <div>
          <Notification />
          <div className='alpacaThree_titleComponent'>
            <h3>Balance Alpaca</h3>
            <FilterComponent
              resetFilter={resetFilter}
              limit={false}
              date={true}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              simpleDate
            />
          </div>
          <Tableau tbody={transformData(alpaca)} thead={['Date', 'T0', 'T1', 'T2', 'State']} />
        </div>
      ) : (
        <div>
          loading...
        </div>
      )}
    </div>
  )
}

export default AlpacaThree