import { useState, useEffect, useContext } from "react"
import AuthContext from "contexte/AuthContext"
import { useTranslation } from "react-i18next"
import "./availableMoney.css"
import DashboardLayout from "components/layout/dashboard/DashboardLayout"
import FilterComponent from "components/Filters/FilterComponent/FilterComponent"
import PageLoader from "components/pageLoader/PageLoader"
import Tableau from "components/DataComponents/Tableau/Tableau"
import { fetchAvailableMoney } from "services/fetchTradeReports"
import TableUser from "components/TableUser/TableUser"

const AvailableMoney = () => {
    const { user } = useContext(AuthContext);
    const { t } = useTranslation();

    const [availableMoney, setAvailableMoney] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);

    const fetchData = async () => {

        try {

            const response = await fetchAvailableMoney(user, limit, page, );
            setAvailableMoney(response);
            setLoading(false);
        } catch (e) {
            console.log(e);
            setError(true);
        }
    }

    useEffect(() => {
            fetchData();
    }, [page, limit]);

    const transformDataToTbody = (data) => {

        return data.map(item => {

            return {
                data: [
                    item.uuid ?
                    <TableUser
                        firstName={item.firstName}
                        lastName={item.lastName}
                        picture={item.uuid}
                        key={item.uuid}
                    /> : 'TOTAL',
                    item.available,
                    item.withdraws,
                    item.trades,
                    item.total
                ],
                utils: {
                  id: item.uuid && item.uuid
                }
            };
        });
    }

    const handleLimit = (newLimit) => {
      setLimit(newLimit);
      setPage(0)
    }

    return (
        <DashboardLayout page={t("pages_name.report_trade")}>
            <div>
                {
                    !loading ? (
                        <div>
                            <div className="flexbox">
                                <h3>Argent disponible non tradé</h3>
                                <FilterComponent
                                    page={true}
                                    limit={true}
                                    setPage={setPage}
                                    fetchLimit={limit}
                                    setFetchLimit={handleLimit}
                                />
                            </div>
                            <div>
                                <Tableau
                                    thead={['Utilisateur', 'Portefeuille', 'Retrait en attente', 'Achat/Vente en attente', 'Total']}
                                    tbody={transformDataToTbody(availableMoney)}
                                    limit={limit}
                                    page={page}
                                    setPage={setPage}
                                    pagination={true}
                                    hadLink
                                    linkPath='/user/'
                                    firstLineSummup
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='pageLoaderBox'>
                            <PageLoader />
                        </div>
                    )
                }
            </div>
        </DashboardLayout>
    )
}

export default AvailableMoney