import axios from "axios"

export const googleLoginServices = async (user) => {
    return axios.post(process.env.REACT_APP_BASE_URL + process.env.REACT_APP_VERSION + '/auth/login/google', {
        username: user.email,
        password: "wLY8!Am$I@fXd7iA%aczJrsi4eqpa$q%3^I6TFM5QnWOrOh$d"
    }, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}