// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.margin_transaction_details_item_title {
  margin: 0;
  font-size: 15px;
  font-weight: 700;
  display: block;
  color: #7f7f7f;
}`, "",{"version":3,"sources":["webpack://./src/pages/finances/subpages/MarginTransactions/MarginTransactionById/marginTransactionById.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,cAAc;AAChB","sourcesContent":[".margin_transaction_details_item_title {\n  margin: 0;\n  font-size: 15px;\n  font-weight: 700;\n  display: block;\n  color: #7f7f7f;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
