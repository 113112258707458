import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const Notification = ({ autoClose, hideProgressBar, theme }) => {
  return (
    <ToastContainer
      position={theme || 'top-center'}
      autoClose={autoClose || 5000}
      hideProgressBar={hideProgressBar || false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme='colored'
      z-index={1000000}
      zIndex={10000000}
    />)
}

export default Notification;
