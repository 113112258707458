// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table_ticker_cell {
  display: flex;
  flex-direction: line;
  align-items: center;
  justify-content: space-between;
}

.table_ticker_cell button{
  padding: 15px 15px;
  width: max-content;
  font-weight: bold;
  border-radius: 10px;
  background-color: #2B64F5;
  color: white;
  display: none;
}

.table_ticker_cell:hover button{
  display: block;

}`, "",{"version":3,"sources":["webpack://./src/pages/actifs/subpages/Tickers/AllTickers/alltickers.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,oBAAoB;EACpB,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,cAAc;;AAEhB","sourcesContent":[".table_ticker_cell {\n  display: flex;\n  flex-direction: line;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.table_ticker_cell button{\n  padding: 15px 15px;\n  width: max-content;\n  font-weight: bold;\n  border-radius: 10px;\n  background-color: #2B64F5;\n  color: white;\n  display: none;\n}\n\n.table_ticker_cell:hover button{\n  display: block;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
