import { useState, useEffect, useContext } from 'react'
import AuthContext from 'contexte/AuthContext'
import { fetchLeverageUserPositions } from 'services/fetchUserById'
import PageLoader from 'components/pageLoader/PageLoader'
import Tableau from 'components/DataComponents/Tableau/Tableau'
import './userLeverageTransactions.css'
import { useTranslation } from 'react-i18next';
import Notification from 'components/notifications/Notification'
import { launchError } from 'components/notifications'
import TickerImage from 'components/DataComponents/TickerImage/TickerImage'
import { fetchLeverageTransactions } from 'services/marginServices'
import { formatDate, MARGIN_RELEASE_DATE } from 'utils/date'
import Modal from 'components/modal/Modal'
import Badge from 'components/Badge/Badge'
import colors from 'assets/colors'


const transformeDataToTbody = (data, callFromModal = false) => {
  return data.length && data.map((item, index) => {
    if (!item) return { data: [] }


    return {
      data: [
        <TickerImage ticker={item.ticker} />,
        item.amount,
        <Badge
          text={item.type === 'BUY' ? 'ACHAT' : 'VENTE'}
          color={colors.badge[item.type]}
        />,
        formatDate({ date: item.filled_at, time: true })
      ],
      utils: {
        id: item.id
    }
    }
  })
}


const UserLeverageTransactions = ({ id }) => {

  const { user } = useContext(AuthContext)
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [transactions, setTransactions] = useState([])
  const [page, setPage] = useState(0)
  const [fetchLimit, setFetchLimit] = useState(10)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalIndex, setModalIndex] = useState(0)

  const tableHeaders = ['Ticker', 'Quantité', 'Type', 'Date']


  const fetchData = async () => {
    try {
      const data = await fetchLeverageTransactions(user, page, fetchLimit, new Date(MARGIN_RELEASE_DATE), new Date(), 'ALL', id)
      setTransactions(data)
      setLoading(false)
    } catch {
      console.log('error')
    }
  }

  const onClickFunction = (index) => {
    setModalIndex(index)
    setIsModalOpen(true);
  }

  useEffect(() => {
    fetchData()
  }, [page])

  return (
    <div>
      {
        !loading ? (
          <div>
            <Notification />
            <Tableau
              tbody={transformeDataToTbody(transactions)}
              thead={tableHeaders}
              limit={fetchLimit}
              pagination={true}
              setPage={setPage}
              page={page}
              onClickFunction={onClickFunction}
              hadLink={true}
              linkPath={'/finances/margin-transaction/'}
            />
          </div>
        ) : (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        )
      }
      {
        isModalOpen && (
          <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} closeButton={true} triggerFonction={true} onMountFunction={() => { }}>
            <h2 className='modal_title'>Détail de la transaction</h2>
            <p className='modal_user_leverage_transactions_text'>Ticker : <span>{transactions[modalIndex].ticker}</span></p>
            <p className='modal_user_leverage_transactions_text'>Type : <span>{transactions[modalIndex].type}</span></p>
            <p className='modal_user_leverage_transactions_text'>Date : <span>{transactions[modalIndex].filled_at}</span></p>
            <p className='modal_user_leverage_transactions_text'>Quantité : <span>{transactions[modalIndex].amount}</span></p>
            <p className='modal_user_leverage_transactions_text'>Total : <span>{transactions[modalIndex].total}</span></p>
            <p className='modal_user_leverage_transactions_text'>Montant investi : <span>{transactions[modalIndex].invested}</span></p>
            <p className='modal_user_leverage_transactions_text'>Montant prêté : <span>{transactions[modalIndex].borrowed_amount}</span></p>
            <p className='modal_user_leverage_transactions_text'>Frais overnight journalier : <span>{transactions[modalIndex].overnight_fees}</span></p>
            {transactions[modalIndex].type === 'SELL' && <p className='modal_user_leverage_transactions_text'>Durée de détention moyenne : <span>{transactions[modalIndex].avg_holding_period}</span></p>}
            {transactions[modalIndex].type === 'SELL' && <p className='modal_user_leverage_transactions_text'>Gains estimés : <span>{transactions[modalIndex].gains}</span></p>}
          </Modal>
        )
      }
    </div>
  )
}

{/* <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} closeButton={true} triggerFonction={true} onMountFunction={() => { }} width={'800px'}>
<h2 className='modal_title'>Détail de la queue</h2>
<ScrollBar>
  <div className='queue_detail'><pre>
    {JSON.stringify(queues[modalIndex], null, 2)}
  </pre></div>
</ScrollBar>
</Modal> */}

export default UserLeverageTransactions