import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services"; 

export const fetchOrders = async (user, page, limit, startDate, endDate, type, statusType) => {

    try {
        const params = `?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}&type=${type}${statusType ? `&statusType=${statusType}`: ''}`
        const getPayouts = await axios.get(`${API_BASE_URL}/trades/list${params}`, buildHeaders(user)
        )
        
        return getPayouts.data

    } catch (err) {

        console.log(err)
    }

}
