import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchPendingPayouts = async ({user, page, limit, search}) => {

    try {
        const params = `?&page=${page}&limit=${limit}${search ? `&search=${search}` : ''}`
        const { data } = await axios.get(`${API_BASE_URL}/payouts/pending/list${params}`,
            buildHeaders(user))
        return data

    } catch (err) {
        throw err
    }

}

export const initiateManualPayout = async (user, payee_uuid, bank_id, iban, amount, fees, goliathsCustomFees, revolutCustomFees) => {
    try {
        const { data } = await axios.post(`${API_BASE_URL}/payouts/admin/sms`,
            {
                payee_uuid,
                iban,
                amount: Number(amount),
                fees,
                bank_id,
                goliathsCustomFees: goliathsCustomFees ? Number(goliathsCustomFees) : goliathsCustomFees,
                revolutCustomFees: revolutCustomFees ? Number(revolutCustomFees) : revolutCustomFees
            },
            buildHeaders(user))
        return data

    } catch (err) {
        throw err
    }
}

export const verifyCodeManualPayout = async (user, code) => {
    try {
        const { data } = await axios.post(`${API_BASE_URL}/payouts/admin/verify`,
            {
                code
            },
            buildHeaders(user))
        return data

    } catch (err) {
        throw err
    }
}
