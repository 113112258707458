import { useState, useContext, useEffect } from 'react'
import DashboardLayout from '../../../../../components/layout/dashboard/DashboardLayout'
import './ibanById.css'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import AuthContext from '../../../../../contexte/AuthContext';
import { approveIban, rejectIban } from 'services/fetchIbans';
import Notification from 'components/notifications/Notification';
import { launchSuccess, launchError } from 'components/notifications'
import { useNavigate } from 'react-router-dom';
import { formatIBAN } from 'utils/iban';
// import { ToastContainer, toast } from 'react-toastify';


const IbanById = (props) => {

    const { user } = useContext(AuthContext)
    const { t } = useTranslation()
    const domain = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_VERSION;

    const navigate = useNavigate();

    const [id, setId] = useState(window.location.pathname.split('/')[3])
    const [loading, setLoading] = useState(true)
    const [ibanDetails, setIbanDetails] = useState({})
    const [reason, setReason] = useState(null);

    const fetchData = async () => {

        try {

            const ibansDetailsData = await axios.get(`${domain}/payouts/iban/details/${id}`, { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${user}` } })

            console.log(ibansDetailsData.data)
            setIbanDetails(ibansDetailsData.data)
            setLoading(false)

        } catch (e) {
            console.log(e)
        }

    }

    useEffect(() => {
        fetchData();
    }, [])


    const approve = async () => {
        try {
            await approveIban(user, ibanDetails.bank_account_id)
            navigate('/finances/iban')
            launchSuccess(t('notifications.payouts.ibans.approve.success'));
        } catch (error) {
            console.log('error catch')
            launchError(t('notifications.payouts.ibans.approve.error'))
        }
    }

    const reject = async () => {
        try {
            await rejectIban(user, ibanDetails.bank_account_id, reason)
            navigate('/finances/iban')
            launchSuccess(t('notifications.payouts.ibans.reject.success'));
        } catch (error) {
            console.log('error reject catch');
            launchError(t('notifications.payouts.ibans.reject.error'))
        }
    }

    return (
        <DashboardLayout page={'Finances / Ibans / ' + id}>
            <div className='w-full'>
                {
                    !loading ? (
                        <div className='w-full'>
                            <Notification />
                            <div className='ibanById_header'>
                                <h2 className='ibanById_title'>Détail de l'iban</h2>
                                <p className='ibanById_title'>{ibanDetails.status}</p>
                            </div>
                            <div className='ibanById_secion'>
                                <div className='ibanById_userInformation'>
                                    <div className='ibanById_userInformation_profil'>
                                        <div className='ibanById_userInformation_profil_img' style={{ backgroundImage: `url(${process.env.REACT_APP_CDN_URL}/usr/pic/${ibanDetails.client_pic})` }} />
                                        <p>{ibanDetails.client_firstName + " " + ibanDetails.client_lastName}</p>
                                    </div>
                                    <div className='ibanById_userInformation_infos'>
                                        <p><strong>Email</strong> : {ibanDetails.client_email}</p>
                                        <p><strong>Téléphone</strong> : {ibanDetails.client_phone}</p>
                                    </div>
                                </div>

                                <div className='ibanById_userInformation'>
                                    <div className='ibanById_ibanInformation_iban'>
                                        <p><strong>Nom de la banque</strong> : {ibanDetails.bank_name}</p>
                                        <p><strong>Nom associé</strong> : {ibanDetails.iban_associated_name}</p>
                                        <p><strong>Nom OCR</strong> : {ibanDetails.ocr_full_name || "N/A"} {ibanDetails.iban_associated_name === ibanDetails.ocr_full_name ? (<span className='ocr_confirmed'>Confirmé</span>) : (<span className='ocr_not_confirmed'>Différent</span>)}</p>
                                    </div>
                                    <div className='ibanById_ibanInformation_ocr'>
                                        <p><strong>Création de l'iban</strong> : {ibanDetails.created_at}</p>
                                        <p><strong>IBAN</strong> : {formatIBAN(ibanDetails.iban)}</p>
                                        <p className='ocr_validation'><strong>IBAN OCR OCR</strong> : {ibanDetails.ocr_iban || "N/A"} {ibanDetails.iban === ibanDetails.ocr_iban ? (<span className='ocr_confirmed'>Confirmé</span>) : (<span className='ocr_not_confirmed'>Différent</span>)}</p>
                                    </div>
                                </div>

                                <div className='ibanById_userInformation iban_container_img'>
                                    <img src={`${process.env.REACT_APP_CDN_URL}/${ibanDetails.file_link}`} alt="" className='iban_file' />
                                </div>

                                <div className='ibanById_userInformation iban_refusal_reason'>
                                    <select
                                        id="reasons_dropdown"
                                        onChange={(e) => setReason(e.target.value)}>
                                        <option value="">Sélectionner une raison de refus</option>
                                        <option value="name_not_match">Le nom ne correspond pas</option>
                                        <option value="picture_not_match">L'image de correspond pas</option>
                                    </select>
                                </div>

                                <div className='ibanById_button_container'>
                                    <button className='ibanById_refused'
                                        disabled={!reason}
                                        onClick={() => reject()}>Refuser l'IBAN</button>
                                    <button className='ibanById_accepted'
                                        onClick={() => approve()}>Accepter l'IBAN</button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>Loading...</div>
                    )
                }
            </div>
        </DashboardLayout>
    )
}

export default IbanById