import './KpiBox.css'
import { Link } from 'react-router-dom';

const formatNumberWithSpaces = (number) => {
    const numberStr = number?.toString() || '';
    return numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export const Box = ({ title, data, comparedData, unit, ppComparison, link }) => {

  let compareToDisplay
  if (comparedData) {
    compareToDisplay = comparedData;
    if (ppComparison) {
      compareToDisplay = computePercentageAndColor(data, comparedData)
    }
  }

  return (
    <Link to={link} style={{ textDecoration: 'none' }}>
      <div className='kpi_box_container'>
        <p className="kpi_box_title">{title}</p>
        <div className='kpi_box_datas'>
          <p className="kpi_box_data">{formatNumberWithSpaces(data)}</p> {unit ? <span>{unit}</span> : null}{compareToDisplay ? <span>({formatNumberWithSpaces(compareToDisplay)})</span> : null}
        </div>
      </div>
    </Link>
  )
}

const computePercentageAndColor = (data, comparator) => {
  const pp = Math.round(((data - comparator) / comparator) * 100);
  return `${pp} %`
}

// if (kpiData.selectedData && kpiData.comparedData) {
//     const base = kpiData.selectedData
//     const pp = Math.round(((base - comparator) / comparator) * 100);

//     volumeDiv += `<br/><div style="display: inline; color: ${color}; font-size: large" id="compare_${kpiName}">(${pp}%)</div>`
// }