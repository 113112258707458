// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_layout{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.home_layout_left{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.home_layout_right{
  display: none;
}


.home_title{
    font-size: 18px;
}

.home_flex_children{
  display: flex ;
  flex-direction: row ;
  gap: 10px ;
  align-items: start;
}

`, "",{"version":3,"sources":["webpack://./src/pages/home/home.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;AACf;;;AAGA;IACI,eAAe;AACnB;;AAEA;EACE,cAAc;EACd,oBAAoB;EACpB,UAAU;EACV,kBAAkB;AACpB","sourcesContent":[".home_layout{\n  display: flex;\n  flex-direction: row;\n  gap: 10px;\n}\n\n.home_layout_left{\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n\n.home_layout_right{\n  display: none;\n}\n\n\n.home_title{\n    font-size: 18px;\n}\n\n.home_flex_children{\n  display: flex ;\n  flex-direction: row ;\n  gap: 10px ;\n  align-items: start;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
