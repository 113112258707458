import React, { useEffect } from 'react';
import './modal.css';

const Modal = ({ isOpen, onClose, children, closeButton, triggerFonction, onMountFunction, width }) => {
    const modalClass = isOpen ? 'modal-overlay open' : 'modal-overlay';

    useEffect(() => {
        if (triggerFonction) {
            onMountFunction();
        }
    }, []);

    return (
        <div className={modalClass}>
            <div className="modal-container" style={{width: width || null}}>
                {
                    closeButton && (
                        <button className="close-button" onClick={onClose}>
                            X
                        </button>
                    )
                }
                {children}
            </div>
        </div>
    );
};

export default Modal;
