import React from 'react'
import './money.css'
import { useTranslation } from 'react-i18next';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import { Link } from 'react-router-dom'

const Money = () => {
  const { t } = useTranslation();

  return (
    <DashboardLayout page={t("pages_name.report_trade")}>
      <div className='externalDeposit_titleComponent'>
        <h2>{t("pages.report_trade.title")}</h2>
      </div>
      <div>
        <ul>
          <Link to={'/reports/money/profits'}>Profits</Link>
          <Link to={'/reports/money/profits-action'}>Profits par action</Link>
        </ul>
      </div>
    </DashboardLayout>
  )
}

export default Money
