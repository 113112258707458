// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.forms-container-page{
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 20px;
  overflow: hidden;
}

.forms-container{
  width: 100%;
  position: relative;
}

.forms-tab-content{
}

.form_transactions_switcher{
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  z-index: 1;
}

.transition_forms{
  border-radius: 0 0 0 12px;
  padding: 10px 20px;
}

.transition_forms_tabs{
  background-color: #fff !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/forms/forms.css"],"names":[],"mappings":";AACA;EACE,WAAW;EACX,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;AACA;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,gBAAgB;EAChB,gCAAgC;EAChC,8BAA8B;EAC9B,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,iCAAiC;AACnC","sourcesContent":["\n.forms-container-page{\n  width: 100%;\n  border: 1px solid #e6e6e6;\n  border-radius: 20px;\n  overflow: hidden;\n}\n\n.forms-container{\n  width: 100%;\n  position: relative;\n}\n\n.forms-tab-content{\n}\n\n.form_transactions_switcher{\n  position: absolute;\n  top: 0;\n  right: 0;\n  background: #fff;\n  border-bottom: 1px solid #e6e6e6;\n  border-left: 1px solid #e6e6e6;\n  z-index: 1;\n}\n\n.transition_forms{\n  border-radius: 0 0 0 12px;\n  padding: 10px 20px;\n}\n\n.transition_forms_tabs{\n  background-color: #fff !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
