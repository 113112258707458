import DashboardLayout from "components/layout/dashboard/DashboardLayout"
import { useTranslation } from 'react-i18next'
import "./psLayout.css"
import { useEffect, useState } from "react";


const PsLayout = ({ children }) => {

  const { t } = useTranslation();
  const [selectedTabs, setSelectedTabs] = useState();
  const [productId, setProductId] = useState();

  useEffect(() => {
    setSelectedTabs(window.location.pathname.split('/')[3]);
    setProductId(window.location.pathname.split('/')[2]);
  }, [])

  return (
    <DashboardLayout page={t('pages_name.structured_products')}>
      <div className="pslayout-box">
        <div className="pslayout-header">
          <a href={`/structured-products/${productId}/infos`} className="pslayout-header-link"><div className={`${selectedTabs === "infos" ? "pslayout-header-button-selected" : "pslayout-header-button"}`}>Informations</div></a>
          <a href={`/structured-products/${productId}/financement`} className="pslayout-header-link"><div className={`${selectedTabs === "financement" ? "pslayout-header-button-selected" : "pslayout-header-button"}`}>Financement</div></a>
          <a href={`/structured-products/${productId}/sous-jaccent`} className="pslayout-header-link"><div className={`${selectedTabs === "sous-jaccent" ? "pslayout-header-button-selected" : "pslayout-header-button"}`}>Sous-jaccent</div></a>
          <a href={`/structured-products/${productId}/texts`} className="pslayout-header-link"><div className={`${selectedTabs === "texts" ? "pslayout-header-button-selected" : "pslayout-header-button"}`}>Textes</div></a>
          <a href={`/structured-products/${productId}/dates`} className="pslayout-header-link"><div className={`${selectedTabs === "dates" ? "pslayout-header-button-selected" : "pslayout-header-button"}`}>Dates</div></a>
          <a href={`/structured-products/${productId}/recap`} className="pslayout-header-link"><div className={`${selectedTabs === "recap" ? "pslayout-header-button-selected" : "pslayout-header-button"}`}>Récap</div></a>
        </div>
        <div >
          {children}
        </div>
      </div>
    </ DashboardLayout>
  )
}

export default PsLayout