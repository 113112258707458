import './userDataArray.css'
import { useTranslation } from 'react-i18next';
import { useState, useCallback } from 'react'
import UserCashLogs from './UserCashLogs/UserCashLogs';
import UserPortfolio from './UserPortfolio/UserPortfolio';
import UserTransactions from './UserTransactions/UserTransactions';
import UserStructuredProducts from './UserStructuredProducts/UserStructuredProducts';
import UserLeveragePositions from './UserLeveragePositions/UserLeveragePositions';
import UserLeverageTransactions from './UserLeverageTransactions/UserLeverageTransactions';

const UserDataArray = ({ id }) => {

    const { t } = useTranslation();

    const [tab, setTab] = useState(1)

    const getComponent = useCallback((t) => {
        switch (t) {
            case 0:
                return <UserTransactions id={id} />
            case 1:
                return <UserPortfolio id={id} />
            case 2:
                return <UserCashLogs id={id} />
            case 3:
                return <UserStructuredProducts id={id} />
            case 4:
                return <UserLeveragePositions id={id} />
            case 5:
                return <UserLeverageTransactions id={id} />
            default:
                return null;
        }
    }, [id])

    return (
        <div>
            <div>
                <div className='transactions_switcher'>
                    {['Transactions', 'Portfolio', 'Cash Logs', 'Produits Structurés', 'Leverage Positions', 'Leverage Transactions'].map((title, index) =>
                        (<div key={index} className={`transactions_tab ${tab === index ? "transactions_tab_select" : "transactions_tab_not_select"}`} onClick={() => { setTab(index) }}>{title}</div>)
                    )}
                    {/* <div className={`transactions_tab ${tab === 1 ? "transactions_tab_select" : "transactions_tab_not_select"}`} onClick={() => { setTab(1) }}>Transactions</div>
                    <div className={`transactions_tab ${tab === 2 ? "transactions_tab_select" : "transactions_tab_not_select"}`} onClick={() => { setTab(2) }}>Portfolio</div>
                    <div className={`transactions_tab ${tab === 3 ? "transactions_tab_select" : "transactions_tab_not_select"}`} onClick={() => { setTab(3) }}>Cash Logs</div>
                    <div className={`transactions_tab ${tab === 4 ? "transactions_tab_select" : "transactions_tab_not_select"}`} onClick={() => { setTab(4) }}>Cash Logs</div> */}
                </div>
                {
                    getComponent(tab)
                }
            </div>

        </div>)
}

export default UserDataArray