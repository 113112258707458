import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const useFilters = (initialFilters) => {
  const [filters, setFilters] = useState(initialFilters);

  const navigate = useNavigate();
  const handleFiltersChange = useCallback((filterKey, updater) => {

    let startDate1, endDate1;
    if (filterKey === 'selectedDate') {
      const date = updater()
      startDate1 = date?.date1?.startDate?.toISOString()?.split('T')[0]
      endDate1 = date?.date1?.endDate?.toISOString()?.split('T')[0]

    }

    setFilters((prevFilters) => {

      return {
        ...prevFilters,
        ['page']: prevFilters.refreshData ? 0 : prevFilters.page,
        [filterKey]: typeof updater === 'function' ? updater(prevFilters[filterKey]) : updater,
        ['refreshData']: true,
      }
    });

    if (filterKey === 'selectedDate') {
      const queryParams = [{filterKey: 'startDate1', updater: startDate1}, {filterKey: 'endDate1', updater: endDate1}]
      navigate(window.location.pathname + addFiltersToQueryParams(queryParams))
    } else {
      navigate(window.location.pathname + addFilterToQueryParams(filterKey, updater))
    }
  }, []);

  const resetFilters = useCallback(() => setFilters(initialFilters), [initialFilters]);

  return { filters, handleFiltersChange, resetFilters };
};


const addFilterToQueryParams = (filterKey, updater) => {
  let params = new URLSearchParams(window.location.search);
  const newParam = typeof updater === 'function' ? updater(params.get(filterKey)) : updater;
  if (newParam) {
    params.set(filterKey, newParam);
  } else {
    params.delete(filterKey);
  }
  return params.toString() ? `?${params.toString()}` : params;
}

const addFiltersToQueryParams = (paramsToAdd) => {
  let params = new URLSearchParams(window.location.search);
  for (let { filterKey, updater } of paramsToAdd) {
    const newParam = typeof updater === 'function' ? updater(params.get(filterKey)) : updater;
    if (newParam) {
      params.set(filterKey, newParam);
    } else {
      params.delete(filterKey);
    }
  }
  return params.toString() ? `?${params.toString()}` : params;
}


export default useFilters;
