// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.exante_alert_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  padding: 15px 100px;
  gap: 5px;
  border-radius: 10px;
  margin-bottom: 2rem;
}

.unsufficient_amount {
  background-color: #f10505;
  border: solid 1px #880c0c;
}

.sufficient_amount {
  background-color: #03c619;
  border: solid 1px #009111;
}


.exante_alert_box_text {
  display: flex;
  gap: 5px;
  font-size: 17px;
  font-weight: 800;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/DataComponents/ExanteAlert/exanteAlert.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;EACnB,QAAQ;EACR,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B;;;AAGA;EACE,aAAa;EACb,QAAQ;EACR,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd","sourcesContent":[".exante_alert_box {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-self: center;\n  align-items: center;\n  padding: 15px 100px;\n  gap: 5px;\n  border-radius: 10px;\n  margin-bottom: 2rem;\n}\n\n.unsufficient_amount {\n  background-color: #f10505;\n  border: solid 1px #880c0c;\n}\n\n.sufficient_amount {\n  background-color: #03c619;\n  border: solid 1px #009111;\n}\n\n\n.exante_alert_box_text {\n  display: flex;\n  gap: 5px;\n  font-size: 17px;\n  font-weight: 800;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
