import React from 'react'
import './actifs.css'
import DashboardLayout from '../../components/layout/dashboard/DashboardLayout'
import { useTranslation } from 'react-i18next';


const Actifs = () => {

  const { t } = useTranslation();

  return (
   <DashboardLayout page={t('pages_name.actifs')}>
     <div>Actifs</div>
   </DashboardLayout>
  )
}

export default Actifs