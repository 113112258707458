import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchTickers = async (user, page, limit, search) => {
  try {
    const params = `?page=${page}&limitRedis=${limit}${search ? `&value=${search}` : ''}`
    const { data } = await axios.get(`${API_BASE_URL}/tickers/list${params}`,
      buildHeaders(user)
    )
    return data

  } catch (err) {
    throw err
  }
}

export const fetchTickersNotFound = async (user, page, limit, search) => {
  try {
    const params = `?page=${page}&limitRedis=${limit}${search ? `&value=${search}` : ''}`
    const { data } = await axios.get(`${API_BASE_URL}/tickers/tickersnotfound/list${params}`,
      buildHeaders(user)
    )
    return data
  } catch (err) {
    throw err
  }
}

export const searchNewTickers = async (user, ticker) => {
  try {
    const params = `?ticker=${ticker}`
    const { data } = await axios.get(`${API_BASE_URL}/tickers/search${params}`,
      buildHeaders(user)
    )
    return data
  } catch (err) {
    throw err
  }
}

export const createNewTicker = async (user, ticker) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/tickers/new`, ticker,
      buildHeaders(user)
    )
    return data
  } catch (err) {
    throw err
  }
}

export const editTickerName = async (user, ticker_id, ticker_name) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/tickers/${ticker_id}`, { name: ticker_name },
      buildHeaders(user)
    )
    return data
  } catch (error) {

  }
}

export const searchEtfs = async (user, search) => {
  try {
    const params = `?search=${search}`
    const { data } = await axios.get(`${API_BASE_URL}/tickers/etf/search${params}`,
      buildHeaders(user)
    )
    return data
  } catch (err) {
    throw err
  }
}

export const updateEtf = async (user, id, etf) => {
  try {
    const { data } = await axios.put(`${API_BASE_URL}/tickers/etf/${id}`, etf,
      buildHeaders(user)
    )
    return data
  } catch (err) {
    throw err
  }
}