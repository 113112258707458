import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import { useTranslation } from 'react-i18next';
import './truelayer.css'
import Notification from 'components/notifications/Notification';
import BankTransfer from 'components/BankTransfer/BankTransfer';
import { initiateTruelayerBankTransfer, truelayerVerifySmsCode } from 'services/bankTransferServices';


const Truelayer = () => {

  const { t } = useTranslation()

  return (
    <DashboardLayout page={t("pages_name.truelayer")}>
      <div>
        <Notification />
        <BankTransfer
          title={'Nouveau virement vers Truelayer'}
          initiatePayment={initiateTruelayerBankTransfer}
          verifySmsCode={truelayerVerifySmsCode}
        />
      </div>
    </DashboardLayout>
  )
}

export default Truelayer;