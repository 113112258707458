
import axios from "axios"
import { API_BASE_URL, buildHeaders } from "utils/services"

export const fetchCreatorCategories = async ({ user, page, limit }) => {
  try {
    const params = `?page=${page}&limit=${limit}`
    const { data } = await axios.get(`${API_BASE_URL}/creators/categories/list${params}`, buildHeaders(user));
    return data
  } catch (error) {
    throw error
  }
}

export const fetchCreatorsByCategory = async ({ user, page, limit, category_id }) => {
  try {
    const params = `?page=${page}&limit=${limit}`
    const { data } = await axios.get(`${API_BASE_URL}/creators/category/${category_id}/list${params}`, buildHeaders(user));
    return data
  } catch (error) {
    throw error
  }
}

export const fetchCategoryDetails = async ({ user, category_id }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/creators/category/${category_id}/details`, buildHeaders(user));
    return data
  } catch (error) {
    throw error
  }
}

export const addCreatorToCategory = async ({ user, categoryId, creatorId }) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/creators/listing`,
      { categoryId, creatorId },
      buildHeaders(user)
    );
    return data
  } catch (error) {
    throw error
  }
}

export const removeCreatorFromCategory = async ({ user, categoryId, creatorId }) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/creators/delisting`,
      { categoryId, creatorId },
      buildHeaders(user)
    );
    return data
  } catch (error) {
    throw error
  }
}