import axios from "axios";

export const fetchTradeGraph = async (user, periode, today) => {

    try {
        const getGraph = await axios.get(process.env.REACT_APP_BASE_URL + process.env.REACT_APP_VERSION +  "/dashboard/trades/graph?filter=" + periode + "&date="+ today, { 
            headers: { 
                "Content-Type": "application/json", 
                "Authorization": `Bearer ${user}` } 
        })
        
        return getGraph.data

    } catch (err) {

        console.log(err)
    }

}