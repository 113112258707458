import { useState, useEffect } from 'react'
import './SearchUser.css'

const SearchUser = ({ selectUser, selectedUser, users, handleSearch, label }) => {

  const [search, setSearch] = useState('');


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch(search)
    }, 300)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const clear = () => {
    setSearch('');
    selectUser({})
  }

  return (
    <div className='search_user_container'>
      {!selectedUser.uuid ? (
        <input
          type="text"
          placeholder={ label || 'Entrer un email'}
          className='input_selectedUser'
          onChange={(e) => setSearch(e.target.value)}
        />

      ) : (
        <div className={`${selectedUser?.uuid ? "search_user_selected" : "search_user_selected"} relative`}>
          <span onClick={() => selectUser(selectedUser)}>
            <p>{selectedUser.firstName} {selectedUser.lastName}</p>
            <p>({selectedUser.email})</p>
          </span>
          <div onClick={clear} className='delete_item_select'>x</div>
        </div>
      )}
      {!selectedUser.uuid && users.length > 0 && search.length > 0 && (
        <ul className='search_user_users_list_box'>
          {users.map((user, index) => (
            <li key={index} onClick={() => selectUser(user)} className="search_user_not_selected">
              <div className='search_user_item_selectMatchingUser'>
                <p>{user.firstName + " " + user.lastName}</p>
                <p>{user.email}</p>
                <p></p>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchUser