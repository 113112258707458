import React from 'react'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import './cadeaux.css'
import { useTranslation } from 'react-i18next';

const Cadeaux = () => {

    const { t } = useTranslation()
    return (
        <DashboardLayout page={t("pages_name.cadeaux")}>
            <div>
                Cadeaux
            </div>
        </DashboardLayout>
    )
}

export default Cadeaux