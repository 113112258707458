import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const initiateTruelayerBankTransfer = async (user, amount) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/accounts/truelayer/bank/transfer/sms`, { amount }, buildHeaders(user))
    return data;
  } catch (error) {
    throw error
  }
}

export const initiateAlpacaBankTransfer = async (user, amount) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/accounts/alpaca/bank/transfer/sms`, { amount }, buildHeaders(user))
    return data
  } catch (err) {
    throw err
  }
}

export const alpacaVerifySmsCode = async (user, code) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/accounts/alpaca/bank/transfer/verify`, { code }, buildHeaders(user))
    return data
  } catch (err) {
      console.log(err)
      throw err
  }
}

export const truelayerVerifySmsCode = async (user, code) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/accounts/truelayer/bank/transfer/verify`, { code }, buildHeaders(user))
    return data
  } catch (err) {
      throw err
  }
}