// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_flex_full{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.alpaca_container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.alpaca_item_box{
    background-color: #F2F3FC;
    padding: 10px 30px;
    border-radius: 16px;
}

.alpaca_item_header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.alpaca_opened_icon{
    font-size: 20px;
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/pages/actifs/subpages/Alpaca_root/Alpaca/alpaca.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;IACX,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".header_flex_full{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    width: 100%;\n    justify-content: space-between;\n}\n\n.alpaca_container{\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    margin-top: 20px;\n}\n\n.alpaca_item_box{\n    background-color: #F2F3FC;\n    padding: 10px 30px;\n    border-radius: 16px;\n}\n\n.alpaca_item_header{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.alpaca_opened_icon{\n    font-size: 20px;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
