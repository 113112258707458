import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import './alpacaRoot.css'
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import Alpaca from './Alpaca/Alpaca';
import AlpacaTwo from './AlpacaTwo/AlpacaTwo';
import AlpacaThree from './AlpacaThree/AlpacaThree';
import NewAlpacaTransfert from './NewAlpacaTransfert/NewAlpacaTransfert';
import AlpacaTransferts from './AlpacaTransferts/AlpacaTransferts';

const AlpacaRoot = () => {

  const { t } = useTranslation();

  const [tab, setTab] = useState(1)

  const getComponent = () => {
    switch (tab) {
      case 1:
        return <Alpaca />
      case 2:
        return <AlpacaTwo />
      case 3:
        return <AlpacaThree />
      case 4:
        return <NewAlpacaTransfert />
      case 5:
        return <AlpacaTransferts />
      default:
        return <Alpaca />
    }
  }

  return (
    <DashboardLayout page={t("pages_name.alpaca")}>
      <div>
        <div>
          <div className='transactions_switcher'>
            <div className={`transactions_tab ${tab === 1 ? "transactions_tab_select" : "transactions_tab_not_select"}`} onClick={() => { setTab(1) }}>Alpaca</div>
            <div className={`transactions_tab ${tab === 2 ? "transactions_tab_select" : "transactions_tab_not_select"}`} onClick={() => { setTab(2) }}>Alpaca 1</div>
            <div className={`transactions_tab ${tab === 3 ? "transactions_tab_select" : "transactions_tab_not_select"}`} onClick={() => { setTab(3) }}>Alpaca 2</div>
            <div className={`transactions_tab ${tab === 4 ? "transactions_tab_select" : "transactions_tab_not_select"}`} onClick={() => { setTab(4) }}>Nouveau virement</div>
            <div className={`transactions_tab ${tab === 5 ? "transactions_tab_select" : "transactions_tab_not_select"}`} onClick={() => { setTab(5) }}>Virements effectués</div>
          </div>
          {
            getComponent()
          }
        </div>

      </div>
    </DashboardLayout>
  )
}

export default AlpacaRoot