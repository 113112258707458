import { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import AuthContext from 'contexte/AuthContext';
import "./tickersNotFound.css"
import DashboardLayout from '../../../../components/layout/dashboard/DashboardLayout'
import { fetchTickersNotFound } from 'services/tickersServices';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';

const TickersNotFound = () => {

  const { t } = useTranslation();
  const { user } = useContext(AuthContext)

  const [loading, setLoading] = useState(true)
  const [tickersNotFound, setTickersNotFound] = useState([])
  const [page, setPage] = useState(0)
  const [fetchLimit, setFetchLimit] = useState(10)
  const [search, setSearch] = useState("")

  const fetchData = async () => {
    try {
      const data = await fetchTickersNotFound(user, page, fetchLimit, search)
      setTickersNotFound(data)
      setLoading(false)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchData()
  }, [fetchLimit, page, search])

  const transformDataToTbody = (data) => {
    return data.map((item) => {
      return {
        data: [
          item.ticker,
          item.searched
        ]
      }
    })
  }

  const resetFilter = () => {
    setFetchLimit(10)
  }

  return (
    <DashboardLayout page={t("pages_name.not_found")}>
      <div>
        {!loading ? (
          <div>
            <div className='externalDeposit_titleComponent'>
              <h2>Liste des Tickers non-trouvés</h2>
              <FilterComponent 
                limit={true}
                withSearch={true}
                fetchLimit={fetchLimit}
                setFetchLimit={setFetchLimit}
                search={search}
                setSearch={setSearch}
              />
            </div>

            <div className="table-container">
              <div className="table-column">
                <Tableau
                  thead={["Tickers", "Rehcerches"]}
                  tbody={transformDataToTbody(tickersNotFound)}
                  handleNextPage={() => { setPage(page + 1) }}
                  limit={fetchLimit}
                />
              </div>
            </div>
          </div>
        ) : (
          <div>Loading..</div>
        )}
      </div>
    </DashboardLayout>
  )
}

export default TickersNotFound