import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchAlpaca = async (user, page, limit) => {

    try {
        const getAlpaca = await axios.get(process.env.REACT_APP_BASE_URL + process.env.REACT_APP_VERSION + '/accounts/alpaca/ledgers?page=0&limit=10',
            buildHeaders(user)
        )

        return getAlpaca.data

    } catch (err) {

        console.error(err)
    }

}

export const fetchAlpacaThree = async (user, date) => {
    try {
        const options = {
            method: 'POST',
            url: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_VERSION + '/accounts/alpaca/net_summary',
            headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user },
            data: {
                reportDate: date?.toISOString()?.split('T')[0]
            }
        };

        const getAlpacaThree = await axios.request(options)
        const returnedData = getAlpacaThree.data

        return returnedData

    } catch (err) {
        return {};
    }
}


export const fetchAlpacaTwo = async (user, date) => {

    const fakeDate = "2023-11-02"

    try {
        const options = {
            method: 'POST',
            url: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_VERSION + '/accounts/alpaca/net_payment_final',
            headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user },
            data: {
                reportDate: fakeDate
            }
        };

        const getAlpacaTwo = await axios.request(options)
        const returnedData = getAlpacaTwo.data

        return returnedData

    } catch (err) {
        console.error(err)
        throw err
    }
}

export const fetchAlpacaPayments = async ({ user, page, limit, startDate, endDate }) => {
    try {
        const params = `?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
        const { data } = await axios.get(`${API_BASE_URL}/accounts/alpaca/payments/list${params}`,
            buildHeaders(user)
        )
        return data
    } catch (err) {
        console.error(err)
        throw err
    }
}