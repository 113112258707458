import { useState, useEffect } from 'react'
import './SearchStructuredProducts.css'

const SearchStructuredProducts = ({ setSelectedSp, selectedSp, structuredProducts, handleSearch, label }) => {

  const [search, setSearch] = useState('');

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch(search)
    }, 300)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const select = (ticker) => {
    setSelectedSp(ticker);
    setSearch('')
  }

  const clear = () => {
    setSearch('');
    setSelectedSp({})
  }

  return (
    <div className='search_sp_container'>
      {!selectedSp?._id ? (
        <input
          type="text"
          placeholder={label || 'Entrer le nom du produit'}
          className='search_sp_input_selectedSp'
          onChange={(e) => setSearch(e.target.value)}
        />

      ) : (
        <div className={`${selectedSp?._id ? "search_sp_selected" : "search_sp_selected"} search_sp_relative`}>
          <span onClick={() => select(selectedSp)}>
            <p>{selectedSp.name} ({selectedSp.products.map(({ ticker }) => ticker).join(', ')})</p>
          </span>
          <div onClick={clear} className='search_sp_delete_item_select'>x</div>
        </div>
      )}
      {!selectedSp?._id && structuredProducts.length > 0 && search?.length > 0 && (
        <ul className='search_sp_posts_list_box'>
          {structuredProducts.map((sp, index) => (
            <li key={index} onClick={() => select(sp)} className="search_sp_not_selected">
              <div className='search_sp_item_selectMatchingUser'>
                <p>{sp.name} ({sp.products.map(({ ticker }) => ticker).join(', ')})</p>
                <p></p>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchStructuredProducts