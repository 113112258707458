import { createContext, useState, useEffect } from "react";

const ResponseContext = createContext({})

export const ResponseProvider = ({ children }) => {

    const [ responseToaster, setResponseToaster ] = useState('')
    const [ errorToaster, setErrorToaster ] = useState('')
    const [ loadingToaster, setLoadingToaster ] = useState(false)

    useEffect(() => {
        if(responseToaster !== ''){
            setTimeout(() => {
                setResponseToaster('')
            }, 3000)
        }

        if(errorToaster !== ''){
            setTimeout(() => {
                setErrorToaster('')
            }, 3000)
        }
    }, [responseToaster, errorToaster])

    return <ResponseContext.Provider value={{ responseToaster, setResponseToaster, errorToaster, setErrorToaster, loadingToaster, setLoadingToaster  }}>{children}</ResponseContext.Provider>
}

export default ResponseContext