// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tbody:before {
    content:"@";
    display:block;
    line-height:10px;
    text-indent:-99999px;
}


.first_th{
  border-radius: 10px 0 0 10px;
}

.last_th{
  border-radius: 0 10px 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/finances/subpages/Comptes/comptes.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,gBAAgB;IAChB,oBAAoB;AACxB;;;AAGA;EACE,4BAA4B;AAC9B;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":["tbody:before {\n    content:\"@\";\n    display:block;\n    line-height:10px;\n    text-indent:-99999px;\n}\n\n\n.first_th{\n  border-radius: 10px 0 0 10px;\n}\n\n.last_th{\n  border-radius: 0 10px 10px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
