import './postsfilters.css'

const PostsFilters = ({
    typeFilter, setTypeFilter
}) => {
    return (
        <div className='postsFilterComponent'>
            <div>
                <input
                    type="radio"
                    name="type"
                    id="firstname"
                    checked={typeFilter === "firstname"}
                    onChange={() => {
                        setTypeFilter("firstname")
                    }}
                />
                <label htmlFor="firstname">Prénom</label>
            </div>
            <div>
            <input
                    type="radio"
                    name="type"
                    id="name"
                    checked={typeFilter === "name"}
                    onChange={() => {
                        setTypeFilter("name")
                    }}
                />
                <label htmlFor="name">Nom</label>
            </div>
            <div>
            <input
                    type="radio"
                    name="type"
                    id="all"
                    checked={typeFilter === "all"}
                    onChange={() => {
                        setTypeFilter("all")
                    }}
                />
                <label htmlFor="all">All</label>
            </div>
            
        </div>
    )
}

export default PostsFilters