// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profil_avatar{
    border-radius: 10px;
}

.profil_component{
    padding: 20px;
    background-color: #F2F3FC;
    width: min-content;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.profil_component_info{
    display: flex;
    gap: 20px;
    align-items: center;    
}

.profile_info{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.profile_name{
    font-size: 22px;
    font-weight: bold;
    margin: 0;
}

.profile_firstname{
    font-size: 15px;
    color: grey;
    margin: 0;
}

.profile_email{
    font-size: 18px;
    margin: 0;
}

.profil_logout{
    width: 100%;
    background-color: #2B64F5;
    padding: 12px 0;
    color: white;
    border-radius: 10px;
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/pages/profile/profil.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,SAAS;AACb;;AAEA;IACI,eAAe;IACf,WAAW;IACX,SAAS;AACb;;AAEA;IACI,eAAe;IACf,SAAS;AACb;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,eAAe;IACf,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":[".profil_avatar{\n    border-radius: 10px;\n}\n\n.profil_component{\n    padding: 20px;\n    background-color: #F2F3FC;\n    width: min-content;\n    border-radius: 10px;\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n}\n\n.profil_component_info{\n    display: flex;\n    gap: 20px;\n    align-items: center;    \n}\n\n.profile_info{\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n.profile_name{\n    font-size: 22px;\n    font-weight: bold;\n    margin: 0;\n}\n\n.profile_firstname{\n    font-size: 15px;\n    color: grey;\n    margin: 0;\n}\n\n.profile_email{\n    font-size: 18px;\n    margin: 0;\n}\n\n.profil_logout{\n    width: 100%;\n    background-color: #2B64F5;\n    padding: 12px 0;\n    color: white;\n    border-radius: 10px;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
