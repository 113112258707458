import axios from "axios";

import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchTradesVolume = async (user, limit, page, startDate, endDate) => {
    try {
        const params = `?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
        const { data } = await axios.get(`${API_BASE_URL}/rapports/trades/volume${params}`,
            buildHeaders(user));
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const fetchCardTradeDetails = async ({user, type, id}) => {
    try {
        const { data } = await axios.get(`${API_BASE_URL}/trades/details/${type}/${id}`,
            buildHeaders(user));
        return data;
    } catch (error) {
        throw error
    }
}