import PsLayout from "pages/structured/layout/PsLayout"
import "./recap.css"
import { useEffect, useState, useContext } from "react"
import AuthContext from "contexte/AuthContext"
import { useTranslation } from 'react-i18next';
import PageLoader from "components/pageLoader/PageLoader";
import { useNavigate } from "react-router-dom"
import { convertDraftToSp, fetchSpDraft } from "services/spDraftServices"
import Notification from "components/notifications/Notification";
import { launchError, launchSuccess } from "components/notifications";

const Recap = () => {

  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [product, setProduct] = useState({});


  const fetchActualData = async () => {
    try {
      const response = await fetchSpDraft(user, window.location.pathname.split('/')[2]);
      setProduct(response)
      setLoading(false)
    } catch (e) {
      console.log(e);
      setLoading(false)
      setError(e)
    }
  }

  useEffect(() => {
    fetchActualData();
  }, [])

  const getYieldFrequencyText = (frequency) => {
    switch (frequency) {
      case "monthly":
        return "Mensuel";
      case "annualy":
        return "Annuel";
      case "quarterly":
        return "Trimestriel";
      default:
        return "";
    }
  };

  const launchSp = async (e) => {
    e.preventDefault();
    try {
      await convertDraftToSp(user, product._id);
      launchSuccess(t('notifications.structured_products.convert.success'))
    } catch (err) {
      launchError(t('notifications.structured_products.convert.error'))
    }
  }


  return (
    <PsLayout>
      <Notification/>
      {
        loading ? (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        ) : (
          error ? (
            <div>Erreur</div>
          ) : (
            <form className="recap-page" onSubmit={launchSp}>
              <div className="recap-first">
                <div className="recap-first-left" style={{
                  backgroundImage: "url(" + product?.picture + ")",
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat'
                }}>
                  <div className="recap-first-left-absolute">
                    <p className="no-padding">{product?.name}</p>
                    <p className="no-padding">Par {product?.provider}</p>
                  </div>
                </div>
                <div className="recap-first-right">
                  <span className="recap-small-text">ISIN {product?.isin}</span>
                  <p className="no-padding product-title">{product?.name} - {product?.total}€</p>
                  <div className="recap-first-grid">
                    <div className="recap-first-grid-item">
                      <p className="no-padding recap-first-grid-title">Rendements</p>
                      <p className="no-padding recap-first-grid-data">{product?.yield}%</p>
                    </div>
                    <div className="recap-first-grid-item">
                      <p className="no-padding recap-first-grid-title">Versement</p>
                      <p className="no-padding recap-first-grid-data">{getYieldFrequencyText(product?.yield_frequency)}</p>
                    </div>
                    <div className="recap-first-grid-item">
                      <p className="no-padding recap-first-grid-title">Barrière</p>
                      <p className="no-padding recap-first-grid-data">{product?.barrier_capital}%</p>
                    </div>
                    <div className="recap-first-grid-item">
                      <p className="no-padding recap-first-grid-title">Durée</p>
                      <p className="no-padding recap-first-grid-data">{product?.maturity} mois</p>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="recap-first">
                <div className="recap-indices">
                  <p className="no-padding">Indices de référence</p>
                  {
                    product.products.map((ticker, index) => (
                      <div key={index} className="psform-ticker-selected-box pointer">
                        <div className="ticker-desc">
                          <img className="ticker-img" src={`https://cdn.goliaths.io/stocks/${ticker.ticker}.png`} alt="" />
                          <span className="psform-input-label">{ticker.name}</span>
                        </div>
                      </div >
                    ))
                  }
                </div>
                <div className="recap-first-right">
                  <div className="recap-user-box">
                    <img className="recap-user-pic" src={`https://cdn.goliaths.io/usr/pic/${product?.user?.uuid}`} alt="" />
                    <span className="recap-user-name">{product?.user?.name}</span>
                  </div>

                </div>
              </div>
              <hr />
              <div className="recap-text-box">
                <p className="no-padding">{product?.sub_description}</p>
                <div className="recap-desc-box">
                  {
                    product?.descriptions?.map((item, index) => (
                      <div className="recap-desc-item" key={index}>
                        <p className="no-padding recap-desc-title">{item.title}</p>
                        <p className="no-padding recap-desc-message">{item.message}</p>
                      </div>
                    )
                    )
                  }
                </div>
              </div>
              <div className="recap-text-box">
                <p className="no-padding recap-desc-title additional-infos">Infos supplémentaires</p>
                <div className="recap-desc-box">
                  {
                    product?.more_info?.map((item, index) => (
                      <div className="recap-desc-item" key={index}>
                        <p className="no-padding recap-desc-title">{item.title}</p>
                        <p className="no-padding recap-desc-message">{item.message}</p>
                      </div>
                    )
                    )
                  }
                </div>
              </div>
              <div className="psform-submit-box">
                <button type="submit" className="psform-submit">Convertir le brouillon</button>
              </div>
            </form>
          )
        )
      }
    </PsLayout >
  )
}

export default Recap