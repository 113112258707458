import { useTranslation } from 'react-i18next';
import { useState, useEffect, useContext } from 'react'
import './creators.css'
import AuthContext from 'contexte/AuthContext';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import Tableau from 'components/DataComponents/Tableau/Tableau';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';
import { fetchCreatorCategories } from 'services/creatorServices';
import PageLoader from 'components/pageLoader/PageLoader';

const Creators = () => {

  const { t } = useTranslation();
  const { user } = useContext(AuthContext)

  const [loading, setLoading] = useState(true)
  const [categories, setCategories] = useState([])
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)

  const fetchData = async () => {
    try {
      setLoading(true)
      const data = await fetchCreatorCategories({ user, page, limit })
      setCategories(data)
      setLoading(false)
    } catch (e) {
      console.log(e)
    }

  }

  useEffect(() => {
    fetchData()
  }, [page, limit])

  const transformDataToTbody = (data) => {
    return data?.map((item) => {
      return {
        data: [

          <div style={{ display: 'flex', alignItems: 'center', gap: "10px" }}>
            <img src={`https://fra1.digitaloceanspaces.com/tsscdn/categories/${item.image}`} alt={item.name} />
            <span style={{ fontWeight: "bold", fontSize: "15px" }}>{item.name}</span>
          </div>
        ],
        utils: {
          id: item._id
        }
      }
    })
  }


  const handleFetchLimit = (newLimit) => {
    setLimit(newLimit);
    setPage(0)
  }

  return (
    <DashboardLayout page={t("pages_name.categorie")}>
      <div>
        {!loading ? (
          <div>
            <div className='externalDeposit_titleComponent'>
              <h2>Liste des Catégories Créateurs</h2>
              <FilterComponent
                limit
                fetchLimit={limit}
                setFetchLimit={handleFetchLimit}
              />
            </div>

            <div className="table-container">
              <div className="table-column">
                <Tableau
                  thead={["Name"]}
                  tbody={transformDataToTbody(categories)}
                  pagination
                  limit={limit}
                  page={page}
                  setPage={setPage}
                  hadLink={true}
                  linkPath={'/users/creators/category/'}
                />
              </div>
            </div>
          </div>
        ) : (
          <PageLoader/>
        )}
      </div>
    </DashboardLayout>
  )
}

export default Creators