import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchStructuredProducts = async ({ user, page, limit, search}) => {
  try {
    let params = `?page=${page}&limit=${limit}${search ? `&search=${search}` : ''}`
    const { data } = await axios.get(`${API_BASE_URL}/structured-products/list${params}`,
      buildHeaders(user)
    );
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchRegisteredUsers = async ({ user, page, limit, productId }) => {
  try {
    let params = `?page=${page}&limit=${limit}`
    const { data } = await axios.get(`${API_BASE_URL}/structured-products/${productId}/registered-users${params}`,
      buildHeaders(user)
    );
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchInvestors = async ({ user, page, limit, productId }) => {
  try {
    let params = `?page=${page}&limit=${limit}`
    const { data } = await axios.get(`${API_BASE_URL}/structured-products/${productId}/investors${params}`,
      buildHeaders(user)
    );
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchStructuredProductDetails = async ({ user, productId }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/structured-products/${productId}/details`,
      buildHeaders(user));
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchStructuredProductsTrades = async ({ user, page, limit, startDate, endDate }) => {
  try {
    const params = `?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
    const { data } = await axios.get(`${API_BASE_URL}/structured-products/trades${params}`,
      buildHeaders(user)
    );
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchStructuredProductGraphData = async ({ user, productId }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/structured-products/${productId}/graph`,
      buildHeaders(user));
    return data;
  } catch (error) {
    throw error
  }
}

export const generateSpReport = async ({ user, productId }) => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/structured-products/${productId}/report`, {},
      buildHeaders(user));
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchSpReport = async ({ user, productId }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/structured-products/${productId}/report`,
      buildHeaders(user));
    return data;
  } catch (error) {
    throw error
  }
}
