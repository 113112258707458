import PsLayout from "pages/structured/layout/PsLayout"
import { useEffect, useState, useContext } from "react"
import AuthContext from "contexte/AuthContext"
import PageLoader from "components/pageLoader/PageLoader";
import { useNavigate } from "react-router-dom"
import "../builder.css"
import { fetchSpDraft, saveSpDraft } from "services/spDraftServices"

const PsFinances = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [product, setProduct] = useState({});

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(product);
    try {
      const response = await saveSpDraft(user, window.location.pathname.split('/')[2], product);
      navigate(`/structured-products/${window.location.pathname.split('/')[2]}/sous-jaccent`)

    } catch (err) {
      console.log(err);
      setError(err)
    }
  }

  const fetchActualData = async () => {
    try {
      const response = await fetchSpDraft(user, window.location.pathname.split('/')[2]);
      setProduct({
        ...response,
        yield_frequency: response.yield_frequency || 'monthly',
        currency: response.currency || 'EUR',
        provider: response.provider || 'Goliaths'
      })
      setLoading(false)
    } catch (e) {
      console.log(e);
      setLoading(false)
      setError(e)
    }
  }

  useEffect(() => {
    fetchActualData();
  }, [])

  const handleInputChange = (e) => {
    const value = e.target.type === 'number' ? Number(e.target.value) : e.target.value;
    console.log(e.target, value)
    setProduct({
      ...product,
      [e.target.name]: e.target.value
    })
  }

  const getDate = (value) => value?.split('T')?.[0]

  return (
    <PsLayout>
      {
        loading ? (
          <div className='pageLoaderBox'>
            <PageLoader />
          </div>
        ) : (
          error ? (
            <div>Erreur</div>
          ) : (
            <form onSubmit={handleSubmit} className="psform">
              <div className="psform-box-flex">
                <div className="psform-input-box">
                  <span className="psform-input-label">Total à financer :</span>
                  <input className="psform-input-text" type="number" name="total" defaultValue={product.total} onChange={handleInputChange} />
                </div >
                <div className="psform-input-box">
                  <span className="psform-input-label">Currency</span>
                  <select name="currency" id="currency" className="psform-input-text" defaultValue={product.currency} onChange={handleInputChange}>
                    <option value="EUR">EUR</option>
                    <option value="USD">USD</option>
                  </select>
                </div>
              </div>
              <div className="psform-box-flex">
                <div className="psform-input-box">
                  <span className="psform-input-label">Maturité :</span>
                  <div>
                    <input className="psform-input-text" type="number" name="maturity" defaultValue={product.maturity} onChange={handleInputChange} />
                    <span>mois</span>
                  </div>
                </div >
                <div className="psform-input-box">
                  <span className="psform-input-label">Date de maturité :</span>
                  <input className="psform-input-text" type="date" name="maturity_date" defaultValue={getDate(product.maturity_date)} onChange={handleInputChange} />

                </div >
                <div className="psform-input-box">
                  <span className="psform-input-label">Rendements :</span>
                  <div>
                    <input className="psform-input-text" type="number" name="yield" step="0.1" defaultValue={product.yield} onChange={handleInputChange} />
                    <span>%</span>
                  </div>
                </div >
                <div className="psform-input-box">
                  <span className="psform-input-label">Fréquence :</span>
                  <select name="yield_frequency" id="yield_frequency" className="psform-input-text" defaultValue={product.yield_frequency} onChange={handleInputChange}>
                    <option value="">-----</option>
                    <option value="monthly">Mensuel</option>
                    <option value="annualy">Annuel</option>
                    <option value="quarterly">Trimestriel</option>
                  </select>
                </div>
              </div>
              <div className="psform-input-box">
                <span className="psform-input-label">Provider :</span>
                <select name="provider" id="provider" className="psform-input-text" defaultValue={product.provider} onChange={handleInputChange}>
                  <option value="Goliaths">Goliaths</option>
                  <option value="Marex">Marex</option>
                  <option value="BNP PARIBAS">BNP PARIBAS</option>
                </select>
              </div>
              <div className="psform-submit-box">
                <button type="submit" className="psform-submit">Sauvegarder et continuer</button>
              </div>
            </form >
          )
        )
      }
    </PsLayout>
  )
}

export default PsFinances