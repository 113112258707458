import './ordersFilter.css'
import { useTranslation } from 'react-i18next'


const OrdersFilter = ({
    isPurchase, setIsPurchase,
    isSale, setIsSale,
    isCanceled, setIsCanceled,
    isOnHold, setIsOnHold,
    hideCancel, hideOnHold
}) => {

    const { t } = useTranslation();

    const handlePurchase = (e) => {
        if (e.target.checked || (!e.target.checked && isSale))
            setIsPurchase(e.target.checked)
    }

    const handleSale = (e) => {
        if (e.target.checked || (!e.target.checked && isPurchase))
            setIsSale(e.target.checked)
    }

    const handleOnHold = (e) => {
        if (e.target.checked && isCanceled) {
            setIsCanceled(prev => !prev)
        }
        setIsOnHold(prev => !prev)


    }

    const handleCanceled = (e) => {
        if (e.target.checked && isOnHold) {
            setIsOnHold(prev => !prev)
        }
        setIsCanceled(prev => !prev)

    }

    return (
        <div className='ordersFilterComponent'>
            <div>
                <input
                    type="checkbox"
                    id="isPurchase"
                    checked={isPurchase}
                    onChange={handlePurchase}
                />
                <label htmlFor="isPurchase">{t('filters.orders.purchase')}</label>
            </div>
            <div>
                <input
                    type="checkbox"
                    id="isSale"
                    checked={isSale}
                    onChange={handleSale}
                />
                <label htmlFor="isSale">{t('filters.orders.sale')}</label>
            </div>
            {!hideOnHold && <div>
                <input
                    type="checkbox"
                    id="isOnHold"
                    checked={isOnHold}
                    onChange={handleOnHold}
                />
                <label htmlFor="isOnHold">{t('filters.orders.on_hold')}</label>
            </div>}
            {!hideCancel && <div>
                <input
                    type="checkbox"
                    id="isCanceled"
                    checked={isCanceled}
                    onChange={handleCanceled}
                />
                <label htmlFor="isCanceled">{t('filters.orders.canceled')}</label>
            </div>}
        </div>
    )
}

export default OrdersFilter