import './LimitSelector.css'

const LimitSelector = ({ fetchLimit, setFetchLimit, resetFilter, noReset, options }) => {

    return (
        <div className="limitSelector_box">

            <select className="custom-select" value={fetchLimit} onChange={(e) => { setFetchLimit(e.target.value) }}>
                {
                    options ? options.map(({ value, display }) => (
                        <option key={value} value={value}>{display}</option>

                    )) :
                        <>
                            <option key={50} value={50}>50</option>
                            <option key={20} value={20}>20</option>
                            <option key={10} value={10}>10</option>
                            <option key={5} value={5}>5</option>
                            <option key={2} value={2}>2</option>
                        </>
                }
            </select>


            {
                !noReset && fetchLimit !== 10 ? (
                    <div>
                        <button onClick={resetFilter} className='delete_filters'>Supprimer</button>
                    </div>
                ) : (<></>)
            }

        </div>
    )
}

export default LimitSelector