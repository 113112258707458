// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal_user_leverage_transactions_text {
  font-weight: 600;
  font-size: 17px;
}`, "",{"version":3,"sources":["webpack://./src/pages/users/userById/UserDataArray/UserLeverageTransactions/userLeverageTransactions.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".modal_user_leverage_transactions_text {\n  font-weight: 600;\n  font-size: 17px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
