import axios from "axios";
import { API_BASE_URL, buildHeaders } from "utils/services";

export const fetchInvestmentPlansByDate = async ({user, startDate, endDate, timeframe}) => {
  try {
    const params = `?startDate=${startDate}&endDate=${endDate}&timeframe=${timeframe}`
    const { data } = await axios.get(`${API_BASE_URL}/investments/date${params}`,
      buildHeaders(user)
    );
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchInvestmentPlansForecast = async ({user, startDate, endDate}) => {
  try {
    const params = `?startDate=${startDate}&endDate=${endDate}`
    const { data } = await axios.get(`${API_BASE_URL}/investments/forecast${params}`,
      buildHeaders(user)
    );
    return data;
  } catch (error) {
    throw error
  }
}