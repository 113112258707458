import React, { useContext } from 'react'
import './trades.css'
import { useTranslation } from 'react-i18next';
import AuthContext from "contexte/AuthContext"
import DashboardLayout from '../../../components/layout/dashboard/DashboardLayout'
import DownloaderLink from 'components/DownloaderLink/DownloaderLink';
import { fetchReportTradeList } from 'services/fetchTradeReports';
import { getDateFilenameFormatted } from 'utils/date';
import { Link } from 'react-router-dom'

const Trades = () => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  return (
    <DashboardLayout page={t("pages_name.report_trade")}>
      <div className='externalDeposit_titleComponent'>
        <h2>{t("pages.report_trade.title")}</h2>
      </div>
      <div>
        <ul>
          <DownloaderLink
            filename={`report_trades_${getDateFilenameFormatted()}.xlsx`}
            fetchData={fetchReportTradeList}
            fetchDataParams={{ user }}
            type={'excel'}
            display={'Liste des trades (Excel)'}
          />
          <Link to="/reports/trade/volume-de-trade">Volume de trade</Link>
          <Link to="/reports/trade/waiting-trade-volume">Volume de trade en attente</Link>
          <Link to="/reports/trade/volume-per-stock-list">Volume de trade par action</Link>
          <Link to="/reports/trade/volume-per-stock">Volume de trade par action  (d/m/y)</Link>
          <Link to="/reports/trade/volume-per-commodity">Volume de trade par commodity</Link>
          <Link to="/reports/trade/volume-per-commodity-timeframe">Volume de trade par commodity (d/m/y)</Link>
          <Link to="/reports/trade/in-trade">Volume en cours de trade</Link>
          <Link to="/reports/trade/available">Argent disponible non tradé</Link>
          <Link to="/reports/trade/card-fees">Frais CB (Gains/Pertes)</Link>
        </ul>
      </div>
    </DashboardLayout>
  )
}

export default Trades
