import { useTranslation } from 'react-i18next';
import { useState, useEffect, useContext } from 'react'
import './categories.css'
import AuthContext from 'contexte/AuthContext';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout'
import { fetchCategories, changeCategorieOrder } from 'services/categoriesServices';
import Tableau from 'components/DataComponents/Tableau/Tableau';
import FilterComponent from 'components/Filters/FilterComponent/FilterComponent';

const Categories = () => {

  const { t } = useTranslation();
  const { user } = useContext(AuthContext)

  const [loading, setLoading] = useState(true)
  const [categories, setCategories] = useState([])
  const [search, setSearch] = useState("")

  const fetchData = async () => {

    try {
      const getCategorieData = await fetchCategories(user, search)
      setCategories(getCategorieData)
      setLoading(false)
    } catch (e) {
      console.log(e)
    }

  }

  useEffect(() => {
    fetchData()
  }, [search])

  const changeOrder = async (id, direction, sort) => {
    console.log(id, direction, sort);
    const changeItemOrder = await changeCategorieOrder(user, id, direction, sort)
    console.log(changeItemOrder);
    fetchData()
  }

  const transformDataToTbody = (data) => {
    return data?.map((item) => {
      return {
        data: [

          <div style={{ display: 'flex', alignItems: 'center', gap: "10px" }}>
            <img src={`https://fra1.digitaloceanspaces.com/tsscdn/categories/${item.image}`} alt={item.name} />
            <span style={{ fontWeight: "bold", fontSize: "15px" }}>{item.name}</span>
          </div>,
          <div className='positionBlock'>
            <span onClick={() => { changeOrder(item._id, "down", item.sort) }} className="changePositionButton" >down</span>
            <span>{item.sort}</span>
            <span onClick={() => { changeOrder(item._id, "up", item.sort) }} className="changePositionButton" >up</span>
          </div>
        ],
        utils: {
          id: item._id
        }
      }
    })
  }

  return (
    <DashboardLayout page={t("pages_name.categorie")}>
      <div>
        {!loading ? (
          <div>
            <div className='externalDeposit_titleComponent'>
              <h2>Liste des Catégories</h2>
              <FilterComponent
                withSearch={true}
                search={search}
                setSearch={setSearch}
              />
            </div>

            <div className="table-container">
              <div className="table-column">
                <Tableau
                  thead={["Name", "Ordre"]}
                  tbody={transformDataToTbody(categories)}
                  hadLink
                  linkPath={'/actifs/category/'}
                />
              </div>
            </div>
          </div>
        ) : (
          <div>Loading..</div>
        )}
      </div>
    </DashboardLayout>
  )
}

export default Categories