import axios from "axios";

export const fetchCardTransactions = async ({ user, type, page, limit, startDate, endDate }) => {

    try {
        const options = {
            method: 'POST',
            url: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_VERSION + "/monitoring/card-transactions/list",
            headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + user },
            data: {
                type,
                page: 0,
                limit,
                startDate,
                endDate,
                pageCardTxs: page
            }
        };

        const transactionsData = await axios.request(options)
        const returnedData = transactionsData.data

        return returnedData
        
    } catch(err) {
        console.log(err)
    };

}